<template>
  <div v-if="value" class="edit-textfield">
    <div class="row" v-if="!multilng">
      <div class="col-md-12">
        <input
          type="text"
          v-model="value.NL"
          :maxlength="value.MaxLength"
          v-if="!rtf && (!disabled || disabled == false)"
        />
        <input
          type="text"
          v-model="value.NL"
          :maxlength="value.MaxLength"
          v-if="!rtf && disabled && disabled == true"
          disabled="true"
        />

        <button
          v-if="rtf"
          @click="insertLink('editNL1' + name)"
          :id="'editNL1' + name + '_il'"
          v-show="false"
        >
          Bestand uploaden
        </button>
        <editor
          :id="'editNL1' + name"
          :ref="'editNL1' + name"
          v-if="rtf"
          v-model="value.NL"
          api-key="2ezt8rse2ncudk7zfc8o8q3k3vf6o5hd7dw9wtrz8rx9p9ip"
          :init="editoroptions"
        ></editor>
      </div>
    </div>

    <div class="row" v-if="multilng">
      <div class="col-md-1 lnglabel">NL</div>
      <div class="col-md-11">
        <input
          type="text"
          v-model="value.NL"
          :maxlength="value.MaxLength"
          v-if="!rtf && (!disabled || disabled == false)"
        />
        <input
          type="text"
          v-model="value.NL"
          :maxlength="value.MaxLength"
          v-if="!rtf && disabled && disabled == 'true'"
          disabled="true"
        />
        <button
          v-if="rtf"
          @click="insertLink('editNL2' + name)"
          :id="'editNL2' + name + '_il'"
          v-show="false"
        >
          Bestand uploaden
        </button>
        <editor
          :id="'editNL2' + name"
          :ref="'editNL2' + name"
          v-if="rtf"
          v-model="value.NL"
          api-key="2ezt8rse2ncudk7zfc8o8q3k3vf6o5hd7dw9wtrz8rx9p9ip"
          :init="editoroptions"
        ></editor>
      </div>
    </div>
    <div class="row" v-if="multilng">
      <div class="col-md-1 lnglabel">EN</div>
      <div class="col-md-11">
        <input
          type="text"
          v-model="value.EN"
          :maxlength="value.MaxLength"
          v-if="!rtf"
        />
        <button
          v-if="rtf"
          @click="insertLink('editEN2' + name)"
          :id="'editEN2' + name + '_il'"
          v-show="false"
        >
          Bestand uploaden
        </button>
        <editor
          :id="'editEN2' + name"
          :ref="'editEN2' + name"
          v-if="rtf"
          v-model="value.EN"
          api-key="2ezt8rse2ncudk7zfc8o8q3k3vf6o5hd7dw9wtrz8rx9p9ip"
          :init="editoroptions"
        ></editor>
      </div>
    </div>
    <!-- Zichtbaarheid configureerbaar  -->
    <div class="row zichtbaarheid" v-if="value.Visibility">
      <div class="col-md-12">
        <span v-if="$root.lng == 'NL'">Zichtbaarheid</span>
        <span v-if="$root.lng == 'EN'">Visibility</span>
        <select
          class="select-button"
          v-if="$root.lng == 'NL'"
          v-model="value.Visibility"
        >
          <option value="1">Publiek</option>
          <option value="2">Alleen binnen UU</option>
          <option value="3">Niet tonen</option>
        </select>
        <select
          class="select-button"
          v-if="$root.lng == 'EN'"
          v-model="value.Visibility"
        >
          <option value="1">Public</option>
          <option value="2">Within UU only</option>
          <option value="3">Not visible</option>
        </select>
      </div>
    </div>

    <popup
      ref="popupLink"
      @okclick="saveLink()"
      titleNL="Insert file/image"
      titleEN="Insert File"
    >
      <form>
        <div>
          <b>Documents:</b>
        </div>
        <div v-for="l in links_doc" :key="l.Guid">
          <input
            type="radio"
            v-model="selectedlink"
            :value="l"
            name="link"
            :id="'input' + l.Id"
          />&nbsp;<label :for="'input' + l.Id">{{ l.Name }}</label>
          <iconbutton
            icon="trash"
            titleNL="Verwijder bestand"
            titleEN="Delete file"
            @click="deleteFile(l)"
            class="deleteButton"
            v-show="l.CanDelete"
          />
        </div>
        <div>
          <b>Images:</b>
        </div>
        <div v-for="i in links_image" :key="i.Guid">
          <input
            type="radio"
            v-model="selectedlink"
            :value="i"
            name="link"
            :id="'input' + i.Id"
          />&nbsp;<label :for="'input' + i.Id">{{ i.Name }}</label>
          <iconbutton
            icon="trash"
            titleNL="Verwijder afbeelding"
            titleEN="Delete image"
            @click="deleteFile(i)"
            class="deleteButton"
            v-show="i.CanDelete"
          />
        </div>
        <div v-show="links == null || links.length == 0">
          Nog geen bestanden aanwezig.
        </div>
        <div>
          <b>
            <label-lng
              NL="Upload afbeelding of document (max. 5 MB)"
              EN="Upload image or document (max 5 MB)"
            />
          </b>
        </div>
        <div>
          <input
            :id="'uploadNewFile' + name"
            type="file"
            style="display: none"
            v-on:change="onUploadFile($event)"
            accept=".pdf, .docx, .png, .jpg, .jpeg, .gif"
          />
          <button
            type="button"
            class="uu-button button-black"
            @click="selecteerBestand"
          >
            Zoek bestand
          </button>
        </div>
        <div class="info" v-show="false">
          <label-lng
            NL="Items die je hier niet kan verwijderen zijn te beheren via de CV tab en Afbeeldingen op de Profiel tab."
            EN="Items die je hier niet kan verwijderen zijn te beheren via de CV tab en Afbeeldingen op de Profiel tab."
          />
        </div>
      </form>
    </popup>
  </div>
</template>

<script>
import EditorTiny from "@tinymce/tinymce-vue";
import popup from "@/components/layout/popup.vue";
import iconbutton from "@/components/layout/iconbutton.vue";
import labellng from "@/components/layout/label-lng.vue";

export default {
  name: "edit-textfield",
  props: ["value", "multilng", "rtf", "disabled", "name"],
  components: {
    popup: popup,
    editor: EditorTiny,
    iconbutton: iconbutton,
    "label-lng": labellng,
  },
  data() {
    return {
      links: [],
      selectedlink: null,
      editor: null,
      editoroptions: {
        menubar: false,
        // statusbar: false,
        plugins: "table code lists link image media autoresize paste",
        toolbar:
          "undo redo | code | formatselect | bold italic underline superscript subscript | bullist numlist outdent indent align | media link customInsertButton | table ",
        bottom_margin: 2,
        max_height: 300,
        min_height: 200,
        block_formats: "Paragraph=p;Header 1=h1;Header 2=h2;Header 3=h3",
        paste_enable_default_filters: false,
        paste_word_valid_elements:
          "b,strong,i,em,h1,h2,ul,li,ol,table,tr,td,th,tbody,thead",
        paste_as_text: true,
        paste_data_images: false,
        relative_urls: false,
        remove_script_host: false,
        // document_base_url : "https://a-www.uu.nl/",
        images_upload_url:
          this.$http.options.root +
          "/edit/TinyUploadFile?page=" +
          this.$route.params.employee,

        setup: function (editor) {
          // Custom button toevoegen voor aanmaken link

          editor.ui.registry.addButton("customInsertButton", {
            text: "Insert file/image",
            onAction: function () {
              // Hier geen toegang tot 'this' van vuejs, dus via onzichtbare button dan maar....
              document.getElementById(editor.id + "_il").click();
            },
          });
        },
      },
    };
  },
  methods: {
    saveLink: function () {
      if (this.selectedlink != null) {
        if (this.selectedlink.IsImage) {
          this.editor.insertContent(
            '<img src="' + this.selectedlink.Url + '" width="300" />'
          );
        } else {
          this.editor.insertContent(
            '<a href="' +
              this.selectedlink.Url +
              '" target="_blank">' +
              this.selectedlink.Name +
              "</a>"
          );
        }
      }
    },

    insertLink: function (refeditor) {
      this.editor = this.$refs[refeditor].editor;
      this.selectedlink = null;
      var token = window.localStorage.getItem("token");
      this.$http
        .get(
          "Edit/GetUserFiles?page=" +
            this.$route.params.employee +
            "&l=" +
            this.$root.lng +
            "&t=" +
            token
        )
        .then(function (response) {
          this.links = response.data;
          this.$refs.popupLink.show({}, this.links, this);
        });
    },

    onUploadFile: function (event) {
      var files = event.target.files || event.dataTransfer.files;
      if (!files.length) return;

      this.uploadFile(files[0]);
    },

    uploadFile: function (f) {
      var reader = new FileReader();
      var self = this;

      reader.addEventListener(
        "load",
        function () {
          var contents = JSON.stringify(reader.result);
          var token = window.localStorage.getItem("token");
          self.$http
            .post("Edit/UploadUserFile?t=" + token, {
              Filename: f.name,
              Content: contents,
              Page: self.$route.params.employee,
            })
            .then(
              function (response) {
                if (response.data === "") {
                  alert(
                    "Maximale grootte voor bestand is 5 MB. / Maxium file size is 5 MB."
                  );
                  return;
                }
                self.links.push(response.data);
                self.selectedlink = response.data;
              },
              function () {}
            );
        },
        false
      );

      reader.readAsDataURL(f);
    },
    selecteerBestand: function () {
      document.getElementById("uploadNewFile" + this.name).click();
    },
    deleteFile: function (f) {
      if (
        confirm(
          this.$root.lng === "NL"
            ? "Weet u zeker dat u dit bestand wilt verwijderen?"
            : "Delete selected file?"
        )
      ) {
        this.$http.post("Edit/DeleteUserFile?id=" + f.Id.toString()).then(
          function (response) {
            if (response.data.Success) {
              var i = this.links.indexOf(f);
              if (i !== -1) this.links.splice(i, 1);
            }
          },
          function () {}
        );
      }
    },
  },

  computed: {
    links_doc: function () {
      return this.links.filter(function (el) {
        return !el.IsImage;
      });
    },
    links_image: function () {
      return this.links.filter(function (el) {
        return el.IsImage;
      });
    },
  },

  created: function () {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.caption {
  font-weight: bold;
}

.edit-textfield {
  margin-bottom: 10px;
}

.edit-textfield > div {
  margin-bottom: 5px;
}

.lnglabel {
  text-align: right;
}

input[type="text"] {
  width: 100%;
}

editEN {
  width: 100%;
  height: 200px;
}

.zichtbaarheid {
  margin-top: 20px;
  margin-right: 20px;
}

.select-button {
  margin-left: 10px;
}

.deleteButton {
  float: right;
}
.info {
  font-size: 8pt;
  background-color: #d7d7d7;
  line-height: 1.5;
  margin-top: 10px;
  margin-left: -15px;
  margin-right: -15px;
  padding: 5px 15px 5px 15px;
}
</style>

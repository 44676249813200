<template>
  <admin-layout title="Overzicht OBP-medewerkers niet gereageerd op welkomstmail">
    <div class="row">
      <div class="col-md-2">Faculteit</div>
      <div class="col-md-10">
        <select v-model="facultyId" class="inputfilter">
          <option v-for="f in faculties" :value="f.FacultyId" :key="f.Guid">{{f.Faculty}}</option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="col-md-2">Organisatieonderdeel</div>
      <div class="col-md-10">
        <select v-model="departmentId" class="inputfilter">
          <option v-for="d in departments" :value="d.DepartmentId" :key="d.Guid">{{d.Department}}</option>
        </select>
      </div>
    </div>
    <br />

    <table class="table table-sm table-hover">
      <thead>
        <tr>
          <th></th>
          <th>Medewerker</th>
          <th>SolisID</th>
          <th>E-mail</th>
          <th>Toestemming gevraagd op</th>
          <th>Acties</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="r in rapportage" :key="r.Guid">
          <td>
            <input type="checkbox" v-model="r.Checked" />
          </td>
          <td>{{r.Name}}</td>
          <td>{{r.SolisID}}</td>
          <td>{{r.Email}}</td>
          <td>{{r.Date}}</td>
          <td>
            <a
              href="#"
              onclick="return false;"
              @click="reminder(r);"
              v-show="r.Result==null"
            >Reminder sturen</a>
            <img
              src="../../assets/green.png"
              v-show="r.Result=='Ok'"
              title="Reminder is verstuurd naar medewerker"
            />
            <img
              src="../../assets/red.png"
              v-show="r.Result != null && r.Result != 'Ok'"
              :title="r.Result"
            />
          </td>
        </tr>
      </tbody>
    </table>
    <a href="#" @click="all(true)" onclick="return false;">Alles selecteren</a>
    <a href="#" onclick="return false;" @click="all(false)">Niets selecteren</a>
    <br />
    <br />
    <a
      href="#"
      @click="sendAll()"
      onclick="return false;"
    >Stuur reminder naar geselecteerde medewerkers</a>
  </admin-layout>
</template>

<script>
import AdminLayout from "@/components/layout/admin-layout.vue";

export default {
  name: "BeheerRapportOBP",
  components: {
    "admin-layout": AdminLayout
  },
  data() {
    return {
      rapportage: [],
      facultyId: null,
      faculties: [],
      departmentId: null,
      departments: []
    };
  },
  methods: {
    loaddata: function() {
      if (this.facultyId != null && this.departmentId != null) {
        this.$Progress.start();
        var department = this.departmentId.toString();

        this.$http
          .get(
            "Admin/RapportageOBP?faculty=" +
              this.facultyId.toString() +
              "&department=" +
              department
          )
          .then(
            function(response) {
              this.rapportage = response.data;
              this.$Progress.finish();
            },
            function() {
              // Fail
              this.$Progress.finish();
            }
          );
      }
    },
    all: function(v) {
      for (var i = 0; i < this.rapportage.length; i++) {
        this.rapportage[i].Checked = v;
      }
    },
    reminder: function(r) {
      this.$Progress.start();
      this.$http.post("Admin/RapportageOBPReminder", r).then(
        function(response) {
          if (response.data.Success) {
            r.Result = "Ok";
          } else {
            r.Result = response.data.Message;
          }
          this.$Progress.finish();
        },
        function() {
          // Fail
          r.Result = "Aanroep verzendfunctie mislukt.";
          this.$Progress.finish();
        }
      );
    },
    sendAll: function() {
      for (var i = 0; i < this.rapportage.length; i++) {
        if (this.rapportage[i].Checked) {
          this.reminder(this.rapportage[i]);
        }
      }
    }
  },
  created: function() {
    this.$http.get("Admin/GetAdminFaculties").then(function(response) {
      this.faculties = response.data;
      if (this.faculties.length > 0) {
        this.facultyId = this.faculties[0].FacultyId;
      }
    });
  },
  watch: {
    facultyId: function() {
      this.$http
        .get(
          "Public/GetDepartments?l=" +
            this.$root.lng +
            "&f=" +
            this.facultyId.toString()
        )
        .then(function(response) {
          this.departments = response.data;
          this.departments.splice(0, 0, {
            DepartmentId: 0,
            Department: "<Hele faculteit>"
          });
          if (this.departments.length > 0) {
            this.departmentId = this.departments[0].DepartmentId;
          } else {
            this.loaddata();
          }
        });
    },
    departmentId: function() {
      this.loaddata();
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.iconcolumn {
  width: 30px;
}

.inputfilter {
  width: 500px;
}
</style>

<template>
  <div v-if="medewerker" class="TabProfile public-tab-content">
    <!-- Profieltekst -->
    <div class="row" v-if="medewerker.Profile">
      <div class="col-md-12">
        <span v-html="medewerker.Profile" class="rtffield" />
      </div>
    </div>
    <textfield nameNL nameEN v-if="medewerker.Images && medewerker.Images.length > 0">
      <div v-for="i in medewerker.Images" :key="i.Id">
        <img :src="$http.options.root + i.ImageUrl + token" :title="i.ImageText" />
        <br />
        {{i.ImageText}}
      </div>
    </textfield>
    <textfield nameNL="Links" nameEN="Links" v-if="medewerker.Links && medewerker.Links.length > 0">
      <ul>
        <li v-for="l in medewerker.Links" :key="l.Guid">
          <a :href="l.Url" target="_blank" :title="l.Url" class="external">{{l.Name}}</a>
        </li>
      </ul>
    </textfield>
    <textfield nameNL="Leeropdracht" nameEN="Chair" v-if="medewerker.Chair">{{medewerker.Chair}}</textfield>
    <textfield
      nameNL="Leerstoel van"
      nameEN="Endowed by"
      v-if="medewerker.EndowedBy"
    >{{medewerker.EndowedBy}}</textfield>
    <textfield
      nameNL="Aanstellingsdatum"
      nameEN="Date of Appointment"
      v-if="medewerker.DateOfAppointment"
    >{{formatdate(medewerker.DateOfAppointment)}}</textfield>
    <textfield
      nameNL="Oratiedatum"
      nameEN="Inaugural lecture date"
      v-if="medewerker.DateInauguralLecture"
    >{{formatdate(medewerker.DateInauguralLecture)}}</textfield>
  </div>
</template>

<script>
import textfield from '@/components/layout/textfield.vue'
import datehelper from '@/helpers/date-helper.vue'

export default {
  name: 'tab-profile',
  data () {
    return {
      token: window.localStorage.getItem('token')
    };
  },
  props: ['medewerker'],
  components: {
    textfield: textfield,
  },
  mixins: [datehelper]
};
</script>

<style scoped>
.link-image {
  height: 24px;
  width: 24px;
  padding-bottom: 25px;
}
</style>

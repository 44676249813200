<template>
  <simple-layout :title="pagetitle">
    <div v-if="success">
      <div v-if="$route.query.akkoord == '1'">
        Hartelijk dank voor uw toestemming!
        <br />
        <br />Uw profielpagina is nu vindbaar op de website van de Universiteit Utrecht, zie
        <a
          :href="'/medewerkers/' + url"
          class="link"
        >uu.nl/medewerkers/{{url}}</a>.
        <br />Daar vindt u ook een link om de inhoud te bewerken (klik rechtsboven op Bewerken).
        <br />
        <br />
        <br />
        <br />Thank you very much for granting permission!
        <br />
        <br />Your profile page is now published on the website of Utrecht University, see
        <a
          :href="'/staff/' + url"
          class="link"
        >uu.nl/staff/{{url}}</a>.
        <br />There you will also find a link to edit your page (click Edit at the top right corner of your page).
      </div>
      <div v-if="$route.query.akkoord == '0'">
        U geeft geen toestemming voor publicatie van uw profielpagina op de website van de Universiteit Utrecht.
        <br />Uw pagina wordt niet gepubliceerd. Dit betekent dat uw collega’s en anderen uw (contact)gegevens niet online kunnen vinden.
        <br />Mocht u zich bedenken en alsnog toestemming willen geven, neem dan contact op met de
        <a
          href="https://intranet.uu.nl/praktische-zaken/publiciteit-en-social-media-praktische-zaken/aanstelling-publiciteit-en-social"
          target="_blank"
          class="link"
        >beheerder</a> van de profielpagina's van uw faculteit of dienst.
        <br />
        <br />
        <br />
        <br />You do not grant permission to publish your profile page on the website of Utrecht University.
        <br />Your page will not be published. This means that your colleagues and others will not be able to find your (contact) information online.
        <br />If you change your mind and would like to give permission, please contact the personal pages
        <a
          href="https://intranet.uu.nl/en/personal-pages-staff-0"
          target="_blank"
          class="link"
        >administrator</a> of your Faculty or Service Department.
      </div>
    </div>
    <div v-if="!success">
      Fout bij profielpagina publiceren. Mogelijk heeft u al eerder gereageerd op deze mail.
      <br />Neem contact op met de
      <a
        href="https://intranet.uu.nl/praktische-zaken/publiciteit-en-social-media-praktische-zaken/aanstelling-publiciteit-en-social"
        target="_blank"
        class="link"
      >beheerder</a> van de profielpagina's.
    </div>
  </simple-layout>
</template>

<script>
import SimpleLayout from "@/components/layout/simple-layout.vue";

export default {
  name: "profielpagina-confirm",
  data() {
    return {
      url: "",
      pagetitle: "",
      success: true
    };
  },
  components: {
    "simple-layout": SimpleLayout
  },
  methods: {
    // Load employee-data from webserivce
    loaddata: function() {
      this.$http
        .post(
          "Edit/ConfirmPage?page=" +
            this.$route.params.employee +
            "&public=" +
            this.$route.query.akkoord
        )
        .then(
          function(response) {
            this.success = response.data.Success;
            if (this.success) this.url = response.data.Message;
            if (!this.success) this.pagetitle = "Fout bij toestemming geven";
          },
          function() {
            this.success = false;
            if (!this.success) this.pagetitle = "Fout bij toestemming geven";
          }
        );
    }
  },
  watch: {},
  created: function() {
    if (this.$route.query.akkoord === "1") {
      this.pagetitle = "Pagina gepubliceerd / Page published";
    }
    if (this.$route.query.akkoord === "0") {
      this.pagetitle = "Pagina niet gepubliceerd / Page not published";
    }
    this.loaddata();
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.link {
  text-decoration: underline;
}
</style>

<template>
  <div class="search">
    <div class="row photo">
      <div class="col-md-12">
        <img
          src="../../assets/header_organisatie_1600x250.jpg"
          class="img-responsive w-100"
        />
      </div>
    </div>

    <div class="row searchheader font-headline">
      <div class="col-md-1"></div>
      <div class="col-md-10">
        <h1>
          <span v-show="$root.lng == 'NL'">Zoek een medewerker</span>
          <span v-show="$root.lng == 'EN'">Search for employees</span>
        </h1>
      </div>
      <div class="col-md-1"></div>
    </div>

    <div class="row searchexpression font-sidebar">
      <div class="col-md-1"></div>
      <div class="col-md-7">
        <autocomplete
          ref="searchautocomplete"
          :placeholder="placeholdertext"
          :sourceurl="autocompleteurl"
          @OnItemSelect="autocompleteclick"
          @OnEnterPress="enterpress"
          results-display="label"
          results-value="id"
          input-class="autocomplete-search-desktop"
          showSectionHeaders="true"
        />
        <div class="search-mode">
          <label-lng NL="Zoek op " EN="Search for " />
        </div>
        <div class="searchmodeoptions">
          <b-form-radio-group
            plain
            stacked
            v-model="searchmode"
            :options="searchmodeoptions"
          />
        </div>
      </div>
      <div class="col-md-3">
        <b-button class="button" v-on:click="searchbutton()" v-if="false"
          >Zoek</b-button
        >
      </div>
      <div class="col-md-1"></div>
    </div>

    <div class="font-headline">
      <div class="row searchresult" v-if="searchresult.Count > 0">
        <div class="col-md-1"></div>
        <div class="col-md-10">
          <h2>
            <label-lng NL="Zoekresultaat" EN="Search result" />
            "{{ searchexpression }}"
          </h2>
        </div>
        <div class="col-md-1"></div>
      </div>

      <!-- Expertises -->
      <div
        class="row"
        v-show="searchresult.Expertises && searchresult.Expertises.length > 0"
      >
        <div class="col-md-1"></div>
        <div class="col-md-10">
          <div
            class="row resultrow"
            v-for="r in searchresult.Expertises"
            :key="r.Guid"
          >
            <div class="SearchresultSubject">
              <router-link
                :to="{
                  name: 'organogram' + $root.lng,
                  query: { expertise: r.Id }
                }"
              >
                <label-lng NL="Expertise" EN="Expertise" />
                {{ r.Name }}
              </router-link>
            </div>
          </div>
        </div>
        <div class="col-md-1"></div>
      </div>

      <!-- Vaardigheden -->
      <div
        class="row"
        v-show="searchresult.Skills && searchresult.Skills.length > 0"
      >
        <div class="col-md-1"></div>
        <div class="col-md-10">
          <div
            class="row resultrow"
            v-for="r in searchresult.Skills"
            :key="r.Guid"
          >
            <div class="SearchresultSubject">
              <router-link
                :to="{ name: 'organogram' + $root.lng, query: { skill: r.Id } }"
              >
                <label-lng NL="Vaardigheid" EN="Skill" />
                {{ r.Name }}
              </router-link>
            </div>
          </div>
        </div>
        <div class="col-md-1"></div>
      </div>

      <!-- Focusgebieden -->
      <div
        class="row"
        v-show="searchresult.FocusAreas && searchresult.FocusAreas.length > 0"
      >
        <div class="col-md-1"></div>
        <div class="col-md-10">
          <div
            class="row resultrow"
            v-for="r in searchresult.FocusAreas"
            :key="r.Guid"
          >
            <div class="SearchresultSubject">
              <router-link
                :to="{ name: 'organogram' + $root.lng, query: { focusarea: r.Id } }"
              >
                <label-lng NL="Onderzoeksthema" EN="Research area" />
                {{ r.Name }}
              </router-link>
            </div>
          </div>
        </div>
        <div class="col-md-1"></div>
      </div>

      <!-- Zoekresultaat regel Medewerker -->
      <div
        class="row resultrow"
        v-for="r in searchresult.Employees"
        :key="r.Guid"
      >
        <div class="col-md-1"></div>
        <div class="col-md-7">
          <!-- Linker kolom van resultaat bevat naam, en (max 2) aanstellingen -->
          <h3>
            <router-link
              :to="{ name: 'Public' + $root.lng, params: { employee: r.Url } }"
              >{{ r.Name }}</router-link
            >
          </h3>
          <div class="aanstelling" v-if="r.Positions && r.Positions.length > 0">
            <div class="position">{{ r.Positions[0].Position }}</div>
            <div>
              {{ r.Positions[0].Level2 }}
              <span v-if="r.Positions[0].Level3 && r.Positions[0].Level3 != ''"
                >- {{ r.Positions[0].Level3 }}</span
              >
            </div>
          </div>
          <div class="aanstelling" v-if="r.Positions && r.Positions.length > 1">
            <div class="position" v-if="r.Positions[1] && r.Positions[1] != ''">
              {{ r.Positions[1].Position }}
            </div>
            <div>
              {{ r.Positions[1].Level2 }}
              <span v-if="r.Positions[1].Level3 && r.Positions[1].Level3 != ''"
                >- {{ r.Positions[1].Level3 }}</span
              >
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <!-- Rechter kolom bevat contactgegevens -->
          <div v-if="r.Email && r.Email != ''">
            <icon name="envelope" scale="0.8" class="icon" />
            <a :href="'mailto:' + r.Email">{{ r.Email }}</a>
          </div>
          <div v-if="r.Tel && r.Tel != ''">
            <icon name="phone" scale="0.9" class="icon" />
            <a :href="'tel:' + r.Tel">{{ r.Tel }}</a>
          </div>
          <div v-if="r.Positions[0]">{{ r.Positions[0].Level1 }}</div>
          <div
            v-if="
              r.Positions.length > 1 &&
                r.Positions[0].Level1 != r.Positions[1].Level1
            "
          >
            {{ r.Positions[1].Level1 }}
          </div>
        </div>
        <div class="col-md-1"></div>
      </div>

      <!-- Geen resultaat gevonden -->
      <div class="row searchresult" v-if="noresult">
        <div class="col-md-1"></div>
        <div class="col-md-10">
          <h2>
            <label-lng NL="Niets gevonden met zoekterm" EN="No results for" />
            "{{ searchexpression }}"
          </h2>
        </div>
        <div class="col-md-1"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Autocomplete from "@/components/layout/autocomplete.vue";
import labellng from "@/components/layout/label-lng.vue";
import mobiledetect from "mobile-detect";

export default {
  name: "search",
  components: {
    autocomplete: Autocomplete,
    "label-lng": labellng,
    //mobiledetect: mobiledetect
  },
  data() {
    return {
      searchexpression: "",
      autocompleteurl: "",
      searchmode: "N",
      searchmodeoptions: [
        { text: "Naam", value: "N" },
        { text: "Onderwerp of expertise", value: "O" },
        { text: "Telefoonnummer of e-mailadres", value: "T" }
      ],
      searchresult: [],
      noresult: false,
      placeholdertext: ""
    };
  },
  head: {
    link: [
      {
        rel: "alternate",
        media: "only screen and (max-width: 750px)",
        href: "https://www.uu.n/medewerkers/mobiel",
        id: "link-mob"
      }
    ]
  },
  methods: {
    // Load employee-data form webserivce
    search: function() {
      if (JSON.stringify(this.$route.query) !== "{}") {
        var token = window.localStorage.getItem("token");
        if (!token) {
          token = "";
        }
        this.searchmodefromUri();
        if (this.$route.query.q) {
          this.searchexpression = this.$route.query.q;
        }
        if (this.$route.query.medewerker) {
          this.searchexpression = this.$route.query.medewerker;
        }
        if (this.$route.query.employee) {
          this.searchexpression = this.$route.query.employee;
        }
        if (this.$route.query.solisid) {
          this.searchexpression = this.$route.query.solisid;
        }
        this.$Progress.start();
        this.noresult = false;
        this.$http
          .get(
            "Public/search?expression=" +
              encodeURIComponent(JSON.stringify(this.$route.query)) +
              "&t=" +
              token +
              "&l=" +
              this.$root.lng
          )
          .then(function(response) {
            this.searchresult = response.data;
            this.$Progress.finish();
            this.noresult = this.searchresult.Count === 0;
            // Direct naar profielpagina met 1 resultaat-regel. Alleen bij zoeken op solisid.
            var zoekSolisId =
              JSON.stringify(this.$route.query)
                .toLowerCase()
                .indexOf("solisid") !== -1;
            if (
              this.searchresult.Employees &&
              this.searchresult.Employees.length === 1 &&
              zoekSolisId
            ) {
              this.$router.push({
                name: "Public" + this.$root.lng,
                params: { employee: this.searchresult.Employees[0].Url }
              });
            }
          });
      }
    },
    autocompleteclick: function(value) {
      // if (value.startsWith("0")) // -- startWith werkt niet in IE11 :(
      if (value.match(/^0/g)) {
        // 0 = meer resultaten voor....
        var term = value.substring(1);
        this.$router.push({ query: { q: term, mode: this.getsearchmode() } });
        this.$refs.searchautocomplete.clear();
        this.searchexpression = term;
        return;
      }

      if (this.searchmode === "O") {
        this.$router.push({
          query: {
            q: this.$refs.searchautocomplete.getTerm(),
            mode: this.getsearchmode()
          }
        });
        this.searchOnderwerp(value);
      } else {
        // Zoek op naam
        var id = value.substring(1);
        this.$router.push({
          query: {
            q: this.$refs.searchautocomplete.getTerm(),
            mode: this.getsearchmode()
          }
        });
        this.gotoEmployeeById(id);
      }
    },
    enterpress: function(value) {
      if (!value || value === "" || !/^[M|A|G|O|F|E][0-9]{1,8}$/.test(value)) {
        // Er is geen item uit de auto-complete gekozen, zoek op gedeelte van tekst die is ingevuld.
        var term = this.$refs.searchautocomplete.getTerm();
        this.$router.push({ query: { q: term, mode: this.getsearchmode() } });
        this.$refs.searchautocomplete.clear();
      } else {
        this.searchexpression = this.$refs.searchautocomplete.getTerm();
        this.$refs.searchautocomplete.clear();
        this.autocompleteclick();
      }
    },
    searchOnderwerp: function(onderwerpId) {
      var id = onderwerpId.substring(1);
      // Resultaat aangeklikt in autocomplete-lijst
      // M => open medewerkerpagina
      // if (onderwerpId.startsWith('M')) // -- startWith werkt niet in IE11 :(
      if (onderwerpId.match(/^M/g)) this.gotoEmployeeById(id);
      // E => open expertise in organogram
      // if (onderwerpId.startsWith('E')) // -- startWith werkt niet in IE11 :(
      if (onderwerpId.match(/^E/g)) this.gotoExpertiseById(id);
      // V => open expertise in organogram
      // if (onderwerpId.startsWith('V')) // -- startWith werkt niet in IE11 :(
      if (onderwerpId.match(/^V/g)) this.gotoSkillById(id);
      // F => open focuesgebied in organogram
      if (onderwerpId.match(/^F/g)) this.gotoFocusareaById(id);
      /*
      // Todo (moet ook naar organogram gaan)
      if (onderwerpId.match(/^A/g))
        alert("Todo: Open afdeling-pagina voor id " + id);
        //this.$router.push({ name: 'Department' + this.$root.lng, query: { Id: onderwerpId.substring(1) }});
      if (onderwerpId.match(/^G/g))
        alert("Todo: Open groep-pagina voor id " + id);
      if (onderwerpId.match(/^O/g))
        alert("Todo: Open departement-pagina voor id " + id);
      if (onderwerpId.match(/^F/g))
        alert("Todo: Open functie-pagina voor id " + id);
      */
    },
    gotoEmployeeById: function(id) {
      this.$http.get("Public/GetPageName?id=" + id).then(function(response) {
        if (response.data.Success) {
          this.$router.push({
            name: "Public" + this.$root.lng,
            params: { employee: response.data.Message }
          });
        }
      });
    },
    gotoExpertiseById: function(id) {
      // Ga naar organogram met geselecteerde expertise
      this.$router.push({
        name: "organogram" + this.$root.lng,
        query: { expertise: id }
      });
    },
    gotoSkillById: function(id) {
      // Ga naar organogram met geselecteerde skill
      this.$router.push({
        name: "organogram" + this.$root.lng,
        query: { skill: id }
      });
    },
    gotoFocusareaById: function(id) {
      // Ga naar organogram met geselecteerde focusgebie
      this.$router.push({
        name: "organogram" + this.$root.lng,
        query: { focusarea: id }
      });
    },
    setautocompleteurl: function() {
      var token = window.localStorage.getItem("token");
      if (!token) token = "";
      this.autocompleteurl =
        this.$http.options.root +
        "/RestApi/medewerkerautocomplete?mode=" +
        this.searchmode +
        "&t=" +
        token +
        "&taal=" +
        this.$root.lng +
        "&catrow=1&term=";
    },
    searchbutton: function() {},
    switchlng: function() {
      this.$router.push({ name: "Search" + this.$root.lng });
      this.search();
      this.setautocompleteurl();
      this.setsearchlng();
      this.setplaceholdertext();
    },
    formattedDisplay(result) {
      if (result.id === "") {
        return "<div class='categoryrow'>" + result.label + "</div>";
      } else return result.label;
    },
    setsearchlng: function() {
      if (this.$root.lng === "NL") {
        this.searchmodeoptions[0].text = "Naam";
        this.searchmodeoptions[1].text = "Onderwerp of expertise";
        this.searchmodeoptions[2].text = "Telefoonnummer of e-mailadres";
      } else {
        this.searchmodeoptions[0].text = "Name";
        this.searchmodeoptions[1].text = "Subject or expertise";
        this.searchmodeoptions[2].text = "Phone number or email address";
      }
    },
    getsearchmode: function() {
      if (this.searchmode === "N") return "naam";
      if (this.searchmode === "O") return "onderwerp";
      if (this.searchmode === "T") return "tel";
      return "";
    },
    searchmodefromUri: function() {
      if (this.$route.query.mode && this.$route.query.mode === "onderwerp") {
        this.searchmode = "O";
      } else {
        if (this.$route.query.mode && this.$route.query.mode === "tel") {
          this.searchmode = "T";
        } else {
          this.searchmode = "N";
        }
      }
    },
    searchmodetoUri: function() {
      this.$router.push({
        query: { q: this.$route.query.q, mode: this.getsearchmode() }
      });
    },
    setplaceholdertext: function() {
      if (this.$root.lng === "NL") {
        if (this.searchmode === "N") {
          this.placeholdertext = "Zoek een medewerker";
        }
        if (this.searchmode === "O") {
          this.placeholdertext = "Zoek een onderwerp of expertise";
        }
        if (this.searchmode === "T") {
          this.placeholdertext = "Zoek een telefoonnummer of e-mailadres";
        }
      } else {
        if (this.searchmode === "N") {
          this.placeholdertext = "Search for employees";
        }
        if (this.searchmode === "O") {
          this.placeholdertext = "Search for subject or expertise";
        }
        if (this.searchmode === "T") {
          this.placeholdertext = "Search for phone number or e-mail";
        }
      }
    },
    mobileDectectAndRedirect: function() {
      if (window.sessionStorage.getItem("mobile2desktop") == null) {
        // Niet naar mobiel switchen als mobile2desktop gezet is.
        var mobdect = new mobiledetect(window.navigator.userAgent);
        if (mobdect.mobile() != null && mobdect.tablet() == null) {
          // Mobile device (behalve tablets) gaan naar mobiele view
          if (this.$root.lng === "NL") {
            window.location = window.location
              .toString()
              .toLowerCase()
              .replace("/medewerkers", "/medewerkers/mobiel");
          } else {
            window.location = window.location
              .toString()
              .toLowerCase()
              .replace("/staff", "/staff/mobile");
          }
        }
      }
    },
    // Zet q-parameter uit url in autocomplete
    setAutcompleteFromUrl: function() {
      if (this.$route.query.q && this.$route.query.q !== "") {
        this.$refs.searchautocomplete.setText(this.$route.query.q);
      }
    }
  },
  watch: {
    // Reload employee-data on language switch
    searchmode: function() {
      this.setautocompleteurl();
      this.searchmodetoUri();
      this.setplaceholdertext();
    },
    "$route.query.q": function() {
      this.search(true);
    }
  },
  created: function() {
    this.mobileDectectAndRedirect();
    this.$root.$on("switchlng", this.switchlng);
    // Taalswitch beschikbaar op zoekpagina
    // this.$parent.switchlngAvailable(true);
  },
  mounted: function() {
    this.searchmodefromUri();
    this.setautocompleteurl();
    this.search(true);
    this.setsearchlng();
    this.setplaceholdertext();
    this.setAutcompleteFromUrl();
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.searchheader {
  background-color: #ffcd00;
  padding-top: 10px;
  padding-bottom: 10px;
  min-height: 50px;
}

.searchexpression {
  margin-top: 30px;
  margin-bottom: 20px;
}

.searchresult {
  margin-top: 20px;
}

.search-mode {
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 16px;
}

.searchmodeoptions {
  font-weight: 400;
  line-height: 32px;
  font-size: 16px;
}

.button {
  color: #ffcd00;
}

h2 {
  font-size: 14pt;
  margin-bottom: 20px;
}

/* Zoekresultaat. text-decoration: underline; maar weggehaald en bold gemaakt */

h3 {
  font-size: 14pt;
  font-weight: 600;
  padding-top: 0px;
}

/* Marges tussen resultaat-regels */
.resultrow {
  margin-bottom: 15px;
}

/* Lijn tussen resultaat-regels (onder 2e en 3e kolom) */
.resultrow > div:nth-child(2),
.resultrow > div:nth-child(3) {
  border-bottom: 0px solid #dddddd;
}

/* Tussenruimte tussen de aanstellingen*/
.aanstelling {
  margin-bottom: 15px;
}

/* Functiebeschrijving in resultaat iets groter font */
.position {
  font-size: 120%;
  margin-bottom: 5px;
}

.SearchresultSubject {
  font-size: 120%;
  margin-bottom: 10px;
  border-bottom: 1px solid #dddddd;
  padding-bottom: 10px;
  width: 100%;
}

/* Foto bovenaan de zoekpagina */
.photo {
  margin-left: -15px;
  margin-right: -30px;
}

.photo > div {
  padding-left: 0px;
}
</style>

<style>
.search .autocomplete-search {
  border: solid 1px !important;
  border-color: #ffcd00 !important;
  border-radius: 5px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-left: 10px !important;
}

.search .categoryrow {
  font-weight: bold;
  font-size: 115%;
  width: 100%;
  height: 100%;
  cursor: default;
}

.search .autocomplete__results__item {
  font-size: 13px;
  padding-top: 3px;
  padding-bottom: 3px;
}

/*
.search .autocomplete__results__item:has(.categoryrow) {
}
*/

.search input[type="radio"] {
  position: relative;
  display: inline-block;
  margin-right: 5px;
}

/* Kruisje van autocomplete op juiste hoogte */
.search .autocomplete--clear {
  position: relative;
  top: -6px;
}
</style>

<script>
import moment from "moment";

export default {
  data() {
    return {};
  },
  methods: {
    formatdate: function(date) {
      if (this.$root.lng === "NL") return moment(date).format("DD-MM-YYYY");
      else return moment(date).format("DD.MM.YYYY");
    }
  }
};
</script>

<template>
  <span>
    <a
      href="javascript:void(0)"
      @click="$emit('click');"
      :title="($root.lng=='NL' ? titleNL : titleEN)"
    >
      <icon :name="icon" v-bind="$attrs" />
    </a>
  </span>
</template>

<script>
export default {
  name: "iconbutton",
  props: ["icon", "titleNL", "titleEN", "click"]
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

<template>
  <div v-if="medewerker" class="TabEditResearch">
    <confirm ref="confirmdialog"></confirm>
    <!-- Modal -->
    <b-modal
      id="modalProjectSelect"
      ref="modalProjectSelect"
      :title="$root.lng == 'NL' ? 'Selecteer project' : 'Select project'"
      ok-only
      size="lg"
      centered
    >
      <form action="javascript:void(0);">
        <div class="form-group search-project">
          <label-lng
            NL="Zoek bestaand project"
            EN="Search for existing project"
          ></label-lng>
          <input v-model="searchproject" class="form-control" maxlength="200" />
          <input
            type="checkbox"
            v-model="searchclosedprojects"
            id="closed"
          />&nbsp;<label for="closed">
            <label-lng
              NL="Doorzoek ook beëindigde projecten"
              EN="Search also inclosed projects"
            ></label-lng>
          </label>
        </div>
        <div class="search-buttons">
          <b-button v-on:click="searchProject()" type="submit">
            <label-lng NL="Zoeken" EN="Search"></label-lng> </b-button
          >&nbsp;<b-button v-on:click="newProject()">
            <label-lng
              NL="Nieuw project aanmaken"
              EN="Create new project"
            ></label-lng>
          </b-button>
        </div>

        <div class="form-group" v-if="projects && projects.length == 0">
          <br />
          <b-alert variant="info" show>
            <label-lng NL="Geen projecten gevonden" EN="No projects found" />
          </b-alert>
        </div>

        <div class="project-select-result">
          <table
            v-if="projects && projects.length > 0"
            class="table table-sm table-bordered table-search"
          >
            <thead>
              <tr>
                <th>
                  <label-lng NL="Titel project" EN="Title project"></label-lng>
                </th>
                <th>
                  <label-lng NL="Start" EN="Start"></label-lng>
                </th>
                <th>
                  <label-lng NL="Eind" EN="End"></label-lng>
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="prj in projects" :key="prj.Guid">
                <td>{{ prj.Title }}</td>
                <td>{{ prj.Start ? formatdate(prj.Start) : "" }}</td>
                <td>{{ prj.End ? formatdate(prj.End) : "" }}</td>
                <td>
                  <b-button v-on:click="selectProject(prj)" variant="link">
                    <label-lng NL="Selecteer" EN="Select"></label-lng>
                  </b-button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </form>
    </b-modal>

    <!-- Popup -->
    <div v-if="curprj" class="popupdiv">
      <edit-layout>
        <h1>
          {{ $root.lng == "EN" ? "Edit Project" : "Bewerk project" }}
          {{ curprj.Title.NL }}
        </h1>
        <h2>
          {{
            $root.lng == "EN"
              ? "1. Standard projectdetails"
              : "1. Algemene projectinformatie"
          }}
        </h2>
      </edit-layout>
      <edit-layout>
        <label-lng
          NL="LET OP: De algemene projectinformatie (onderdeel 1) is zichtbaar op de profielpagina’s van alle projectleden binnen de Universiteit Utrecht.
                  Wanneer u deze gegevens wijzigt, worden ze automatisch ook op de profielpagina’s van uw mede-projectleden aangepast.
                  Uw individuele aanvullingen (onderdeel 2, zie hieronder) worden alleen op uw eigen profielpagina getoond."
          EN="LET OP: De algemene projectinformatie (onderdeel 1) is zichtbaar op de profielpagina’s van alle projectleden binnen de Universiteit Utrecht.
                  Wanneer u deze gegevens wijzigt, worden ze automatisch ook op de profielpagina’s van uw mede-projectleden aangepast.
                  Uw individuele aanvullingen (onderdeel 2, zie hieronder) worden alleen op uw eigen profielpagina getoond."
        ></label-lng>
      </edit-layout>

      <edit-layout
        nameNL="Titel*"
        nameEN="Title*"
        :info="GetInfo(info, 'Medewerkerproject.TitelNL')"
      >
        <edit-textfield
          :value="curprj.Title"
          multilng="true"
          autofocus
          name="ER1"
          ><!--v-validate="'required'"-->
        </edit-textfield>
      </edit-layout>

      <edit-layout
        nameNL="Onderzoeksbeschrijving"
        nameEN="Research description"
        :info="GetInfo(info, 'Medewerkerproject.BeschrijvingNL')"
      >
        <edit-textfield
          :value="curprj.Description"
          multilng="true"
          rtf="true"
          name="resbeschr"
        ></edit-textfield>
      </edit-layout>

      <edit-layout
        nameNL="Startdatum"
        nameEN="Startdate"
        :info="GetInfo(info, 'Medewerkerproject.Start')"
      >
        <edit-datefield :value="curprj.Start"></edit-datefield>
      </edit-layout>

      <edit-layout
        nameNL="Einddatum"
        nameEN="Enddate"
        :info="GetInfo(info, 'Medewerkerproject.Eind')"
      >
        <edit-datefield :value="curprj.End"></edit-datefield>
      </edit-layout>

      <edit-layout
        nameNL="Financiering*"
        nameEN="Funding*"
        :info="GetInfo(info, 'Medewerkerproject.Financiering')"
      >
        <select
          v-model="curprj.FundingId"
          id="prjFunding"
          class="form-control"
          name="prjFunding"
        >
          <!--v-validate="'required'"-->
          <!--:class="{ 'is-invalid': errors.has('vvsPrj.prjFunding') }" -->
          <option v-for="fun in fundings" :value="fun.Key" :key="fun.Guid">
            {{ fun.Value }}
          </option>
        </select>
      </edit-layout>

      <edit-layout
        nameNL="Toelichting financiering"
        nameEN="Description funding*"
        :info="GetInfo(info, 'Medewerkerproject.FinancieringToelichtingNL')"
      >
        <edit-textfield
          :value="curprj.FundingDescription"
          multilng="true"
          name="ER2"
        ></edit-textfield>
      </edit-layout>

      <edit-layout
        nameNL="Projectleden"
        nameEN="Projectmembers"
        :info="GetInfo(info, 'Medewerkerproject.Projectleden')"
      >
        <table class="table table-sm table-bordered table-search">
          <thead>
            <tr>
              <th>
                <label-lng NL="Naam" EN="Name"></label-lng>
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="mem in curprj.UUMembers" :key="mem.Guid">
              <td>{{ mem.Name }}</td>
              <td>
                <iconbutton
                  icon="trash"
                  titleNL="Verwijderen"
                  titleEN="Delete"
                  @click="deleteProjectMember(mem)"
                  v-show="mem.CanDelete"
                />
              </td>
            </tr>
          </tbody>
        </table>

        <div class="form-group">
          <span>
            <label-lng
              NL="Zoek projectlid"
              EN="Search projectmember"
            ></label-lng
            >&nbsp;<input v-model="searchprojectmember" />&nbsp;<b-button
              v-on:click="searchProjectMember()"
            >
              <label-lng NL="Zoeken" EN="Search"></label-lng>
            </b-button>
          </span>
        </div>

        <div v-if="projectmembers && projectmembers.length > 0">
          <label-lng NL="Selecteer projectlid" EN="Select projectmember" />
          <table class="table table-sm table-bordered table-search">
            <thead>
              <tr>
                <th>
                  <label-lng NL="Naam" EN="Name"></label-lng>
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="mem in projectmembers" :key="mem.Guid">
                <td>{{ mem.Name }}</td>
                <td>
                  <b-button
                    v-on:click="selectProjectMember(mem)"
                    variant="link"
                  >
                    <label-lng NL="Selecteer" EN="Select"></label-lng>
                  </b-button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </edit-layout>

      <edit-layout
        nameNL="Externe projectleden"
        nameEN="External projectmembers"
        :info="GetInfo(info, 'Medewerkerproject.ExterneProjectleden')"
      >
        <edit-textfield
          :value="curprj.NonUUMembers"
          name="ER3"
        ></edit-textfield>
      </edit-layout>
      <edit-layout>
        <h2>
          {{
            $root.lng == "EN"
              ? "2. Personal contributions to project"
              : "2. Individuele bijdrage aan project"
          }}
        </h2>
        <div>
          <label-lng
            NL="De gegevens bij individuele projecteigenschappen zijn alleen zichtbaar op uw eigen profielpagina."
            EN="De gegevens bij individuele projecteigenschappen zijn alleen zichtbaar op uw eigen profielpagina."
          ></label-lng>
        </div>
      </edit-layout>
      <edit-layout
        nameNL="Rol binnen project*"
        nameEN="Role within project*"
        :info="GetInfo(info, 'Medewerkerproject.Rol')"
      >
        <select
          v-model="curprj.ProjectRoleId"
          id="prjRole"
          class="form-control"
          name="prjRole"
        >
          <!-- :class="{ 'is-invalid': errors.has('vvsPrj.prjRole') }" -->
          <!--v-validate="'required'"-->
          <option v-for="role in roles" :value="role.Key" :key="role.Guid">
            {{ role.Value }}
          </option>
        </select>
      </edit-layout>

      <edit-layout
        nameNL="Project tonen"
        nameEN="Show project*"
        :info="GetInfo(info, 'Medewerkerproject.Zichtbaar')"
      >
        <span>
          <input
            type="checkbox"
            id="prjVisible"
            v-model="curprj.Visible"
          />&nbsp;<label-lng
            NL="Project tonen op profielpagina"
            EN="Show project on page"
          ></label-lng>
        </span>
      </edit-layout>

      <edit-layout
        nameNL="Individuele projectbeschrijving"
        nameEN="Personal project description"
        :info="GetInfo(info, 'Medewerkerproject.MedBeschrijvingNL')"
      >
        <edit-textfield
          :value="curprj.IndividualDescription"
          multilng="true"
          rtf="true"
          name="resindi"
        ></edit-textfield>
      </edit-layout>

      <edit-layout nameNL nameEN info>
        <button class="uu-button button-yellow" v-on:click="saveProject()">
          <label-lng NL="Project opslaan" EN="Save Project" />
        </button>&nbsp;<button class="uu-button button-yellow" v-on:click="cancelProject()">
          <label-lng NL="Annuleren" EN="Cancel" />
        </button>
      </edit-layout>
    </div>
    <edit-layout nameNL nameEN info v-show="!curprj">
      <div v-html="GetInfo(info, 'Onderzoek.Algemeen')"></div>
    </edit-layout>

    <edit-layout nameNL="Projecten" nameEN="Projects" info v-show="!curprj">
      <table class="table table-sm table-bordered table-projects">
        <thead>
          <tr>
            <th>
              <label-lng NL="Project" EN="Project"></label-lng>
            </th>
            <th>
              <label-lng NL="Tonen op profielpagina" EN="Visible"></label-lng>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="prj in medewerker.Projects" :key="prj.Guid">
            <td>{{ prj.Title.NL }}</td>
            <td>{{ prj.Visible ? "Ja" : "Nee" }}</td>
            <td>
              <iconbutton
                icon="pencil-alt"
                titleNL="Bewerken"
                titleEN="Edit"
                @click="editProject(prj)"
              />&nbsp;<iconbutton
                icon="trash"
                titleNL="Verwijderen"
                titleEN="Delete"
                @click="deleteProject(prj)"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <button
        class="uu-button button-black"
        v-on:click="addProject()"
        variant="link"
      >
        <label-lng NL="Project toevoegen" EN="Add project"></label-lng>
      </button>
    </edit-layout>

    <edit-layout
      nameNL="Vrije tekst onderzoek (bovenaan)"
      nameEN="Additional information on research activities (top)"
      :info="GetInfo(info, 'Onderzoek.TekstTopNL')"
      v-show="!curprj"
    >
      <edit-textfield
        :value="medewerker.ProjectsFreeTextTop"
        multilng="true"
        rtf="true"
        name="restop"
      ></edit-textfield>
    </edit-layout>

    <edit-layout
      nameNL="Vrije tekst onderzoek (onderaan)"
      nameEN="Additional information on research activities (bottom)"
      :info="GetInfo(info, 'Onderzoek.TekstNL')"
      v-show="!curprj"
    >
      <edit-textfield
        :value="medewerker.ProjectsFreeTextBottom"
        multilng="true"
        rtf="true"
        name="resbot"
      ></edit-textfield>
    </edit-layout>
  </div>
</template>

<script>
import datehelper from "@/helpers/date-helper.vue";
import labellng from "@/components/layout/label-lng.vue";
import edittextfield from "@/components/layout/edit-textfield.vue";
import editlayout from "@/components/layout/edit-layout.vue";
import infohelper from "@/helpers/info-helper.vue";
import iconbutton from "@/components/layout/iconbutton.vue";
import editdatefield from "@/components/layout/edit-datefield.vue";
import confirmdialog from "@/components/layout/confirm.vue";

export default {
  name: "tab-edit-research",
  props: ["medewerker", "info"],
  data() {
    return {
      searchproject: "",
      searchclosedprojects: false,
      searchprojectmember: "",
      project: null,
      projects: null,
      fundings: null,
      projectmembers: null,
      roles: null,
      curprj: null,
      editingproject: null,
    };
  },
  components: {
    "label-lng": labellng,
    "edit-textfield": edittextfield,
    "edit-layout": editlayout,
    // "edit-layout-popup": editlayoutpopup,
    iconbutton: iconbutton,
    "edit-datefield": editdatefield,
    confirm: confirmdialog,
  },
  methods: {
    loaddata: function () {
      this.$http
        .get("Edit/GetFundings?l=" + this.$root.lng)
        .then(function (response) {
          this.fundings = response.data;
        });
      this.$http
        .get("Edit/GetProjectRoles?l=" + this.$root.lng)
        .then(function (response) {
          this.roles = response.data;
        });
    },
    beforeSave: function () {
      // var data = this.$refs.popupProject.getData();
    },
    deleteProject: function (prj) {
      var data = this;
      this.$refs.confirmdialog.confirm(
        "Project verwijderen?",
        "Delete project?",
        function () {
          var i = data.medewerker.Projects.indexOf(prj);
          if (i !== -1) data.medewerker.Projects.splice(i, 1);
        }
      );
    },
    deleteProjectMember: function (mem) {
      var data = this.curprj; // this.$refs.popupProject.getData();
      var i = data.UUMembers.indexOf(mem);
      if (i !== -1) data.UUMembers.splice(i, 1);
    },
    addProject: function () {
      this.searchproject = "";
      this.projects = null;
      this.$refs.modalProjectSelect.show();
    },
    editProject: function (prj) {
      this.curprj = JSON.parse(JSON.stringify(prj)); // Deepcopy
      this.editingproject = prj; // Referentie
      this.$parent.popupmodeOn();
      // this.$refs.popupProject.show(prj, this.medewerker.Projects, this);
    },
    saveProject: function () {
      if (this.editingproject) {
        var index = this.medewerker.Projects.indexOf(this.editingproject);
        if (index >= 0) {
          this.medewerker.Projects.splice(
            index,
            1,
            JSON.parse(JSON.stringify(this.curprj))
          );
        } else {
          this.medewerker.Projects.push(this.curprj);
        }
      } else {
        this.medewerker.Projects.push(this.curprj);
      }
      this.curprj = null;
      this.$parent.popupmodeOff();
    },
    cancelProject: function () {
      this.curprj = null;
      this.$parent.popupmodeOff();
    },
    selectProject: function (prj) {
      this.project = null;
      this.$http
        .get("edit/NewProject?projectId=" + prj.Id.toString())
        .then(function (response) {
          this.project = response.data;
          this.$refs.modalProjectSelect.hide();
          this.curprj = JSON.parse(JSON.stringify(response.data)); // Deepcopy
          this.editingproject = this.project; // Referentie
          this.$parent.popupmodeOn();
          /*
          this.$refs.popupProject.show(
            this.project,
            this.medewerker.Projects,
            this
          );
          */
        });
    },
    selectProjectMember: function (mem) {
      var data = this.curprj; // this.$refs.popupProject.getData();

      data.UUMembers.splice(0, 0, mem);

      this.projectmembers = null;
      this.searchprojectmember = "";
    },
    newProject: function () {
      this.projects = null;
      this.project = null;
      this.$http.get("edit/NewProject").then(function (response) {
        this.project = response.data;
        this.$refs.modalProjectSelect.hide();
        this.curprj = this.project;
        this.editingproject = null;
        this.$parent.popupmodeOn();
      });
    },
    searchProject: function () {
      this.projects = null;
      var url =
        "edit/SearchProjects?q=" +
        encodeURIComponent(this.searchproject) +
        "&l=" +
        this.$root.lng;
      if (this.searchclosedprojects) url += "&closed=true";
      this.$http.get(url).then(function (response) {
        this.projects = response.data;
      });
    },
    searchProjectMember: function () {
      this.projectmembers = null;
      this.$http
        .get(
          "edit/SearchProjectMembers?q=" +
            encodeURIComponent(this.searchprojectmember)
        )
        .then(function (response) {
          this.projectmembers = response.data;
        });
    },
  },
  created: function () {
    this.loaddata();
  },
  mixins: [infohelper, datehelper],
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.project-select-result {
  max-height: 400px;
  overflow-y: scroll;
}

.search-project {
  margin-bottom: 10px;
}

.search-buttons {
  margin-bottom: 10px;
}

.popupdiv {
  margin: 10px;
  padding: 10px;
  border: 2px solid gray;
}

h1 {
  font-size: 17pt;
}
h2 {
  font-size: 14pt;
}
</style>

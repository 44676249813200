<template>
  <admin-layout title="Beheer">
    <div v-if="allowedBeheerOverzicht">
      <router-link :to="{ name: 'BeheerOverzicht' }">Beheer profielpagina's</router-link>
    </div>
    <div v-if="allowedNew">
      <router-link :to="{ name: 'BeheerNew' }">Handmatig profielpagina aanmaken</router-link>
    </div>
    <div v-if="allowedBeheerMails">
      <router-link :to="{ name: 'BeheerMails' }">Verstuur medewerker mails</router-link>
    </div>
    <div v-if="allowedClearCache">
      <a href="#" @click="clearcache()">Reset cache</a>
    </div>

    <h2 class="header-minder-bottom-margin">Expertises</h2>
    <div v-if="allowedBeheerExpertises">
      <router-link :to="{ name: 'BeheerExpertises' }">Expertises</router-link>
    </div>
    <div v-if="allowedBeheerExpertises">
      <router-link :to="{ name: 'BeheerSkills' }">Vaardigheden</router-link>
    </div>

    <h2 class="header-minder-bottom-margin">Nevenfuncties</h2>
    <div v-if="allowedAdminAdditionalfunctions">
      <router-link :to="{ name: 'AdminAdditionalfunctions' }">Beheer nevenfuncties</router-link>
    </div>
    <div v-if="allowedBeheerAdditionalfunctionsConfig">
      <router-link :to="{ name: 'BeheerAdditionalfunctionsConfig' }">Nevenfuncties configuratie</router-link>
    </div>

    <h2 class="header-minder-bottom-margin">Rapportages</h2>
    <div v-if="allowedRapportVulling">
      <router-link :to="{ name: 'BeheerRapportVulling' }">Rapportage vulling profielpagina's</router-link>
    </div>
    <div v-if="allowedRapportOBP">
      <router-link
        :to="{ name: 'BeheerRapportOPB' }"
      >Overzicht OBP-medewerkers niet gereageerd op welkomstmail</router-link>
    </div>
    <div v-if="allowedRapportNevenfuncties">
      <router-link :to="{ name: 'BeheerRapportNevenfuncties' }">Overzicht nevenfuncties</router-link>
    </div>
    <div v-if="allowedRapportMedewerkers">
      <router-link :to="{ name: 'BeheerRapportMedewerkers' }">Overzicht medewerkers</router-link>
    </div>

    <h2 class="header-minder-bottom-margin">Beheer stamtabellen</h2>
    <div v-if="allowedBeheerLocaties">
      <router-link :to="{ name: 'BeheerLocaties' }">Locaties</router-link>
    </div>
    <div v-if="allowedBeheerFooterlinks">
      <router-link :to="{ name: 'BeheerFooterlinks' }">Footerlinks</router-link>
    </div>
    <div v-if="allowedBeheerOM">
      <router-link :to="{ name: 'BeheerOM' }">Organisatiestructuur</router-link>
    </div>
    <div v-if="allowedFocusarea">
      <router-link :to="{ name: 'BeheerFocusarea' }">Focusgebieden/strategische thema's</router-link>
    </div>
    <div v-if="allowedPositions">
      <router-link :to="{ name: 'BeheerPositions' }">Functies</router-link>
    </div>
    <div v-if="allowedAdmins">
      <router-link :to="{ name: 'BeheerAdmins' }">Beheerders</router-link>
    </div>
    <div v-if="allowedStudyProgrammes">
      <router-link :to="{ name: 'BeheerStudyProgrammes' }">Opleidingen</router-link>
    </div>

    <h2 class="header-minder-bottom-margin">Importeren</h2>
    <div v-if="allowedImportOsiris">
      <router-link :to="{ name: 'BeheerImportOsiris' }">Cursussen uit Osiris importeren</router-link>
    </div>
  </admin-layout>
</template>

<script>
import AdminLayout from '@/components/layout/admin-layout.vue'

export default {
  name: 'Beheer',
  components: {
    'admin-layout': AdminLayout
  },
  data() {
    return {
      userinfo: null,
      allowedBeheerOverzicht: false,
      allowedBeheerLocaties: false,
      allowedBeheerMails: false,
      allowedAdminAdditionalfunctions: false,
      allowedBeheerAdditionalfunctionsConfig: false,
      allowedBeheerFooterlinks: false,
      allowedBeheerExpertises: false,
      allowedBeheerOM: false,
      allowedClearCache: false,
      allowedNew: false,
      allowedFocusarea: false,
      allowedPositions: false,
      allowedAdmins: false,
      allowedStudyProgrammes: false,
      allowedRapportVulling: false,
      allowedRapportOBP: false,
      allowedRapportNevenfuncties: false,
      allowedRapportMedewerkers: false,
      allowedImportOsiris: false
    };
  },
  methods: {
    loaddata: function () {
      this.$http.get('Edit/GetUserInfo').then(function (response) {
        this.userinfo = response.data;

        // Shortcuts voor cleanere code in template
        if (this.userinfo) {
          this.allowedBeheerOverzicht =
            this.userinfo.IsAdmin || this.userinfo.IsFacultyAdmin;
          this.allowedBeheerLocaties = this.userinfo.IsAdmin;
          this.allowedBeheerMails =
            this.userinfo.IsAdmin || this.userinfo.IsFacultyAdmin;
          this.allowedAdminAdditionalfunctions =
            this.userinfo.IsAdmin ||
            this.userinfo.IsFacultyAdmin ||
            this.userinfo.IsAdditionalFunctionAdmin;
          this.allowedBeheerAdditionalfunctionsConfig =
            this.userinfo.IsAdmin || this.userinfo.IsFacultyAdmin;
          this.allowedBeheerFooterlinks = this.userinfo.IsAdmin;
          this.allowedBeheerExpertises =
            this.userinfo.IsAdmin || this.userinfo.IsFacultyAdmin;
          this.allowedBeheerOM =
            this.userinfo.IsAdmin || this.userinfo.IsFacultyAdmin;
          this.allowedClearCache = this.userinfo.IsAdmin;
          this.allowedNew = this.userinfo.IsAdmin;
          this.allowedFocusarea = this.userinfo.IsAdmin;
          this.allowedPositions =
            this.userinfo.IsAdmin || this.userinfo.IsFacultyAdmin;
          this.allowedAdmins = this.userinfo.IsAdmin;
          this.allowedStudyProgrammes =
            this.userinfo.IsAdmin || this.userinfo.IsFacultyAdmin;
          this.allowedRapportVulling =
            this.userinfo.IsAdmin || this.userinfo.IsFacultyAdmin;
          this.allowedRapportOBP =
            this.userinfo.IsAdmin || this.userinfo.IsFacultyAdmin;
          this.allowedRapportNevenfuncties =
            this.userinfo.IsAdmin || this.userinfo.IsFacultyAdmin;
          this.allowedRapportMedewerkers =
            this.userinfo.IsAdmin || this.userinfo.IsFacultyAdmin;
          this.allowedImportOsiris = this.userinfo.IsAdmin;
        }
      });
    },
    clearcache: function () {
      if (confirm('Weet u zeker dat u de cache wilt resetten?')) {
        this.$http.get('Admin/ClearCache').then(function (response) {
          if (response.data.Success) alert('Cache is opgeschoond.');
          else alert('Fout bij opschonen cache.');
        });
      }
    }
  },
  created: function () {
    this.loaddata();
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.header-minder-bottom-margin {
  margin-top: 20px;
  margin-bottom: 7px;
}
</style>

<template>
  <div class="AdminLayout">
    <!-- Header  -->
    <div class="row titel font-headline">
      <div class="col-md-1"></div>
      <div class="col-md-10">
        <h1>{{title}}</h1>
      </div>
      <div class="col-md-1"></div>
    </div>

    <!-- Content  -->
    <div class="row content font-sidebar">
      <div class="col-md-1"></div>
      <div class="col-md-10">
        <slot></slot>
      </div>
      <div class="col-md-1"></div>
    </div>

    <!-- Footer  -->
    <div class="row footer font-sidebar">
      <div class="col-md-1"></div>
      <div class="col-md-10">
        <button class="uu-button button-black">
          <router-link
            v-if="$route.name != 'Beheer'"
            :to="{name: 'Beheer'}"
          >&lt;&lt; Terug naar beheerscherm</router-link>
        </button>
      </div>
      <div class="col-md-1"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "admin-layout",
  props: ["title"],
  data() {
    return {};
  },
  methods: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.titel {
  background-color: #ffcd00;
  padding-top: 70px;
  padding-bottom: 23px;
  min-height: 50px;
  margin-bottom: 15px;
}

.content {
  line-height: 34px;
  padding-bottom: 15px;
}

.footer {
  margin-top: 55px;
  margin-bottom: 25px;
}

/* Lijn boven footer */
.footer > div:nth-child(2) {
  padding-top: 15px;
  border-top: solid 1px #cccccc;
}

/*
h1 {
}
*/

h2 {
  font-size: 16px;
  /*margin-bottom: 20px;*/
  font-weight: 700;
}
</style>

<template>
  <div v-if="medewerker" class="TabEditAdditionalFunctions">
    <edit-layout
      nameNL="Nevenfuncties"
      nameEN="Additional functions"
      :info="GetInfo(info, 'Nevenfuncties.TekstNL')"
    >
      <b-alert show variant="warning" v-if="medewerker.AdditionalFunctWorkflow">
        <label-lng
          NL="Wijzigingen in nevenfuncties worden gepubliceerd na goedkeuring van uw leidinggevende."
          EN="Wijzigingen in nevenfuncties worden gepubliceerd na goedkeuring van uw leidinggevende."
        />
      </b-alert>
      <edit-textfield :value="medewerker.AdditionalFunctions" multilng="true" rtf="true" name="AF"></edit-textfield>
    </edit-layout>
  </div>
</template>

<script>
import labellng from "@/components/layout/label-lng.vue";
import edittextfield from "@/components/layout/edit-textfield.vue";
import editlayout from "@/components/layout/edit-layout.vue";
import infohelper from "@/helpers/info-helper.vue";

export default {
  name: "tab-edit-additionalfunctions",
  props: ["medewerker", "info"],
  data() {
    return {};
  },
  components: {
    "label-lng": labellng,
    "edit-textfield": edittextfield,
    "edit-layout": editlayout
  },
  methods: {},
  created: function () {},
  mixins: [infohelper]
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

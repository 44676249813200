<template>
  <admin-layout title="Beheer expertises">
    <div class="filter-caption">Filter</div>
    <div>Naam expertise of synoniem</div>
    <form class="filter-form" action="#">
      <div>
        <input maxlength="1000" v-model="searchterm" autofocus class="search-input" />
        <button
          type="submit"
          onclick="return false;"
          class="uu-button button-black"
          @click="searchExpertise()"
        >Zoek</button>
      </div>
    </form>

    <div v-if="expertises.length > 0">
      <table class="table table-sm table-bordered">
        <thead>
          <tr class="kolomkop">
            <th>Expertise NL</th>
            <th>Expertise EN</th>
            <th
              title="Aantal medewerkers met openbare pagina met de expertise. Tussen haakjes het aantal medewerkers met alleen intern zichtbare pagina. Deze waardes worden 1x per dag bepaald."
            ># medewerkers</th>
            <th>Synoniemen</th>
            <th>ID nummer</th>
            <th>Acties</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="e in expertises" :key="e.Guid">
            <td>{{e.NameNL}}</td>
            <td>{{e.NameEN}}</td>
            <td
              :title="(e.PublicCount == 1 ? '1 medewerker met openbare pagina heeft deze expertise. ' : e.PublicCount.toString() + ' medewerkers met openbare pagina hebben deze expertise. ') + (e.InternalCount == 1 ? '1 medewerker met alleen intern zichtbare pagina heeft deze expertise.' : e.InternalCount.toString() + ' medewerkers met alleen intern zichtbare pagina hebben deze expertise.')"
            >
              {{e.PublicCount}}
              <span v-show="e.InternalCount > 0">(+{{e.InternalCount}})</span>
            </td>
            <td>{{e.Synonym}}</td>
            <td>{{e.Id}}</td>
            <td class="iconcolumn">
              <iconbutton
                icon="pencil-alt"
                titleNL="Bewerken"
                titleEN="Edit"
                @click="editExpertise(e)"
              />
              <iconbutton
                icon="trash"
                titleNL="Verwijderen"
                titleEN="Delete"
                @click="deleteExpertise(e)"
              />
              <iconbutton
                icon="user"
                titleNL="Overzicht medewerkers"
                titleEN="Overview Employees"
                @click="overzichtMedewerkers(e)"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-show="noresult">Geen expertise gevonden.</div>
    <div>
      <button class="uu-button button-black" @click="addExpertise()">Expertise toevoegen</button>
    </div>

    <popup
      ref="popupExpertise"
      @okclick="beforeSaveExpertise()"
      titleNL="Expertise"
      titleEN="Expertise"
    >
      <form slot-scope="popup">
        <div v-if="popup.data != null">
          <div class="form-group">
            <label for="namenl">Omschrijving NL</label>
            <input
              v-model="popup.data.NameNL"
              class="form-control"
              name="NameNL"
              id="namenl"
              maxlength="200"
            />
            <!-- :class="{'is-invalid': errors.has('vvsE.NameNL') }"  -->
          </div>
          <div class="form-group">
            <label for="nameen">Omschrijving EN</label>
            <input
              v-model="popup.data.NameEN"
              class="form-control"
              name="NameEN"
              id="nameen"
              maxlength="200"
            /><!-- :class="{'is-invalid': errors.has('vvsE.NameEN') }" -->
          </div>
          <div class="form-group">
            <label for="synonym">Synoniemen</label>
            <table class="table table-sm table-bordered">
              <thead class="kolomkop">
                <th>Synoniem</th>
                <th>Acties</th>
              </thead>
              <tbody>
                <tr v-for="s in popup.data.Synonym" :key="s.Guid">
                  <td>{{s}}</td>
                  <td class="iconcolumn">
                    <iconbutton
                      icon="pencil-alt"
                      titleNL="Bewerken"
                      titleEN="Edit"
                      @click="editSynonym(s)"
                    />
                    <iconbutton
                      icon="trash"
                      titleNL="Verwijderen"
                      titleEN="Delete"
                      @click="deleteSynonym(s)"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <button
              type="button"
              class="uu-button button-black"
              @click="addSynonym()"
            >Synoniem toevoegen</button>
          </div>
        </div>
      </form>
    </popup>

    <confirm ref="confirmdialog"></confirm>

    <popup ref="popupOverview" titleNL="Medewerkers" titleEN="Employees">
      <form>
        <div class="overview">
          <div v-for="o in overview" :key="o.Guid">
            <a :href="o.Url" target="_blank">{{o.Name}}</a>
            ({{o.Status}})
          </div>
        </div>
      </form>
    </popup>
  </admin-layout>
</template>

<script>
import confirmdialog from "@/components/layout/confirm.vue";
import iconbutton from "@/components/layout/iconbutton.vue";
import popup from "@/components/layout/popup.vue";
import AdminLayout from "@/components/layout/admin-layout.vue";

export default {
  name: "BeheerExpertises",
  components: {
    confirm: confirmdialog,
    popup: popup,
    iconbutton: iconbutton,
    "admin-layout": AdminLayout
  },
  data() {
    return {
      searchterm: "",
      expertises: [],
      noresult: false,
      overview: []
    };
  },
  methods: {
    loaddata: function() {},
    searchExpertise: function() {
      this.$Progress.start();
      this.noresult = false;
      this.$http
        .get("Edit/SearchExpertise?term=" + encodeURIComponent(this.searchterm))
        .then(
          function(response) {
            this.$Progress.finish();
            this.expertises = response.data;
            this.noresult = this.expertises.length === 0;
          },
          function() {
            this.$Progress.finish();
            this.expertises = [];
          }
        );
    },
    editExpertise: function(e) {
      this.$refs.popupExpertise.show(e, this.expertises, this);
    },
    saveExpertise: function(e) {
      this.$Progress.start();
      this.$http.post("Admin/SaveExpertise", e).then(
        function() {
          this.$Progress.finish();
          this.searchterm = e.NameNL;
          this.searchExpertise(); // opnieuw zoekopdracht uitvoeren voor refresh
        },
        function() {
          this.$Progress.finish();
        }
      );
    },
    deleteExpertise: function(e) {
      var self = this;
      this.$refs.confirmdialog.confirm(
        "Expertise verwijderen?",
        "Delete expertise?",
        function() {
          this.$http.post("Admin/DeleteExpertise", e).then(function(response) {
            if (!response.data.Success) {
              alert(response.data.Message);
            } else {
              self.searchExpertise(); // opnieuw zoekopdracht uitvoeren voor refresh
            }
          });
        }
      );
    },
    beforeSaveExpertise: function() {
      var data = this.$refs.popupExpertise.getData();
      this.saveExpertise(data);
    },
    addExpertise: function() {
      var e = { Id: null, NameNL: "", NameEN: "", Synonym: [] };
      this.$refs.popupExpertise.show(e, this.expertises, this);
    },
    deleteSynonym: function(s) {
      var data = this.$refs.popupExpertise.getData();
      var index = data.Synonym.indexOf(s);
      if (index > -1) {
        data.Synonym.splice(index, 1);
      }
      this.$refs.popupExpertise.forceUpdate();
    },
    editSynonym: function(synonym) {
      var data = this.$refs.popupExpertise.getData();
      var newsynonym = prompt("Synoniem van expertise", synonym);
      for (var i = 0; i < data.Synonym.length; i++) {
        if (data.Synonym[i] === synonym) {
          data.Synonym[i] = newsynonym;
        }
      }
      this.$refs.popupExpertise.forceUpdate();
    },
    addSynonym: function() {
      var data = this.$refs.popupExpertise.getData();
      var newsynonym = prompt("Nieuw synoniem van expertise");
      data.Synonym.push(newsynonym);
      this.$refs.popupExpertise.forceUpdate();
    },
    overzichtMedewerkers: function(e) {
      this.$http
        .get("Admin/GetMedewerkersExpertise?id=" + e.Id.toString())
        .then(function(response) {
          this.overview = response.data;
          this.$refs.popupOverview.showSimple();
        });
    }
  },
  created: function() {
    this.loaddata();
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.kolomkop {
  font-weight: bold;
  font-size: 18px;
  line-height: 34px;
  margin-bottom: 3px;
  margin-top: 10px;
}

.iconcolumn {
  width: 50px;
}

.info {
  font-size: 8pt;
  line-height: 15px;
}

.filter-caption {
  font-weight: bold;
}

.search-input {
  height: 44px;
  position: relative;
  top: 1px;
  margin-right: 20px;
  width: 400px;
}

.filter-form {
  margin-bottom: 10px;
}

/*
.synonym-list {
}
*/
</style>

<template>
  <div>{{$route.meta.redirecttype}} {{$route.query.Id}}</div>
</template>

<script>
export default {
  name: "redirect",
  components: {},
  data() {
    return {};
  },
  methods: {},
  created: function() {
    switch (this.$route.meta.redirecttype) {
      case "expertise":
        this.$router.push({
          name: "organogram" + this.$root.lng,
          query: { expertise: this.$route.query.Id }
        });
        break;
      case "skill":
        this.$router.push({
          name: "organogram" + this.$root.lng,
          query: { skill: this.$route.query.Id }
        });
        break;
      case "position":
        this.$router.push({
          name: "organogram" + this.$root.lng,
          query: { skill: "-" + this.$route.query.Id }
        });
        break;
      case "focusarea":
        this.$router.push({
          name: "organogram" + this.$root.lng,
          query: { focusarea: this.$route.query.Id }
        });
        break;
      case "department":
        this.$http
          .get("Public/GetOMCodes?level=department&Id=" + this.$route.query.Id)
          .then(function(response) {
            this.$router.push({
              name: "organogram2" + this.$root.lng,
              params: {
                faculty: response.data.FacultyCode,
                department: response.data.DepartmentCode
              }
            });
          });
        break;
      case "section":
        this.$http
          .get("Public/GetOMCodes?level=section&Id=" + this.$route.query.Id)
          .then(function(response) {
            this.$router.push({
              name: "organogram3" + this.$root.lng,
              params: {
                faculty: response.data.FacultyCode,
                department: response.data.DepartmentCode,
                section: response.data.SectionCode
              }
            });
          });
        break;
      case "group":
        this.$http
          .get("Public/GetOMCodes?level=group&Id=" + this.$route.query.Id)
          .then(function(response) {
            this.$router.push({
              name: "organogram4" + this.$root.lng,
              params: {
                faculty: response.data.FacultyCode,
                department: response.data.DepartmentCode,
                section: response.data.SectionCode,
                group: response.data.GroupCode
              }
            });
          });
        break;
      case "afbeelding":
        window.location =
          "/medewerkers/RestApi/Public/GetImage?Employee=" +
          encodeURIComponent(this.$route.query.Profielfoto.toString());
        break;
      case "oude-pp-nl":
        window.location =
          "/medewerkers/" +
          encodeURIComponent(this.$route.query.Profielpagina.toString());
        break;
      case "oude-pp-en":
        window.location =
          "/staff/" +
          encodeURIComponent(this.$route.query.Profielpagina.toString());
        break;
      default:
        // Onbekende redirect => naar /medewerkers
        window.location = "/medewerkers";
        break;
    }
  }
};
</script>

<template>
  <div class="DivLogout">
    <div class="row">
      <div class="col-md-1"></div>
      <div class="col-md-10"></div>
      <div class="col-md-1"></div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  name: "login",
  data() {
    return {};
  },
  methods: {
    logout: function () {
      if (this.$route.query.SAMLRequest) {
        // Logout-request van de IdP
        this.browserlogoff();
        this.$http
          .get(
            "Edit/GetLogoutResponse?SAMLRequest=" +
              encodeURIComponent(Buffer.from(this.$route.query.SAMLRequest).toString('base64'))
          )
          .then(function (response) {
            // Redirect naar IdP-logout met response in querystring
            window.location = response.data;
          });

      } else {
        // Terugkeer van logout van IdP. Lokale sessie beeindigen en naar startscherm.
        this.browserlogoff();
        this.$router.push({ name: "SearchNL", params: {} });
      }

    },
    browserlogoff: function () {
      Vue.http.headers.common["Authorization"] = null;
      window.localStorage.setItem("authUser", null);
      window.sessionStorage.setItem("authUser", null);
      window.localStorage.setItem("token", null);
      window.localStorage.setItem("isadmin", null);
      this.$root.$emit("login_event");
    },
  },
  created: function () {
    this.logout();
  },
  components: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

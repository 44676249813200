<template>
  <div class="row">
    <div class="col-md-12">
      <div class="caption">
        <span v-if="$root.lng=='NL'">{{nameNL}}</span>
        <span v-if="$root.lng=='EN'">{{nameEN}}</span>
      </div>
      <div class="fieldcontent">
        <slot></slot>
        <span v-if="html" v-html="html"></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'textfield',
  props: ['nameNL', 'nameEN', 'html']
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.caption {
  font-weight: 700;
  line-height: 34px;
  font-size: 18px;
  margin-bottom: 4px;
}

.fieldcontent {
  margin-bottom: 25px;
}

/* Bullets in content, the textfield.vue */
.fieldcontent ul {
  list-style-type: disc;
  padding: 0 0 0 20px;
  list-style-position: outside;
}
</style>

<template>
  <div class="ProfielpaginaEdit">
    <b-alert v-show="errormessage != ''" variant="danger" show>
      <label-lng
        NL="Fout bij opslaan profielpagina."
        EN="Error saving profile page."
      ></label-lng>
      {{ errormessage }}
    </b-alert>

    <div v-if="medewerker">
      <div class="row header-balk font-sidebar">
        <bs-title
          :title="
            ($root.lng == 'NL' ? 'Bewerken pagina ' : 'Edit page ') +
            medewerker.Name
          "
        ></bs-title>

        <div class="col-md-9">
          <h1>
            <label-lng NL="Pagina bewerken " EN="Edit page " />
            {{ medewerker.Name }} ({{ medewerker.EmployeeType }})
          </h1>
        </div>
        <div class="col-md-3 headerbuttonsdiv" v-show="!popupmode">
          <button class="uu-button button-black" v-on:click="save()">
            <label-lng NL="Opslaan en sluiten" EN="Save and close" />
          </button> <button class="uu-button button-black" v-on:click="cancel()">
            <label-lng NL="Annuleren" EN="Cancel" />
          </button>
        </div>
      </div>

      <div class="row" v-show="!popupmode">
        <div class="col-md-10">
          <div class="tabset">
            <a
              href="javascript:void(0)"
              :class="{ actief: activetab == medewerker.Tabs.General.Title }"
              v-if="medewerker.Tabs.General.Visible"
              v-on:click="switchtab(medewerker.Tabs.General.Title)"
              >{{ medewerker.Tabs.General.Title }}</a
            >
            <a
              href="javascript:void(0)"
              :class="{ actief: activetab == medewerker.Tabs.Profile.Title }"
              v-if="medewerker.Tabs.Profile.Visible"
              v-on:click="switchtab(medewerker.Tabs.Profile.Title)"
              >{{ medewerker.Tabs.Profile.Title }}</a
            >
            <a
              href="javascript:void(0)"
              :class="{ actief: activetab == medewerker.Tabs.CV.Title }"
              v-if="medewerker.Tabs.CV.Visible"
              v-on:click="switchtab(medewerker.Tabs.CV.Title)"
              >{{ medewerker.Tabs.CV.Title }}</a
            >
            <a
              href="javascript:void(0)"
              :class="{
                actief: activetab == medewerker.Tabs.Publications.Title,
              }"
              v-if="medewerker.Tabs.Publications.Visible"
              v-on:click="switchtab(medewerker.Tabs.Publications.Title)"
              >{{ medewerker.Tabs.Publications.Title }}</a
            >
            <a
              href="javascript:void(0)"
              :class="{ actief: activetab == medewerker.Tabs.Research.Title }"
              v-if="medewerker.Tabs.Research.Visible"
              v-on:click="switchtab(medewerker.Tabs.Research.Title)"
              >{{ medewerker.Tabs.Research.Title }}</a
            >
            <a
              href="javascript:void(0)"
              :class="{ actief: activetab == medewerker.Tabs.Courses.Title }"
              v-if="medewerker.Tabs.Courses.Visible"
              v-on:click="switchtab(medewerker.Tabs.Courses.Title)"
              >{{ medewerker.Tabs.Courses.Title }}</a
            >
            <a
              href="javascript:void(0)"
              :class="{
                actief: activetab == medewerker.Tabs.AdditionalFunctions.Title,
              }"
              v-if="medewerker.Tabs.AdditionalFunctions.Visible"
              v-on:click="switchtab(medewerker.Tabs.AdditionalFunctions.Title)"
              >{{ medewerker.Tabs.AdditionalFunctions.Title }}</a
            >
            <a
              href="javascript:void(0)"
              :class="{ actief: activetab == medewerker.Tabs.CustomTabs.Title }"
              v-if="medewerker.Tabs.CustomTabs.Visible"
              v-on:click="switchtab(medewerker.Tabs.CustomTabs.Title)"
              >{{ medewerker.Tabs.CustomTabs.Title }}</a
            >
            <a
              href="javascript:void(0)"
              :class="{ actief: activetab == medewerker.Tabs.Contact.Title }"
              v-if="medewerker.Tabs.Contact.Visible"
              v-on:click="switchtab(medewerker.Tabs.Contact.Title)"
              >{{ medewerker.Tabs.Contact.Title }}</a
            >
            <a
              href="javascript:void(0)"
              :class="{ actief: activetab == medewerker.Tabs.Admin.Title }"
              v-if="medewerker.Tabs.Admin.Visible"
              v-on:click="switchtab(medewerker.Tabs.Admin.Title)"
              >{{ medewerker.Tabs.Admin.Title }}</a
            >
            <a
              href="javascript:void(0)"
              :class="{ actief: activetab == medewerker.Tabs.Help.Title }"
              v-if="medewerker.Tabs.Help.Visible"
              v-on:click="switchtab(medewerker.Tabs.Help.Title)"
              >{{ medewerker.Tabs.Help.Title }}</a
            >
            <a
              href="javascript:void(0)"
              :class="{ actief: activetab == medewerker.Tabs.Statistics.Title }"
              v-if="medewerker.Tabs.Statistics.Visible"
              v-on:click="switchtab(medewerker.Tabs.Statistics.Title)"
              >{{ medewerker.Tabs.Statistics.Title }}</a
            >
          </div>
        </div>
        <div class="col-md-2 toelichtingbar"></div>
      </div>

      <tab-edit-general
        :medewerker="medewerker"
        :info="info"
        v-show="istabactive(medewerker.Tabs.General)"
      />
      <tab-edit-profile
        :medewerker="medewerker"
        :faculties="faculties"
        :info="info"
        v-show="istabactive(medewerker.Tabs.Profile)"
      />
      <tab-edit-cv
        :medewerker="medewerker"
        :info="info"
        v-show="istabactive(medewerker.Tabs.CV)"
      />
      <tab-edit-publications
        :medewerker="medewerker"
        :info="info"
        v-show="istabactive(medewerker.Tabs.Publications)"
      />
      <tab-edit-research
        :medewerker="medewerker"
        :info="info"
        v-show="istabactive(medewerker.Tabs.Research)"
      />
      <tab-edit-courses
        :medewerker="medewerker"
        :faculties="faculties"
        :info="info"
        v-show="istabactive(medewerker.Tabs.Courses)"
      />
      <tab-edit-additionalfunctions
        :medewerker="medewerker"
        :info="info"
        v-show="istabactive(medewerker.Tabs.AdditionalFunctions)"
      />
      <tab-edit-custom
        :medewerker="medewerker"
        :info="info"
        v-show="istabactive(medewerker.Tabs.CustomTabs)"
      />
      <tab-edit-contact
        :medewerker="medewerker"
        :info="info"
        v-show="istabactive(medewerker.Tabs.Contact)"
      />
      <tab-edit-admin
        :medewerker="medewerker"
        :info="info"
        v-show="istabactive(medewerker.Tabs.Admin)"
      />
      <tab-edit-help
        :medewerker="medewerker"
        :info="info"
        v-show="istabactive(medewerker.Tabs.Help)"
      />
      <tab-edit-statistics
        :medewerker="medewerker"
        v-show="istabactive(medewerker.Tabs.Statistics)"
      />

      <div class="row footer-save-bar" v-show="!popupmode">
        <div class="col-md-12">
          <button class="uu-button button-yellow" v-on:click="save()">
            <label-lng NL="Opslaan en sluiten" EN="Save and close" /></button
          >&nbsp;<button class="uu-button button-yellow" v-on:click="cancel()">
            <label-lng NL="Annuleren" EN="Cancel" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import labellng from "@/components/layout/label-lng.vue";
import bstitle from "@/components/layout/title.vue";
import datehelper from "@/helpers/date-helper.vue";
import Tabgeneral from "@/components/edit/tab-edit-general.vue";
import Tabprofile from "@/components/edit/tab-edit-profile.vue";
import Tabcontact from "@/components/edit/tab-edit-contact.vue";
import Tabcv from "@/components/edit/tab-edit-cv.vue";
import Tabresearch from "@/components/edit/tab-edit-research.vue";
import Tabpublications from "@/components/edit/tab-edit-publications.vue";
import Tabcourses from "@/components/edit/tab-edit-courses.vue";
import Tabadditionalfunctions from "@/components/edit/tab-edit-additionalfunctions.vue";
import TabAdmin from "@/components/edit/tab-edit-admin.vue";
import TabHelp from "@/components/edit/tab-edit-help.vue";
import TabStatistics from "@/components/edit/tab-edit-statistics.vue";
import TabCustom from "@/components/edit/tab-edit-custom.vue";

export default {
  name: "profielpagina-edit",
  data() {
    return {
      medewerker: null,
      started: null,
      faculties: [],
      errormessage: "",
      info: null,
      activetab: null,
      popupmode: false,
    };
  },
  components: {
    "label-lng": labellng,
    "bs-title": bstitle,
    "tab-edit-general": Tabgeneral,
    "tab-edit-profile": Tabprofile,
    "tab-edit-contact": Tabcontact,
    "tab-edit-cv": Tabcv,
    "tab-edit-research": Tabresearch,
    "tab-edit-publications": Tabpublications,
    "tab-edit-courses": Tabcourses,
    "tab-edit-additionalfunctions": Tabadditionalfunctions,
    "tab-edit-admin": TabAdmin,
    "tab-edit-help": TabHelp,
    "tab-edit-statistics": TabStatistics,
    "tab-edit-custom": TabCustom,
  },
  methods: {
    // Load employee-data from webserivce
    loaddata: function () {
      this.ready = false;
      this.$Progress.start();
      var token = window.localStorage.getItem("token");
      this.$http
        .get(
          "Edit/GetEmployeeData?page=" +
            this.$route.params.employee +
            "&l=" +
            this.$root.lng +
            "&t=" +
            token +
            "&_=" +
            new Date().getTime()
        )
        .then(
          function (response) {
            this.$Progress.finish();
            this.medewerker = response.data;
            this.busy = false;
            this.ready = true;
            this.activetab = this.firsttab().Title;
          },
          function () {
            this.$Progress.finish();
            this.$router.push({
              name: "unauthorized",
              query: { page: this.$route.params.employee },
            });
          }
        );
      this.$http
        .get("Public/GetFaculties?l=" + this.$root.lng)
        .then(function (response) {
          this.faculties = response.data;
        });
      this.$http
        .get(
          "Edit/GetInfoText?l=" +
            this.$root.lng +
            "&p=" +
            this.$route.params.employee
        )
        .then(function (response) {
          this.info = response.data;
        });
    },
    save: function () {
      this.$Progress.start();
      this.$http.post("Edit/SaveEmployee", this.medewerker).then(
        function (response) {
          if (response.data.Success) {
            this.$Progress.finish();
            this.errormessage = "";
            var routename = "PublicNL";
            if (this.$root.lng === "EN") routename = "PublicEN";
            this.$router.push({
              name: routename,
              params: { employee: this.$route.params.employee },
            });
          } else {
            this.$Progress.finish();
            this.errormessage = response.data.Message;
          }
        },
        function () {
          this.$Progress.finish();
          this.errormessage = "Opslaan mislukt.";
        }
      );
    },
    cancel: function () {
      var routename = "PublicNL";
      if (this.$root.lng === "EN") routename = "PublicEN";
      this.$router.push({
        name: routename,
        params: { employee: this.$route.params.employee },
      });
    },
    switchtab: function (tabname) {
      this.activetab = tabname;
    },
    istabactive: function (tab) {
      return tab.Visible && this.activetab === tab.Title;
    },
    firsttab: function () {
      if (this.medewerker.Tabs.General.Visible) {
        return this.medewerker.Tabs.General;
      }
      if (this.medewerker.Tabs.Profile.Visible) {
        return this.medewerker.Tabs.Profile;
      }
      if (this.medewerker.Tabs.CV.Visible) {
        return this.medewerker.Tabs.CV;
      }
      if (this.medewerker.Tabs.Publications.Visible) {
        return this.medewerker.Tabs.Publications;
      }
      if (this.medewerker.Tabs.Research.Visible) {
        return this.medewerker.Tabs.Research;
      }
      if (this.medewerker.Tabs.Courses.Visible) {
        return this.medewerker.Tabs.Courses;
      }
      if (this.medewerker.Tabs.AdditionalFunctions.Visible) {
        return this.medewerker.Tabs.AdditionalFunctions;
      }
      if (this.medewerker.Tabs.Contact.Visible) {
        return this.medewerker.Tabs.Contact;
      }
      if (this.medewerker.Tabs.Admin.Visible) {
        return this.medewerker.Tabs.Admin;
      }
      if (this.medewerker.Tabs.Help.Visible) {
        return this.medewerker.Tabs.Help;
      }
      if (this.medewerker.Tabs.Statistics.Visible) {
        return this.medewerker.Tabs.Statistics;
      }
      return this.medewerker.Tabs.General;
    },
    popupmodeOn: function () {
      this.popupmode = true;
    },
    popupmodeOff: function () {
      this.popupmode = false;
    },
  },
  watch: {},
  created: function () {
    this.loaddata();
  },
  mixins: [datehelper],
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.headerbuttonsdiv {
  text-align: right;
  padding-top: 10px; /* Hiermee bepaal je de vertikale positie van de buttons opslaan/sluiten/annuleren */
}

.headerbuttonsdiv > button {
  margin-top: 0px;
}

.overlay {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.header-balk {
  background-color: #ffcd00;
  padding-top: 23px;
  padding-bottom: 23px;
  min-height: 50px;
  padding-left: 23px;
  padding-right: 23px;
}

/* Row met tabjes */
.tabset {
  margin-top: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #efefef;
  padding-left: 25px;
  padding-bottom: 0px;
  height: 27px;
}

/* de tabjes zelf */
.tabset a {
  border: 1px solid #efefef;
  border-bottom: 0px;
  padding-left: 15px;
  padding-right: 15px;
  height: 26px;
  font-size: 16px;
  font-family: Open Sans;
}

/* de geselecteerde tab*/
.actief {
  background-color: #ffcd00;
}

.toelichtingbar {
  background-color: #efefef;
}

.footer-save-bar {
  background-color: #fff;
  padding-top: 5px;
  padding-bottom: 20px;
}
</style>

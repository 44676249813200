<template>
  <edit-layout nameNL nameEN info>
    <span v-html="GetInfo(info,'Help.Algemeen')" />
  </edit-layout>
</template>

<script>
import infohelper from "@/helpers/info-helper.vue";
import editlayout from "@/components/layout/edit-layout.vue";

export default {
  name: "tab-edit-help",
  props: ["medewerker", "info"],
  data() {
    return {};
  },
  components: {
    "edit-layout": editlayout
  },
  methods: {},
  created: function() {},
  mixins: [infohelper]
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

<template>
  <div v-if="medewerker" class="TabEditResearchOutput">
    <edit-layout nameNL nameEN info>
      <div v-html="GetInfo(info, 'Publicatie.Algemeen')"></div>
    </edit-layout>
    <div v-show="medewerker.PureConnection">
      <edit-layout
        nameNL="Publicaties"
        nameEN="Research output"
        :info="GetInfo(info, 'Publicaties.Lijst')"
      >
        <button
          class="uu-button button-black"
          @click="refreshPure()"
          v-show="!purerefresh"
        >
          <label-lng
            NL="Refresh publicaties uit Pure"
            EN="Refesh publications from Pure"
          />
        </button>
        <span>
          <label-lng
            v-show="purerefresh"
            NL="Bezig met refresh..."
            EN="Refreshing..."
          />
        </span>
      </edit-layout>
    </div>
    <div v-show="!medewerker.PureConnection">
      <edit-layout nameNL="Pure-koppeling" nameEN="Pure connection" info>
        <label-lng
          NL="De Pure-koppeling is uitgeschakeld."
          EN="The Pure connection is disabled."
        />
      </edit-layout>
    </div>

    <edit-layout
      nameNL="Zichtbaarheid 'in de media'"
      nameEN="Visibility 'Media'"
      v-if="ZichtbaarheidMedia && !purerefresh"
    >
      <div v-for="pz in ZichtbaarheidMedia" :key="pz.Guid">
        <input
          type="checkbox"
          v-model="pz.Visible"
          :id="'ZM' + pz.Field"
        />&nbsp;<label :for="'ZM' + pz.Field">{{ pz.Displaytext }}</label>
        <br />
      </div>
    </edit-layout>

    <edit-layout
      class="free-text"
      nameNL="Vrije tekst 'in de media'"
      nameEN="Additional information 'Media'"
      :info="GetInfo(info, 'Publicaties.VrijetekstInDeMediaNL')"
      v-if="medewerker.MediaFreeText && !purerefresh"
    >
      <edit-textfield
        :value="medewerker.MediaFreeText"
        multilng="true"
        rtf="true"
        name="pubmed"
      ></edit-textfield>
    </edit-layout>

    <edit-layout
      nameNL="Zichtbaarheid 'prijzen'"
      nameEN="Visibility 'Prizes'"
      v-if="ZichtbaarheidPrijzen && !purerefresh"
    >
      <div v-for="pz in ZichtbaarheidPrijzen" :key="pz.Guid">
        <input
          type="checkbox"
          v-model="pz.Visible"
          :id="'ZP' + pz.Field"
        />&nbsp;<label :for="'ZP' + pz.Field">{{ pz.Displaytext }}</label>
        <br />
      </div>
    </edit-layout>

    <edit-layout
      class="free-text"
      nameNL="Vrije tekst 'prijzen'"
      nameEN="Additional information 'Prizes'"
      :info="GetInfo(info, 'Publicaties.VrijetekstPrijzenNL')"
      v-if="medewerker.PrizesFreeText && !purerefresh"
    >
      <edit-textfield
        :value="medewerker.PrizesFreeText"
        multilng="true"
        rtf="true"
        name="pubprft"
      ></edit-textfield>
    </edit-layout>

    <edit-layout
      nameNL="Zichtbaarheid publicaties"
      nameEN="Visibility publications"
      v-if="ZichtbaarheidPublicaties && !purerefresh"
    >
      <div v-for="pz in ZichtbaarheidPublicaties" :key="pz.Guid">
        <input
          type="checkbox"
          v-model="pz.Visible"
          :id="'ZU' + pz.Field"
        />&nbsp;<label :for="'ZU' + pz.Field">{{ pz.Displaytext }}</label>
        <br />
      </div>
    </edit-layout>

    <edit-layout
      class="free-text"
      nameNL="Vrije tekst publicaties bovenaan"
      nameEN="Additional information on publications Top"
      :info="GetInfo(info, 'Publicaties.VrijetekstTopNL')"
      v-if="medewerker.PublicationsFreeTop && !purerefresh"
    >
      <edit-textfield
        :value="medewerker.PublicationsFreeTop"
        multilng="true"
        rtf="true"
        name="pubtop"
      ></edit-textfield>
    </edit-layout>

    <edit-layout
      class="free-text"
      nameNL="Vrije tekst publicaties onderaan"
      nameEN="Additional information on publications Bottom"
      :info="GetInfo(info, 'Publicatie.TekstNL')"
      v-if="medewerker.PublicationsFreeBottom && !purerefresh"
    >
      <edit-textfield
        :value="medewerker.PublicationsFreeBottom"
        multilng="true"
        rtf="true"
        name="pubbot"
      ></edit-textfield>
    </edit-layout>
    <edit-layout
      nameNL="Zichtbaarheid 'activiteiten'"
      nameEN="Visibility 'Activities'"
      v-if="ZichtbaarheidActiviteiten && !purerefresh"
    >
      <div v-for="pz in ZichtbaarheidActiviteiten" :key="pz.Guid">
        <input
          type="checkbox"
          v-model="pz.Visible"
          :id="'ZA' + pz.Field"
        />&nbsp;<label :for="'ZA' + pz.Field">{{ pz.Displaytext }}</label>
        <br />
      </div>
    </edit-layout>

    <edit-layout
      class="free-text"
      nameNL="Vrije tekst 'activiteiten'"
      nameEN="Additional information 'Activities'"
      :info="GetInfo(info, 'Publicaties.VrijetekstActivitiesNL')"
      v-if="medewerker.ActivitiesFreeText && !purerefresh"
    >
      <edit-textfield
        :value="medewerker.ActivitiesFreeText"
        multilng="true"
        rtf="true"
        name="pubactft"
      ></edit-textfield>
    </edit-layout>
  </div>
</template>

<script>
import labellng from "@/components/layout/label-lng.vue";
import edittextfield from "@/components/layout/edit-textfield.vue";
import editlayout from "@/components/layout/edit-layout.vue";
import infohelper from "@/helpers/info-helper.vue";

export default {
  name: "tab-edit-publications",
  props: ["medewerker", "info"],
  data() {
    return {
      purerefresh: false,
    };
  },
  components: {
    "label-lng": labellng,
    "edit-textfield": edittextfield,
    "edit-layout": editlayout,
  },
  methods: {
    refreshPure: function () {
      this.$Progress.start();
      this.$parent.popupmodeOn();
      this.purerefresh = true;
      this.medewerker.Publications = null;
      this.$http
        .get(
          "Edit/RefreshPublications?page=" +
            this.$route.params.employee +
            "&t=&l=" +
            this.$root.lng
        )
        .then(
          function () {
            this.$Progress.finish();
            this.purerefresh = false;
            this.$parent.popupmodeOff();
            // this.medewerker.PublicatieZichtbaarheid = response.data;
          },
          function () {
            this.$Progress.finish();
            this.purerefresh = false;
            this.$parent.popupmodeOff();
          }
        );
    },
  },
  computed: {
    ZichtbaarheidMedia() {
      if (this.medewerker != null) {
        return this.medewerker.PublicatieZichtbaarheid.filter(
          (c) => c.Type === "Media"
        );
      } else {
        return null;
      }
    },
    ZichtbaarheidPrijzen() {
      if (this.medewerker != null) {
        return this.medewerker.PublicatieZichtbaarheid.filter(
          (c) => c.Type === "Prizes"
        );
      } else {
        return null;
      }
    },
    ZichtbaarheidPublicaties() {
      if (this.medewerker != null) {
        return this.medewerker.PublicatieZichtbaarheid.filter(
          (c) => c.Type === "Publications"
        );
      } else {
        return null;
      }
    },
    ZichtbaarheidActiviteiten() {
      if (this.medewerker != null) {
        return this.medewerker.PublicatieZichtbaarheid.filter(
          (c) => c.Type === "Activities"
        );
      } else {
        return null;
      }
    },
  },
  created: function () {},
  mixins: [infohelper],
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.groupname {
  font-weight: bold;
  font-size: 12pt;
}

.publication-group-header {
  font-weight: bold;
  margin-top: 10px;
  font-size: 11px;
}

.publication-row {
  font-size: 9px;
  margin-bottom: 5px;
}

.publication-filter {
  font-size: 9px;
}

.free-text {
  margin-bottom: 10px;
}
</style>

<template>
  <div v-if="medewerker" class="TabResearch public-tab-content">
    <!-- Eventuele vrije tekst -->
    <textfield
      nameNL
      nameEN
      :html="medewerker.ProjectsFreeTextTop"
      v-if="medewerker.ProjectsFreeTextTop"
    ></textfield>

    <!-- Projecten -->
    <label-lng
      class="project-soort font-headline"
      NL="Projecten"
      EN="Projects"
      v-if="medewerker.Projects && medewerker.Projects.length > 0"
    />
    <div v-for="p in medewerker.Projects" :key="p.Guid">
      <div class="row" v-if="p.Title">
        <div class="col-md-12">
          <div class="project font-headline">
            <label-lng NL="Project" EN="Project" />
          </div>
          <div class="project-title">
            {{ p.Title }}
            <span class="project-timespan">
              <span v-if="p.Start">{{ formatdate(p.Start) }}</span>
              <span v-if="p.End"
                ><label-lng NL=" tot " EN=" to " />{{ formatdate(p.End) }}
              </span>
            </span>
          </div>
        </div>
      </div>
      <textfield
        nameNL="Algemene projectbeschrijving"
        nameEN="General project description"
        :html="p.DescriptionGeneral"
        v-if="p.DescriptionGeneral"
      ></textfield>
      <textfield nameNL="Rol" nameEN="Role" v-if="p.Role">{{
        p.Role
      }}</textfield>
      <textfield
        nameNL="Individuele projectbeschrijving"
        nameEN="Individual project description"
        :html="p.DescriptionIndividual"
        v-if="p.DescriptionIndividual"
      ></textfield>
      <textfield nameNL="Financiering" nameEN="Funding" v-if="p.Funding"
        >{{ p.Funding }} {{ p.FundingDescription }}</textfield
      >
      <textfield
        nameNL="Projectleden"
        nameEN="Project members UU"
        v-if="p.UUMembers && p.UUMembers.length > 0"
      >
        <ul>
          <li v-for="l in p.UUMembers" :key="l.Guid">
            <router-link
              :to="{ name: 'Public' + $root.lng, params: { employee: l.Url } }"
              target="_blank"
              >{{ l.Name }}</router-link
            >
          </li>
        </ul>
      </textfield>
      <textfield
        nameNL="Overige projectleden"
        nameEN="External project members"
        v-if="p.NonUUMembers && p.NonUUMembers.length > 0"
      >
        <ul>
          <li v-for="l in p.NonUUMembers" :key="l.Guid">{{ l.Name }}</li>
        </ul>
      </textfield>
    </div>

    <!-- Afgesloten projecten -->
    <label-lng
      class="caption"
      NL="Afgesloten projecten"
      EN="Completed Projects"
      v-if="
        medewerker.ProjectsCompleted && medewerker.ProjectsCompleted.length > 0
      "
    />

    <div v-for="p in medewerker.ProjectsCompleted" :key="p.Guid">
      <div class="row" v-if="p.Title">
        <div class="col-md-12">
          <div class="project font-headline">
            <label-lng NL="Project" EN="Project" />
          </div>
          <div class="project-title">
            {{ p.Title }}
            <span class="project-timespan">
              <span v-if="p.Start">{{ formatdate(p.Start) }}</span>
              <label-lng NL=" tot " EN=" to " />
              {{ formatdate(p.End) }}
            </span>
          </div>
        </div>
      </div>
      <textfield
        nameNL="Algemene projectbeschrijving"
        nameEN="General project description"
        :html="p.DescriptionGeneral"
        v-if="p.DescriptionGeneral"
      ></textfield>
      <textfield nameNL="Rol" nameEN="Role" v-if="p.Role">{{
        p.Role
      }}</textfield>
      <textfield
        nameNL="Individuele projectbeschrijving"
        nameEN="Individual project description"
        :html="p.DescriptionIndividual"
        v-if="p.DescriptionIndividual"
      ></textfield>
      <textfield nameNL="Financiering" nameEN="Funding" v-if="p.Funding"
        >{{ p.Funding }} {{ p.FundingDescription }}</textfield
      >
      <textfield
        nameNL="Projectleden"
        nameEN="Project members UU"
        v-if="p.UUMembers && p.UUMembers.length > 0"
      >
        <ul>
          <li v-for="l in p.UUMembers" :key="l.Guid">
            <router-link
              :to="{ name: 'Public' + $root.lng, params: { employee: l.Url } }"
              target="_blank"
              >{{ l.Name }}</router-link
            >
          </li>
        </ul>
      </textfield>
      <textfield
        nameNL="Overige projectleden"
        nameEN="External project members"
        v-if="p.NonUUMembers && p.NonUUMembers.length > 0"
      >
        <ul>
          <li v-for="l in p.NonUUMembers" :key="l.Guid">{{ l.Name }}</li>
        </ul>
      </textfield>
    </div>

    <!-- Eventuele vrije tekst -->
    <textfield
      nameNL
      nameEN
      :html="medewerker.ProjectsFreeTextBottom"
      v-if="medewerker.ProjectsFreeTextBottom"
    ></textfield>
  </div>
</template>

<script>
import labellng from "@/components/layout/label-lng.vue";
import textfield from "@/components/layout/textfield.vue";
import datehelper from "@/helpers/date-helper.vue";

export default {
  name: "tab-research",
  data() {
    return {};
  },
  props: ["medewerker"],
  components: {
    textfield: textfield,
    "label-lng": labellng,
  },
  mixins: [datehelper],
};
</script>

<style scoped>
.project-soort {
  font-size: 36px;
  line-height: 40px;
  font-weight: 100;
}

.caption {
  font-weight: 700;
  line-height: 34px;
  font-size: 18px;
  margin-bottom: 4px;
}

.project {
  font-size: 18px;
  padding-top: 1.2em;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 34px;
}

.project-timespan {
  font-size: 90%;
}

.project-title {
  padding-bottom: 25px;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
</style>

<script>
export default {
  name: "bs-title",
  props: ["title"],
  methods: {
    forceUpdate: function() {
      document.title = this.title;
    }
  },
  created() {
    document.title = this.title;
  },
  watch: {
    title() {
      // only used when the title changes after page load
      document.title = this.title;
    }
  },
  render() { return null }
};
</script>

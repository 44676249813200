<template>
  <div v-if="medewerker" class="TabCV">
    <div v-html="medewerker.CV" v-if="medewerker.CV" class="rtffield"></div>
    <a
      :href="$http.options.root + medewerker.CVUrl + token"
      target="_blank"
      v-if="medewerker.CVUrl"
    >
      <button class="uu-button button-black">
        <label-lng NL="Download CV" EN="Download CV" />
      </button>
    </a>
  </div>
</template>

<script>
import labellng from '@/components/layout/label-lng.vue'

export default {
  name: 'tab-cv',
  data () {
    return {
      token: window.localStorage.getItem('token')
    }
  },
  props: ['medewerker'],
  components: {
    'label-lng': labellng
  }
};
</script>

<style scoped>
.TabCV {
  line-height: 34px;
}
</style>

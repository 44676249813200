<template>
  <div v-if="content" class="TabCustom">
    <div v-html="content" class="rtffield"></div>
  </div>
</template>

<script>
export default {
  name: 'tab-custom',
  data () {
    return {}
  },
  props: ['content'],
  components: {
  }
};
</script>

<style scoped>
.TabCustom {
  line-height: 34px;
}
</style>

<template>
  <admin-layout title="Beheer focusgebieden/strategische thema's">
    <div class="row" v-if="focusareas">
      <div class="col-md-12">
        <table class="table table-sm table-hover">
          <thead>
            <tr>
              <th>Nederlands</th>
              <th>Engels</th>
              <th>#Med</th>
              <th class="iconcolumn">Acties</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="fa in focusareas" :key="fa.Guid">
              <td>{{ fa.Name.NL }}</td>
              <td>{{ fa.Name.EN }}</td>
              <td>{{ fa.Count }}</td>
              <td>
                <iconbutton icon="pencil-alt" titleNL="Bewerken" titleEN="Edit" @click="edit(fa)" />
                <iconbutton
                  icon="trash-alt"
                  titleNL="Verwijderen"
                  titleEN="Delete"
                  @click="del(fa)"
                  v-show="fa.Count == 0"
                />
              </td>
            </tr>
          </tbody>
        </table>
        <button class="uu-button button-black" @click="nw()">Nieuw focusgebied/strategisch thema</button>
      </div>
    </div>

    <popup
      ref="popupEdit"
      @okclick="beforeSave()"
      titleNL="Focusgebied/strategisch thema"
      titleEN="Focusarea"
    >
      <form slot-scope="popup">
        <div v-if="popup.data != null">
          <div class="form-group">
            <label for="namenl">Naam NL</label>
            <input
              v-model="popup.data.Name.NL"
              class="form-control"
              name="namenl"
              id="namenl"
              :maxlength="popup.data.Name.MaxLength"
            />
          </div>
          <div class="form-group">
            <label for="nameen">Naam EN</label>
            <input
              v-model="popup.data.Name.EN"
              class="form-control"
              name="nameen"
              id="nameen"
              :maxlength="popup.data.Name.MaxLength"
            />
          </div>
          <div class="form-group">
            <label for="urlnl">Url NL</label>
            <input
              v-model="popup.data.Url.NL"
              class="form-control"
              name="urlnl"
              id="urlnl"
              :maxlength="popup.data.Url.MaxLength"
            />
          </div>
          <div class="form-group">
            <label for="urlen">Url EN</label>
            <input
              v-model="popup.data.Url.EN"
              class="form-control"
              name="urlen"
              id="urlen"
              :maxlength="popup.data.Url.MaxLength"
            />
          </div>
        </div>
      </form>
    </popup>
    <confirm ref="confirmdialog"></confirm>
  </admin-layout>
</template>

<script>
import popup from "@/components/layout/popup.vue";
import iconbutton from "@/components/layout/iconbutton.vue";
import AdminLayout from "@/components/layout/admin-layout.vue";
import confirmdialog from "@/components/layout/confirm.vue";

export default {
  name: "BeheerFocusarea",
  components: {
    popup: popup,
    iconbutton: iconbutton,
    "admin-layout": AdminLayout,
    confirm: confirmdialog
  },
  data() {
    return {
      focusareas: []
    };
  },
  methods: {
    loaddata: function() {
      this.$Progress.start();
      this.$http.get("Admin/GetFocusareas").then(
        function(response) {
          this.focusareas = response.data;
          this.$Progress.finish();
        },
        function() {
          // Fail
          this.$Progress.finish();
        }
      );
    },
    edit: function(ent) {
      this.$refs.popupEdit.show(ent, this.focusareas, this);
    },
    beforeSave: function() {
      var data = this.$refs.popupEdit.getData();

      this.$http.post("Admin/SaveFocusArea", data).then(function() {
        this.loaddata();
      });
    },
    del: function(ent) {
      var self = this;
      this.$refs.confirmdialog.confirm(
        "Weet u zeker dat u dit focusgebied wilt verwijderen?",
        "Deleting focusarea. Are you sure?",
        function() {
          this.$http
            .post("Admin/DeleteFocusarea", ent)
            .then(function(response) {
              if (!response.data.Success) {
                alert(response.data.Message);
              } else {
                self.loaddata();
              }
            });
        }
      );
    },
    nw: function() {
      this.$http.get("Admin/NewFocusArea").then(function(response) {
        this.$refs.popupEdit.show(response.data, this.focusareas, this);
      });
    }
  },
  created: function() {
    this.loaddata();
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.iconcolumn {
  width: 30px;
}
</style>

<template>
  <div v-if="medewerker" class="TabEditGeneral">
    <edit-layout
      nameNL="Naam"
      nameEN="Name"
      :info="GetInfo(info, 'Header.NaamEnTitel')"
    >
      <div class="row namerow">
        <div class="col-md-4">
          <div>
            <label-lng NL="Titel" EN="Title" />
          </div>
          <div>
            <input
              type="text"
              v-model="medewerker.Title"
              :disabled="!medewerker.EditNameAndTitle"
              maxlength="100"
            />
          </div>
          <div>
            <label-lng NL="Voorvoegsels" EN="Prefix" />
          </div>
          <div>
            <input
              type="text"
              v-model="medewerker.Prefix"
              :disabled="!medewerker.EditNameAndTitle"
              maxlength="100"
            />
          </div>
        </div>
        <div class="col-md-4">
          <div>
            <label-lng NL="Voorletters" EN="Initials" />
          </div>
          <div>
            <input
              type="text"
              v-model="medewerker.Initials"
              :disabled="!medewerker.EditNameAndTitle"
              maxlength="100"
            />
          </div>
          <div>
            <label-lng NL="Achternaam" EN="Last name" />
          </div>
          <div>
            <input
              type="text"
              v-model="medewerker.Lastname"
              :disabled="!medewerker.EditNameAndTitle"
              maxlength="400"
            />
          </div>
        </div>
        <div class="col-md-4">
          <div>
            <label-lng NL="Voornaam" EN="First name" />
          </div>
          <div>
            <input
              type="text"
              v-model="medewerker.Firstname"
              :disabled="!medewerker.EditNameAndTitle"
              maxlength="400"
            />
          </div>
          <div>
            <label-lng NL="Titel achter" EN="Title after" />
          </div>
          <div>
            <input
              type="text"
              v-model="medewerker.TitleAfter"
              :disabled="!medewerker.EditNameAndTitle"
              maxlength="100"
            />
          </div>
        </div>
      </div>
    </edit-layout>

    <edit-layout
      nameNL="Profielfoto"
      nameEN="Profile picture"
      :info="GetInfo(info, 'Header.Profielfoto')"
    >
      <div class="row photorow">
        <div class="col-md-8">
          <div class="photo-frame">
            <div>
              <img
                :src="$http.options.root + medewerker.PhotoUrl"
                alt="Profielfoto"
                v-show="!medewerker.PhotoDeleted"
              />
            </div>
          </div>
          <div class="photo-controls">
            <button
              class="uu-button button-black"
              onclick="document.getElementById('inputProfilePhoto').click()"
            >
              <label-lng NL="Upload foto" EN="Upload photo" />
            </button>
            <br />
            <button class="uu-button button-black" @click="remove()">
              <label-lng NL="Verwijder foto" EN="Delete photo" />
            </button>
            <input
              id="inputProfilePhoto"
              type="file"
              style="display: none"
              v-on:change="upload($event)"
              accept="image/jpeg"
            />
          </div>
        </div>
        <div class="col-md-4">
          <input
            type="checkbox"
            v-model="medewerker.PhotoAD"
            id="PhotoAD"
            :disabled="medewerker.PhotoADDisabled"
          />&nbsp;<label for="PhotoAD">
            <label-lng
              NL="Toon mijn foto ook in interne UU-applicaties"
              EN="Display my picture also in internal UU applications"
            />
          </label>
          <div class="photo-attributes">
            <label-lng
              NL="Zichtbaarheid profielfoto"
              EN="Visibility picture"
            />&nbsp;
            <select
              v-if="$root.lng == 'NL'"
              v-model="medewerker.PhotoVisibility"
            >
              <option value="1">Publiek</option>
              <option value="2">Alleen binnen UU</option>
            </select>
            <select
              v-if="$root.lng == 'EN'"
              v-model="medewerker.PhotoVisibility"
            >
              <option value="1">Public</option>
              <option value="2">Within UU only</option>
            </select>
          </div>
        </div>
      </div>
    </edit-layout>

    <edit-layout
      nameNL="Naamweergave"
      nameEN="Name display"
      :info="GetInfo(info, 'Profiel.Naamweergave')"
      v-if="medewerker.NameDisplayId"
    >
      <select class="form-control" v-model="medewerker.NameDisplayId.NL">
        <option
          v-for="nd in medewerker.NameDisplay"
          :value="nd.Id"
          :key="nd.Guid"
        >
          {{ nd.Name }}
        </option>
      </select>
    </edit-layout>

    <!-- Leeropdracht en aanstellingsdatum  -->
    <edit-layout
      nameNL="Leeropdracht"
      nameEN="Chair"
      :info="GetInfo(info, 'Profiel.LeeropdrachtNL')"
      v-if="medewerker.Chair"
    >
      <edit-textfield
        :value="medewerker.Chair"
        multilng="true"
        name="EG1"
      ></edit-textfield>
    </edit-layout>

    <edit-layout
      nameNL="Leerstoel van"
      nameEN="Endowed by"
      :info="GetInfo(info, 'Profiel.LeerstoelVanNL')"
      v-if="medewerker.EndowedBy"
    >
      <edit-textfield
        :value="medewerker.EndowedBy"
        multilng="true"
        name="EG2"
      ></edit-textfield>
    </edit-layout>

    <edit-layout
      nameNL="Aanstellingsdatum"
      nameEN="Date of Appointment"
      :info="GetInfo(info, 'Profiel.Benoemingsdatum')"
      v-if="medewerker.DateOfAppointment"
    >
      <edit-datefield :value="medewerker.DateOfAppointment"></edit-datefield>
    </edit-layout>

    <edit-layout
      nameNL="Oratiedatum"
      nameEN="Inaugural lecture"
      :info="GetInfo(info, 'Profiel.Oratiedatum')"
      v-if="medewerker.DateInauguralLecture"
    >
      <edit-datefield :value="medewerker.DateInauguralLecture"></edit-datefield>
    </edit-layout>

    <!-- Bio -->
    <edit-layout
      nameNL="Korte omschrijving"
      nameEN="Short description"
      :info="GetInfo(info, 'Profiel.BioNL')"
      v-if="medewerker.Bio"
    >
      <edit-textfield
        :value="medewerker.Bio"
        multilng="true"
        name="EG3"
      ></edit-textfield>
    </edit-layout>

    <!-- Focusareas -->
    <edit-layout
      nameNL="Onderzoeksthema's"
      nameEN="Research areas"
      :info="GetInfo(info, 'Profiel.Focusgebied')"
      v-if="medewerker.FocusAreas"
    >
      <tab-edit-profile-focusareas
        :medewerker="medewerker"
        :info="info"
      ></tab-edit-profile-focusareas>
    </edit-layout>

    <!-- Expertises -->
    <edit-layout
      nameNL="Onderzoeksspecialismen en wetenschappelijke expertises"
      nameEN="Research specialties and scientific areas of expertise"
      :info="GetInfo(info, 'Profiel.Expertises')"
      v-if="medewerker.Expertises"
    >
      <tab-edit-profile-expertises
        :medewerker="medewerker"
        :info="info"
      ></tab-edit-profile-expertises>
    </edit-layout>

    <!-- Vaardigheden (skills) -->
    <edit-layout
      nameNL="Vaardigheden"
      nameEN="Skills"
      :info="GetInfo(info, 'Profiel.Vaardigheden')"
      v-if="medewerker.Skills"
    >
      <tab-edit-profile-skills
        :medewerker="medewerker"
        :info="info"
      ></tab-edit-profile-skills>
    </edit-layout>
  </div>
</template>

<script>
import labellng from "@/components/layout/label-lng.vue";
import editlayout from "@/components/layout/edit-layout.vue";
import infohelper from "@/helpers/info-helper.vue";
import edittextfield from "@/components/layout/edit-textfield.vue";
import editdatefield from "@/components/layout/edit-datefield.vue";
import profileexpertises from "@/components/edit/tab-edit-profile-expertises.vue";
import profileskills from "@/components/edit/tab-edit-profile-skills.vue";
import profilefocusareas from "@/components/edit/tab-edit-profile-focusareas.vue";

export default {
  name: "tab-edit-general",
  data() {
    return {};
  },
  props: ["medewerker", "info"],
  components: {
    "label-lng": labellng,
    "edit-layout": editlayout,
    "edit-textfield": edittextfield,
    "edit-datefield": editdatefield,
    "tab-edit-profile-expertises": profileexpertises,
    "tab-edit-profile-skills": profileskills,
    "tab-edit-profile-focusareas": profilefocusareas,
  },
  methods: {
    remove: function () {
      if (confirm("Verwijder profielfoto? / Remove profile picture?")) {
        this.medewerker.PhotoDeleted = true;
      }
    },
    upload: function (event) {
      var files = event.target.files || event.dataTransfer.files;
      if (!files.length) return;

      var f = files[0];
      var reader = new FileReader();
      var self = this;

      reader.addEventListener(
        "load",
        function () {
          var contents = JSON.stringify(reader.result);

          self.$http
            .post("Edit/SaveFile", {
              Filename: "profielfoto.jpg",
              Content: contents,
              ResizeProfilePicture: true,
              EmployeeId: self.medewerker.Id,
              Filetype: "Profpic",
            })
            .then(function (response) {
              self.medewerker.Photocode = response.data;
              self.medewerker.PhotoDeleted = false;
              self.medewerker.PhotoUrl =
                "/Edit/TempPhoto?code=" + response.data;
            });
        },
        false
      );

      reader.readAsDataURL(f);
    },
  },
  mixins: [infohelper],
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.namerow {
  margin-bottom: 10px;
}

.namerow input[type="text"] {
  width: 100%;
}

.photo-frame {
  width: 175px;
  height: 260px;
  background-color: #e6e6e6;
  line-height: 1;
  position: relative; /* Truuk om horizontaal en verticaal te centreren */
  float: left;
}

.photo-controls {
  margin-left: 25px;
  float: left;
  margin-top: -15px;
}

.photo-controls button {
  width: 200px;
}

.photo-attributes {
  clear: both;
}

.photo-frame > div {
  /* Truuk om horizontaal en verticaal te centreren */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>

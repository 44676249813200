<template>
  <admin-layout title="Beheer profielpagina's">
    <confirm ref="confirmdialog"></confirm>
    <h2>Filter</h2>
    <div class="row">
      <div class="col-md-4">
        <form onsubmit="return false;">
          <div class="form-group">
            <label for="name">Naam medewerker of SolisID</label>
            <input type="text" class="form-control" v-model="name" id="name" />
          </div>
          <div class="form-group">
            <label for="status">Pagina-status</label>
            <select class="form-control" v-model="status" id="status">
              <option value="all">&lt;Alle&gt;</option>
              <option value="public">Openbare pagina's</option>
              <option value="offline">Offline pagina's</option>
              <option value="internal">Interne pagina's</option>
            </select>
          </div>
          <div class="form-group">
            <label for="faculty">Faculteit</label>
            <select class="form-control" v-model="faculty" id="faculty">
              <option v-for="f in faculties" :value="f.FacultyId" :key="f.Guid">
                {{ f.Faculty }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label for="date">Aangemaakt na</label>
            <datepicker
              v-model="date"
              monday-first
              clear-button
              bootstrapStyling
            ></datepicker>
          </div>
          <div class="form-group">
            <input
              type="submit"
              class="uu-button button-black"
              @click="search()"
              value="Zoek"
            />
          </div>
        </form>
      </div>
    </div>
    <div v-show="result.length > 0">
      <h2>Zoekresultaat</h2>
      <h5>{{ result.length }} medewerker(s) gevonden</h5>
      <table class="table table-sm table-hover">
        <thead>
          <tr>
            <th>Medewerker</th>
            <th>SolisID</th>
            <th>Pagina status</th>
            <th>Actie</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="m in result" :key="m.Guid">
            <td>{{ m.Name }}</td>
            <td>{{ m.SolisID }}</td>
            <td>{{ m.State }}</td>
            <td>
              <a
                :href="'../' + m.Pagename"
                target="_blank"
                :title="'Open pagina van ' + m.Name"
                v-if="m.State != 'Offline'"
              >
                <icon name="user" />&nbsp;</a
              >
              <a
                :href="'../bewerken/' + m.Pagename"
                target="_blank"
                :title="'Bewerk pagina van ' + m.Name"
              >
                <icon name="pencil-alt" />&nbsp;</a
              >
              <iconbutton
                icon="trash"
                titleNL="Verwijder profielpagina"
                titleEN="Delete profile page"
                @click="deletepp(m)"
                class="delete"
                v-if="userinfo.IsAdmin"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-show="noresult">
      Geen resultaat gevonden.
      <span v-show="nocriteria">Vul naam/SolisID of 'aangemaakt na' in.</span>
    </div>
    <div v-show="searching">Bezig met zoeken...</div>
  </admin-layout>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import confirmdialog from "@/components/layout/confirm.vue";
import iconbutton from "@/components/layout/iconbutton.vue";
import AdminLayout from "@/components/layout/admin-layout.vue";

export default {
  name: "BeheerOverzicht",
  components: {
    Datepicker: Datepicker,
    iconbutton: iconbutton,
    confirm: confirmdialog,
    "admin-layout": AdminLayout,
  },
  data() {
    return {
      name: "",
      status: "all",
      faculty: "-1",
      date: null,
      faculties: [],
      result: [],
      noresult: false,
      searching: false,
      nocriteria: false,
      userinfo: null,
    };
  },
  methods: {
    loaddata: function () {
      this.$http
        .get("Public/GetFaculties?l=" + this.$root.lng)
        .then(function (response) {
          this.faculties = response.data;
          this.faculties.splice(
            0,
            0,
            { FacultyId: -1, Faculty: "<Alle faculteiten>" },
            { FacultyId: -2, Faculty: "<Zonder faculteit>" }
          );
        });

      this.$http.get("Edit/GetUserInfo").then(function (response) {
        this.userinfo = response.data;
      });
    },
    search: function () {
      this.$Progress.start();
      this.nocriteria =
        this.name === "" && this.date == null && this.status === "all";
      var data = {
        name: this.name,
        faculty: this.faculty,
        state: this.status,
        date: this.date,
      };
      this.searching = true;
      this.noresult = false;
      this.result = [];
      this.$http.post("Admin/SearchAdmin", data).then(
        function (response) {
          this.$Progress.finish();
          this.result = response.data;
          this.noresult = response.data.length === 0;
          this.searching = false;
        },
        function () {
          // Fail
          this.$Progress.finish();
        }
      );
    },
    // open/edit met router gaat niet, moet in nieuw tabje....
    open: function (pagename) {
      this.$router.push({ name: "PublicNL", params: { employee: pagename } });
    },
    edit: function (pagename) {
      this.$router.push({ name: "EditNL", params: { employee: pagename } });
    },
    deletepp: function (m) {
      var data = this;
      var nl = "";
      var en = "";
      if (m.IAM) {
        nl =
          "Weet je zeker dat je de profielpagina inclusief alle onderliggende data wilt verwijderen?<br/>Let op: medewerker is nog aanwezig in IAM.";
        en =
          "Weet je zeker dat je de profielpagina inclusief alle onderliggende data wilt verwijderen?<br/>Let op: medewerker is nog aanwezig in IAM.";
      } else {
        nl =
          "Weet je zeker dat je de profielpagina inclusief alle onderliggende data wilt verwijderen?";
        en =
          "Weet je zeker dat je de profielpagina inclusief alle onderliggende data wilt verwijderen?";
      }
      this.$refs.confirmdialog.confirm(nl, en, function () {
        this.$http
          .get("Admin/EmployeeDelete?page=" + m.Pagename)
          .then(function () {
            // Refresh
            data.search();
          });
      });
    },
  },
  created: function () {
    this.loaddata();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.delete {
  color: red;
}
</style>

<template>
  <div class="mobile-search">
    <!-- Header -->
    <mobile-header />

    <!-- Mobiel zoekscherm -->
    <div class="Mobile-font">
      <div class="row photo">
        <div class="col-md-12">
          <img src="../../assets/header-mobiel.jpg" class="img-fluid w-100" />
        </div>
      </div>
      <div class="row gele-balk">
        <div class="col-12 Search-Caption">
          <label-lng NL="Zoek een medewerker" EN="Search employee" />
        </div>
      </div>

      <div class="row Search-Input">
        <div class="col-12">
          <autocomplete
            ref="searchautocomplete"
            :placeholder="placeholdertext"
            :sourceurl="autocompleteurl"
            @OnItemSelect="autocompleteclick"
            @OnEnterPress="enterpress"
            results-display="label"
            results-value="id"
            input-class="autocomplete-mobile"
          />

          <div class="search-mode">
            <label-lng NL="Zoek op " EN="Search for " />
          </div>
          <div class="searchmodeoptions">
            <b-form-radio-group plain stacked v-model="searchmode" :options="searchmodeoptions" />
          </div>
        </div>
      </div>

      <div class="row search-result" v-if="searchresult && searchresult.Count > 0">
        <div class="col-12 search-result-header">
          <label-lng NL="Zoekresultaat" EN="Results" />
        </div>
        <div class="col-12 result-row" v-for="r in searchresult.Employees" :key="r.Guid">
          <router-link
            :to="{ name: 'mobile' + $root.lng, params:{ employee: r.Url }}"
            style="width:100%"
          >
            <h3>{{r.Name}}</h3>
            <span>{{r.Positions[0].Position}}</span>
          </router-link>
        </div>
      </div>

      <div
        class="row search-no-result"
        v-if="noresult && searchexpression && searchexpression !== ''"
      >
        <h2>
          <label-lng NL="Niets gevonden met zoekterm" EN="No results for" />
          "{{searchexpression}}"
        </h2>
      </div>
    </div>

    <div class="Mobile-font fixed-bottom back-button" @click="gotoOrganogram()">
      <img src="../../assets/tree-white.png" />
      <label-lng NL="Organogram" EN="Organisational Chart" />
    </div>
  </div>
</template>

<script>
import labellng from "@/components/layout/label-lng.vue";
import Autocomplete from "@/components/layout/autocomplete.vue";
import mobileheader from "@/components/mobile/mobile-header.vue";

export default {
  name: "mobile-search",
  components: {
    "label-lng": labellng,
    autocomplete: Autocomplete,
    "mobile-header": mobileheader
  },
  data() {
    return {
      token: window.localStorage.getItem("token"),
      searchexpression: "",
      autocompleteurl: "",
      searchmode: "N", // Voorlopig alleen op naam
      searchresult: [],
      noresult: false,
      searchmodeoptions: [
        { text: "Naam", value: "N" },
        { text: "Onderwerp of expertise", value: "O" },
        { text: "Telefoonnummer of e-mailadres", value: "T" }
      ],
      placeholdertext: ""
    };
  },
  head: {
    link: [
      { rel: "canonical", href: "https://www.uu.n/medewerkers", id: "link-dt" }
    ]
  },
  methods: {
    loaddata: function() {},
    setautocompleteurl: function() {
      this.autocompleteurl =
        this.$http.options.root +
        "/RestApi/medewerkerautocomplete?mode=" +
        this.searchmode +
        "&t=" +
        this.token +
        "&taal=" +
        this.$root.lng +
        "&catrow=1&term=";
    },
    authHeaders: function() {
      var ah = this.$http.headers.common["Authorization"];
      return {
        Authorization: ah
      };
    },

    search: function() {
      if (JSON.stringify(this.$route.query) !== "{}") {
        var token = window.localStorage.getItem("token");
        if (!token) token = "";
        this.searchmodefromUri();
        this.searchexpression = this.$route.query.q;
        if (this.searchexpression == null || this.searchexpression === "") {
          this.searchexpression = this.$route.query.d;
        }
        this.$Progress.start();
        this.noresult = false;
        this.$http
          .get(
            "Public/search?expression=" +
              encodeURIComponent(JSON.stringify(this.$route.query)) +
              "&t=" +
              token +
              "&l=" +
              this.$root.lng +
              "&m=1"
          )
          .then(function(response) {
            this.searchresult = response.data;
            this.$Progress.finish();
            this.noresult = this.searchresult.Count === 0;
          });
      }
    },
    setsearchlng: function() {
      if (this.$root.lng === "NL") {
        this.searchmodeoptions[0].text = "Naam";
        this.searchmodeoptions[1].text = "Onderwerp of expertise";
        this.searchmodeoptions[2].text = "Telefoonnummer of e-mailadres";
      } else {
        this.searchmodeoptions[0].text = "Name";
        this.searchmodeoptions[1].text = "Subject or expertise";
        this.searchmodeoptions[2].text = "Phone number or email address";
      }
    },
    formattedDisplay(result) {
      return result.label;
    },
    autocompleteclick: function(value) {
      this.searchexpression = value;
      switch (value[0]) {
        case "0":
          this.moreResultsFor(value.substring(1));
          break;
        case "E":
          // Zoek op expertise
          this.searchExpertise(value.substring(1));
          break;
        case "V":
          // Zoek op vaardigheid
          this.searchVaardigheid(value.substring(1));
          break;
        case "F":
          // Zoek op focusgbied
          this.searchFocusarea(value.substring(1));
          break;
        case "M":
          // Open employee
          var id = value.substring(1);
          this.gotoEmployeeById(id);
          break;
        default:
          break;
      }
    },
    enterpress: function(value) {
      this.hideVirtualKeyboard();
      this.moreResultsFor(value);
    },
    moreResultsFor: function(term) {
      this.$router.push({ query: { q: term, mode: this.getsearchmode() } });
      this.$refs.searchautocomplete.clear();
      this.searchexpression = term;
    },
    searchExpertise: function(id) {
      var term = this.$refs.searchautocomplete.getTerm();
      this.$router.push({
        query: { expertise: id, mode: "onderwerp", d: term }
      });
      this.$refs.searchautocomplete.clear();
      this.search();
    },
    searchVaardigheid: function(id) {
      var term = this.$refs.searchautocomplete.getTerm();
      this.$router.push({
        query: { vaardigheid: id, mode: "onderwerp", d: term }
      });
      this.$refs.searchautocomplete.clear();
      this.search();
    },
    searchFocusarea: function(id) {
      var term = this.$refs.searchautocomplete.getTerm();
      this.$router.push({
        query: { focusarea: id, mode: "onderwerp", d: term }
      });
      this.$refs.searchautocomplete.clear();
      this.search();
    },
    gotoEmployeeById: function(id) {
      this.$http.get("Public/GetPageName?id=" + id).then(function(response) {
        if (response.data.Success) {
          this.$router.push({
            name: "mobile" + this.$root.lng,
            params: { employee: response.data.Message }
          });
          this.loaddata();
        }
      });
    },
    getsearchmode: function() {
      if (this.searchmode === "N") return "naam";
      if (this.searchmode === "O") return "onderwerp";
      if (this.searchmode === "T") return "tel";
      return "";
    },
    searchmodefromUri: function() {
      if (this.$route.query.mode && this.$route.query.mode === "onderwerp") {
        this.searchmode = "O";
      } else {
        if (this.$route.query.mode && this.$route.query.mode === "tel") {
          this.searchmode = "T";
        } else {
          this.searchmode = "N";
        }
      }
    },
    searchmodetoUri: function() {
      this.$router.push({
        query: { q: this.$route.query.q, mode: this.getsearchmode() }
      });
    },
    goBack: function() {
      this.$router.go(-1);
    },
    gotoOrganogram: function() {
      this.$router.push({ name: "mobileorganogram" + this.$root.lng });
    },
    setplaceholdertext: function() {
      if (this.$root.lng === "NL") {
        if (this.searchmode === "N") {
          this.placeholdertext = "Zoek een medewerker";
        }
        if (this.searchmode === "O") {
          this.placeholdertext = "Zoek een onderwerp of expertise";
        }
        if (this.searchmode === "T") {
          this.placeholdertext = "Zoek een telefoonnummer of e-mailadres";
        }
      } else {
        if (this.searchmode === "N") {
          this.placeholdertext = "Search for employees";
        }
        if (this.searchmode === "O") {
          this.placeholdertext = "Search for subject or expertise";
        }
        if (this.searchmode === "T") {
          this.placeholdertext = "Search for phone number or e-mail";
        }
      }
    },
    switchlng: function() {
      // this.search();
      this.setautocompleteurl();
      this.setsearchlng();
      this.setplaceholdertext();
    },
    hideVirtualKeyboard: function() {
      if (
        document.activeElement &&
        document.activeElement.blur &&
        typeof document.activeElement.blur === "function"
      ) {
        document.activeElement.blur();
      }
    }
  },
  watch: {
    searchmode: function() {
      this.setautocompleteurl();
      this.searchmodetoUri();
      this.setplaceholdertext();
    },
    "$route.query.q": function() {
      this.search(true);
    }
  },
  created: function() {
    this.$root.$on("switchlng", this.switchlng);
    this.loaddata();
    this.setplaceholdertext();
  },
  mounted: function() {
    this.setautocompleteurl();
    this.search(true);
    this.setsearchlng();
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.gele-balk {
  background-color: #ffcd00;
  min-height: 50px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 16pt;
}

.Mobile-font {
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 0.95em;
}

.Caption {
  font-weight: bold;
}

.Search-Input {
  background-color: #ffcd00;
  min-height: 60px;
  padding-top: 15px;
  font-size: 16px;
  margin-top: -10px;
}

.search-mode {
  margin-top: 20px;
  margin-bottom: 5px;
  font-weight: 700;
  font-size: 16px;
}

.searchmodeoptions {
  font-weight: 400;
  line-height: 26px;
  font-size: 16px;
  padding-bottom: 10px;
}

/* Footer met terug-knop */
.back-button {
  background-color: #262626;
  border-top: 4px solid #fff;
  line-height: 50px;
  margin-left: 0px;
  padding-left: 10px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
}

/* Resultaat-div */
.search-result {
  margin-bottom: 50px; /* Ivm footer */
}

/* "Zoekresultaat" header */
.search-result-header {
  background-color: #efefef;
  font-size: 16px;
  line-height: 35px;
  margin-bottom: 5px;
}

/* Eén zoekresultaat-regel */
.result-row {
  border-bottom: 1px solid #efefef;
  margin-bottom: 5px;
  padding-bottom: 5px;
  line-height: 12px;
}

/* Naam van de medewerker*/
.result-row h3 {
  font-size: 16px;
  padding-top: 3px;
}

/* Functie */
.result-row span {
  font-size: 12px;
}

/* Geen resultaat-melding */
.search-no-result h2 {
  font-size: 12px;
}

.photo {
  margin-left: -15px;
  margin-right: -15px;
}

.photo div {
  padding-left: 0px;
  padding-right: 0px;
}

.photo img {
  width: 100%;
  height: auto;
}
</style>

<style>
</style>

import { render, staticRenderFns } from "./profielpagina.vue?vue&type=template&id=13996746&scoped=true&"
import script from "./profielpagina.vue?vue&type=script&lang=js&"
export * from "./profielpagina.vue?vue&type=script&lang=js&"
import style0 from "./profielpagina.vue?vue&type=style&index=0&id=13996746&scoped=true&lang=css&"
import style1 from "./profielpagina.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13996746",
  null
  
)

export default component.exports
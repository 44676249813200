<template>
  <div class="info">
    <ul v-if="this.$slots.default[0].text != ''">
      <li>
        <span v-html="this.$slots.default[0].text"></span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "edit-info"
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.info {
  padding: 10px;
  font-size: 8pt;
}

ul {
  padding-left: 0px;
  margin-bottom: 0px;
}

li {
  list-style-type: square;
}
</style>

<template>
  <div v-if="value" class="editdatefield">
    <div class="row">
      <div class="col-md-12">
        <datepicker v-model="value.Value" monday-first clear-button bootstrapStyling></datepicker>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
/*
  Deze import breekt IE11. Dus datepicker voorlopig maar altijd in het Engels....
  import * as lang from 'vuejs-datepicker/src/locale/index.js';
*/

export default {
  name: "edit-datefield",
  props: ["value"],
  components: {
    Datepicker: Datepicker,
  },

  data () {
    return {
      /* languages : lang, */
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.editdatefield {
  margin-bottom: 10px;
}

.editdatefield > div {
  margin-bottom: 5px;
}
</style>

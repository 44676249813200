<!--
  Layout van veld op bewerk-scherm
-->
<template>
  <div class="editlayout">
    <div class="row">
      <div class="col-md-10 caption">
        <span v-if="$root.lng=='NL'">{{nameNL}}</span>
        <span v-if="$root.lng=='EN'">{{nameEN}}</span>
      </div>
      <div class="col-md-2 toelichtingbar"></div>
    </div>
    <div class="row">
      <div class="col-md-10 fieldcontent">
        <slot></slot>
      </div>
      <div class="col-md-2 toelichtingbar">
        <edit-info>{{info}}</edit-info>
      </div>
    </div>
  </div>
</template>

<script>
import Editinfo from "@/components/layout/edit-info.vue";

export default {
  name: "edit-layout",
  props: ["nameNL", "nameEN", "info"],
  components: {
    "edit-info": Editinfo
  },
  data() {
    return {};
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.caption {
  font-weight: bold;
  font-size: 18px;
  line-height: 34px;
  margin-bottom: 3px;
}

/*
.editlayout {
}
*/

input[type="text"] {
  width: 100%;
}

.fieldcontent {
  padding-bottom: 20px;
  font-size: 15px;
  line-height: 34px;
}

.toelichtingbar {
  background-color: #efefef;
}
</style>

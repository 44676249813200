<template>
  <div v-if="medewerker" class="TabEditProfileStudyProgrammes">
    <confirm ref="confirmdialog"></confirm>

    <div class="row">
      <div class="col-md-12">
        <table class="table table-sm table-bordered">
          <thead>
            <tr>
              <th>
                <label-lng NL="Opleiding" EN="Study Programme"></label-lng>
              </th>
              <th class="iconcolumn"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="sp in medewerker.StudyProgrammes" :key="sp.Guid">
              <td>{{sp.Name}}</td>
              <td>
                <iconbutton
                  icon="pencil-alt"
                  titleNL="Bewerken"
                  titleEN="Edit"
                  @click="editSP(sp)"
                />&nbsp;<iconbutton
                  icon="trash"
                  titleNL="Verwijderen"
                  titleEN="Delete"
                  @click="deleteSP(sp)"
                />
              </td>
            </tr>
          </tbody>
        </table>
        <button class="uu-button button-black" v-on:click="addSP()" variant="link">
          <label-lng NL="Opleiding toevoegen" EN="Add Study Programme"></label-lng>
        </button>
      </div>
    </div>

    <popup
      ref="popupSP"
      @okclick="beforeSaveSP()"
      titleNL="Opleiding"
      titleEN="Study programme"
    >
      <form slot-scope="popup">
        <div v-if="popup.data != null">
          <div class="form-group">
            <label for="spf">
              <label-lng NL="Faculteit/dienst" EN="Faculty/Service Department"></label-lng>
            </label>
            <select v-model="studyprogrammefacultyid" class="form-control" id="spf">
              <option v-for="f in getfaculties()" :value="f.FacultyId" :key="f.Guid">{{ f.Faculty }}</option>
            </select>
          </div>
          <div class="form-group">
            <label for="spsp">
              <label-lng NL="Opleiding" EN="Study programme"></label-lng>
            </label>
            <select v-model="selectedstudyprogramme" class="form-control" id="spsp">
              <option v-for="sp in studyprogrammes" v-bind:value="sp" :key="sp.Guid">{{ sp.Name }}</option>
            </select>
          </div>
          <b-alert v-show="selectedstudyprogramme == null" variant="warning" show>
            <label-lng NL="Kies een opleiding" EN="Select a Study Programme"></label-lng>
          </b-alert>
        </div>
      </form>
    </popup>
  </div>
</template>

<script>
import labellng from "@/components/layout/label-lng.vue";
import confirmdialog from "@/components/layout/confirm.vue";
import popup from "@/components/layout/popup.vue";
import iconbutton from "@/components/layout/iconbutton.vue";

export default {
  name: "tab-edit-profile-studyprogrammes",
  props: ["medewerker", "faculties"],
  data() {
    return {
      studyprogrammefacultyid: null,
      studyprogrammes: [],
      selectedstudyprogramme: null,
      editstudyprogramme: null
    };
  },
  components: {
    "label-lng": labellng,
    confirm: confirmdialog,
    popup: popup,
    iconbutton: iconbutton
  },
  methods: {
    addSP: function() {
      this.selectedstudyprogramme = null;
      this.editstudyprogramme = null;
      this.$refs.popupSP.show({}, this.medewerker.StudyProgrammes, this);
    },
    editSP: function(sp) {
      this.editstudyprogramme = sp;
      this.studyprogrammefacultyid = sp.FacultyId;
      this.selectSP();
      this.$refs.popupSP.show(sp, this.medewerker.StudyProgrammes, this);
    },
    deleteSP: function(sp) {
      var mdw = this.medewerker;
      this.$refs.confirmdialog.confirm(
        "Opleiding verwijderen?",
        "Delete study programme?",
        function() {
          var i = mdw.StudyProgrammes.indexOf(sp);
          if (i !== -1) mdw.StudyProgrammes.splice(i, 1);
        }
      );
    },
    selectSP: function() {
      if (this.editstudyprogramme != null) {
        var sp = null; // this.studyprogrammes.find(function(f) { return (f.Id.toString() === this.editstudyprogrammeid.toString()); });
        for (var i = 0; i < this.studyprogrammes.length; i++) {
          if (this.studyprogrammes[i].Id === this.editstudyprogramme.Id) {
            sp = this.studyprogrammes[i];
          }
        }
        this.selectedstudyprogramme = sp;
      } else {
        this.selectedstudyprogramme = null;
      }
    },
    getfaculties: function() {
      return this.faculties;
    },
    loadstudyprogrammes: function(faculty) {
      this.$http
        .get(
          "Edit/GetStudyProgrammes?l=" +
            this.$root.lng +
            "&f=" +
            faculty.toString()
        )
        .then(function(response) {
          this.studyprogrammes = response.data;
          this.selectSP();
        });
    },
    beforeSaveSP: function() {
      if (this.selectedstudyprogramme) {
        var data = this.$refs.popupSP.getData();

        data.Id = this.selectedstudyprogramme.Id;
        data.Name = this.selectedstudyprogramme.Name;
        data.FacultyId = this.studyprogrammefacultyid;
      }
    }
  },
  watch: {
    studyprogrammefacultyid: function(newfaculty) {
      this.loadstudyprogrammes(newfaculty);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
table {
  table-layout: fixed;
}
td {
  overflow: hidden;
}

.iconcolumn {
  width: 50px;
}
</style>

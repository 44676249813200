<template>
  <div class="Quick-Search">
    <div class="ac-container">
      <autocomplete
        ref="quicksearch"
        :placeholder="$root.lng=='NL' ? 'Zoek medewerker' : 'Search employee'"
        :sourceurl="this.$http.options.root + '/RestApi/medewerkerautocomplete?mode=Q&t='+token+'&taal='+this.$root.lng+'&catrow=0&term='"
        @OnItemSelect="search"
        @OnEnterPress="enter"
        results-display="label"
        results-value="id"
        input-class="autocomplete-quicksearch"
      />
    </div>
    <div class="searchbutton" :title="$root.lng=='NL'?'Zoek':'Search'" @click="searchclick()">
      <icon name="search" class="searchicon"></icon>
    </div>
  </div>
</template>

<script>
import Autocomplete from '@/components/layout/autocomplete.vue'
export default {
  name: 'QuickSearch',
  data() {
    return {
      searchterm: '',
      token: '',
      oldsearchterm: ''
    }
  },
  components: {
    autocomplete: Autocomplete
  },
  methods: {
    search: function (value) {
      this.$refs.quicksearch.clear();
      this.gotoEmployeeById(value.substring(1));
    },
    enter: function (value) {
      this.$router.push({
        name: 'Search' + this.$root.lng,
        query: { q: value }
      });
    },
    gotoEmployeeById: function (id) {
      this.$http.get('Public/GetPageName?id=' + id).then(function (response) {
        if (response.data.Success) {
          this.$router.push({
            name: 'Public' + this.$root.lng,
            params: { employee: response.data.Message }
          });
        }
      });
    },
    searchclick: function () {
      var term = this.$refs.quicksearch.getTerm();
      this.$router.push({
        name: 'Search' + this.$root.lng,
        query: { q: term }
      });
    }
  },
  created: function () {
    this.token = window.localStorage.getItem('token');
    if (!this.token) this.token = '';
  }
};
</script>

<style scoped>
.Quick-Search {
  height: 40px;
  text-align: left;
  padding: 0px;
  display: flex;
}

.ac-container {
  width: 100px;
  background-color: #efefef;
  height: 40px;
  padding: 0px;
  margin: 0px;
  flex-grow: 1;
}

.searchbutton {
  height: 40px;
  width: 40px;
  text-align: center;
  cursor: pointer;
  background-color: #000000;
  color: #ffffff;
  margin: 0px;
  padding: 0px;
  float: left;
  overflow: hidden;
}

.searchicon {
  position: relative;
  top: 4px;
}
</style>

import Vue from 'vue'
import vueResource from 'vue-resource'
import BootstrapVue from 'bootstrap-vue'
import App from './App'
import router from './router'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import Icon from 'vue-awesome/components/Icon'
import 'vue-awesome/icons'
//import VeeValidate from 'vee-validate'
import VueChart from 'vue-chart-js'
import VueProgressBar from 'vue-progressbar'
import VueHead from 'vue-head'

Vue.config.productionTip = false
Vue.use(VueHead)
Vue.use(vueResource)
Vue.use(BootstrapVue)
Vue.component('icon', Icon)
//Vue.use(VeeValidate)
Vue.use(VueChart)

Vue.use(VueProgressBar, {
  color: '#FFCD00',
  failedColor: 'red',
  thickness: '5px',
  autoRevert: false
})

// Vue.http.options.root = 'http://localhost:8080/RestApi' // DEV
// Vue.http.options.root = 'https://dev.test-uu.nl/RestApi' // DEV-TEST
Vue.http.options.root = 'https://dev2.test-uu.nl/RestApi' // DEV-TEST2
// Vue.http.options.root = 'https://test-uu.nl/RestApi' // DEV-ACC
// Vue.http.options.root = 'https://a-www.uu.nl/medewerkers/RestApi' // ACC bij UU
// Vue.http.options.root = 'https://www.uu.nl/medewerkers/RestApi' // PROD

router.beforeEach((to, from, next) => {
  if (!to.meta.customTitle) {
    document.title = 'Medewerkers - Universiteit Utrecht'
  }

  if (to.query.t && to.query.t.length === 32) {
    window.localStorage.setItem('token', to.query.t)
    Vue.http.headers.common['Authorization'] = 'Bearer ' + to.query.t
  }
  // Als er geen Auth-header is, maar wel token in localStorage, dan deze ophalen
  if (Vue.http.headers.common['Authorization'] == undefined || Vue.http.headers.common['Authorization'] == null) {
    let ls = window.localStorage.getItem('token')
    if (ls && ls.length === 32)
      Vue.http.headers.common['Authorization'] = 'Bearer ' + ls;
  }

  if (to.meta.requiresAuth) {
    // Authenticatie nodig
    // Check geldigheid token
    if (Vue.http.headers.common['Authorization']) {

      Vue.http.post('Edit/ValidateToken').then((response) => {
        var checkresult = response;
        if (!checkresult.data.Success) {
          // Token is verlopen, verwijder header zodat user naar de IdP gaat
          Vue.http.headers.common['Authorization'] = null;
          window.localStorage.setItem('token', null)
        } else {
          window.localStorage.setItem('isadmin', checkresult.data.IsAdmin)
        }

        // Afhandeling router-actie voor pagina met Auth. 
        // Deze code is een kopie van de afhandeling hieronder, omdat dit moet plaatsvinden na result van Edit/ValidateToken
        // Helaas is await niet mogelijk binnen beforeEach.
        var auth = Vue.http.headers.common['Authorization']
        if (auth) {
          // Ingelogd
          next()
        } else {

          // Nog niet ingelogd
          let SAMLEnabled = Vue.http.options.root.indexOf("://dev") == -1;
          if (Vue.http.options.root.indexOf("://localhost") != -1)
            SAMLEnabled = false;
          if (SAMLEnabled) {
            Vue.http.get('Edit/GetAuthUrl?returnurl=' + encodeURIComponent(to.path)).then(
              function (response) {
                // Doorsturen naar SAML IdP inlogscherm
                window.location = response.data;
              });
          } else {
            // Naar oud PP loginscherm
            next({ name: 'login', params: { returnpath: to.path } });
          }
        }
        // Einde afhandeling routeractie.
        return;

      });
    }

    // Afhandeling router-actie voor pagina met Auth.
    // Dit is situatie dat er nog geen "Vue.http.headers.common['Authorization']" aanwezig is.
    var auth = Vue.http.headers.common['Authorization']
    if (auth) {
      // Ingelogd
      next()
    } else {
      // Nog niet ingelogd
      let SAMLEnabled = Vue.http.options.root.indexOf("://dev") == -1;
      if (Vue.http.options.root.indexOf("://localhost") != -1)
        SAMLEnabled = false;
      if (SAMLEnabled) {
        Vue.http.get('Edit/GetAuthUrl?returnurl=' + encodeURIComponent(to.path)).then(
          function (response) {
            // Doorsturen naar SAML IdP inlogscherm
            window.location = response.data;
          });
      } else {
        // Naar oud PP loginscherm
        next({ name: 'login', params: { returnpath: to.path } });
      }

    }
  } else {
    // Geen authenicatie nodig
    next()
  }
})

/* eslint-disable no-new */
new Vue({
  router,
  render: h => h(App),
  data() {
    return {
      /* Globale variabelen */
      HeaderFaculteitUrl: null,
      lng: null,
      lngSwitchOverride: true
    }
  },
  created: function () {
    if (!this.lng) {
      if (this.$route.name != null) {
        // if (this.$route.name.endsWith('EN')) -- endsWith werkt niet in IE11 :(
        if (this.$route.name.match(/EN$/g)) {
          this.lng = 'EN'
        } else {
          this.lng = 'NL'
        }
      } else this.lng = 'NL'
    }
  }
}).$mount('#app')


import Vue from 'vue'
import Router from 'vue-router'
import PageIndex from '@/components/public/pageindex.vue'
import Profielpagina from '@/components/public/profielpagina.vue'
import ProfielpaginaEdit from '@/components/edit/profielpagina-edit.vue'
import Search from '@/components/search/search.vue'
import Login from '@/components/common/login.vue'
import Logout from '@/components/common/logout.vue'
import Beheer from '@/components/admin/beheer.vue'
import BeheerOverzicht from '@/components/admin/beheer-overzicht.vue'
import BeheerLocaties from '@/components/admin/beheer-locaties.vue'
import BeheerMails from '@/components/admin/beheer-mails.vue'
import BeheerOM from '@/components/admin/beheer-om.vue'
import BeheerNew from '@/components/admin/beheer-newpage.vue'
import BeheerFocusarea from '@/components/admin/beheer-focusarea.vue'
import BeheerPositions from '@/components/admin/beheer-positions.vue'
import BeheerAdmins from '@/components/admin/beheer-admins.vue'
import BeheerStudyProgrammes from '@/components/admin/beheer-studyprogrammes.vue'
import BeheerRapportVulling from '@/components/admin/rapportage-vulling.vue'
import BeheerRapportOPB from '@/components/admin/rapportage-obpnietgereageerd.vue'
import BeheerRapportNevenfuncties from '@/components/admin/rapportage-nevenfuncties.vue'
import BeheerRapportMedewerkers from '@/components/admin/rapportage-medewerkers.vue'
import BeheerAdditionalfunctions from '@/components/admin/beheer-additionalfunctions.vue'
import BeheerAdditionalfunctionsConfig from '@/components/admin/beheer-additionalfunction-config.vue'
import BeheerFooterlinks from '@/components/admin/beheer-footerlinks.vue'
import BeheerImportOsiris from '@/components/admin/beheer-import-osiris.vue'
import NotFound from '@/components/common/404.vue'
import Akkoord from '@/components/edit/confirm.vue'
import Organogram from '@/components/public/organogram.vue'
import Unauthorized from '@/components/common/401.vue'
import Forbidden from '@/components/common/403.vue'
import Mobile from '@/components/mobile/mobile.vue'
import MobileSearch from '@/components/mobile/mobile-search.vue'
import BeheerExpertises from '@/components/admin/beheer-expertises.vue'
import BeheerSkills from '@/components/admin/beheer-skills.vue'
import MobileOrganogram from '@/components/mobile/organogram.vue'
import Redirect from '@/components/common/redirect.vue'

Vue.use(Router)

/*
lngSwitch == true  => taalswitch zichtbaar
lngSwitch == false => taalswitch niet zichtbaar
lngSwitch == null  => zichtbaarheid taalswitch wordt bepaald door $root.lngSwitchOverride

*/

export default new Router({
  mode: 'history',
  /* base: '/medewerkers', */
  routes: [
    /* Common */
    {
      path: '/',
      name: 'Default',
      component: Search,
      meta: { lngSwitch: null }
    },
    {
      path: '/medewerkers/login/:returnpath',
      name: 'login',
      component: Login,
      meta: { lngSwitch: false }
    },
    {
      path: '/medewerkers/logout',
      name: 'logout',
      component: Logout,
      meta: { lngSwitch: false }
    },
    {
      path: '/medewerkers/Unauthorized',
      name: 'unauthorized',
      component: Unauthorized,
      meta: { lngSwitch: false }
    },
    {
      path: '/medewerkers/Forbidden',
      name: 'forbidden',
      component: Forbidden
    },
    /* Akkoord */
    {
      path: '/medewerkers/bewerken/akkoord/:employee',
      name: 'AkkoordNL',
      component: Akkoord,
      meta: { requiresAuth: true, lngSwitch: false }
    },
    {
      path: '/staff/edit/confirm/:employee',
      name: 'AkkoordEN',
      component: Akkoord,
      meta: { requiresAuth: true, lngSwitch: false }
    },
    /* Edit */
    {
      path: '/medewerkers/bewerken/:employee',
      name: 'EditNL',
      component: ProfielpaginaEdit,
      meta: { requiresAuth: true, lngSwitch: false }
    },
    {
      path: '/staff/edit/:employee',
      name: 'EditEN',
      component: ProfielpaginaEdit,
      meta: { requiresAuth: true, lngSwitch: false }
    },
    /* Public */
    {
      path: '/medewerkers/pageindex',
      name: 'PageIndexNL',
      component: PageIndex,
      meta: { lngSwitch: true }
    },
    {
      path: '/staff/pageindex',
      name: 'PageIndexEN',
      component: PageIndex,
      meta: { lngSwitch: true }
    },
    {
      path: '/medewerkers/zoek',
      name: 'SearchResultNL',
      component: Search,
      meta: { lngSwitch: true }
    },
    {
      path: '/staff/search',
      name: 'SearchResultEN',
      component: Search,
      meta: { lngSwitch: true }
    },
    {
      path: '/medewerkers/organogram',
      name: 'organogramNL',
      component: Organogram,
      meta: { lngSwitch: true }
    },
    {
      path: '/medewerkers/organogram/:faculty',
      name: 'organogram1NL',
      component: Organogram,
      meta: { lngSwitch: true }
    },
    {
      path: '/medewerkers/organogram/:faculty/:department',
      name: 'organogram2NL',
      component: Organogram,
      meta: { lngSwitch: true }
    },
    {
      path: '/medewerkers/organogram/:faculty/:department/:section',
      name: 'organogram3NL',
      component: Organogram,
      meta: { lngSwitch: true }
    },
    {
      path: '/medewerkers/organogram/:faculty/:department/:section/:group',
      name: 'organogram4NL',
      component: Organogram,
      meta: { lngSwitch: true }
    },
    {
      path: '/staff/organisationalchart',
      name: 'organogramEN',
      component: Organogram,
      meta: { lngSwitch: true }
    },
    {
      path: '/staff/organizationalchart',
      redirect: { name: 'organogramEN' }
    },
    {
      path: '/staff/organisationalchart/:faculty',
      name: 'organogram1EN',
      component: Organogram,
      meta: { lngSwitch: true }
    },
    {
      path: '/staff/organizationalchart/:faculty',
      redirect: { name: 'organogram1EN' }
    },
    {
      path: '/staff/organisationalchart/:faculty/:department',
      name: 'organogram2EN',
      component: Organogram,
      meta: { lngSwitch: true }
    },
    {
      path: '/staff/organizationalchart/:faculty/:department',
      redirect: { name: 'organogram2EN' }
    },
    {
      path: '/staff/organisationalchart/:faculty/:department/:section',
      name: 'organogram3EN',
      component: Organogram,
      meta: { lngSwitch: true }
    },
    {
      path: '/staff/organizationalchart/:faculty/:department/:section',
      redirect: { name: 'organogram3EN' }
    },
    {
      path: '/staff/organisationalchart/:faculty/:department/:section/:group',
      name: 'organogram4EN',
      component: Organogram,
      meta: { lngSwitch: true }
    },
    {
      path: '/staff/organizationalchart/:faculty/:department/:section/:group',
      redirect: { name: 'organogram4EN' }
    },
    /* Admin */
    {
      path: '/medewerkers/admin/',
      name: 'Beheer',
      component: Beheer,
      meta: { requiresAuth: true, lngSwitch: false }
    },
    {
      path: '/medewerkers/admin/overzicht',
      name: 'BeheerOverzicht',
      component: BeheerOverzicht,
      meta: { requiresAuth: true, lngSwitch: false }
    },
    {
      path: '/medewerkers/admin/mail',
      name: 'BeheerMails',
      component: BeheerMails,
      meta: { requiresAuth: true, lngSwitch: false }
    },
    {
      path: '/medewerkers/admin/om',
      name: 'BeheerOM',
      component: BeheerOM,
      meta: { requiresAuth: true, lngSwitch: false }
    },
    {
      path: '/medewerkers/admin/new',
      name: 'BeheerNew',
      component: BeheerNew,
      meta: { requiresAuth: true, lngSwitch: false }
    },
    {
      path: '/medewerkers/admin/focusarea',
      name: 'BeheerFocusarea',
      component: BeheerFocusarea,
      meta: { requiresAuth: true, lngSwitch: false }
    },
    {
      path: '/medewerkers/admin/position',
      name: 'BeheerPositions',
      component: BeheerPositions,
      meta: { requiresAuth: true, lngSwitch: false }
    },
    {
      path: '/medewerkers/admin/admins',
      name: 'BeheerAdmins',
      component: BeheerAdmins,
      meta: { requiresAuth: true, lngSwitch: false }
    },
    {
      path: '/medewerkers/admin/studyprogrammes',
      name: 'BeheerStudyProgrammes',
      component: BeheerStudyProgrammes,
      meta: { requiresAuth: true, lngSwitch: false }
    },
    {
      path: '/medewerkers/admin/rapportages/vulling',
      name: 'BeheerRapportVulling',
      component: BeheerRapportVulling,
      meta: { requiresAuth: true, lngSwitch: false }
    },
    {
      path: '/medewerkers/admin/rapportages/obp',
      name: 'BeheerRapportOPB',
      component: BeheerRapportOPB,
      meta: { requiresAuth: true, lngSwitch: false }
    },
    {
      path: '/medewerkers/admin/rapportages/nevenfuncties',
      name: 'BeheerRapportNevenfuncties',
      component: BeheerRapportNevenfuncties,
      meta: { requiresAuth: true, lngSwitch: false }
    },
    {
      path: '/medewerkers/admin/rapportages/medewerkers',
      name: 'BeheerRapportMedewerkers',
      component: BeheerRapportMedewerkers,
      meta: { requiresAuth: true, lngSwitch: false }
    },
    {
      path: '/medewerkers/admin/import/osiris',
      name: 'BeheerImportOsiris',
      component: BeheerImportOsiris,
      meta: { requiresAuth: true, lngSwitch: false }
    },
    {
      path: '/medewerkers/admin/locaties',
      name: 'BeheerLocaties',
      component: BeheerLocaties,
      meta: { requiresAuth: true, lngSwitch: false }
    },
    {
      path: '/medewerkers/admin/nevenfuncties',
      name: 'AdminAdditionalfunctions',
      component: BeheerAdditionalfunctions,
      meta: { requiresAuth: true, lngSwitch: false }
    },
    {
      path: '/medewerkers/admin/nevenfuncties-config',
      name: 'BeheerAdditionalfunctionsConfig',
      component: BeheerAdditionalfunctionsConfig,
      meta: { requiresAuth: true, lngSwitch: false }
    },
    {
      path: '/medewerkers/admin/footerlinks',
      name: 'BeheerFooterlinks',
      component: BeheerFooterlinks,
      meta: { requiresAuth: true, lngSwitch: false }
    },
    {
      path: '/medewerkers/admin/expertise',
      name: 'BeheerExpertises',
      component: BeheerExpertises,
      meta: { requiresAuth: true, lngSwitch: false }
    },
    {
      path: '/medewerkers/admin/skills',
      name: 'BeheerSkills',
      component: BeheerSkills,
      meta: { requiresAuth: true, lngSwitch: false }
    },
    /* Mobiel */
    {
      path: '/staff/mobile/organisationalchart',
      name: 'mobileorganogramEN',
      component: MobileOrganogram,
      meta: { lngSwitch: true }
    },
    {
      path: '/staff/mobile/organizationalchart',
      redirect: { name: 'mobileorganogramEN' }
    },
    {
      path: '/staff/mobile/organisationalchart/:faculty',
      name: 'mobileorganogram1EN',
      component: MobileOrganogram,
      meta: { lngSwitch: true }
    },
    {
      path: '/staff/mobile/organizationalchart/:faculty',
      redirect: { name: 'mobileorganogram1EN' }
    },
    {
      path: '/staff/mobile/organisationalchart/:faculty/:department',
      name: 'mobileorganogram2EN',
      component: MobileOrganogram,
      meta: { lngSwitch: true }
    },
    {
      path: '/staff/mobile/organizationalchart/:faculty/:department',
      redirect: { name: 'mobileorganogram2EN' }
    },
    {
      path: '/staff/mobile/organisationalchart/:faculty/:department/:section',
      name: 'mobileorganogram3EN',
      component: MobileOrganogram,
      meta: { lngSwitch: true }
    },
    {
      path: '/staff/mobile/organizationalchart/:faculty/:department/:section',
      redirect: { name: 'mobileorganogram3EN' }
    },
    {
      path: '/mobile/organisationalchart/:faculty/:department/:section/:group',
      name: 'mobileorganogram4EN',
      component: MobileOrganogram,
      meta: { lngSwitch: true }
    },
    {
      path: '/mobile/organizationalchart/:faculty/:department/:section/:group',
      redirect: { name: 'mobileorganogram4EN' }
    },
    {
      path: '/medewerkers/mobiel/organogram',
      name: 'mobileorganogramNL',
      component: MobileOrganogram,
      meta: { lngSwitch: true }
    },
    {
      path: '/medewerkers/mobiel/organogram/:faculty',
      name: 'mobileorganogram1NL',
      component: MobileOrganogram,
      meta: { lngSwitch: true }
    },
    {
      path: '/medewerkers/mobiel/organogram/:faculty/:department',
      name: 'mobileorganogram2NL',
      component: MobileOrganogram,
      meta: { lngSwitch: true }
    },
    {
      path: '/medewerkers/mobiel/organogram/:faculty/:department/:section',
      name: 'mobileorganogram3NL',
      component: MobileOrganogram,
      meta: { lngSwitch: true }
    },
    {
      path:
        '/medewerkers/mobiel/organogram/:faculty/:department/:section/:group',
      name: 'mobileorganogram4NL',
      component: MobileOrganogram,
      meta: { lngSwitch: true }
    },
    {
      path: '/staff/mobile/search',
      name: 'mobileSearchEN2',
      component: MobileSearch,
      meta: { lngSwitch: true }
    },
    {
      path: '/medewerkers/mobiel/zoek',
      name: 'mobileSearchNL',
      component: MobileSearch,
      meta: { lngSwitch: true }
    },
    {
      path: '/staff/mobile/:employee',
      name: 'mobileEN',
      component: Mobile,
      meta: { lngSwitch: true }
    },
    {
      path: '/staff/mobile/:employee/:tab',
      name: 'mobileENtab',
      component: Mobile,
      meta: { lngSwitch: true }
    },
    {
      path: '/medewerkers/mobiel/:employee',
      name: 'mobileNL',
      component: Mobile,
      meta: { lngSwitch: true }
    },
    {
      path: '/medewerkers/mobiel/:employee/:tab',
      name: 'mobileNLtab',
      component: Mobile,
      meta: { lngSwitch: true }
    },
    {
      path: '/staff/mobile',
      name: 'mobileSearchEN',
      component: MobileSearch,
      meta: { lngSwitch: true }
    },
    {
      path: '/medewerkers/mobiel',
      name: 'mobileSearchNL2',
      component: MobileSearch,
      meta: { lngSwitch: true }
    },

    /********************************************************************************************************/
    /* redirects */
    {
      path: '/medewerkers/PageExpertise.aspx',
      name: 'redirectNL001',
      component: Redirect,
      meta: { redirecttype: 'expertise' }
    },
    {
      path: '/staff/PageExpertise.aspx',
      name: 'redirectEN002',
      component: Redirect,
      meta: { redirecttype: 'expertise' }
    },
    {
      path: '/medewerkers/PageVaardigheid.aspx',
      name: 'redirectNL003',
      component: Redirect,
      meta: { redirecttype: 'skill' }
    },
    {
      path: '/staff/PageVaardigheid.aspx',
      name: 'redirectEN004',
      component: Redirect,
      meta: { redirecttype: 'skill' }
    },
    {
      path: '/medewerkers/PageFunctie.aspx',
      name: 'redirectNL005',
      component: Redirect,
      meta: { redirecttype: 'position' }
    },
    {
      path: '/staff/PageFunctie.aspx',
      name: 'redirectEN006',
      component: Redirect,
      meta: { redirecttype: 'position' }
    },
    {
      path: '/medewerkers/PageFocusgebied.aspx',
      name: 'redirectNL007',
      component: Redirect,
      meta: { redirecttype: 'focusarea' }
    },
    {
      path: '/staff/PageFocusgebied.aspx',
      name: 'redirectEN008',
      component: Redirect,
      meta: { redirecttype: 'focusarea' }
    },
    {
      path: '/medewerkers/PageAfdeling.aspx',
      name: 'redirectNL009',
      component: Redirect,
      meta: { redirecttype: 'section' }
    },
    {
      path: '/staff/PageAfdeling.aspx',
      name: 'redirectEN010',
      component: Redirect,
      meta: { redirecttype: 'section' }
    },
    {
      path: '/medewerkers/PageOrganisatieonderdeel.aspx',
      name: 'redirectNL011',
      component: Redirect,
      meta: { redirecttype: 'department' }
    },
    {
      path: '/staff/PageOrganisatieonderdeel.aspx',
      name: 'redirectEN012',
      component: Redirect,
      meta: { redirecttype: 'department' }
    },
    {
      path: '/medewerkers/PageGroep.aspx',
      name: 'redirectNL013',
      component: Redirect,
      meta: { redirecttype: 'group' }
    },
    {
      path: '/staff/PageGroep.aspx',
      name: 'redirectEN014',
      component: Redirect,
      meta: { redirecttype: 'group' }
    },
    {
      path: '/medewerkers/Afbeelding.aspx',
      name: 'redirectNL015',
      component: Redirect,
      meta: { redirecttype: 'afbeelding' }
    },
    {
      path: '/staff/Afbeelding.aspx',
      name: 'redirectEN016',
      component: Redirect,
      meta: { redirecttype: 'afbeelding' }
    },
    {
      path: '/medewerkers/default.aspx',
      name: 'redirectNL017',
      component: Redirect,
      meta: { redirecttype: 'oude-pp-nl' }
    },
    {
      path: '/staff/default.aspx',
      name: 'redirectEN018',
      component: Redirect,
      meta: { redirecttype: 'oude-pp-en' }
    },
    /***************************************************************/
    /* Public, direct achter medewerkers/ of staff/. Deze moeten onderaan staan ivm de prio's. */
    {
      path: '/medewerkers',
      name: 'SearchNL',
      component: Search,
      meta: { lngSwitch: true }
    },
    {
      path: '/staff',
      name: 'SearchEN',
      component: Search,
      meta: { lngSwitch: true }
    },
    {
      path: '/medewerkers/:employee',
      name: 'PublicNL',
      component: Profielpagina,
      meta: { lngSwitch: null, customTitle: true }
    },
    {
      path: '/medewerkers/:employee/:tab',
      name: 'PublicTabNL',
      component: Profielpagina,
      meta: { lngSwitch: null, customTitle: true }
    },
    {
      path: '/staff/:employee',
      name: 'PublicEN',
      component: Profielpagina,
      meta: { lngSwitch: null, customTitle: true }
    },
    {
      path: '/staff/:employee/:tab',
      name: 'PublicTabEN',
      component: Profielpagina,
      meta: { lngSwitch: null, customTitle: true }
    },
    /* Not found */
    {
      path: '/medewerkers/404',
      name: 'NotFoundNL',
      component: NotFound
    },
    {
      path: '/staff/404',
      name: 'NotFoundEN',
      component: NotFound
    },
    {
      path: '*',
      name: 'notfound',
      component: NotFound,
      meta: { lngSwitch: false }
    }
  ],

  /* Scroll altijd helemaal naar boven bij wisseling van 'pagina'  (geeft alleen error in console in Edge) */
  scrollBehavior (/*to, from, savedPosition*/) {
    return { x: 0, y: 0 }
  }
})

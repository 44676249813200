<template>
  <admin-layout title="Rapportage nevenfuncties">
    <div class="row">
      <div class="col-md-2">Faculteit</div>
      <div class="col-md-10">
        <select v-model="facultyId" class="inputfilter">
          <option v-for="f in faculties" :value="f.FacultyId" :key="f.Guid">{{f.Faculty}}</option>
        </select>
      </div>
    </div>
    <br />
    <a :href="rapporturlHtml" target="_blank">Download overzicht (html)</a><br/>
    <a :href="rapporturlXlsx" target="_blank">Download overzicht (excel)</a>
  </admin-layout>
</template>

<script>
import AdminLayout from "@/components/layout/admin-layout.vue";

export default {
  name: "BeheerRapportNevenfuncties",
  components: {
    "admin-layout": AdminLayout
  },
  data() {
    return {
      facultyId: null,
      faculties: [],
      rapporturlHtml: "",
      rapporturlXlsx: "",
    };
  },
  methods: {},
  created: function() {
    this.$http.get("Admin/GetAdminFaculties").then(function(response) {
      this.faculties = response.data;
      if (this.faculties.length > 0) {
        this.facultyId = this.faculties[0].FacultyId;
      }
    });
  },
  watch: {
    facultyId: function() {
      var url =
        this.$http.options.root +
        "/Admin/RapportageNevenfuncties?faculty=" +
        this.facultyId.toString() +
        "&t=" +
        window.localStorage.getItem("token");
      this.rapporturlHtml = url + "&format=html";
      this.rapporturlXlsx = url + "&format=xlsx";
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

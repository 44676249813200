<template>
  <div v-if="medewerker" class="TabEditCV">
    <edit-layout
      nameNL="Curriculum vitae"
      nameEN="Curriculum vitae"
      :info="GetInfo(info, 'CV.CVNL')"
    >
      <edit-textfield
        :value="medewerker.CV"
        multilng="true"
        rtf="true"
        name="CV"
      ></edit-textfield>
    </edit-layout>

    <edit-layout
      nameNL="NL PDF"
      nameEN="NL PDF"
      :info="GetInfo(info, 'CV.PdfNL')"
    >
      <label-lng
        v-if="
          medewerker.CVfilename.NL == null || medewerker.CVfilename.NL == ''
        "
        NL="Geen bestand geselecteerd"
        EN="No file selected"
      />
      <label
        v-if="medewerker.CVfilename.NL && medewerker.CVfilename.NL != ''"
        >{{ medewerker.CVfilename.NL }}</label
      >
      <input
        id="inpCVNL"
        type="file"
        style="display: none"
        v-on:change="onCVNL($event)"
        accept=".pdf"
      />
      <div>
        <button
          class="uu-button button-black"
          id="btnCVNL"
          onclick="document.getElementById('inpCVNL').click()"
        >
          <label-lng NL="Selecteer" EN="Select" /></button
        >&nbsp;<button
          class="uu-button button-black"
          v-if="medewerker.CVfilename.NL && medewerker.CVfilename.NL != ''"
          @click="clearCVNL()"
        >
          <label-lng NL="Wissen" EN="Clear" />
        </button>
      </div>
    </edit-layout>

    <edit-layout
      nameNL="EN PDF"
      nameEN="EN PDF"
      :info="GetInfo(info, 'CV.PdfEN')"
    >
      <label-lng
        v-if="
          medewerker.CVfilename.EN == null || medewerker.CVfilename.EN == ''
        "
        NL="Geen bestand geselecteerd"
        EN="No file selected"
      />
      <label
        v-if="medewerker.CVfilename.EN && medewerker.CVfilename.EN != ''"
        >{{ medewerker.CVfilename.EN }}</label
      >
      <input
        id="inpCVEN"
        type="file"
        style="display: none"
        v-on:change="onCVEN($event)"
        accept=".pdf"
      />
      <div>
        <button
          class="uu-button button-black"
          id="btnCVEN"
          onclick="document.getElementById('inpCVEN').click()"
        >
          <label-lng NL="Selecteer" EN="Select" />
        </button>
        <button
          class="uu-button button-black"
          v-if="medewerker.CVfilename.EN && medewerker.CVfilename.EN != ''"
          @click="clearCVEN()"
        >
          <label-lng NL="Wissen" EN="Clear" />
        </button>
      </div>
    </edit-layout>

    <div v-if="errmsg != ''">
      <b-alert show variant="error">{{ errmsg }}</b-alert>
    </div>
  </div>
</template>

<script>
import labellng from "@/components/layout/label-lng.vue";
import edittextfield from "@/components/layout/edit-textfield.vue";
import editlayout from "@/components/layout/edit-layout.vue";
import infohelper from "@/helpers/info-helper.vue";

export default {
  name: "tab-edit-cv",
  props: ["medewerker", "info"],
  data() {
    return {
      errmsg: "",
    };
  },
  components: {
    "label-lng": labellng,
    "edit-textfield": edittextfield,
    "edit-layout": editlayout,
  },
  methods: {
    onCVNL: function (event) {
      var files = event.target.files || event.dataTransfer.files;
      if (!files.length) return;

      this.SaveCVNL(files[0]);
    },
    onCVEN: function (event) {
      var files = event.target.files || event.dataTransfer.files;
      if (!files.length) return;

      this.SaveCVEN(files[0]);
    },
    clearCVNL: function () {
      this.medewerker.CVfilename.NL = "";
      this.medewerker.CVcode.NL = "";
    },
    clearCVEN: function () {
      this.medewerker.CVfilename.EN = "";
      this.medewerker.CVcode.EN = "";
    },
    SaveCVNL: function (f) {
      var reader = new FileReader();
      var self = this;

      reader.addEventListener(
        "load",
        function () {
          var contents = JSON.stringify(reader.result);

          self.$http
            .post("Edit/SaveFile", {
              Filename: f.name,
              Content: contents,
              EmployeeId: self.medewerker.Id,
              Filetype: "CVNL",
            })
            .then(
              function (response) {
                self.medewerker.CVfilename.NL = f.name;
                self.medewerker.CVcode.NL = response.data;
              },
              function (response) {
                self.errmsg = response.data;
              }
            );
        },
        false
      );

      reader.readAsDataURL(f);
    },
    SaveCVEN: function (f) {
      var reader = new FileReader();
      var self = this;

      reader.addEventListener(
        "load",
        function () {
          var contents = JSON.stringify(reader.result);

          self.$http
            .post("Edit/SaveFile", {
              Filename: f.name,
              Content: contents,
              EmployeeId: self.medewerker.Id,
              Filetype: "CVEN",
            })
            .then(
              function (response) {
                self.medewerker.CVfilename.EN = f.name;
                self.medewerker.CVcode.EN = response.data;
              },
              function (response) {
                self.errmsg = response.data;
              }
            );
        },
        false
      );

      reader.readAsDataURL(f);
    },
  },
  created: function () {},
  mixins: [infohelper],
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

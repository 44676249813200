<template>
  <admin-layout title="Beheer locaties">
    <div class="row" v-if="locaties">
      <div class="col-md-12">
        <table class="table table-sm table-bordered">
          <thead>
            <tr>
              <th>Gebouwcode</th>
              <th>Gebouwnaam</th>
              <th>Adres</th>
              <th>Postcode</th>
              <th>Plaats</th>
              <th>#Med</th>
              <th class="iconcolumn"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="loc in locaties" :key="loc.Guid">
              <td>{{ loc.Code }}</td>
              <td>{{ loc.BuildingName }}</td>
              <td>{{ loc.Street + ' ' + loc.Number }}</td>
              <td>{{ loc.Zipcode }}</td>
              <td>{{ loc.City }}</td>
              <td>{{ loc.EmployeeCount }}</td>
              <td>
                <iconbutton
                  icon="pencil-alt"
                  titleNL="Bewerken"
                  titleEN="Edit"
                  @click="editLocatie(loc)"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <popup
      ref="popupLocatie"
      @okclick="beforeSaveLocatie()"
      titleNL="Locatie"
      titleEN="Locatie"
    >
      <form slot-scope="popup">
        <div v-if="popup.data != null">
          <div class="form-group">
            <label for="code">Code</label>
            <input
              readonly
              v-model="popup.data.Code"
              class="form-control"
              name="Code"
              id="code"
            />
          </div>
          <div class="form-group">
            <label for="buildingname">Gebouwnaam</label>
            <input
              v-model="popup.data.BuildingName"
              class="form-control"
              name="BuildingName"
              id="buildingname"
              maxlength="100"
            />
          </div>
          <div class="form-group">
            <label for="street">Straat</label>
            <input
              v-model="popup.data.Street"
              class="form-control"
              name="Street"
              id="street"
              maxlength="100"
            />
          </div>
          <div class="form-group">
            <label for="number">Nummer</label>
            <input
              v-model="popup.data.Number"
              class="form-control"
              name="Number"
              id="number"
              maxlength="10"
            />
          </div>
          <div class="form-group">
            <label for="zipcode">Postcode</label>
            <input
              v-model="popup.data.Zipcode"
              class="form-control"
              name="Zipcode"
              id="zipcode"
              maxlength="6"
            />
          </div>
          <div class="form-group">
            <label for="city">Plaats</label>
            <input
              v-model="popup.data.City"
              class="form-control"
              name="City"
              id="city"
              maxlength="100"
            />
          </div>
          <div class="form-group">
            <label for="urlnl">URL NL</label>
            <input
              v-model="popup.data.UrlNL"
              class="form-control"
              name="UrlNL"
              id="urlnl"
              maxlength="500"
            />
          </div>
          <div class="form-group">
            <label for="urlen">URL EN</label>
            <input
              v-model="popup.data.UrlEN"
              class="form-control"
              name="UrlEN"
              id="urlen"
              maxlength="500"
            />
          </div>
        </div>
      </form>
    </popup>
  </admin-layout>
</template>

<script>
import popup from "@/components/layout/popup.vue";
import iconbutton from "@/components/layout/iconbutton.vue";
import AdminLayout from "@/components/layout/admin-layout.vue";

export default {
  name: "BeheerLocaties",
  components: {
    popup: popup,
    iconbutton: iconbutton,
    "admin-layout": AdminLayout
  },
  data() {
    return {
      locaties: []
    };
  },
  methods: {
    loaddata: function() {
      this.$Progress.start();
      this.$http.get("Admin/GetLocations").then(
        function(response) {
          this.locaties = response.data;
          this.$Progress.finish();
        },
        function() {
          // Fail
          this.$Progress.finish();
        }
      );
    },
    editLocatie: function(loc) {
      this.$refs.popupLocatie.show(loc, this.locaties, this);
    },
    beforeSaveLocatie: function() {
      var data = this.$refs.popupLocatie.getData();

      if (data) {
        this.$http.post("Admin/SaveLocation", data).then(function(response) {
          if (!response.Success) {
            // TODO: Wat te doen bij foutsituatie ?
          }
        });
      }
    }
  },
  created: function() {
    this.loaddata();
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.iconcolumn {
  width: 30px;
}
</style>

<template>
  <admin-layout title="Beheer footer">
    <popup
      ref="popupFooterlink"
      @okclick="beforeSaveFooterlink()"
      titleNL="Footerlink"
      titleEN="Footerlink"
    >
      <form slot-scope="popup" >
        <div v-if="popup.data != null">
          <div class="form-group">
            <label for="titlenl">Titel NL</label>
            <input
              v-model="popup.data.TitleNL"
              class="form-control"
              name="TitleNL"
              id="titlenl"
              maxlength="500"
            />
          </div>
          <div class="form-group">
            <label for="urlnl">Url NL</label>
            <input
              v-model="popup.data.UrlNL"
              class="form-control"
              name="UrlNL"
              id="urlnl"
              maxlength="1000"
            />
          </div>
          <div class="form-group">
            <label for="titleen">Titel EN</label>
            <input
              v-model="popup.data.TitleEN"
              class="form-control"
              name="TitleEN"
              id="titleen"
              v-validate="'required'"
              maxlength="500"
            />
          </div>
          <div class="form-group">
            <label for="urlen">Url EN</label>
            <input
              v-model="popup.data.UrlEN"
              class="form-control"
              name="UrlEN"
              id="urlen"
              maxlength="1000"
            />
          </div>
          <div class="form-group">
            <input type="checkbox" id="newtab" v-model="popup.data.NewTab" />
            <label for="newtab">Nieuwe tab</label>
          </div>
        </div>
      </form>
    </popup>

    <confirm ref="confirmdialog"></confirm>

    <b-tabs small ref="footerlinktabs">
      <b-tab title="Linker kolom" v-if="headerLeft">
        <div class="row">
          <div class="col-md-12">
            <div class="kolomkop">Kolomkop</div>
          </div>
        </div>

        <div class="row" v-if="headerLeft">
          <div class="col-md-1">Titel NL</div>
          <div class="col-md-4">
            <input
              id="LeftTitleNL"
              type="text"
              v-model="headerLeft.TitleNL"
              maxlength="500"
              style="width: 100%;"
            />
          </div>
          <div class="col-md-7">
            <input
              id="LeftUrlNL"
              type="text"
              v-model="headerLeft.UrlNL"
              maxlength="1000"
              style="width: 100%;"
            />
          </div>
        </div>

        <div class="row" v-if="headerLeft">
          <div class="col-md-1">Titel EN</div>
          <div class="col-md-4">
            <input
              id="LeftTitleEN"
              type="text"
              v-model="headerLeft.TitleEN"
              maxlength="500"
              style="width: 100%;"
            />
          </div>
          <div class="col-md-7">
            <input
              id="LeftUrlEN"
              type="text"
              v-model="headerLeft.UrlEN"
              maxlength="1000"
              style="width: 100%;"
            />
          </div>
        </div>

        <div class="row" v-if="headerLeft">
          <div class="col-md-1">Nw. tab</div>
          <div class="col-md-4">
            <input id="LeftNewTab" type="checkbox" v-model="headerLeft.NewTab" />
          </div>
          <div class="col-md-7"></div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <button
              class="uu-button button-black"
              v-on:click="saveFooterlink(headerLeft)"
            >Kolomkop opslaan</button>
          </div>
        </div>

        <div class="row">&nbsp;</div>

        <div class="row">
          <div class="col-md-12">
            <table class="table table-sm table-bordered">
              <thead>
                <tr>
                  <th>Titel NL</th>
                  <th>Titel EN</th>
                  <!--
                   <th>Url NL</th>
                  <th>Url EN</th>
                  -->
                  <th>Nieuwe tab</th>
                  <th class="iconcolumn"></th>
                  <th class="iconcolumn"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="fl in linksLeft" :key="fl.Guid">
                  <td>{{fl.TitleNL}}</td>
                  <td>{{fl.TitleEN}}</td>
                  <!--
                   <td>{{fl.UrlNL}}</td>
                  <td>{{fl.UrlEN}}</td>
                  -->
                  <td v-if="fl.NewTab">Ja</td>
                  <td v-if="!fl.NewTab">Nee</td>
                  <td>
                    <iconbutton
                      icon="chevron-up"
                      titleNL="Hoger"
                      titleEN="Up"
                      @click="up(fl)"
                      class="up"
                    />
                    <iconbutton
                      icon="chevron-down"
                      titleNL="Lager"
                      titleEN="Down"
                      @click="down(fl)"
                      class="down"
                    />
                  </td>
                  <td>
                    <iconbutton
                      icon="pencil-alt"
                      titleNL="Bewerken"
                      titleEN="Edit"
                      @click="editFooterlink(fl)"
                    />
                    <iconbutton
                      icon="trash"
                      titleNL="Verwijderen"
                      titleEN="Delete"
                      @click="deleteFooterlink(fl)"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <button
              class="uu-button button-black"
              v-on:click="addFooterlink(1)"
              variant="link"
            >Footerlink toevoegen</button>
          </div>
        </div>
      </b-tab>

      <b-tab title="Middelste kolom" v-if="headerMiddle">
        <div class="row">
          <div class="col-md-12">
            <div class="kolomkop">Kolomkop</div>
          </div>
        </div>

        <div class="row" v-if="headerMiddle">
          <div class="col-md-1">Titel NL</div>
          <div class="col-md-4">
            <input
              id="LeftTitleNL"
              type="text"
              v-model="headerMiddle.TitleNL"
              maxlength="500"
              style="width: 100%;"
            />
          </div>
          <div class="col-md-7">
            <input
              id="LeftUrlNL"
              type="text"
              v-model="headerMiddle.UrlNL"
              maxlength="1000"
              style="width: 100%;"
            />
          </div>
        </div>

        <div class="row" v-if="headerMiddle">
          <div class="col-md-1">Titel EN</div>
          <div class="col-md-4">
            <input
              id="LeftTitleEN"
              type="text"
              v-model="headerMiddle.TitleEN"
              maxlength="500"
              style="width: 100%;"
            />
          </div>
          <div class="col-md-7">
            <input
              id="LeftUrlEN"
              type="text"
              v-model="headerMiddle.UrlEN"
              maxlength="1000"
              style="width: 100%;"
            />
          </div>
        </div>

        <div class="row" v-if="headerMiddle">
          <div class="col-md-1">Nw. tab</div>
          <div class="col-md-4">
            <input id="LeftNewTab" type="checkbox" v-model="headerMiddle.NewTab" />
          </div>
          <div class="col-md-7"></div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <button
              class="uu-button button-black"
              v-on:click="saveFooterlink(headerMiddle)"
            >Kolomkop opslaan</button>
          </div>
        </div>

        <div class="row">&nbsp;</div>

        <div class="row">
          <div class="col-md-12">
            <table class="table table-sm table-bordered">
              <thead>
                <tr>
                  <th>Titel NL</th>
                  <th>Titel EN</th>
                  <!--
                   <th>Url NL</th>
                  <th>Url EN</th>
                  -->
                  <th>Nieuwe tab</th>
                  <th class="iconcolumn"></th>
                  <th class="iconcolumn"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="fl in linksMiddle" :key="fl.Guid">
                  <td>{{fl.TitleNL}}</td>
                  <td>{{fl.TitleEN}}</td>
                  <!--
                   <td>{{fl.UrlNL}}</td>
                  <td>{{fl.UrlEN}}</td>
                  -->
                  <td v-if="fl.NewTab">Ja</td>
                  <td v-if="!fl.NewTab">Nee</td>
                  <td>
                    <iconbutton
                      icon="chevron-up"
                      titleNL="Hoger"
                      titleEN="Up"
                      @click="up(fl)"
                      class="up"
                    />
                    <iconbutton
                      icon="chevron-down"
                      titleNL="Lager"
                      titleEN="Down"
                      @click="down(fl)"
                      class="down"
                    />
                  </td>
                  <td>
                    <iconbutton
                      icon="pencil-alt"
                      titleNL="Bewerken"
                      titleEN="Edit"
                      @click="editFooterlink(fl)"
                    />
                    <iconbutton
                      icon="trash"
                      titleNL="Verwijderen"
                      titleEN="Delete"
                      @click="deleteFooterlink(fl)"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <button
              class="uu-button button-black"
              v-on:click="addFooterlink(2)"
              variant="link"
            >Footerlink toevoegen</button>
          </div>
        </div>
      </b-tab>

      <b-tab title="Rechter kolom" v-if="headerRight">
        <div class="row">
          <div class="col-md-12">
            <div class="kolomkop">Kolomkop</div>
          </div>
        </div>

        <div class="row" v-if="headerRight">
          <div class="col-md-1">Titel NL</div>
          <div class="col-md-4">
            <input
              id="LeftTitleNL"
              type="text"
              v-model="headerRight.TitleNL"
              maxlength="500"
              style="width: 100%;"
            />
          </div>
          <div class="col-md-7">
            <input
              id="LeftUrlNL"
              type="text"
              v-model="headerRight.UrlNL"
              maxlength="1000"
              style="width: 100%;"
            />
          </div>
        </div>

        <div class="row" v-if="headerRight">
          <div class="col-md-1">Titel EN</div>
          <div class="col-md-4">
            <input
              id="LeftTitleEN"
              type="text"
              v-model="headerRight.TitleEN"
              maxlength="500"
              style="width: 100%;"
            />
          </div>
          <div class="col-md-7">
            <input
              id="LeftUrlEN"
              type="text"
              v-model="headerRight.UrlEN"
              maxlength="1000"
              style="width: 100%;"
            />
          </div>
        </div>

        <div class="row" v-if="headerRight">
          <div class="col-md-1">Nw. tab</div>
          <div class="col-md-4">
            <input id="LeftNewTab" type="checkbox" v-model="headerRight.NewTab" />
          </div>
          <div class="col-md-7"></div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <button
              class="uu-button button-black"
              v-on:click="saveFooterlink(headerRight)"
            >Kolomkop opslaan</button>
          </div>
        </div>

        <div class="row">&nbsp;</div>

        <div class="row">
          <div class="col-md-12">
            <table class="table table-sm table-bordered">
              <thead>
                <tr>
                  <th>Titel NL</th>
                  <th>Titel EN</th>
                  <!--
                   <th>Url NL</th>
                  <th>Url EN</th>
                  -->
                  <th>Nieuwe tab</th>
                  <th class="iconcolumn"></th>
                  <th class="iconcolumn"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="fl in linksRight" :key="fl.Guid">
                  <td>{{fl.TitleNL}}</td>
                  <td>{{fl.TitleEN}}</td>
                  <!--
                   <td>{{fl.UrlNL}}</td>
                  <td>{{fl.UrlEN}}</td>
                  -->
                  <td v-if="fl.NewTab">Ja</td>
                  <td v-if="!fl.NewTab">Nee</td>
                  <td>
                    <iconbutton
                      icon="chevron-up"
                      titleNL="Hoger"
                      titleEN="Up"
                      @click="up(fl)"
                      class="up"
                    />
                    <iconbutton
                      icon="chevron-down"
                      titleNL="Lager"
                      titleEN="Down"
                      @click="down(fl)"
                      class="down"
                    />
                  </td>
                  <td>
                    <iconbutton
                      icon="pencil-alt"
                      titleNL="Bewerken"
                      titleEN="Edit"
                      @click="editFooterlink(fl)"
                    />
                    <iconbutton
                      icon="trash"
                      titleNL="Verwijderen"
                      titleEN="Delete"
                      @click="deleteFooterlink(fl)"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <button
              class="uu-button button-black"
              v-on:click="addFooterlink(3)"
              variant="link"
            >Footerlink toevoegen</button>
          </div>
        </div>
      </b-tab>
    </b-tabs>
  </admin-layout>
</template>

<script>
import confirmdialog from "@/components/layout/confirm.vue";
import iconbutton from "@/components/layout/iconbutton.vue";
import popup from "@/components/layout/popup.vue";
import AdminLayout from "@/components/layout/admin-layout.vue";
import sorting from "@/helpers/sorting.vue";

export default {
  name: "BeheerFooterlinks",
  components: {
    confirm: confirmdialog,
    popup: popup,
    iconbutton: iconbutton,
    "admin-layout": AdminLayout
  },
  data() {
    return {
      footerlinks: null,
      headerLeft: null,
      headerMiddle: null,
      headerRight: null,
      linksLeft: null,
      linksMiddle: null,
      linksRight: null
    };
  },
  methods: {
    loaddata: function() {
      this.$Progress.start();
      this.$http.get("Admin/GetFooterLinks").then(
        function(response) {
          this.$Progress.finish();
          this.footerlinks = response.data;
          this.filldata();
        },
        function() {
          this.$Progress.finish();
          // this.footerlinks = response.data;
        }
      );
    },
    filldata: function() {
      if (this.footerlinks) {
        this.headerLeft = this.GetColumnHeader(1);
        this.headerMiddle = this.GetColumnHeader(2);
        this.headerRight = this.GetColumnHeader(3);
        this.linksLeft = this.GetColumnLinks(1);
        this.linksMiddle = this.GetColumnLinks(2);
        this.linksRight = this.GetColumnLinks(3);
      } else {
        this.headerLeft = null;
        this.headerMiddle = null;
        this.headerRight = null;
        this.linksLeft = null;
        this.linksMiddle = null;
        this.linksRight = null;
      }
    },
    GetColumnLinks: function(column) {
      var links = [];

      for (var i = 0; this.footerlinks && i < this.footerlinks.length; i++) {
        if (
          this.footerlinks[i].Column === column &&
          !this.footerlinks[i].IsHeader
        ) {
          links.push(this.footerlinks[i]);
        }
      }

      return links;
    },
    GetColumnHeader: function(column) {
      for (var i = 0; this.footerlinks && i < this.footerlinks.length; i++) {
        if (
          this.footerlinks[i].Column === column &&
          this.footerlinks[i].IsHeader
        ) {
          return this.footerlinks[i];
        }
      }
      return null;
    },
    addFooterlink: function(column) {
      this.$Progress.start();
      this.$http
        .get(
          "Admin/NewFooterlink?column=" + column.toString(),
          this.footerlinks
        )
        .then(
          function(response) {
            this.$Progress.finish();

            var fl = response.data;
            this.footerlinks.push(fl);
            this.$refs.popupFooterlink.show(fl, this.footerlinks, this);
          },
          function() {
            this.$Progress.finish();
          }
        );
      this.filldata();
    },
    editFooterlink: function(fl) {
      this.$refs.popupFooterlink.show(fl, this.footerlinks, this);
    },
    deleteFooterlink: function(fl) {
      var self = this;
      this.$refs.confirmdialog.confirm(
        "Footerlink verwijderen?",
        "Delete footerlink?",
        function() {
          this.$Progress.start();
          this.$http.post("Admin/DeleteFooterlink?Id=" + fl.Id.toString()).then(
            function() {
              this.$Progress.finish();

              self.loaddata(); // Alles opnieuw inlezen
            },
            function() {
              this.$Progress.finish();
            }
          );
        }
      );
    },
    up: function(fl) {
      this.sortup(fl, this.footerlinks);
      this.$http
        .post("Admin/SaveFooterlinkOrderindex", this.footerlinks)
        .then(function() {
          this.filldata();
        });
    },
    down: function(fl) {
      this.sortdown(fl, this.footerlinks);
      this.$http
        .post("Admin/SaveFooterlinkOrderindex", this.footerlinks)
        .then(function() {
          this.filldata();
        });
    },
    saveFooterlink: function(fl) {
      this.$Progress.start();
      this.$http.post("Admin/SaveFooterlink", fl).then(
        function() {
          this.$Progress.finish();
          this.loaddata(); // Alles opnieuw inlezen
        },
        function() {
          this.$Progress.finish();
        }
      );
    },
    beforeSaveFooterlink: function() {
      var data = this.$refs.popupFooterlink.getData();
      this.saveFooterlink(data);
    }
  },
  created: function() {
    this.loaddata();
  },
  mixins: [sorting]
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* Sortering-pijltjes: zorg dat bovenste up en onderste down niet zichtbaar is */
tbody > tr:first-child > td > .up {
  display: none;
}
tbody > tr:first-child > td > .down {
  margin-left: 20px;
}
tbody > tr:last-child > td > .down {
  display: none;
}

.iconcolumn {
  width: 50px;
}

.kolomkop {
  font-weight: bold;
  font-size: 18px;
  line-height: 34px;
  margin-bottom: 3px;
  margin-top: 10px;
}
</style>

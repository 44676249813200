<template>
  <admin-layout title="Beheer functies">
    <div class="row" v-if="positions">
      <div class="col-md-12">
        <table class="table table-sm table-hover">
          <thead>
            <tr>
              <th>ID</th>
              <th>Nederlands</th>
              <th>Engels</th>
              <th>OM ID's</th>
              <th>#Med</th>
              <th class="iconcolumn">Acties</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="p in positions" :key="p.Guid">
              <td>{{ p.Id }}</td>
              <td>{{ p.Name.NL }}</td>
              <td>{{ p.Name.EN }}</td>
              <td>{{ formatSAPcodes(p.SAPCodes) }}</td>
              <td>{{ p.Count }}</td>
              <td>
                <iconbutton icon="pencil-alt" titleNL="Bewerken" titleEN="Edit" @click="edit(p)" />
                <iconbutton
                  icon="trash-alt"
                  titleNL="Verwijderen"
                  titleEN="Delete"
                  @click="del(p)"
                  v-show="p.Count == 0"
                />
              </td>
            </tr>
          </tbody>
        </table>
        <button class="uu-button button-black" @click="nw()">Nieuwe functie</button>
      </div>
    </div>

    <popup
      ref="popupEdit"
      @okclick="beforeSave()"
      titleNL="Functie"
      titleEN="Position"
    >
      <form slot-scope="popup">
        <div v-if="popup.data != null">
          <div class="form-group">
            <label for="namenl">Naam NL</label>
            <input
              v-model="popup.data.Name.NL"
              class="form-control"
              name="namenl"
              id="namenl"
              :maxlength="popup.data.Name.MaxLength"
            />
          </div>
          <div class="form-group">
            <label for="nameen">Naam EN</label>
            <input
              v-model="popup.data.Name.EN"
              class="form-control"
              name="nameen"
              id="nameen"
              :maxlength="popup.data.Name.MaxLength"
            />
          </div>
          <div class="form-group">
            <input type="checkbox" id="IsHoogleraar" v-model="popup.data.IsHoogleraar" />
            <label for="IsHoogleraar">Hoogleraar</label>
          </div>
          <div class="form-group">
            <input type="checkbox" id="IsWP" v-model="popup.data.IsWP" />
            <label for="IsWP">Wetenschappelijk personeel</label>
          </div>
          <div class="form-group">
            <input type="checkbox" id="IsSecretariaat" v-model="popup.data.IsSecretariaat" />
            <label for="IsSecretariaat">Secretariaat</label>
          </div>
          <div class="form-group">
            <input type="checkbox" id="IsKeyFunction" v-model="popup.data.IsKeyFunction" />
            <label for="IsKeyFunction">Sleutelfunctie</label>
          </div>
          <div class="form-group">
            <input type="checkbox" id="IsAutoPage" v-model="popup.data.IsAutoPage" />
            <label for="IsAutoPage">Pagina automatisch aanmaken</label>
          </div>
          <div class="form-group">
            <label for="sapcodes">SAP codes</label>
            <br />
            <textarea
              class="sapcodes"
              v-model="popup.data.SAPCodes"
              id="sapcodes"
              cols="20"
              rows="3"
            ></textarea>
          </div>
        </div>
      </form>
    </popup>
    <confirm ref="confirmdialog"></confirm>
  </admin-layout>
</template>

<script>
import popup from '@/components/layout/popup.vue'
import iconbutton from '@/components/layout/iconbutton.vue'
import AdminLayout from '@/components/layout/admin-layout.vue'
import confirmdialog from '@/components/layout/confirm.vue'

export default {
  name: 'BeheerPositions',
  components: {
    popup: popup,
    iconbutton: iconbutton,
    'admin-layout': AdminLayout,
    confirm: confirmdialog
  },
  data() {
    return {
      positions: []
    };
  },
  methods: {
    loaddata: function() {
      this.$Progress.start();
      this.$http.get('Admin/GetPositions').then(
        function(response) {
          this.positions = response.data;
          this.$Progress.finish();
        },
        function() {
          // Fail
          this.$Progress.finish();
        }
      );
    },
    edit: function(ent) {
      this.$refs.popupEdit.show(ent, this.positions, this);
    },
    beforeSave: function() {
      var data = this.$refs.popupEdit.getData();

      this.$http.post('Admin/SavePosition', data).then(function() {
        this.loaddata();
      });
    },
    del: function(ent) {
      var self = this;
      this.$refs.confirmdialog.confirm(
        'Weet u zeker dat u deze functie wilt verwijderen?',
        'Deleting position. Are you sure?',
        function() {
          this.$http.post('Admin/DeletePosition', ent).then(function(response) {
            if (!response.data.Success) {
              alert(response.data.Message);
            } else {
              self.loaddata();
            }
          });
        }
      );
    },
    nw: function() {
      this.$http.get('Admin/NewPosition').then(function(response) {
        this.$refs.popupEdit.show(response.data, this.positions, this);
      });
    },
    formatSAPcodes: function(codes) {
      var r = codes.replace(/\r\n/g, ' - ');
      if (r.endsWith(' - ')) r = r.substring(0, r.length - 3);
      return r;
    }
  },
  created: function() {
    this.loaddata();
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.iconcolumn {
  width: 30px;
}

.sapcodes {
  line-height: 1.3;
}
</style>

<template>
  <admin-layout title="Beheer beheerders">
    <div class="row" v-if="admins">
      <div class="col-md-12">
        Faculteit:
        <select v-model="facultyId">
          <option v-for="f in faculties" :value="f.FacultyId" :key="f.Guid">{{f.Faculty}}</option>
        </select>

        <table class="table table-sm table-hover">
          <thead>
            <tr>
              <th>Beheerder</th>
              <th class="iconcolumn">Acties</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="a in admins" :key="a.Guid">
              <td>{{ a.ADname.NL }}</td>
              <td>
                <iconbutton icon="pencil-alt" titleNL="Bewerken" titleEN="Edit" @click="edit(a)" />
                <iconbutton
                  icon="trash-alt"
                  titleNL="Verwijderen"
                  titleEN="Delete"
                  @click="del(a)"
                />
              </td>
            </tr>
          </tbody>
        </table>
        <button class="uu-button button-black" @click="nw()">Nieuwe beheerder</button>
      </div>
    </div>

    <popup
      ref="popupEdit"
      @okclick="beforeSave()"
      titleNL="Beheerder"
      titleEN="Admin"
    >
      <form slot-scope="popup">
        <div v-if="popup.data != null">
          <div class="form-group">
            <label for="namenl">AD name</label>
            <input
              v-model="popup.data.ADname.NL"
              class="form-control"
              name="namenl"
              id="namenl"
              :maxlength="popup.data.ADname.MaxLength"
            />
          </div>
          <div class="form-group">
            Soort beheerder
            <br />
            <input
              type="radio"
              id="soortAlgemeen"
              v-model="popup.data.GeneralAdmin"
              :value="true"
              name="soortbeheerder"
            />
            <label for="soortAlgemeen">Algemeen beheerder</label>
            <br />
            <input
              type="radio"
              id="soortFacultair"
              v-model="popup.data.GeneralAdmin"
              :value="false"
              name="soortbeheerder"
            />
            <label for="soortFacultair">Facultair beheerder</label>
            <select
              v-model="popup.data.FacultyId"
              v-show="!popup.data.GeneralAdmin"
              class="facultiespopup"
            >
              <option v-for="f in faculties" :value="f.FacultyId" :key="f.Guid">{{f.Faculty}}</option>
            </select>
            <br />
          </div>

          <div class="form-group">
            <input type="checkbox" id="mail" v-model="popup.data.ReceivesMail" />
            <label for="mail">Ontvangt indienst/uitdienst mail</label>
          </div>

          <div class="form-group" v-show="popup.data.ReceivesMail">
            <label for="email">Email</label>
            <input
              v-model="popup.data.Email.NL"
              class="form-control"
              name="email"
              id="email"
              :maxlength="popup.data.Email.MaxLength"
            />
          </div>

          <div class="form-group" v-show="popup.data.ReceivesMail">
            <label for="emailfreq">Frequentie mail (om de hoeveel dagen)</label>
            <input
              type="number"
              v-model="popup.data.MailFrequency"
              class="form-control mailfreq"
              name="emailfreq"
              id="emailfreq"
              maxlength="3"
              min="0"
              max="365"
            />
          </div>
        </div>
      </form>
    </popup>
    <confirm ref="confirmdialog"></confirm>
  </admin-layout>
</template>

<script>
import popup from "@/components/layout/popup.vue";
import iconbutton from "@/components/layout/iconbutton.vue";
import AdminLayout from "@/components/layout/admin-layout.vue";
import confirmdialog from "@/components/layout/confirm.vue";

export default {
  name: "BeheerAdmins",
  components: {
    popup: popup,
    iconbutton: iconbutton,
    "admin-layout": AdminLayout,
    confirm: confirmdialog
  },
  data() {
    return {
      admins: [],
      facultyId: null,
      faculties: []
      // facultiespopup: [],
    };
  },
  methods: {
    loaddata: function() {
      if (this.facultyId != null) {
        this.$Progress.start();
        this.$http
          .get("Admin/GetAdmins?faculty=" + this.facultyId.toString())
          .then(
            function(response) {
              this.admins = response.data;
              this.$Progress.finish();
            },
            function() {
              // Fail
              this.$Progress.finish();
            }
          );
      }
    },
    edit: function(ent) {
      this.$refs.popupEdit.show(ent, this.admins, this);
    },
    beforeSave: function() {
      var data = this.$refs.popupEdit.getData();

      this.$http.post("Admin/SaveAdmin", data).then(function(response) {
        if (!response.data.Success) {
          alert("Opslaan mislukt. " + response.data.Message);
        }
        this.loaddata();
      });
    },
    del: function(ent) {
      var self = this;
      this.$refs.confirmdialog.confirm(
        "Weet u zeker dat u deze beheerder wilt verwijderen?",
        "Deleting admin. Are you sure?",
        function() {
          this.$http.post("Admin/DeleteAdmin", ent).then(function(response) {
            if (!response.data.Success) {
              alert(response.data.Message);
            } else {
              self.loaddata();
            }
          });
        }
      );
    },
    nw: function() {
      this.$http.get("Admin/NewAdmin").then(function(response) {
        this.$refs.popupEdit.show(response.data, this.admins, this);
      });
    }
  },
  created: function() {
    this.$http
      .get("Public/GetFaculties?l=" + this.$root.lng)
      .then(function(response) {
        this.faculties = response.data;
        this.faculties.splice(0, 0, {
          FacultyId: 0,
          Faculty: "<algemeen beheerders>"
        });
        this.facultyId = 0;
      });
    // this.$http.get('Public/GetFaculties?l='+this.$root.lng).then(function(response) {
    //   this.facultiespopup = response.data;
    // });
  },
  watch: {
    facultyId: function() {
      this.loaddata();
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.iconcolumn {
  width: 30px;
}

.facultiespopup {
  width: 250px;
}
/*
.mailfreq {
}
*/
</style>

<template>
  <div class="NotFound">
    <div class="row header-balk font-headline">
      <div class="col-md-1"></div>
      <div class="col-md-10">
        <h1>
          404
          <label-lng NL="Niet gevonden" EN="Page not found" />
        </h1>
      </div>
      <div class="col-md-1"></div>
    </div>

    <div class="row not-found-explanation font-sidebar">
      <div class="col-md-1"></div>
      <div class="col-md-10">
        <label-lng NL="De pagina die u opvraagt bestaat niet." EN="Page not found." />
      </div>
      <div class="col-md-1"></div>
    </div>
  </div>
</template>

<script>
import labellng from '@/components/layout/label-lng.vue'

export default {
  name: 'NotFound',
  data () {
    return {}
  },
  methods: {},
  created: function () {},
  components: {
    "label-lng": labellng
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.header-balk {
  background-color: #ffcd00;
  padding-top: 20px;
  padding-bottom: 15px;
  min-height: 50px;
}

.not-found-explanation {
  margin-top: 30px;
  margin-bottom: 30px;
}
</style>

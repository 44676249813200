<template>
  <div class="uu-autocomplete">
    <vue-autosuggest
      ref="uuautocomplete"
      :suggestions="suggestions"
      :inputProps="inputProps"
      :sectionConfigs="sectionConfigs"
      :renderSuggestion="renderSuggestion"
      :getSuggestionValue="getSuggestionValue"
      @input="this.fetchResults"
      v-model="inputtext"
    />
  </div>
</template>

<script>
import { VueAutosuggest } from "vue-autosuggest";
export default {
  name: "autocomplete",
  props: [
    "placeholder",
    "sourceurl",
    "inputClass",
    "resultsDisplay",
    "resultsValue",
    "minChars",
    "showSectionHeaders",
    "enterSelectTopItem",
  ],
  data() {
    return {
      timeout: null,
      selected: null,
      debounceMilliseconds: 50,
      suggestions: [],
      currentvalue: "",
      inputtext: "",
      inputProps: {
        __id: "autosuggest__input",
        __onInputChange: this.fetchResults,
        placeholder: this.placeholder,
        class: "autosuggest__input form-control " + this.inputClass,
        name: "Autocomplete",
        autocomplete: "off",
      },

      sectionConfigs: {
        default: {
          onSelected: () => {
            // Er is op enter gedrukt
            if (this.enterSelectTopItem) {
              // Selecteer bovenste item uit de dropdown
              if (this.suggestions[0].data.length > 1) {
                // 1 ivm dummy-item dat onderaan altijd wordt toegevoegd ivm IE-issue.
                this.$refs.uuautocomplete.searchInput =
                  this.suggestions[0].data[0].Value;
                this.$emit("OnItemSelect", this.suggestions[0].data[0].Key);
              }
            } else {
              // Stuur enterpress-event met ingevoerde tekst
              this.$emit("OnEnterPress", this.$refs.uuautocomplete.searchInput);
              this.currentvalue = "";
              this.suggestions = [];
            }
          },
        },
        section1: {
          // limit: 6,
          label: "",
          onSelected: (selected) => {
            this.selected = selected.item;
            this.$emit("OnItemSelect", selected.item[this.resultsValue]);
            this.$emit("OnObjectSelect", selected.item);

            this.currentvalue = "";
            this.suggestions = [];
          },
        },
        /*
          section2: {
            //limit: 6,
            label: this.titleSection2,
            onSelected: selected => {
              this.selected = selected.item;
            }
          }
        */
      },
    };
  },
  components: {
    VueAutosuggest,
  },
  methods: {
    setText: function (text) {
      //this.$refs.uuautocomplete.internalValue = text;
      this.inputtext = text;
    },
    clear: function () {
      this.inputtext = "";
      this.suggestions = [];
    },
    focus: function () {
      // Uitzoeken hoe te focussen...
    },
    getTerm: function () {
      return this.currentvalue;
    },
    selectedObject: function () {
      return this.selected;
    },
    authHeaders: function () {
      var ah = this.$http.headers.common["Authorization"];
      return {
        Authorization: ah,
      };
    },

    fetchResults(val) {
      this.currentvalue = val;
      if (this.currentvalue === "") this.$emit("Clear");
      var len = 3;
      if (this.minChars) len = parseInt(this.minChars);

      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        if (val === "" || val.length < len) this.suggestions = [];
        else {
          this.$http
            .get(this.sourceurl + encodeURIComponent(val))
            .then(function (response) {
              this.suggestions = [];
              // Lege regel is workaround voor IE/Edge. Klik op onderste item werkt niet :(
              var empty = { category: "", id: "0", label: "", value: "" };
              response.data.push(empty);
              this.suggestions.push({ name: "section1", data: response.data });
            });
        }
        /*
            const photosPromise = axios.get(this.photosUrl);
            const usersPromise = axios.get(this.usersUrl);

            Promise.all([photosPromise, usersPromise]).then(values => {
              this.suggestions = [];
              this.selected = null;

              const photos = this.filterResults(values[0].data, val, "title");
              const users = this.filterResults(values[1].data, val, "name");

              users.length &&
                this.suggestions.push({ name: "destinations", data: users });
              photos.length &&
                this.suggestions.push({ name: "hotels", data: photos });
        */
      }, this.debounceMilliseconds);
    },

    renderSuggestion(suggestion) {
      if (this.showSectionHeaders) {
        if (suggestion.item.id === "") {
          return (
            <div class="section-header">
              {" "}
              {suggestion.item[this.resultsDisplay]}{" "}
            </div>
          );
        } else {
          return suggestion.item[this.resultsDisplay];
        }
      } else {
        return suggestion.item[this.resultsDisplay];
      }
    },
    getSuggestionValue(suggestion) {
      let { /*name,*/ item } = suggestion;
      return item[this.resultsDisplay];
    },
  },
  watch: {
    placeholder: function (newval) {
      this.inputProps.placeholder = newval;
    },
  },
};
</script>

<style>
/* Styling voor gebruik in quick-search */
.autocomplete-quicksearch {
  border-radius: 0px !important;
  font-family: "Open Sans" !important;
  font-size: 14px !important;
  background-color: #efefef !important;
  border: 1px solid #efefef !important;
  height: 40px;
  position: relative;
  top: 2px;
  left: -2px;
}
.autocomplete-quicksearch .autocomplete__inputs input {
  background-color: #efefef;
}

/* Styling voor op desktop-search */
.autocomplete-search-desktop {
  outline: none !important;
  border: solid 1px !important;
  border-color: #ffcd00 !important;
  border-radius: 5px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-left: 10px !important;
}

/* _Styling voor mobiel */
.autocomplete-mobile {
  border: solid 1px !important;
  border-color: #ffcd00 !important;
  border-radius: 5px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-left: 10px !important;
  font-size: 16px; /* Moet minimaal 16px zijn, anders gaat iPhone automatisch inzoomen... */
  height: 45px;
}

/*****************************************/

.autosuggest__input {
  outline: none;
  outline-color: transparent;
  outline: 0px none transparent;
  position: relative;
  display: block;
  /*border: 1px solid #616161;*/
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

*:focus {
  outline: 0px none transparent;
}

.autosuggest__input.autosuggest__input-open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
}

.autosuggest__results {
  font-weight: 300;
  margin: 0;
  position: absolute;
  z-index: 10000002;
  width: 100%;
  border: 1px solid #e0e0e0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background: white;
  padding: 0px;
  max-height: 510px;
  overflow-y: hidden;
  overflow-x: hidden;
}

.autosuggest__results .autosuggest__results-item {
  line-height: 1;
  cursor: pointer;
  padding: 15px;
  font-size: 16px;
}

.autosuggest__results ul {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

#autosuggest ul:nth-child(1) > .autosuggest__results_title {
  border-top: none;
}

.autosuggest__results .autosuggest__results_title {
  color: gray;
  font-size: 11px;
  margin-left: 0;
  padding: 15px 13px 5px;
  border-top: 1px solid lightgray;
}

.autosuggest__results .autosuggest__results-item:active,
.autosuggest__results .autosuggest__results-item:hover,
.autosuggest__results .autosuggest__results-item:focus,
.autosuggest__results
  .autosuggest__results-item.autosuggest__results-item-highlighted {
  background-color: #f6f6f6;
}

.section-header {
  font-weight: bold;
  cursor: default;
}

/* Workaround: in Edge/IE werkt onderste optie in dropdown niet. Dummy-optie toevoegen en deze niet tonen :( */
.autosuggest__results .autosuggest__results-item:last-child {
  height: 0px;
  padding: 5px; /* Minimale hoogte is nodig, bij hoogte 0 werkt het weer niet in IE/Edge */
}
</style>

<style scoped>
</style>

<template>
  <admin-layout title="Beheer nevenfuncties">
    <h2>Selectie</h2>
    <div class="row">
      <div class="col-md-12">
        <!-- Filter -->
        <div class="row">
          <div class="col-md-3">Faculteit</div>
          <div class="col-md-4">
            <select
              class="form-control"
              v-model="filter.FacultyId"
              id="faculty"
            >
              <option
                v-for="f in faculties"
                :value="f.FacultyId"
                :key="f.Guid"
                >{{ f.Faculty }}</option
              >
            </select>
          </div>
          <div class="col-md-5"></div>
        </div>
        <div class="row">
          <div class="col-md-3">Departement</div>
          <div class="col-md-4">
            <select
              class="form-control"
              v-model="filter.DepartmentId"
              id="departement"
            >
              <option
                v-for="d in departments"
                :value="d.DepartmentId"
                :key="d.Guid"
                >{{ d.Department }}</option
              >
            </select>
          </div>
          <div class="col-md-5"></div>
        </div>
        <div class="row">
          <div class="col-md-3">Afdeling</div>
          <div class="col-md-4">
            <select
              class="form-control"
              v-model="filter.SectionId"
              id="section"
            >
              <option
                v-for="a in sections"
                :value="a.SectionId"
                :key="a.Guid"
                >{{ a.Section }}</option
              >
            </select>
          </div>
          <div class="col-md-5"></div>
        </div>
        <div class="row">
          <div class="col-md-3">Medewerker (naam of SolisID)</div>
          <div class="col-md-9">
            <input v-model="filter.Name" />
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">Status</div>
          <div class="col-md-9">
            <input
              id="cbakkoord"
              type="checkbox"
              v-model="filter.StateApproved"
            />
            <label for="cbakkoord">Akkoord</label>&nbsp;&nbsp;
            <input
              id="cbwacht"
              type="checkbox"
              v-model="filter.StateWaitApproval"
            />
            <label for="cbwacht">Wacht op goedkeuring</label>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">Soort medewerker</div>
          <div class="col-md-9">
            <input
              type="radio"
              name="soort"
              v-model="filter.Professors"
              :value="true"
              id="filterProf"
            />
            <label for="filterProf">Hoogleraren</label>&nbsp;&nbsp;
            <input
              type="radio"
              name="soort"
              v-model="filter.Professors"
              :value="false"
              id="filterAll"
            />
            <label for="filterAll">Alle medewerkers</label>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <input
              type="submit"
              class="uu-button button-black"
              @click="loaddata()"
              value="Zoek"
            />
          </div>
          <div class="col-md-9"></div>
        </div>
      </div>
    </div>
    <p></p>
    <h2 v-if="searched">Nevenfuncties ({{ result.length }} medewerkers)</h2>
    <div class="row" v-if="result.length > 0">
      <div class="col-md-12">
        <!-- Overzicht -->
        <a :href="rapporturl" target="_blank">(download)</a>
        <table class="table table-sm">
          <thead>
            <tr>
              <th>Medewerker</th>
              <th>Laatste update</th>
              <th>Nevenfuncties NL</th>
              <th>Nevenfuncties EN</th>
              <th>Status</th>
              <th>Actie</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="r in result" :key="r.Guid">
              <td>
                <router-link
                  :to="{
                    name: 'Public' + $root.lng,
                    params: { employee: r.Url }
                  }"
                  target="_blank"
                  >{{ r.Name }}</router-link
                >
              </td>
              <td>{{ r.LastUpdate ? formatdate(r.LastUpdate) : "" }}</td>
              <td class="nevenfunctiehtml">
                <span v-html="r.AdditionalFunctionNL" />
              </td>
              <td class="nevenfunctiehtml">
                <span v-html="r.AdditionalFunctionEN" />
              </td>
              <td>{{ r.State }}</td>
              <td>
                <a
                  href="#"
                  @click="publish(r)"
                  title="Goedkeuren / publiceren"
                  v-if="r.State != 'Akkoord'"
                >
                  <icon name="check" scale="1.2" />
                </a>
              </td>
            </tr>
          </tbody>
        </table>
        <a :href="rapporturl" target="_blank">Download als Excel-bestand</a>
      </div>
    </div>
  </admin-layout>
</template>

<script>
import datehelper from "@/helpers/date-helper.vue";
import AdminLayout from "@/components/layout/admin-layout.vue";

export default {
  name: "BeheerLocaties",
  components: {
    "admin-layout": AdminLayout
  },
  data() {
    return {
      result: [],
      searched: false,
      filter: {
        FacultyId: null,
        DepartmentId: null,
        SectionId: null,
        Name: "",
        StateApproved: false,
        StateWaitApproval: true,
        Professors: true
      },
      faculties: [],
      departments: [],
      sections: [],
      rapporturl: ""
    };
  },
  methods: {
    loaddata: function() {
      this.$Progress.start();
      this.$http.post("Admin/GetAdditionalFunctionOverview", this.filter).then(
        function(response) {
          this.result = response.data;
          this.searched = true;
          this.setRapporturl();
          this.$Progress.finish();
        },
        function() {
          // Fail
          this.$Progress.finish();
        }
      );
    },
    publish: function(m) {
      this.$http
        .post("Admin/PublishAdditionalFunction?id=" + m.Id.toString())
        .then(function() {
          m.State = "Akkoord";
        });
    },
    loadfaculties: function() {
      this.$http
        .get("Public/GetFaculties?l=" + this.$root.lng)
        .then(function(response) {
          this.faculties = response.data;
          this.faculties.splice(0, 0, { FacultyId: null, Faculty: "<alle>" });
        });
    },
    loaddepartments: function() {
      if (this.filter.FacultyId != null) {
        this.$http
          .get(
            "Public/GetDepartments?f=" +
              this.filter.FacultyId.toString() +
              "&l=" +
              this.$root.lng
          )
          .then(function(response) {
            this.departments = response.data;
            this.departments.splice(0, 0, {
              DepartmentId: null,
              Department: "<alle>"
            });
          });
      } else {
        this.departments = [];
      }
    },
    loadsections: function() {
      if (this.filter.DepartmentId != null) {
        this.$http
          .get(
            "Public/GetSections?d=" +
              this.filter.DepartmentId.toString() +
              "&l=" +
              this.$root.lng
          )
          .then(function(response) {
            this.sections = response.data;
            this.sections.splice(0, 0, {
              SectionId: null,
              Section: "<alle>"
            });
          });
      } else {
        this.sections = [];
      }
    },
    setRapporturl: function() {
      this.rapporturl =
        this.$http.options.root +
        "/Admin/GetAdditionalFunctionOverviewXlsx?filter=" +
        encodeURIComponent(JSON.stringify(this.filter)) +
        "&t=" +
        window.localStorage.getItem("token");
    }
  },
  watch: {
    /*
      filter:
      {  handler: function() {this.loaddata();},
            deep: true
          },
    */
    "filter.FacultyId": function() {
      this.loaddepartments();
      this.sections = [];
      this.filter.SectionId = null;
    },
    "filter.DepartmentId": function() {
      this.loadsections();
    }
  },
  created: function() {
    // this.loaddata();
    this.loadfaculties();
  },
  mixins: [datehelper]
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.nevenfunctiehtml {
  font-size: 70%;
  max-width: 500px;
}
</style>

<template>
  <span>
    <b-modal
      id="modalAlert"
      ref="modalAlert"
      :title='($root.lng=="NL" ? titleNL : titleEN)'
      @ok="onclick"
      :cancel-title='($root.lng=="NL" ? "Ok" : "Ok")'
      v-model="showmodal"
      :ok-only="true"
    >
      <p>{{($root.lng=="NL" ? messageNL : messageEN)}}</p>
    </b-modal>
  </span>
</template>

<script>
export default {
  name: "alert",
  props: [],
  data() {
    return {
      titleNL: null,
      titleEN: null,
      messageNL: null,
      messageEN: null,
      showmodal: false
    };
  },
  methods: {
    onclick: function() {},
    show(messageNL, messageEN, titleNL, titleEN) {
      this.titleNL = titleNL;
      this.titleEN = titleEN;
      this.messageNL = messageNL;
      this.messageEN = messageEN;
      this.showmodal = true;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

<template>
  <div class="Organogram">
    <div class="row organogram-header font-headline">
      <div class="col-md-1"></div>
      <div class="col-md-10">
        <h1>
          <label-lng NL="Organogram" EN="Organisational Chart" />
        </h1>
      </div>
      <div class="col-md-1"></div>
    </div>

    <div class="row">
      <div class="col-md-1 om-background"></div>
      <div class="col-md-3 om-background om">
        <div class="om-structuur font-sidebar">
          <!-- Filters -->
          <div>
            <div class="filter-container">
              <div class="filter-header" @click="showfilters = !showfilters">
                Filters
                <div class="filter-openclose">
                  <icon v-show="!showfilters" name="chevron-up" />
                  <icon v-show="showfilters" name="chevron-down" />
                </div>
              </div>
              <div class="filter-content" v-show="showfilters">
                <!-- Expertise -->
                <div v-show="!filter.active || filter.expertise">
                  <autocomplete
                    ref="filterexpertise"
                    :sourceurl="autocompleteurlexpertise"
                    results-display="Value"
                    results-value="Key"
                    enterSelectTopItem="true"
                    :placeholder="
                      $root.lng == 'NL'
                        ? 'Zoek op expertises'
                        : 'Search for expertise'
                    "
                    @OnItemSelect="filterexpertiseInput"
                    input-class="autocomplete-organogram"
                  ></autocomplete>

                  <span
                    class="filter-remove"
                    v-show="filter.active"
                    @click="clearfilterExpertise()"
                  >
                    <label-lng NL="Verwijder filter" EN="Remove filter" />
                    <span class="filter-remove-btn">
                      <icon name="times" />
                    </span>
                  </span>
                </div>
                <!-- Vaardigheid -->
                <div v-show="!filter.active || filter.skill">
                  <autocomplete
                    ref="filterskill"
                    :sourceurl="autocompleteurlskill"
                    results-display="Value"
                    results-value="Key"
                    enterSelectTopItem="true"
                    :placeholder="
                      $root.lng == 'NL'
                        ? 'Zoek op vaardigheden of functie'
                        : 'Search for skills or position'
                    "
                    @OnItemSelect="filterskillInput"
                    input-class="autocomplete-organogram"
                  ></autocomplete>
                  <span
                    class="filter-remove"
                    v-show="filter.active"
                    @click="clearfilterSkill()"
                  >
                    <label-lng NL="Verwijder filter" EN="Remove filter" />
                    <span class="filter-remove-btn">
                      <icon name="times" />
                    </span>
                  </span>
                </div>
                <!-- Focusgebied -->
                <div v-show="!filter.active || filter.focusareaId != 0">
                  <select
                    v-model="filter.focusareaId"
                    class="filter-combobox"
                    @change="onChangeFilterFocusarea()"
                  >
                    <option value="0">
                      <label-lng NL="Onderzoeksthema" EN="Research area" />
                    </option>
                    <option v-for="f in focusareas" :value="f.Id" :key="f.Guid">
                      {{ f.Name }}
                    </option>
                  </select>
                  <span
                    class="filter-remove font-headline"
                    v-show="filter.active"
                    @click="clearfilterFocusArea()"
                  >
                    <label-lng NL="Verwijder filter" EN="Remove filter" />
                    <span class="filter-remove-btn">
                      <icon name="times" />
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <!-- organisatiestructuur  -->
          <div class="om-element active root-element" @click="selectUU()">
            <label-lng NL="Universiteit Utrecht" EN="Utrecht University" />
          </div>
          <div v-for="f in faculties" class="level1" :key="f.Guid">
            <div
              class="om-element"
              :class="{ active: f == selectedFaculty }"
              @click="selectFaculty(f)"
            >
              <tree-node
                :ChildCount="f.ChildCount"
                :Selected="f == selectedFaculty"
              />
              <div>{{ f.Faculty }}</div>
            </div>

            <div v-show="f == selectedFaculty" class="level2">
              <span v-show="departments == null" class="loading">
                <label-lng NL="Bezig met laden..." EN="Loading..." />
              </span>
              <div v-for="d in departments" :key="d.Guid">
                <div
                  class="om-element"
                  :class="{ active: d == selectedDepartment }"
                  @click="selectDepartment(d)"
                >
                  <tree-node
                    :ChildCount="d.ChildCount"
                    :Selected="d != selectedDepartment"
                  />
                  <div>{{ d.Department }}</div>
                </div>

                <div v-show="d == selectedDepartment" class="level3">
                  <span v-show="sections == null" class="loading">
                    <label-lng NL="Bezig met laden..." EN="Loading..." />
                  </span>
                  <div v-for="s in sections" :key="s.Guid">
                    <div
                      class="om-element"
                      :class="{ active: s == selectedSection }"
                      @click="selectSection(s)"
                    >
                      <tree-node
                        :ChildCount="s.ChildCount"
                        :Selected="s == selectedSection"
                      />
                      <div>{{ s.Section }}</div>
                    </div>

                    <div v-show="s == selectedSection" class="level4">
                      <span v-show="groups == null" class="loading">
                        <label-lng NL="Bezig met laden..." EN="Loading..." />
                      </span>
                      <div v-for="g in groups" :key="g.Guid">
                        <div
                          class="om-element"
                          :class="{ active: g == selectedGroup }"
                          @click="selectGroup(g)"
                        >
                          <tree-node
                            :ChildCount="g.ChildCount"
                            :Selected="g == selectedGroup"
                          />
                          <div>{{ g.Group }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-7 content">
        <div class="section-title font-headline">
          <div>
            <span v-show="homepageurl == ''">{{ name }}</span>
            <a
              :href="homepageurl"
              target="_blank"
              v-show="homepageurl != ''"
              :title="
                $root.lng == 'NL'
                  ? 'Open homepage van onderdeel'
                  : 'Open homepage'
              "
              >{{ name }}</a
            >
            <a
              :href="homepageurl"
              target="_blank"
              v-show="homepageurl != ''"
              :title="
                $root.lng == 'NL'
                  ? 'Open homepage van onderdeel'
                  : 'Open homepage'
              "
              ><img
                src="../../assets/external.png"
                :title="
                  $root.lng == 'NL'
                    ? 'Open homepage van onderdeel'
                    : 'Open homepage'
                "
                style="margin-left: 10px"
              />
            </a>
          </div>
          <div class="filter-caption" v-show="filter.active && 1 == 0">
            <span v-show="filter.focusareaId != 0">
              <label-lng NL="Focusgebied" EN="Focus area" />
              {{ filter.focusarea_display }}
            </span>
            <span v-show="filter.expertise != null">
              <label-lng NL="Expertise" EN="Expertise" />
              {{ filter.expertise_display }}
            </span>
            <span v-show="filter.skill != null">
              <label-lng NL="Vaardigheid" EN="Skill" />
              {{ filter.skill_display }}
            </span>
          </div>
        </div>
        <div
          class="homepagebuttons uu-button"
          v-if="name != '' && !filter.active && !initial"
        >
          <button
            class="uu-button"
            :class="{
              'button-yellow': page != 'M',
              'button-black': page == 'M',
            }"
            @click="viewMedewerkers"
          >
            <label-lng NL="Medewerkers" EN="Employees" />
          </button>
          <button
            class="uu-button"
            :class="{
              'button-yellow': page != 'E',
              'button-black': page == 'E',
            }"
            @click="viewExpertises"
          >
            <label-lng
              NL="Wetenschappelijke expertises"
              EN="Scientific expertises"
            />
          </button>
          <button
            class="uu-button"
            :class="{
              'button-yellow': page != 'V',
              'button-black': page == 'V',
            }"
            @click="viewVaardigheden"
          >
            <label-lng NL="Vaardigheden" EN="Skills" />
          </button>
        </div>

        <div class="content-area" v-show="ou == null && !initial">
          <span class="loading">
            <label-lng NL="Bezig met laden..." EN="Loading..." />
          </span>
        </div>
        <div
          class="content-area"
          v-show="!filter.active && selectedFaculty == null && initial"
        >
          <div class="melding-level-uu">
            <label-lng
              NL="Gebruik het menu links op deze pagina om door de organisatie te bladeren, eventueel in combinatie met een aanvullend filter op expertise, een functie of vaardigheid, of een onderzoeksthema."
              EN="Browse the organisation by using the left menu. Additionally, you can use one of the filter options for expertise, a skill or position, or research area."
            />
          </div>
        </div>
        <div
          class="content-area"
          v-show="filter.active && ou != null && ou.Employees.length == 0"
        >
          <span class="melding-level-uu">
            <label-lng
              NL="Er zijn geen medewerkers die aan het filter voldoen."
              EN="No matching employees found."
            />
          </span>
        </div>

        <div class="content-area" v-if="ou != null">
          <div v-show="page == 'M'">
            <!-- Medewerkers -->
            <span
              class="employee-block"
              v-for="e in ou.Employees"
              :key="e.Guid"
            >
              <div class="photo-div">
                <router-link
                  :to="{
                    name: 'Public' + $root.lng,
                    params: { employee: e.Url },
                  }"
                  v-if="e.PhotoUrl"
                >
                  <img
                    :src="$http.options.root + e.PhotoUrl + token"
                    :title="e.Name"
                    class="img-fluid"
                  />
                </router-link>
              </div>
              <div class="employee-text">
                <router-link
                  :to="{
                    name: 'Public' + $root.lng,
                    params: { employee: e.Url },
                  }"
                  >{{ e.Name }}</router-link
                >
              </div>
              <div class="employee-text">{{ e.Position }}</div>
              <div class="employee-text">
                <a :href="'mailto:' + e.Email">{{ e.Email }}</a>
              </div>
              <div class="employee-text">
                <a :href="'tel:' + e.Phone">{{ e.Phone }}</a>
              </div>
            </span>
            <!-- More-button -->
            <div class="more-results" v-show="ou.More > 0">
              <button
                class="uu-button button-black"
                @click="showMore()"
                v-show="!loadingMore"
              >
                <label-lng
                  :NL="
                    'Overige ' +
                    ou.More.toString() +
                    ' medewerker' +
                    (ou.More != 1 ? 's' : '') +
                    ' ophalen'
                  "
                  :EN="
                    'Get remaining ' +
                    ou.More.toString() +
                    ' employee' +
                    (ou.More != 1 ? 's' : '')
                  "
                />
              </button>
              <span>
                <label-lng
                  v-show="loadingMore"
                  NL="Bezig met laden..."
                  EN="Loading..."
                />
              </span>
            </div>
          </div>

          <div v-show="page == 'E'">
            <!-- Expertises -->
            <ul v-for="e in ou.Expertises" :key="e.Guid">
              <li>
                <a
                  :href="
                    $router.resolve({ name: 'organogram' + $root.lng }).href +
                    '?expertise=' +
                    e.Key
                  "
                  >{{ e.Value }}</a
                >
              </li>
            </ul>
          </div>

          <div v-show="page == 'V'">
            <!-- Vaardigheden -->
            <ul v-for="s in ou.Skills" :key="s.Guid">
              <li>
                <a
                  :href="
                    $router.resolve({ name: 'organogram' + $root.lng }).href +
                    '?skill=' +
                    s.Key
                  "
                  >{{ s.Value }}</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-md-1"></div>
    </div>
  </div>
</template>

<script>
import labellng from "@/components/layout/label-lng.vue";
import Autocomplete from "@/components/layout/autocomplete.vue";
import treenode from "@/components/layout/tree-node.vue";

export default {
  name: "organogram",
  data() {
    return {
      token: window.localStorage.getItem("token"),
      initial: true,
      faculties: [],
      selectedFaculty: null,
      departments: [],
      selectedDepartment: null,
      sections: [],
      selectedSection: null,
      groups: [],
      selectedGroup: null,
      ou: null,
      homepageurl:
        this.$root.lng === "NL" ? "https://www.uu.nl" : "https://www.uu.nl/en",
      name:
        this.$root.lng === "NL" ? "Universiteit Utrecht" : "Utrecht University",
      page: "M" /* M = medewerkers, E = expertises, V = vaardigheden */,
      showfilters: true,
      focusareas: [],
      filter: {
        active: false,
        focusareaId: 0,
        expertise: null,
        expertiseinput: null,
        skill: null,
        skillinput: null,
        expertise_display: "",
        skill_display: "",
        focusarea_display: "",
      },
      autocompleteurlexpertise: "",
      autocompleteurlskill: "",
      empUrl: "",
      loadingMore: false,
    };
  },
  props: ["medewerker"],
  components: {
    "label-lng": labellng,
    autocomplete: Autocomplete,
    "tree-node": treenode,
  },
  methods: {
    selectUU: function () {
      this.homepageurl =
        this.$root.lng === "NL" ? "https://www.uu.nl" : "https://www.uu.nl/en";
      this.name =
        this.$root.lng === "NL" ? "Universiteit Utrecht" : "Utrecht University";
      this.ou = null;
      this.initial = true;
      if (this.filter.active) {
        // Niveau UU alleen ophalen als er een filter is.
        this.empUrl =
          "Public/GetEmployeesOrganogram?l=" +
          this.$root.lng +
          "&token=" +
          this.token +
          this.getfilter();
        this.$http.get(this.empUrl).then(function (response) {
          this.ou = response.data;
          this.initial = false;
        });
      } else {
        // Geen filter => geen medewerkers tonen
        this.ou = null;
        this.initial = true;
      }
      this.selectedFaculty = null;
      this.selectedDepartment = null;
      this.selectedSection = null;
      this.selectedGroup = null;
      this.departments = null;
      this.page = "M";
      this.departments = [];
      this.sections = [];
      this.groups = [];
      this.setUrl(1);
      this.loadfaculties();
    },
    selectFaculty: function (faculty) {
      //  Er wordt op de opengeklapte faculteit geklikt => naar UU-niveau
      // if (this.selectedFaculty == faculty)
      // {
      //  this.gotoLevelUU();
      //  this.selectUU();
      //  return;
      // }
      this.selectedFaculty = faculty;
      this.initFaculty(faculty);
      this.loadDepartments(faculty.Code);
      this.setUrl(2);
    },
    loadfaculties: function (defaultFacultyCode, showdetails) {
      this.$http
        .get("Public/GetFaculties?l=" + this.$root.lng + this.getfilter())
        .then(function (response) {
          this.faculties = response.data;
          // Selecteer faculteit uit url
          if (defaultFacultyCode != null) {
            for (var i = 0; i < this.faculties.length; i++) {
              if (
                this.faculties[i].Code.toUpperCase() ===
                defaultFacultyCode.toUpperCase()
              ) {
                this.selectedFaculty = this.faculties[i];
              }
            }
            if (showdetails) {
              this.initFaculty(this.selectedFaculty);
            }
          }
        });
    },
    initFaculty: function (faculty) {
      this.homepageurl = faculty.FacultyUrl;
      this.name = faculty.Faculty;
      this.ou = null;
      this.initial = false;
      // Laad employees
      this.empUrl =
        "Public/GetEmployeesOrganogram?f=" +
        faculty.FacultyId.toString() +
        "&l=" +
        this.$root.lng +
        "&token=" +
        this.token +
        this.getfilter();
      this.$http.get(this.empUrl).then(function (response) {
        this.ou = response.data;
        this.initial = false;
      });
      this.selectedDepartment = null;
      this.selectedSection = null;
      this.selectedGroup = null;
    },
    loadDepartments: function (
      facultyCode,
      defaultDepartmentCode,
      showdetails
    ) {
      this.departments = [];
      this.$http
        .get(
          "Public/GetDepartments?fc=" +
            facultyCode +
            "&l=" +
            this.$root.lng +
            "&skipNoName=true" +
            this.getfilter()
        )
        .then(function (response) {
          this.departments = response.data;

          // Selecteer departement uit url
          if (defaultDepartmentCode != null) {
            for (var i = 0; i < this.departments.length; i++) {
              if (
                this.departments[i].Code.toUpperCase() ===
                defaultDepartmentCode.toUpperCase()
              ) {
                this.selectedDepartment = this.departments[i];
              }
            }
            if (showdetails) {
              this.initDepartment(this.selectedDepartment);
            }
          }
        });
    },
    selectDepartment: function (department) {
      // if (this.selectedDepartment === department) {
      //  this.selectedDepartment = null;
      //  return;
      // }
      this.selectedDepartment = department;
      this.initDepartment(department);
      this.loadSections(department.Code);
      this.setUrl(3);
    },
    initDepartment: function (department) {
      this.homepageurl = department.DepartmentUrl;
      this.name = department.Department;
      this.ou = null;
      this.empUrl =
        "Public/GetEmployeesOrganogram?d=" +
        department.DepartmentId.toString() +
        "&l=" +
        this.$root.lng +
        "&token=" +
        this.token +
        this.getfilter();
      this.$http.get(this.empUrl).then(function (response) {
        this.ou = response.data;
        this.initial = false;
      });
      this.selectedSection = null;
      this.selectedGroup = null;
    },
    loadSections: function (departmentCode, defaultSectionCode, showdetails) {
      this.sections = [];
      this.$http
        .get(
          "Public/GetSections?dc=" +
            departmentCode +
            "&l=" +
            this.$root.lng +
            "&skipNoName=true" +
            this.getfilter()
        )
        .then(function (response) {
          this.sections = response.data;

          // Selecteer section uit url
          if (defaultSectionCode != null) {
            for (var i = 0; i < this.sections.length; i++) {
              if (
                this.sections[i].Code.toUpperCase() ===
                defaultSectionCode.toUpperCase()
              ) {
                this.selectedSection = this.sections[i];
              }
            }
            if (showdetails) {
              this.initSection(this.selectedSection);
            }
          }
        });
    },
    selectSection: function (section) {
      // if (this.selectedSection === section) {
      //  this.selectedSection =  null;
      //  return;
      // }
      this.selectedSection = section;
      this.initSection(section);
      this.loadGroups(section.Code);
      this.setUrl(4);
    },
    initSection: function (section) {
      this.homepageurl = section.SectionUrl;
      this.name = section.Section;
      this.ou = null;
      this.empUrl =
        "Public/GetEmployeesOrganogram?s=" +
        section.SectionId.toString() +
        "&l=" +
        this.$root.lng +
        "&token=" +
        this.token +
        this.getfilter();
      this.$http.get(this.empUrl).then(function (response) {
        this.ou = response.data;
        this.initial = false;
      });
      this.selectedGroup = null;
    },
    loadGroups: function (sectionCode, defaultGroupCode, showdetails) {
      this.groups = [];
      this.$http
        .get(
          "Public/GetGroups?sc=" +
            sectionCode +
            "&l=" +
            this.$root.lng +
            "&skipNoName=true" +
            this.getfilter()
        )
        .then(function (response) {
          this.groups = response.data;

          // Selecteer group uit url
          if (defaultGroupCode != null) {
            for (var i = 0; i < this.groups.length; i++) {
              if (
                this.groups[i].Code.toUpperCase() ===
                defaultGroupCode.toUpperCase()
              ) {
                this.selectedGroup = this.groups[i];
              }
            }
            if (showdetails) {
              this.initGroup(this.selectedGroup);
            }
          }
        });
    },
    selectGroup: function (group) {
      this.selectedGroup = group;
      this.initGroup(group);
      this.setUrl(5);
    },
    initGroup: function (group) {
      this.homepageurl = group.GroupUrl;
      this.name = group.Group;
      this.ou = null;
      this.empUrl =
        "Public/GetEmployeesOrganogram?g=" +
        group.GroupId.toString() +
        "&l=" +
        this.$root.lng +
        "&token=" +
        this.token +
        this.getfilter();
      this.$http.get(this.empUrl).then(function (response) {
        this.ou = response.data;
        this.initial = false;
      });
    },
    viewMedewerkers: function () {
      this.page = "M";
    },
    viewExpertises: function () {
      this.page = "E";
    },
    viewVaardigheden: function () {
      this.page = "V";
    },

    loadfocusareas: function () {
      this.$http
        .get("Public/GetFocusareas?l=" + this.$root.lng)
        .then(function (response) {
          this.focusareas = response.data;
        });
    },
    filterexpertiseInput: function (value) {
      this.filter.expertise = value;
      this.filter.active = true;
      this.routerPush({ query: { expertise: value } });
      this.$route.query.expertise = value;
      this.selectUU();
    },
    clearfilterExpertise: function () {
      this.filter.expertise = null;
      this.filter.expertiseinput = "";
      if (this.$refs.filterexpertise) {
        this.$refs.filterexpertise.clear();
      }
      this.filter.active = false;
      this.$route.query.expertise = "";
      this.selectUU();
    },
    setautocompleteurlexpertise: function () {
      this.autocompleteurlexpertise =
        this.$http.options.root +
        "/Public/SearchExpertise?l=" +
        this.$root.lng +
        "&term=";
    },
    filterskillInput: function (value) {
      this.filter.skill = value;
      this.filter.active = true;
      this.routerPush({ query: { skill: value } });
      this.$route.query.skill = value;
      this.selectUU();
    },
    clearfilterSkill: function () {
      this.filter.skill = null;
      this.filter.skillinput = "";
      if (this.$refs.filterskill) {
        this.$refs.filterskill.clear();
      }
      this.filter.active = false;
      this.$route.query.skill = "";
      this.selectUU();
    },
    setautocompleteurlskill: function () {
      this.autocompleteurlskill =
        this.$http.options.root +
        "/Public/SearchSkillPosition?l=" +
        this.$root.lng +
        "&term=";
    },
    onChangeFilterFocusarea: function () {
      this.filter.active = true;
      this.routerPush({ query: { focusarea: this.filter.focusareaId } });
      this.$route.query.focusarea = this.filter.focusareaId;
      this.selectUU();
    },
    getFocusareaName: function (id) {
      if (id === 0) {
        return "";
      } else {
        for (var i = 0; i < this.focusareas.length; i++) {
          if (this.focusareas[i].Id === id) {
            return this.focusareas[i].Name;
          }
        }
      }
    },
    clearfilterFocusArea: function () {
      this.filter.focusareaId = 0;
      this.filter.active = false;
      this.$route.query.focusarea = "";
      this.selectUU();
    },
    getfilter: function () {
      var f = "";
      if (this.$route.query.skill != null && this.$route.query.skill !== "") {
        f += "&skill=" + this.$route.query.skill;
      }
      if (
        this.$route.query.focusarea != null &&
        this.$route.query.focusarea !== "0" &&
        this.$route.query.focusarea !== ""
      ) {
        f += "&focusarea=" + this.$route.query.focusarea;
      }
      if (
        this.$route.query.expertise != null &&
        this.$route.query.expertise !== ""
      ) {
        f += "&expertise=" + this.$route.query.expertise;
      }
      return f;
    },
    openSkill: function (id, text) {
      this.filter.skill = parseInt(id);
      this.filter.expertiseinput = "";
      this.filter.skillinput = text;
      this.$refs.filterskill.setText(text);
      this.filter.expertise = null;
      this.filter.focusareaId = 0;
      this.showfilters = true;
      this.filter.skill_display = text; 
      this.page = "M";
    },
    openExpertise: function (id, text) {
      this.filter.expertise = parseInt(id);
      this.filter.expertiseinput = text;
      this.$refs.filterexpertise.setText(text);
      this.filter.skillinput = "";
      this.filter.skill = null;
      this.filter.focusareaId = 0;
      this.showfilters = true;
      this.filter.expertise_display = text;
      this.page = "M";
    },
    openFocusArea: function (id, text) {
      this.filter.focusareaId = parseInt(id);
      this.filter.skill = null;
      this.filter.expertise = null;
      this.showfilters = true;
      if (text == null || text === "") {
        this.filter.focusarea_display = this.getFocusareaName(
          this.filter.focusareaId
        );
      } else {
        this.filter.focusarea_display = text;
      }
      this.page = "M";
    },
    filterFromUrl: function () {
      var filter = false;
      if (this.$route.query.skill != null) {
        filter = true;
        // if (this.$route.query.skill.toString().startsWith("-")) // -- startWith werkt niet in IE11 :(
        if (this.$route.query.skill.toString().match(/^-/g)) {
          // Negatief getal => functie
          this.$http
            .get(
              "Public/GetPosition?l=" +
                this.$root.lng +
                "&id=" +
                this.$route.query.skill.toString().substring(1)
            )
            .then(function (response) {
              this.openSkill(this.$route.query.skill, response.data.Name);
            });
        } else {
          this.$http
            .get(
              "Public/GetSkill?l=" +
                this.$root.lng +
                "&id=" +
                this.$route.query.skill
            )
            .then(function (response) {
              this.openSkill(this.$route.query.skill, response.data.Name);
            });
        }
      }
      if (this.$route.query.expertise != null) {
        filter = true;
        this.$http
          .get(
            "Public/GetExpertise?l=" +
              this.$root.lng +
              "&id=" +
              this.$route.query.expertise
          )
          .then(function (response) {
            this.openExpertise(this.$route.query.expertise, response.data.Name);
          });
      }
      if (this.$route.query.focusarea != null) {
        filter = true;
        this.$http
          .get(
            "Public/GetFocusarea?l=" +
              this.$root.lng +
              "&id=" +
              this.$route.query.focusarea
          )
          .then(function (response) {
            this.openFocusArea(this.$route.query.focusarea, response.data.Name);
          });
      }
      this.filter.active = filter;
    },

    // Zet de huidige positie in de OM-boom in het url
    setUrl: function (level) {
      if (level === 1) {
        this.routerPush({
          name: "organogram" + this.$root.lng,
          params: {},
          query: this.getFilterQuery(),
        });
      }
      if (level === 2) {
        this.routerPush({
          name: "organogram1" + this.$root.lng,
          params: { faculty: this.selectedFaculty.Code },
          query: this.getFilterQuery(),
        });
      }
      if (level === 3) {
        this.routerPush({
          name: "organogram2" + this.$root.lng,
          params: {
            faculty: this.selectedFaculty.Code,
            department: this.selectedDepartment.Code,
          },
          query: this.getFilterQuery(),
        });
      }
      if (level === 4) {
        this.routerPush({
          name: "organogram3" + this.$root.lng,
          params: {
            faculty: this.selectedFaculty.Code,
            department: this.selectedDepartment.Code,
            section: this.selectedSection.Code,
          },
          query: this.getFilterQuery(),
        });
      }
      if (level === 5) {
        this.routerPush({
          name: "organogram4" + this.$root.lng,
          params: {
            faculty: this.selectedFaculty.Code,
            department: this.selectedDepartment.Code,
            section: this.selectedSection.Code,
            group: this.selectedGroup.Code,
          },
          query: this.getFilterQuery(),
        });
      }
    },

    // Ophalen faculteit/dep/sec/group uit url.
    gotoOMfromUrl: function () {
      var level = 1;
      if (this.$route.params.faculty) level = 2;
      if (this.$route.params.department) level = 3;
      if (this.$route.params.section) level = 4;
      if (this.$route.params.group) level = 5;
      if (level === 1) this.selectUU();
      if (level > 1) {
        this.loadfaculties(this.$route.params.faculty, level === 2); // faculteiten ophalen
      }
      if (level >= 2) {
        this.loadDepartments(
          this.$route.params.faculty,
          this.$route.params.department,
          level === 3
        ); // Departementen ophalen
      }
      if (level >= 3) {
        this.loadSections(
          this.$route.params.department,
          this.$route.params.section,
          level === 4
        ); // Afdelingen ophalen
      }
      if (level >= 4) {
        this.loadGroups(
          this.$route.params.section,
          this.$route.params.group,
          level === 5
        ); // Groepen ophalen
      }
    },
    // Maak query-object voor huidige filter
    getFilterQuery: function () {
      if (
        this.$route.query.focusarea != null &&
        this.$route.query.focusarea !== "0" &&
        this.$route.query.focusarea !== ""
      ) {
        return { focusarea: this.$route.query.focusarea };
      }
      if (this.$route.query.skill != null && this.$route.query.skill !== "") {
        return { skill: this.$route.query.skill };
      }
      if (
        this.$route.query.expertise != null &&
        this.$route.query.expertise !== ""
      ) {
        return { expertise: this.$route.query.expertise };
      }
      return {};
    },

    showMore: function () {
      if (this.empUrl !== "") {
        this.empUrl += "&fullresult=true";
        this.loadingMore = true;
        this.$http.get(this.empUrl).then(function (response) {
          this.ou = response.data;
          this.loadingMore = false;
        });
      }
    },
    // Doe alleen push als je nog niet op het gevraagde url zit. Dit voorkomt error "Uncaught NavigationDuplicated: Avoided redundant navigation to current location: ..."
    routerPush(to) {
      if (to.name != this.$route.name) {
        this.$router.push(to);
        return;
      }
      if (JSON.stringify(to.params) != JSON.stringify(this.$route.params)) {
        this.$router.push(to);
        return;
      }
      if (JSON.stringify(to.query) != JSON.stringify(this.$route.query)) {
        this.$router.push(to);
        return;
      }
    },
  },
  created: function () {
    this.loadfocusareas(); // Dropdown met focusarea voor filter vullen.
    this.filterFromUrl(); // Zet filter-properties vanuit filter-settings in url.
    this.gotoOMfromUrl(); // Ga naar het OM-onderdeel van het betreffende onderdeel. Laadt de medewerkers van het laagste niveau dat in het url is meegegeven.
    this.setautocompleteurlexpertise();
    this.setautocompleteurlskill();
  },
  mounted: function () {},
  watch: {
    filter: {
      handler: function () {
        // Filter wijzigt
        var newActive =
          this.filter.focusareaId !== 0 ||
          this.filter.expertise != null ||
          this.filter.skill != null;
        // var oldActive = this.filter.active;
        this.filter.active = newActive;

        if (newActive) {
          this.filter.expertise_display = this.$refs.filterexpertise.getTerm();
          this.filter.skill_display = this.$refs.filterskill.getTerm();
          this.filter.focusarea_display = this.getFocusareaName(
            this.filter.focusareaId
          );
        }
      },
      deep: true,
    },
    "filter.focusareaId": function (nw) {
      if (nw !== 0) {
        // Zet focusarea in querystring
        this.routerPush({ query: { focusarea: nw } });
      } else {
        // Verwijder querystring (eigenlijk alleen focusarea verwijderen, nog onderzoeken)
        this.routerPush({ query: {} });
      }
    },
    "filter.expertise": function (nw) {
      if (nw != null) {
        // Zet expertise in querystring
        this.routerPush({ query: { expertise: nw } });
      } else {
        // Verwijder querystring (eigenlijk alleen expertise verwijderen, nog onderzoeken)
        this.routerPush({ query: {} });
      }
    },
    "filter.skill": function (nw) {
      if (nw != null) {
        // Zet skill in querystring
        if (nw != this.filter.skill)
          this.routerPush({ query: { skill: nw } });
      } else {
        // Verwijder querystring (eigenlijk alleen skill verwijderen, nog onderzoeken)
        this.routerPush({ query: {} });
      }
    },
    "$route.query.expertise": function () {
      this.filterFromUrl();
    },
    "$route.query.skill": function () {
      this.filterFromUrl();
    },
    "$route.query.focusarea": function () {
      this.filterFromUrl();
    },
  },
  computed: {},
};
</script>

<style scoped>
/* Gele balk bovenaan */
.organogram-header {
  background-color: #ffcd00;
  padding-top: 10px;
  padding-bottom: 10px;
  min-height: 50px;
}

.Organogram {
  min-height: 500px;
}

/* Achtergrond van om-structuur en linker marge-kolom */
.om-background {
  background-color: #efefef;
}

/* om is het gedeelte waar de om-structuur staat */
.om {
  padding-bottom: 100px;
  padding-top: 35px;
}

/* Div waar OM-structuur in staat */
.om-structuur {
  font-size: 10pt; /* Niet te groot, anders past het niet*/
  margin-top: 15px;
  line-height: 40px;
}

/*Styling van ieder niveau in de OM-structuur */
.om-element {
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-content: flex-start;
  line-height: 1.1; /* Hier standaard regelhoogte (ivm regeloverloop). Met padding zorgen voor regelafstand. */
  padding-top: 10px; /* Afstand tussen om-elementen met padding*/
  padding-bottom: 10px;
}
/* Als je met je muis over om-elementen gaat */
.om-element > div:hover {
  text-decoration: underline;
}

/* Root-element is Universiteit Utrecht */
.root-element {
  font-size: 15px;
  font-weight: 700;
}

/* Geselecteerd OM-element*/
.active {
  font-weight: 700;
}

/* Inspringen van de verschillende OM-niveau's */
.level1 {
  margin-left: 0px;
}
.level2 {
  margin-left: 20px;
}
.level3 {
  margin-left: 30px;
}
.level4 {
  margin-left: 40px;
}

/* Div met 3 knoppen Homepage en detail-page */
.homepagebuttons {
  height: 90px;
  line-height: 90px;
  padding-left: 80px; /* Knoppen (ongeveer) uitlijnen met foto's */
  padding-top: 0px;
  padding-bottom: 15px;
}

.section-title {
  padding-top: 40px;
  margin-left: 80px;
  font-weight: 400;
  font-size: 38px;
  line-height: 1.4;
  margin-bottom: 3px;
}

/* Tussenruimte buttons homepage/detail-page */
.homepagebuttons > a {
  margin-right: 94px;
}

/* Tussenruimte tussen de knoppen */
.homepagebuttons button {
  margin-right: 5px;
}

/* Beschrijving van filter, onder de naam van het OM-onderdeel*/
.filter-caption {
  margin-top: 10px;
  font-size: 10pt;
}

/* Het gedeelte waar de foto's komen te staan */
.content-area {
  font-size: 18px;
  line-height: 20px;
  padding-top: 25px;
  padding-left: 60px;
}

.loading {
  font-family: "Open Sans";
  font-size: 10pt;
}
.content-area .loading {
  padding-left: 60px;
}

.melding-level-uu {
  padding-left: 20px;
  line-height: 1.8;
}

.employee-block {
  display: inline-block;
  width: 190px;
  height: 325px;
  background-color: #efefef;
  margin-left: 20px;
  margin-bottom: 12px;
  overflow: hidden;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 7px;
  padding-right: 8px;
  font-size: 12px;
  font-family: "Open Sans";
}

.employee-text {
  width: 100%;
  text-align: center;
}

.photo-div {
  height: 200px;
  width: 175px;
  line-height: 200px;
  overflow: hidden;
  text-align: center;
}

.photo-div img {
  max-height: 200px;
}

/* Filters */
.filter-container {
  border: 3px solid #ced4da; /*#FFCD00;*/
  padding: 0px 10px 0px 10px;
  background-color: #ffffff;
  color: #3b3b3b;
  margin-bottom: 15px;
}

.filter-header {
  font-size: 14px;
  cursor: pointer;
}

.filter-openclose {
  float: right;
}

.filter-content {
  line-height: 25px;
}

.filter-content > div {
  min-height: 40px;
  padding-top: 5px;
  padding-bottom: 10px;
}

.filter-content select {
  width: 100%;
  border: 3px solid #ced4da;
  border-radius: 0px;
  padding-left: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #666666;
  height: 47px;
  font-family: "Open Sans";
  font-size: 1rem;
}

.filter-remove {
  background-color: #000;
  display: inline-block;
  color: #fff;
  height: 30px;
  padding-left: 10px;
  padding-right: 10px;
  line-height: 30px;
  font-size: 13px;
  margin-top: 25px;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
}

.filter-remove-btn {
  color: #fff;
  margin-left: 20px;
  position: relative;
  top: -1px;
  cursor: pointer;
}

.more-results {
  text-align: center;
  margin-bottom: 20px;
  min-height: 40px;
  line-height: 40px;
}
</style>

<style>
.autocomplete-organogram {
  border-radius: 0px !important;
  border-width: 3px !important;
  height: 47px !important;
}

.autocomplete-organogram:focus {
  border-color: #ced4da !important;
}
</style>

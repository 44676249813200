<template>
  <admin-layout title="Beheer opleidingen">
    <div class="row" v-if="studyprogrammes">
      <div class="col-md-12">
        Faculteit:
        <select v-model="facultyId">
          <option v-for="f in faculties" :value="f.FacultyId" :key="f.Guid">{{f.Faculty}}</option>
        </select>

        <table class="table table-sm table-hover">
          <thead>
            <tr>
              <th>Nederlands</th>
              <th>Engels</th>
              <th>ID</th>
              <th>#Med</th>
              <th class="iconcolumn">Acties</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="sp in studyprogrammes" :key="sp.Guid">
              <td>{{ sp.Name.NL }}</td>
              <td>{{ sp.Name.EN }}</td>
              <td>{{ sp.Id }}</td>
              <td>{{ sp.Count }}</td>
              <td>
                <iconbutton icon="pencil-alt" titleNL="Bewerken" titleEN="Edit" @click="edit(sp)" />
                <iconbutton
                  icon="trash-alt"
                  titleNL="Verwijderen"
                  titleEN="Delete"
                  @click="del(sp)"
                  v-if="sp.Count == 0"
                />
              </td>
            </tr>
          </tbody>
        </table>
        <button class="uu-button button-black" @click="nw()">Nieuwe opleiding</button>
      </div>
    </div>

    <popup
      ref="popupEdit"
      @okclick="beforeSave()"
      titleNL="Opleiding"
      titleEN="Study programme"
      size="lg"
    >
      <form slot-scope="popup" >
        <div v-if="popup.data != null">
          <div class="form-group">
            <div class="row">
              <div class="col-md-6">
                <label for="namenl">Naam NL</label>
              </div>
              <div class="col-md-6">
                <label for="addnl">Toevoeging NL</label>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <input
                  v-model="popup.data.Name.NL"
                  class="form-control"
                  name="namenl"
                  id="namenl"
                  :maxlength="popup.data.Name.MaxLength"
                />
              </div>
              <div class="col-md-6">
                <input
                  v-model="popup.data.Addition.NL"
                  class="form-control"
                  name="addnl"
                  id="addnl"
                  :maxlength="popup.data.Addition.MaxLength"
                />
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <div class="col-md-6">
                <label for="nameen">Naam EN</label>
              </div>
              <div class="col-md-6">
                <label for="adden">Toevoeging EN</label>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <input
                  v-model="popup.data.Name.EN"
                  class="form-control"
                  name="nameen"
                  id="nameen"
                  :maxlength="popup.data.Name.MaxLength"
                />
              </div>
              <div class="col-md-6">
                <input
                  v-model="popup.data.Addition.EN"
                  class="form-control"
                  name="adden"
                  id="adden"
                  :maxlength="popup.data.Addition.MaxLength"
                />
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="urlnl">Url NL</label>
            <input
              v-model="popup.data.Url.NL"
              class="form-control"
              name="urlnl"
              id="urlnl"
              :maxlength="popup.data.Url.MaxLength"
            />
          </div>
          <div class="form-group">
            <label for="urlen">Url EN</label>
            <input
              v-model="popup.data.Url.EN"
              class="form-control"
              name="urlen"
              id="urlen"
              :maxlength="popup.data.Url.MaxLength"
            />
          </div>
          <div class="form-group">
            <label for="typesp">Type opleiding</label>
            <select v-model="popup.data.Type" class="form-control" name="typesp" id="typesp">
              <option value="B">Bachelor</option>
              <option value="M">Master</option>
              <option value="R">Research Master</option>
            </select>
          </div>
          <div class="form-group">
            <label for="faculteiten">Faculteiten</label>
            <div class="two-columns">
              <ul class="list-unstyled">
                <li v-for="f in popup.data.Faculties" :key="f.Guid">
                  <input type="checkbox" :id="'FA'+f.Id" :value="f.Id" v-model="f.Checked" />
                  <label :for="'FA'+f.Id">{{f.Faculty}}</label>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </form>
    </popup>
    <confirm ref="confirmdialog"></confirm>
  </admin-layout>
</template>

<script>
import popup from "@/components/layout/popup.vue";
import iconbutton from "@/components/layout/iconbutton.vue";
import AdminLayout from "@/components/layout/admin-layout.vue";
import confirmdialog from "@/components/layout/confirm.vue";

export default {
  name: "BeheerStudyProgrammes",
  components: {
    popup: popup,
    iconbutton: iconbutton,
    "admin-layout": AdminLayout,
    confirm: confirmdialog
  },
  data() {
    return {
      studyprogrammes: [],
      facultyId: null,
      faculties: []
      // facultiespopup: [],
    };
  },
  methods: {
    loaddata: function() {
      if (this.facultyId != null) {
        this.$Progress.start();
        this.$http
          .get("Admin/GetStudyProgrammes?faculty=" + this.facultyId.toString())
          .then(
            function(response) {
              this.studyprogrammes = response.data;
              this.$Progress.finish();
            },
            function() {
              // Fail
              this.$Progress.finish();
            }
          );
      }
    },
    edit: function(ent) {
      this.$refs.popupEdit.show(ent, this.studyprogrammes, this);
    },
    beforeSave: function() {
      var data = this.$refs.popupEdit.getData();

      this.$http
        .post("Admin/SaveStudyProgramme", data)
        .then(function(response) {
          if (!response.data.Success) {
            alert("Opslaan mislukt. " + response.data.Message);
          }
          this.loaddata();
        });
    },
    del: function(ent) {
      var self = this;
      this.$refs.confirmdialog.confirm(
        "Weet u zeker dat u deze opleding wilt verwijderen?",
        "Deleting Study Programme. Are you sure?",
        function() {
          this.$http
            .post("Admin/DeleteStudyProgramme", ent)
            .then(function(response) {
              if (!response.data.Success) {
                alert(response.data.Message);
              } else {
                self.loaddata();
              }
            });
        }
      );
    },
    nw: function() {
      this.$http
        .get("Admin/NewStudyProgramme?faculty=" + this.facultyId.toString())
        .then(function(response) {
          this.$refs.popupEdit.show(response.data, this.studyprogrammes, this);
        });
    }
  },
  created: function() {
    this.$http.get("Admin/GetAdminFaculties").then(function(response) {
      this.faculties = response.data;
      this.facultyId = response.data[0].FacultyId;
    });
    // this.$http.get('Public/GetFaculties?l='+this.$root.lng).then(function(response) {
    // this.facultiespopup = response.data;
    // });
  },
  watch: {
    facultyId: function() {
      this.loaddata();
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.iconcolumn {
  width: 30px;
}
</style>

<template>
  <admin-layout title="Osiris-bestand importeren">
    <div>
      Selecteer het bestand dat vanuit Osiris geëxporteerd is. Het bestand wordt geüpload
      en direct verwerkt.
    </div>
    <div class="let-op">
      Let op: door de timeout op de gateway is het mogelijk dat er tijdens het verwerken
      na 30 of 60 seconde een foutmelding verschijnt. Op de achtergrond draait de import
      wel door. Na een minuut of 5 (afhankelijk van de grootte van het bestand) is de data
      verwerkt en verschijnen de nieuwe cursussen.
    </div>
    <input
      id="inpOsirisFile"
      type="file"
      style="display: none"
      v-on:change="onOsirisUpload($event)"
      accept=".csv"
    />
    <div>
      <button
        v-if="!bezig"
        class="uu-button button-black"
        onclick="document.getElementById('inpOsirisFile').click()"
      >
        Selecteer en importeer bestand
      </button>
      <br />
      <label v-if="bezig">Bezig met verwerken...</label>
      <label v-if="bestand && bestand != ''">{{ bestand }} is verwerkt.</label>
    </div>
  </admin-layout>
</template>

<script>
import AdminLayout from "@/components/layout/admin-layout.vue";

export default {
  name: "BeheerImportOsiris",
  components: {
    "admin-layout": AdminLayout,
  },
  data() {
    return {
      bestand: null,
      bezig: false,
    };
  },
  methods: {
    onOsirisUpload: function (event) {
      this.bezig = true;
      this.bestand = null;
      var files = event.target.files || event.dataTransfer.files;
      if (!files.length) return;
      this.SaveOsiris(files[0]);
    },
    SaveOsiris: function (f) {
      var reader = new FileReader();
      var self = this;

      reader.addEventListener(
        "load",
        function () {
          self.$Progress.start();
          var contents = JSON.stringify(reader.result);

          self.$http
            .post("Admin/ImportOsiris", { Filename: f.name, Content: contents })
            .then(
              function (response) {
                self.$Progress.finish();
                self.bezig = false;
                self.bestand = f.name;
                if (response.data.Success === false) {
                  self.bestand = null;
                  alert(response.data.Message);
                }
              },
              function () {
                self.$Progress.finish();
                self.bezig = false;
                alert("Importeren mislukt.");
                // self.errmsg = response.data;
              }
            );
        },
        false
      );

      reader.readAsDataURL(f);
    },
  },
  created: function () {},
  watch: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.let-op {
  border: 1px solid silver;
  padding: 10px;
  font-size: 9pt;
  line-height: 1.3;
  width: 50%;
}
</style>

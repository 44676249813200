<template>
  <div v-if="medewerker" class="TabEditCourses">
    <!-- Vrije tekst -->
    <edit-layout
      nameNL="Vrije tekst onderwijs"
      nameEN="Additional information on educational activities"
      :info="GetInfo(info, 'Onderwijs.TekstNL')"
    >
      <edit-textfield :value="medewerker.CoursesFreeText" multilng="true" rtf="true" name="curs"></edit-textfield>
    </edit-layout>

    <!-- Opleidingen -->
    <edit-layout
      nameNL="Betrokken bij de opleiding(en)"
      nameEN="Involved in the following Study Programme(s)"
      :info="GetInfo(info, 'Profiel.Opleiding')"
      v-if="medewerker.StudyProgrammes"
    >
      <tab-edit-profile-studyprogrammes
        :medewerker="medewerker"
        :faculties="faculties"
        :info="info"
      ></tab-edit-profile-studyprogrammes>
    </edit-layout>

    <!-- Geimporteerde cursussen -->
    <edit-layout
      nameNL="Onderwijs"
      nameEN="Courses"
      :info="GetInfo(info, 'Onderwijs.Algemeen')"
      v-if="medewerker.Courses && medewerker.Courses.length > 0"
    >
      <span
        v-show="$root.lng=='NL'"
      >Op dit moment verzorgt {{medewerker.Name}} de volgende cursus(sen):</span>
      <span v-show="$root.lng=='EN'">Currently {{medewerker.Name}} teaches the following course(s):</span>
      <div class="row">
        <div class="col-md-12">
          <table class="table table-condense">
            <thead>
              <tr>
                <th>
                  <label-lng NL="Code" EN="Code" />
                </th>
                <th>
                  <label-lng NL="Beschrijving" EN="Description" />
                </th>
                <th>
                  <label-lng NL="V/D" EN="F/P" />
                </th>
                <th>
                  <label-lng NL="Niveau" EN="Level" />
                </th>
                <th>
                  <label-lng NL="ECTS" EN="ECTS" />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="c in medewerker.Courses" :key="c.Guid">
                <td>{{c.Code}}</td>
                <td>{{c.Name}}</td>
                <td>{{c.Type}}</td>
                <td>{{c.Level}}</td>
                <td>{{c.ECTS}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </edit-layout>
  </div>
</template>

<script>
import labellng from "@/components/layout/label-lng.vue";
import edittextfield from "@/components/layout/edit-textfield.vue";
import infohelper from "@/helpers/info-helper.vue";
import editlayout from "@/components/layout/edit-layout.vue";
import profilestudyprogrammes from "@/components/edit/tab-edit-profile-studyprogrammes.vue";

export default {
  name: "tab-edit-courses",
  props: ["medewerker", "faculties", "info"],
  data() {
    return {};
  },
  components: {
    "label-lng": labellng,
    "edit-textfield": edittextfield,
    "edit-layout": editlayout,
    "tab-edit-profile-studyprogrammes": profilestudyprogrammes
  },
  methods: {},
  created: function() {},
  mixins: [infohelper]
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

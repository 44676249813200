<template>
  <div v-if="medewerker" class="TabEditStatistics">
    <edit-layout nameNL="Uw profiel" nameEN="Your profile" info>
      <div style="float: left;">
        <div style="width: 200px; height: 30px; border: 1px solid black;">
          <div
            v-bind:style="{ 'background-color': GetColor(medewerker.StatsPct), 'height': '100%', 'width': medewerker.StatsPct + '%' }"
          ></div>
          <div style="float: left; margin-left: 5px;">
            {{medewerker.StatsPct}}
            <label-lng NL="% compleet" EN="% Complete" />
            <br />
          </div>
        </div>
      </div>
    </edit-layout>

    <edit-layout nameNL="Tips om score te verbeteren" nameEN="To improve your score: " info>
      <label-lng NL="Vul de volgende velden in" EN="Complete the following fields:" />:
      <br />
      <span v-html="'<ul>' + medewerker.StatsTips + '</ul>'" />
    </edit-layout>

    <edit-layout nameNL="Pageviews" nameEN="Pageviews" info v-if="medewerker.StatsPageviews">
      <div class="row">
        <div class="col-md-4">
          <table class="table table-sm table-bordered">
            <thead>
              <tr>
                <th>
                  <label-lng NL="Maand" EN="Month"></label-lng>
                </th>
                <th>
                  <label-lng NL="Jaar" EN="Year"></label-lng>
                </th>
                <th>
                  <label-lng NL="Views" EN="Views"></label-lng>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="spv in medewerker.StatsPageviews" :key="spv.Guid">
                <td>{{ GetMonth(spv.Month) }}</td>
                <td>{{ GetYear(spv.Month) }}</td>
                <td>{{ spv.Pageviews }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-md-8">
          <vue-chart type="bar" :data="chartData"></vue-chart>
        </div>
      </div>
    </edit-layout>

    <edit-layout
      nameNL="Top 10 meeste pageviews binnen departement"
      nameEN="Top 10 most pageviews within department"
      info
      v-if="medewerker.StatsDepartments"
    >
      <div class="row">
        <div class="col-md-8">
          <label>
            <label-lng NL="Departement" EN="Department"></label-lng>
          </label>
          <select v-model="departmentid" class="form-control" id="statsdepartment">
            <option
              v-for="d in medewerker.StatsDepartments"
              :value="d.Id"
              :key="d.Guid"
            >{{ d.Name }}</option>
          </select>
        </div>
        <div class="col-md-4"></div>
      </div>
      <div class="row" v-if="statsdepartment && (statsdepartment.length > 0)">
        <div class="col-md-4">
          <table class="table table-sm table-bordered">
            <thead>
              <tr>
                <th>
                  <label-lng NL="Positie" EN="Rank"></label-lng>
                </th>
                <th>
                  <label-lng NL="Medewerker" EN="Employee"></label-lng>
                </th>
                <th>
                  <label-lng NL="Views" EN="Views"></label-lng>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="s in statsdepartment" :key="s.Guid">
                <td>{{ s.Ranking }}</td>
                <td>{{ s.Name }}</td>
                <td>{{ s.Views }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-md-8"></div>
      </div>
    </edit-layout>

    <edit-layout nameNL="Wat zijn pageviews?" nameEN="What are page views?" info>
      <div v-show="$root.lng=='NL'">
        <em>Pageviews</em> is het aantal keer dat de pagina is bekeken. Wanneer een bezoeker de pagina meerdere keren opent, telt dit als meerdere pageviews. Ook bezoeken van bijvoorbeeld zoekmachines en webrobots tellen mee. Wanneer een bezoeker meerdere tabbladen op de profielpagina aanklikt, telt dit als één pageview.
      </div>
      <div v-show="$root.lng=='EN'">
        <em>Page views</em> indicates how many times the page has been viewed. Repeated views of a single page count as separate page views. Visits by search engines and web robots also count as page views. A visitor clicking several tabs on a profile page counts as one page view.
      </div>
    </edit-layout>
  </div>
</template>

<script>
import labellng from "@/components/layout/label-lng.vue";
import editlayout from "@/components/layout/edit-layout.vue";
import infohelper from "@/helpers/info-helper.vue";

export default {
  name: "tab-edit-statistics",
  props: ["medewerker"],
  data() {
    return {
      departmentid: null,
      statsdepartment: [],
      chartData: {
        labels: this.GetChartLabels(),
        datasets: this.GetChartDatasets()
      }
    };
  },
  components: {
    "label-lng": labellng,
    "edit-layout": editlayout
  },
  methods: {
    GetColor: function(score) {
      if (score <= 33) return "#C00A35";
      if (score > 33 && score <= 66) return "#F08000";
      if (score > 66) return "#FFCD00";
    },
    GetChartLabels: function() {
      var result = [];

      for (var i = 0; i < this.medewerker.StatsPageviews.length; i++) {
        result.push(this.medewerker.StatsPageviews[i].Month);
      }

      return result;
    },
    GetChartDatasets: function() {
      var result = [];
      var resData = [];

      for (var i = 0; i < this.medewerker.StatsPageviews.length; i++) {
        resData.push(this.medewerker.StatsPageviews[i].Pageviews);
      }

      result.push({
        label: "Pageviews",
        backgroundColor: "#f87979",
        data: resData
      });

      return result;
    },
    GetMonth: function(txt) {
      var pieces = txt.split(" - ");
      return pieces[0];
    },
    GetYear: function(txt) {
      var pieces = txt.split(" - ");
      return pieces[1];
    },
    loadstatsdepartments: function(department) {
      this.$http
        .get("Edit/GetStatsPerDepartment?depid=" + department.toString())
        .then(function(response) {
          this.statsdepartment = response.data;
        });
    }
  },
  watch: {
    departmentid: function(newdepartment) {
      this.loadstatsdepartments(newdepartment);
    }
  },
  created: function() {
    if (
      this.medewerker.StatsDepartments &&
      this.medewerker.StatsDepartments.length > 0
    ) {
      this.departmentid = this.medewerker.StatsDepartments[0].Id;
    }
  },
  mixins: [infohelper]
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

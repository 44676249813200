<!--
  Layout van veld op bewerk-scherm
-->
<template>
  <div class="editlayoutpopup">
    <div class="row">
      <div class="col-md-12 caption">
        <span v-if="$root.lng=='NL'">{{nameNL}}</span>
        <span v-if="$root.lng=='EN'">{{nameEN}}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 fieldcontent">
        <slot></slot>
      </div>
    </div>
    <div class="row" v-if="info != ''">
      <div class="col-md-12 toelichtingbar">
        <edit-info>{{info}}</edit-info>
      </div>
    </div>
  </div>
</template>

<script>
import Editinfo from "@/components/layout/edit-info.vue";

export default {
  name: "edit-layout-popup",
  props: ["nameNL", "nameEN", "info"],
  components: {
    "edit-info": Editinfo
  },
  data() {
    return {};
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.caption {
  font-weight: bold;
}

.editlayoutpopup {
  margin-bottom: 10px;
  margin-left: 20px;
  margin-right: 20px;
}

/*
.editlayout {

}
*/

input[type="text"] {
  width: 100%;
}

/*
.fieldcontent {
}
*/

.toelichtingbar {
  background-color: #d7d7d7;
  line-height: 1.5;
  margin-top: 5px;
  padding-left: 20px;
}
</style>

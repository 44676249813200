<template>
  <div v-if="medewerker" class="TabAdditionalFunctions public-tab-content">
    <div v-html="medewerker.AdditionalFunctions" class="rtffield"></div>
  </div>
</template>

<script>

export default {
  name: 'tab-additionalfunctions',
  data () {
    return {
    }
  },
  props: ['medewerker', 'lng'],
  components: {
  }
}
</script>

<style scoped>
</style>

<template>
  <div v-if="medewerker" class="TabEditCustom">
    <edit-layout
      nameNL="Vrije pagina 1"
      nameEN="Custom page 1"
      :info="GetInfo(info, 'CustomTab.General')"
    >
      <input
        type="checkbox"
        id="ct1"
        v-model="medewerker.CustomTab1.Enabled"
      />&nbsp;<label for="ct1">
        <label-lng NL="Vrije pagina 1 inschakelen" EN="Enable custom page 1" />
      </label>
      <div v-show="medewerker.CustomTab1.Enabled">
        <label-lng NL="Titel" EN="Title" class="sub-header"></label-lng>
        <edit-textfield
          :value="medewerker.CustomTab1.Title"
          multilng="true"
          name="cuta1"
        ></edit-textfield>
        <label-lng NL="Inhoud" EN="Content" class="sub-header"></label-lng>
        <edit-textfield
          :value="medewerker.CustomTab1.Content"
          multilng="true"
          rtf="true"
          name="fr1"
        ></edit-textfield>
      </div>
    </edit-layout>

    <edit-layout nameNL="Vrije pagina 2" nameEN="Custom page 2">
      <input
        type="checkbox"
        id="ct2"
        v-model="medewerker.CustomTab2.Enabled"
      />&nbsp;<label for="ct2">
        <label-lng NL="Vrije pagina 2 inschakelen" EN="Enable custom page 2" />
      </label>
      <div v-show="medewerker.CustomTab2.Enabled">
        <label-lng NL="Titel" EN="Title" class="sub-header"></label-lng>
        <edit-textfield
          :value="medewerker.CustomTab2.Title"
          multilng="true"
          name="cuta2"
        ></edit-textfield>
        <label-lng NL="Inhoud" EN="Content" class="sub-header"></label-lng>
        <edit-textfield
          :value="medewerker.CustomTab2.Content"
          multilng="true"
          rtf="true"
          name="fr2"
        ></edit-textfield>
      </div>
    </edit-layout>

    <edit-layout nameNL="Vrije pagina 3" nameEN="Custom page 3">
      <input
        type="checkbox"
        id="ct3"
        v-model="medewerker.CustomTab3.Enabled"
      />&nbsp;<label for="ct3">
        <label-lng NL="Vrije pagina 3 inschakelen" EN="Enable custom page 3" />
      </label>
      <div v-show="medewerker.CustomTab3.Enabled">
        <label-lng NL="Titel" EN="Title" class="sub-header"></label-lng>
        <edit-textfield
          :value="medewerker.CustomTab3.Title"
          multilng="true"
          name="cuta3"
        ></edit-textfield>
        <label-lng NL="Inhoud" EN="Content" class="sub-header"></label-lng>
        <edit-textfield
          :value="medewerker.CustomTab3.Content"
          multilng="true"
          rtf="true"
          name="fr3"
        ></edit-textfield>
      </div>
    </edit-layout>
  </div>
</template>

<script>
import labellng from "@/components/layout/label-lng.vue";
import edittextfield from "@/components/layout/edit-textfield.vue";
import editlayout from "@/components/layout/edit-layout.vue";
import infohelper from "@/helpers/info-helper.vue";

export default {
  name: "tab-edit-custom",
  props: ["medewerker", "info"],
  data() {
    return {};
  },
  components: {
    "label-lng": labellng,
    "edit-textfield": edittextfield,
    "edit-layout": editlayout,
  },
  methods: {},
  created: function () {},
  mixins: [infohelper],
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.sub-header {
  font-weight: 700;
}
</style>

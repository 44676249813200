<template>
  <admin-layout title="Beheer organisatiestructuur">
    <div class="BeheerOM">
      <div class="row">
        <div class="col-md-1">Faculteit</div>
        <div class="col-md-5">
          <select v-model="selectedFaculty">
            <option v-for="f in faculties" :value="f" :key="f.Guid">{{f.Name.NL}}</option>
          </select>
        </div>
        <div class="col-md-6"></div>
      </div>

      <div v-if="selectedFaculty">
        <h2>Eigenschappen van faculteit</h2>
        <div class="row">
          <div class="col-md-1"></div>
          <div class="col-md-5">NL</div>
          <div class="col-md-5">EN</div>
          <div class="col-md-1"></div>
        </div>

        <div class="row">
          <div class="col-md-1">Naam</div>
          <div class="col-md-5">
            <input v-model="selectedFaculty.Name.NL" :maxlength="selectedFaculty.Name.MaxLength" />
          </div>
          <div class="col-md-5">
            <input v-model="selectedFaculty.Name.EN" :maxlength="selectedFaculty.Name.MaxLength" />
          </div>
          <div class="col-md-1"></div>
        </div>

        <div class="row">
          <div class="col-md-1">Afkorting</div>
          <div class="col-md-5">
            <input v-model="selectedFaculty.Afko.NL" :maxlength="selectedFaculty.Afko.MaxLength" />
          </div>
          <div class="col-md-5">
            <input v-model="selectedFaculty.Afko.EN" :maxlength="selectedFaculty.Afko.MaxLength" />
          </div>
          <div class="col-md-1"></div>
        </div>

        <div class="row">
          <div class="col-md-1">PP Url</div>
          <div class="col-md-5">
            <input v-model="selectedFaculty.Url.NL" :maxlength="selectedFaculty.Url.MaxLength" />
          </div>
          <div class="col-md-5">
            <input v-model="selectedFaculty.Url.EN" :maxlength="selectedFaculty.Url.MaxLength" />
          </div>
          <div class="col-md-1"></div>
        </div>

        <div class="row">
          <div class="col-md-1">Homepage</div>
          <div class="col-md-5">
            <input
              v-model="selectedFaculty.Homepage.NL"
              :maxlength="selectedFaculty.Homepage.MaxLength"
            />
          </div>
          <div class="col-md-5">
            <input
              v-model="selectedFaculty.Homepage.EN"
              :maxlength="selectedFaculty.Homepage.MaxLength"
            />
          </div>
          <div class="col-md-1"></div>
        </div>

        <div class="row">
          <div class="col-md-1">OU Id</div>
          <div class="col-md-5">
            <input v-model="selectedFaculty.OMId" maxlength="15" />
          </div>
          <div class="col-md-5"></div>
          <div class="col-md-1"></div>
        </div>

        <div class="row">
          <div class="col-md-1"></div>
          <div class="col-md-5">
            <button class="uu-button button-black" @click="saveFaculty();">Opslaan</button>
          </div>
          <div class="col-md-6"></div>
        </div>

        <h2 v-if="selectedFaculty">Organisatieonderdelen van {{selectedFaculty.Name.NL}}</h2>
        <span
          class="o-info"
        >Klik op een organisatieonderdeel om de onderliggende afdelingen te zien.</span>
        <div v-if="selectedFaculty && departments && departments.length > 0">
          <table class="table table-sm table-hover">
            <thead>
              <tr>
                <th>OM ID</th>
                <th>Naam</th>
                <th>Id</th>
                <th>#Med</th>
                <th>Acties</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="d in departments"
                :key="d.Guid"
                @click="selectedDepartment = d"
                v-bind:class="{ selectedrow: d == selectedDepartment }"
              >
                <td>{{d.OMId}}</td>
                <td>{{d.Name.NL}}</td>
                <td>{{d.Id}}</td>
                <td>{{d.Count}}</td>
                <td>
                  <iconbutton
                    icon="pencil-alt"
                    titleNL="Bewerk organisatieonderdeel"
                    titleEN="Edit department"
                    @click="editDepartment(d)"
                  />
                  <iconbutton
                    icon="trash"
                    titleNL="Verwijder organisatieonderdeel"
                    titleEN="Delete department"
                    @click="deleteDepartment(d)"
                  />
                  <iconbutton
                    icon="user"
                    titleNL="Overzicht medewerkers van dit onderdeel"
                    titleEN="Overview employees"
                    @click="employeesDepartment(d)"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="selectedFaculty">
          <button
            class="uu-button button-black"
            @click="newDepartment();"
          >Nieuw organisatieonderdeel</button>
        </div>

        <h2 v-if="selectedDepartment">Afdelingen van {{selectedDepartment.Name.NL}}</h2>
        <span
          class="o-info"
          v-if="selectedDepartment"
        >Klik op een afdeling om de onderliggende groepen te zien.</span>
        <div v-if="selectedDepartment && sections && sections.length > 0">
          <table class="table table-sm table-hover">
            <thead>
              <tr>
                <th>OM ID</th>
                <th>Naam</th>
                <th>Id</th>
                <th>#Med</th>
                <th>Acties</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="s in sections"
                :key="s.Guid"
                @click="selectedSection = s"
                v-bind:class="{ selectedrow: s == selectedSection }"
              >
                <td>{{s.OMId}}</td>
                <td>{{s.Name.NL}}</td>
                <td>{{s.Id}}</td>
                <td>{{s.Count}}</td>
                <td>
                  <iconbutton
                    icon="pencil-alt"
                    titleNL="Bewerk afdeling"
                    titleEN="Edit section"
                    @click="editSection(s)"
                  />
                  <iconbutton
                    icon="trash"
                    titleNL="Verwijder afdeling"
                    titleEN="Delete section"
                    @click="deleteSection(s)"
                  />
                  <iconbutton
                    icon="user"
                    titleNL="Overzicht medewerkers van dit onderdeel"
                    titleEN="Overview employees"
                    @click="employeesSection(s)"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="selectedDepartment">
          <button class="uu-button button-black" @click="newSection();">Nieuwe afdeling</button>
        </div>

        <h2 v-if="selectedSection">Groepen van {{selectedSection.Name.NL}}</h2>
        <div v-if="selectedSection && groups && groups.length > 0">
          <table class="table table-sm table-hover">
            <thead>
              <tr>
                <th>OM ID</th>
                <th>Naam</th>
                <th>Id</th>
                <th>#Med</th>
                <th>Acties</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="g in groups"
                :key="g.Guid"
                @click="selectedGroup = g"
                v-bind:class="{ selectedrow: g == selectedGroup }"
              >
                <td>{{g.OMId}}</td>
                <td>{{g.Name.NL}}</td>
                <td>{{g.Id}}</td>
                <td>{{g.Count}}</td>
                <td>
                  <iconbutton
                    icon="pencil-alt"
                    titleNL="Bewerk groep"
                    titleEN="Edit group"
                    @click="editGroup(g)"
                  />
                  <iconbutton
                    icon="trash"
                    titleNL="Verwijder groep"
                    titleEN="Delete group"
                    @click="deleteGroup(g)"
                  />
                  <iconbutton
                    icon="user"
                    titleNL="Overzicht medewerkers van dit onderdeel"
                    titleEN="Overview employees"
                    @click="employeesGroup(g)"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="selectedSection">
          <button class="uu-button button-black" @click="newGroup();">Nieuwe groep</button>
        </div>
      </div>

      <!-- Department -->
      <popup
        ref="popupDepartment"
        @okclick="saveDepartment()"
        titleNL="Organisatieonderdeel"
        titleEN="Department"
      >
        <form slot-scope="popup">
          <div v-if="popup.data != null">
            <div class="form-group">
              <label for="namenl">Naam NL</label>
              <input
                v-model="popup.data.Name.NL"
                class="form-control"
                name="NameNL"
                id="namenl"
                :maxlength="popup.data.Name.MaxLength"
              />
            </div>
            <div class="form-group">
              <label for="nameen">Naam EN</label>
              <input
                v-model="popup.data.Name.EN"
                class="form-control"
                name="NameEN"
                id="nameen"
                :maxlength="popup.data.Name.MaxLength"
              />
            </div>
            <div class="form-group">
              <label for="code">Afkorting</label>
              <input
                v-model="popup.data.Afko.NL"
                class="form-control"
                name="CodeNL"
                id="codenl"
                :maxlength="popup.data.Afko.MaxLength"
              />
            </div>
            <div class="form-group">
              <label for="urlnl">Homepage NL</label>
              <input
                v-model="popup.data.Homepage.NL"
                class="form-control"
                name="UrlNL"
                id="urlnl"
                :maxlength="popup.data.Homepage.MaxLength"
              />
            </div>
            <div class="form-group">
              <label for="urlen">Homepage EN</label>
              <input
                v-model="popup.data.Homepage.EN"
                class="form-control"
                name="UrlEN"
                id="urln"
                :maxlength="popup.data.Homepage.MaxLength"
              />
            </div>
            <div class="form-group">
              <label for="omid">OM-ID</label>
              <input
                v-model="popup.data.OMId"
                class="form-control"
                name="OMId"
                id="omid"
                maxlength="15"
              />
            </div>
            <div class="form-group" v-if="popup.data.Id">
              <label for="targetomid">Verplaats alle medewerkers naar OM-ID:</label>

              <div class="input-group">
                <input
                  v-model="popup.data.Target"
                  type="text"
                  class="form-control"
                  name="TargetOMId"
                  id="targetomid"
                  maxlength="15"
                />
                <span class="input-group-btn">
                  <button
                    type="button"
                    class="btn"
                    @click="verplaatsMedewerkers(2, popup.data.Id, popup.data.Target);"
                  >Verplaats medewerkers</button>
                </span>
              </div>
            </div>
          </div>
        </form>
      </popup>

      <!-- Afdeling-->
      <popup
        ref="popupSection"
        @okclick="saveSection()"
        titleNL="Afdeling"
        titleEN="Section"
      >
        <form slot-scope="popup">
          <div v-if="popup.data != null">
            <div class="form-group">
              <label for="namenl">Naam NL</label>
              <input
                v-model="popup.data.Name.NL"
                class="form-control"
                name="NameNL"
                id="namenl"
                :maxlength="popup.data.Name.MaxLength"
              />
            </div>
            <div class="form-group">
              <label for="nameen">Naam EN</label>
              <input
                v-model="popup.data.Name.EN"
                class="form-control"
                name="NameEN"
                id="nameen"
                :maxlength="popup.data.Name.MaxLength"
              />
            </div>
            <div class="form-group">
              <label for="code">Afkorting</label>
              <input
                v-model="popup.data.Afko.NL"
                class="form-control"
                name="CodeNL"
                id="codenl"
                :maxlength="popup.data.Afko.MaxLength"
              />
            </div>
            <div class="form-group">
              <label for="urlnl">Homepage NL</label>
              <input
                v-model="popup.data.Homepage.NL"
                class="form-control"
                name="UrlNL"
                id="urlnl"
                :maxlength="popup.data.Homepage.MaxLength"
              />
            </div>
            <div class="form-group">
              <label for="urlen">Homepage EN</label>
              <input
                v-model="popup.data.Homepage.EN"
                class="form-control"
                name="UrlEN"
                id="urln"
                :maxlength="popup.data.Homepage.MaxLength"
              />
            </div>
            <div class="form-group">
              <label for="omid">OM-ID</label>
              <input
                v-model="popup.data.OMId"
                class="form-control"
                name="OMId"
                id="omid"
                maxlength="15"
              />
            </div>
            <div class="form-group" v-if="popup.data.Id">
              <label for="targetomid">Verplaats alle medewerkers naar OM-ID:</label>

              <div class="input-group">
                <input
                  v-model="popup.data.Target"
                  type="text"
                  class="form-control"
                  name="TargetOMId"
                  id="targetomid"
                  maxlength="15"
                />
                <span class="input-group-btn">
                  <button
                    type="button"
                    class="btn"
                    @click="verplaatsMedewerkers(3, popup.data.Id, popup.data.Target);"
                  >Verplaats medewerkers</button>
                </span>
              </div>
            </div>
          </div>
        </form>
      </popup>

      <!-- Groep-->
      <popup
        ref="popupGroup"
        @okclick="saveGroup()"
        titleNL="Groep"
        titleEN="Group"
      >
        <form slot-scope="popup">
          <div v-if="popup.data != null">
            <div class="form-group">
              <label for="namenl">Naam NL</label>
              <input
                v-model="popup.data.Name.NL"
                class="form-control"
                name="NameNL"
                id="namenl"
                :maxlength="popup.data.Name.MaxLength"
              />
            </div>
            <div class="form-group">
              <label for="nameen">Naam EN</label>
              <input
                v-model="popup.data.Name.EN"
                class="form-control"
                name="NameEN"
                id="nameen"
                :maxlength="popup.data.Name.MaxLength"
              />
            </div>
            <div class="form-group">
              <label for="code">Afkorting</label>
              <input
                v-model="popup.data.Afko.NL"
                class="form-control"
                name="CodeNL"
                id="codenl"
                :maxlength="popup.data.Afko.MaxLength"
              />
            </div>
            <div class="form-group">
              <label for="urlnl">Homepage NL</label>
              <input
                v-model="popup.data.Homepage.NL"
                class="form-control"
                name="UrlNL"
                id="urlnl"
                :maxlength="popup.data.Homepage.MaxLength"
              />
            </div>
            <div class="form-group">
              <label for="urlen">Homepage EN</label>
              <input
                v-model="popup.data.Homepage.EN"
                class="form-control"
                name="UrlEN"
                id="urln"
                :maxlength="popup.data.Homepage.MaxLength"
              />
            </div>
            <div class="form-group">
              <label for="omid">OM-ID</label>
              <input
                v-model="popup.data.OMId"
                class="form-control"
                name="OMId"
                id="omid"
                maxlength="15"
              />
            </div>
            <div class="form-group" v-if="popup.data.Id">
              <label for="targetomid">Verplaats alle medewerkers naar OM-ID:</label>

              <div class="input-group">
                <input
                  v-model="popup.data.Target"
                  type="text"
                  class="form-control"
                  name="TargetOMId"
                  id="targetomid"
                  maxlength="15"
                />
                <span class="input-group-btn">
                  <button
                    type="button"
                    class="btn"
                    @click="verplaatsMedewerkers(4, popup.data.Id, popup.data.Target);"
                  >Verplaats medewerkers</button>
                </span>
              </div>
            </div>
          </div>
        </form>
      </popup>
    </div>
    <confirm ref="confirmdialog"></confirm>

    <popup
      ref="popupOverview"
      titleNL="Overzicht"
      titleEN="Overview"
      :okonly="true"
    >
      <form>
        <div class="overview-title">{{overviewtitle}}</div>
        <div class="overview">
          <div v-for="o in overview" :key="o.Guid">
            <a :href="o.Url" target="_blank">{{o.Name}}</a>
            ({{o.Status}})
          </div>
        </div>
      </form>
    </popup>
  </admin-layout>
</template>

<script>
import AdminLayout from "@/components/layout/admin-layout.vue";
import iconbutton from "@/components/layout/iconbutton.vue";
import popup from "@/components/layout/popup.vue";
import confirmdialog from "@/components/layout/confirm.vue";

export default {
  name: "BeheerOM",
  components: {
    "admin-layout": AdminLayout,
    iconbutton: iconbutton,
    popup: popup,
    confirm: confirmdialog
  },
  data() {
    return {
      selectedFaculty: null,
      faculties: [],
      selectedDepartment: null,
      departments: [],
      selectedSection: null,
      sections: [],
      selectedGroup: null,
      groups: [],
      overview: [],
      overviewtitle: ""
    };
  },
  methods: {
    loaddata: function() {
      this.$http.get("Admin/GetOMItems?level=1").then(function(response) {
        this.faculties = response.data;
        if (this.faculties.length > 0) {
          this.selectedFaculty = this.faculties[0];
        }
      });
    },
    loadDepartments: function() {
      if (this.selectedFaculty) {
        this.$http
          .get(
            "Admin/GetOMItems?level=2&parent=" +
              this.selectedFaculty.Id.toString()
          )
          .then(function(response) {
            this.departments = response.data;
            this.selectedDepartment = null;
            this.selectedSection = null;
            this.selectedGroup = null;
          });
      } else {
        this.departments = null;
      }
    },
    loadSections: function() {
      if (this.selectedDepartment) {
        this.$http
          .get(
            "Admin/GetOMItems?level=3&parent=" +
              this.selectedDepartment.Id.toString()
          )
          .then(function(response) {
            this.sections = response.data;
            this.selectedSection = null;
            this.selectedGroup = null;
          });
      } else {
        this.sections = null;
      }
    },
    loadGroups: function() {
      if (this.selectedSection) {
        this.$http
          .get(
            "Admin/GetOMItems?level=4&parent=" +
              this.selectedSection.Id.toString()
          )
          .then(function(response) {
            this.groups = response.data;
            this.selectedGroup = null;
          });
      } else {
        this.groups = null;
      }
    },
    saveFaculty: function() {
      if (this.selectedFaculty) {
        this.$Progress.start();
        this.$http.post("Admin/SaveOMItem", this.selectedFaculty).then(
          function(response) {
            this.$Progress.finish();
            if (!response.data.Success) alert("Opslaan faculteit mislukt.");
          },
          function() {
            this.$Progress.finish();
          }
        );
      }
    },
    // Organisatieonderdeel
    editDepartment: function(d) {
      this.$refs.popupDepartment.show(d, this.departments, this);
    },
    saveDepartment: function() {
      this.$Progress.start();
      var data = this.$refs.popupDepartment.getData();
      this.$http.post("Admin/SaveOMItem", data).then(
        function() {
          this.$Progress.finish();
          this.loadDepartments();
        },
        function() {
          this.$Progress.finish();
        }
      );
    },
    deleteDepartment: function(d) {
      var self = this;
      this.$refs.confirmdialog.confirm(
        "Weet u zeker dat u dit organisatieonderdeel wilt verwijderen?",
        "Deleting deparment. Are you sure?",
        function() {
          this.$http.post("Admin/DeleteOMItem", d).then(function(response) {
            if (!response.data.Success) {
              alert(response.data.Message);
            } else {
              self.loadDepartments();
            }
          });
        }
      );
    },
    newDepartment: function() {
      this.$http
        .get(
          "Admin/NewOMItem?level=2&parent=" + this.selectedFaculty.Id.toString()
        )
        .then(function(response) {
          this.$refs.popupDepartment.show(
            response.data,
            this.departments,
            this
          );
        });
    },
    // Afdeling
    editSection: function(s) {
      this.$refs.popupSection.show(s, this.sections, this);
    },
    saveSection: function() {
      this.$Progress.start();
      var data = this.$refs.popupSection.getData();
      this.$http.post("Admin/SaveOMItem", data).then(
        function() {
          this.$Progress.finish();
          this.loadSections();
        },
        function() {
          this.$Progress.finish();
        }
      );
    },
    deleteSection: function(s) {
      var self = this;
      this.$refs.confirmdialog.confirm(
        "Weet u zeker dat u deze afdeling wilt verwijderen?",
        "Deleting section. Are you sure?",
        function() {
          this.$http.post("Admin/DeleteOMItem", s).then(function(response) {
            if (!response.data.Success) {
              alert(response.data.Message);
            } else {
              self.loadSections();
            }
          });
        }
      );
    },
    newSection: function() {
      this.$http
        .get(
          "Admin/NewOMItem?level=3&parent=" +
            this.selectedDepartment.Id.toString()
        )
        .then(function(response) {
          this.$refs.popupSection.show(response.data, this.sections, this);
        });
    },
    // Groep
    editGroup: function(g) {
      this.$refs.popupGroup.show(g, this.groups, this);
    },
    saveGroup: function() {
      this.$Progress.start();
      var data = this.$refs.popupGroup.getData();
      this.$http.post("Admin/SaveOMItem", data).then(
        function() {
          this.$Progress.finish();
          this.loadGroups();
        },
        function() {
          this.$Progress.finish();
        }
      );
    },
    deleteGroup: function(g) {
      var self = this;
      this.$refs.confirmdialog.confirm(
        "Weet u zeker dat u deze groep wilt verwijderen?",
        "Deleting group. Are you sure?",
        function() {
          this.$http.post("Admin/DeleteOMItem", g).then(function(response) {
            if (!response.data.Success) {
              alert(response.data.Message);
            } else {
              self.loadGroups();
            }
          });
        }
      );
    },
    newGroup: function() {
      this.$http
        .get(
          "Admin/NewOMItem?level=4&parent=" + this.selectedSection.Id.toString()
        )
        .then(function(response) {
          this.$refs.popupGroup.show(response.data, this.groups, this);
        });
    },
    verplaatsMedewerkers: function(level, sourceId, targetOMID) {
      if (!targetOMID) {
        alert("Vul een doel OM-ID in.");
        return;
      }
      this.$http
        .post(
          "Admin/VerplaatsMedewerkers?level=" +
            encodeURIComponent(level.toString()) +
            "&sourceId=" +
            encodeURIComponent(sourceId.toString()) +
            "&targetOMID=" +
            encodeURIComponent(targetOMID)
        )
        .then(function(response) {
          if (!response.data.Success) {
            alert(
              "Fout bij verplaatsen. Mogelijk ongeldig doel OM-ID opgegeven, of doel OM-ID heeft geen afdeling."
            );
          } else {
            alert("Medewerkers zijn verplaatst.");
          }
        });
    },
    employeesDepartment: function(d) {
      this.overviewtitle = "Medewerkers " + d.Name.NL;
      this.$http
        .get("Admin/GetMedewerkers?level=2&id=" + d.Id.toString())
        .then(function(response) {
          this.overview = response.data;
          this.$refs.popupOverview.showSimple();
        });
    },
    employeesSection: function(s) {
      this.overviewtitle = "Medewerkers " + s.Name.NL;
      this.$http
        .get("Admin/GetMedewerkers?level=3&id=" + s.Id.toString())
        .then(function(response) {
          this.overview = response.data;
          this.$refs.popupOverview.showSimple();
        });
    },
    employeesGroup: function(g) {
      this.overviewtitle = "Medewerkers " + g.Name.NL;
      this.$http
        .get("Admin/GetMedewerkers?level=4&id=" + g.Id.toString())
        .then(function(response) {
          this.overview = response.data;
          this.$refs.popupOverview.showSimple();
        });
    }
  },
  watch: {
    selectedFaculty: function() {
      this.loadDepartments();
    },
    selectedDepartment: function() {
      this.loadSections();
    },
    selectedSection: function() {
      this.loadGroups();
    }
  },
  created: function() {
    this.loaddata();
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
input {
  width: 100%;
  margin-right: 10px;
  margin-top: 3px;
  margin-bottom: 3px;
}

select {
  width: 100%;
  margin-right: 10px;
}

tbody > tr {
  cursor: pointer;
}

h2 {
  margin-top: 25px;
}

.o-info {
  font-size: 8pt;
  font-family: "Merriweather";
}

.selectedrow {
  background-color: #ececec;
}

.overview-title {
  font-weight: bold;
}
.overview {
  height: 400px;
  overflow-y: scroll;
}
</style>

<template>
  <div v-if="medewerker" class="TabContact public-tab-content">
    <textfield nameNL="Volledige naam" nameEN="Full name">{{medewerker.Name}}</textfield>
    <textfield
      nameNL="Contactgegevens"
      nameEN="Contact details"
      v-if="medewerker.ContactDetails && medewerker.ContactDetails.length > 0"
    >
      <div class="contact-block" v-for="contact in medewerker.ContactDetails" :key="contact.Guid">
        <div>
          <label-lng NL="Gebouw: " EN="Building: " v-show="contact.Building && contact.Building != ''" />
          <a :href="contact.Url" target="_blank" v-show="contact.Url">{{contact.Building}}</a>
          <span v-show="!contact.Url">{{contact.Building}}</span>
        </div>
        <div>{{contact.Address}}</div>
        <div>
          <label-lng NL="Kamer" EN="Room" v-show="contact.Room && contact.Room != ''" />
          {{contact.Room}}
        </div>
        <div>{{contact.Zipcode}} {{contact.City}}</div>
        <div v-if="$root.lng=='EN'">The Netherlands</div>
        <div v-if="contact.Phone">
          <label-tel-lng
            NL="Telefoonnummer direct "
            EN="Phone number (direct) "
            :tel="contact.Phone"
          />
        </div>
        <div v-if="contact.PhoneDepartment">
          <label-tel-lng
            NL="Telefoonnummer afdeling "
            EN="Phone number (department) "
            :tel="contact.PhoneDepartment"
          />
        </div>
      </div>
    </textfield>

    <textfield nameNL="E-mail" nameEN="Email" v-if="medewerker.Email">
      <a :href="'mailto:' + medewerker.Email">{{medewerker.Email}}</a>
    </textfield>
    <textfield nameNL="Mobiel" nameEN="Mobile phone" v-if="medewerker.MobileNumber">
      <label-tel-lng NL EN :tel="medewerker.MobileNumber" />
    </textfield>
    <textfield v-if="medewerker.PostalAddress" nameNL="Postadres" nameEN="Postal Address">
      <div>{{medewerker.PostalAddress.Address}}</div>
      <div>{{medewerker.PostalAddress.Zipcode}} {{medewerker.PostalAddress.City}}</div>
      <div v-if="$root.lng=='EN'">The Netherlands</div>
    </textfield>

    <div class="row" v-if="medewerker.Availability">
      <div class="col-md-6">
        <div>
          <textfield nameNL="Werkdagen" nameEN="Workdays" />
        </div>
        <div>
          <table class="table table-condensed table-bordered">
            <thead>
              <tr>
                <th></th>
                <th>
                  <label-lng NL="Ma" EN="Mo" />
                </th>
                <th>
                  <label-lng NL="Di" EN="Tue" />
                </th>
                <th>
                  <label-lng NL="Wo" EN="Wed" />
                </th>
                <th>
                  <label-lng NL="Do" EN="Thu" />
                </th>
                <th>
                  <label-lng NL="Vr" EN="Fr" />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>
                  <label-lng NL="Ochtend" EN="Morning" />
                </th>
                <td>
                  <img
                    v-show="medewerker.Availability.indexOf('MaO') != -1"
                    src="../../assets/check.png"
                  />
                </td>
                <td>
                  <img
                    v-show="medewerker.Availability.indexOf('DiO') != -1"
                    src="../../assets/check.png"
                  />
                </td>
                <td>
                  <img
                    v-show="medewerker.Availability.indexOf('WoO') != -1"
                    src="../../assets/check.png"
                  />
                </td>
                <td>
                  <img
                    v-show="medewerker.Availability.indexOf('DoO') != -1"
                    src="../../assets/check.png"
                  />
                </td>
                <td>
                  <img
                    v-show="medewerker.Availability.indexOf('VrO') != -1"
                    src="../../assets/check.png"
                  />
                </td>
              </tr>
              <tr>
                <th>
                  <label-lng NL="Middag" EN="Afternoon" />
                </th>
                <td>
                  <img
                    v-show="medewerker.Availability.indexOf('MaM') != -1"
                    src="../../assets/check.png"
                  />
                </td>
                <td>
                  <img
                    v-show="medewerker.Availability.indexOf('DiM') != -1"
                    src="../../assets/check.png"
                  />
                </td>
                <td>
                  <img
                    v-show="medewerker.Availability.indexOf('WoM') != -1"
                    src="../../assets/check.png"
                  />
                </td>
                <td>
                  <img
                    v-show="medewerker.Availability.indexOf('DoM') != -1"
                    src="../../assets/check.png"
                  />
                </td>
                <td>
                  <img
                    v-show="medewerker.Availability.indexOf('VrM') != -1"
                    src="../../assets/check.png"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col-md-6"></div>
    </div>

    <textfield nameNL nameEN v-if="medewerker.ContactFreeText">
      <span v-html="medewerker.ContactFreeText"></span>
    </textfield>
  </div>
</template>

<script>
import labellng from '@/components/layout/label-lng.vue'
import labeltellng from '@/components/layout/label-tel-lng.vue'
import textfield from '@/components/layout/textfield.vue'

export default {
  name: 'tab-contact',
  data () {
    return {}
  },
  props: ['medewerker'],
  components: {
    textfield: textfield,
    'label-lng': labellng,
    'label-tel-lng': labeltellng
  }
};
</script>

<style scoped>
.table-bordered {
  margin-bottom: 25px;
}
.contact-block {
  margin-bottom: 25px;
}
</style>

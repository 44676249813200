<template>
  <admin-layout title="Rapportage medewerkers">
    <div class="row">
      <div class="col-md-2">Faculteit</div>
      <div class="col-md-10">
        <select v-model="facultyId" class="inputfilter">
          <option v-for="f in faculties" :value="f.FacultyId" :key="f.Guid">{{f.Faculty}}</option>
        </select>
      </div>
    </div>
    <br />
    <a :href="rapporturl" target="_blank">Download overzicht (xlsx)</a>
    <br />
    <small>Het genereren van het rapport kan een paar minuten duren.</small>
  </admin-layout>
</template>

<script>
import AdminLayout from "@/components/layout/admin-layout.vue";

export default {
  name: "BeheerRapportMedewerkers",
  components: {
    "admin-layout": AdminLayout
  },
  data() {
    return {
      facultyId: null,
      faculties: [],
      rapporturl: ""
    };
  },
  methods: {},
  created: function() {
    this.$http
      .get("Admin/GetAdminFaculties?levelUU=true")
      .then(function(response) {
        this.faculties = response.data;
        if (this.faculties.length > 0) {
          this.facultyId = this.faculties[0].FacultyId;
        }
      });
  },
  watch: {
    facultyId: function() {
      this.rapporturl =
        this.$http.options.root +
        "/Admin/RapportageMedewerkers?faculty=" +
        this.facultyId.toString() +
        "&t=" +
        window.localStorage.getItem("token");
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

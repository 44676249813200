<template>
  <admin-layout title="Mail verzenden">
    <div class="BeheerMails" v-if="!mailsent">
      <div class="row">
        <div class="col-md-2">Aan:</div>
        <div class="col-md-5">
          <textarea name="To" v-model="mail.To" style="width: 100%;" rows="8"></textarea>
        </div>
        <div class="col-md-5">
          <div class="help-text">
            Vul hier de emailadressen in van de medewerkers die u wilt mailen.
            <br />Gebruik een
            <b>;</b> als scheidingsteken of zet iedere emailadres op een aparte regel.
            <br />De mail wordt alleen verstuurd als de medewerker een profielpagina heeft.
            <br />Gebruik de optie
            <b>"Emailadressen selecteren"</b> om de emailadressen van medewerkers automatisch in te voegen.
            <br />
            <br />
          </div>
        </div>
      </div>

      <br />

      <div class="row">
        <div class="col-md-2"></div>
        <div class="col-md-5">
          <button
            class="uu-button button-black"
            width="20%"
            @click="selectMailAddresses()"
          >Emailadressen selecteren</button>
        </div>
        <div class="col-md-5"></div>
      </div>

      <br />

      <div class="row">
        <div class="col-md-2">Onderwerp:</div>
        <div class="col-md-5">
          <input type="text" style="width: 100%;" v-model="mail.Subject" />
        </div>
        <div class="col-md-5">
          <div class="help-text">Het onderwerp van de email.</div>
        </div>
      </div>

      <br />

      <div class="row">
        <div class="col-md-2">Afzender emailadres:</div>
        <div class="col-md-5">
          <input type="text" style="width: 100%;" v-model="mail.SenderEmail" />
        </div>
        <div class="col-md-5">
          <div
            class="help-text"
          >Vul hier het afzender emailadres in. Dit is verplicht, zonder dit adres wordt er geen mail verstuurd!</div>
        </div>
      </div>

      <br />

      <div class="row">
        <div class="col-md-2">Afzender naam:</div>
        <div class="col-md-5">
          <input type="text" style="width: 100%;" v-model="mail.SenderName" />
        </div>
        <div class="col-md-5">
          <div
            class="help-text"
          >Vul hier de naam in die de medewerker ziet als afzender van de mail (uw naam).</div>
        </div>
      </div>

      <br />

      <div class="row">
        <div class="col-md-12">
          Mailtekst:
          <br />In de tekst kunt u de volgende variabelen gebruiken:
          <br />
          <ul>
            <li>[medewerker] = de volledige naam van de medewerker.</li>
            <li>[url] is het url van de profielpagina [urlen] is engelstalige url.</li>
            <li>[email] = emailadres van medewerker.</li>
            <li>[laatstedatum] = datum dat medewerker voor het laatst de pagina heeft bijgewerkt.</li>
          </ul>
          <i>Let op: alle variabelen moeten met kleine letters en inclusief blokhaken worden geschreven.</i>
          <br />
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <editor
            id="mailbody"
            ref="mailbody"
            v-model="mail.Body"
            api-key="2ezt8rse2ncudk7zfc8o8q3k3vf6o5hd7dw9wtrz8rx9p9ip"
            :init="editoroptions"
          ></editor>
        </div>
      </div>

      <br />

      <div class="row">
        <div class="col-md-12">
          <button class="uu-button button-black" width="20%" @click="sendMails()">Verzenden</button>
        </div>
      </div>

      <br />

      <span>
        <b-modal
          id="modalPopup"
          ref="modalPopup"
          :title="'Selecteer emailadressen'"
          @ok="modalokclick"
          @cancel="modalcancelclick"
          :ok-title="'Email adressen toevoegen'"
          :cancel-title="'Sluiten'"
        >
          <div class="form-group">
            <label for="fac">Faculteit</label>
            <select v-model="facultyid" class="form-control" id="fac">
              <option v-for="f in faculties" :value="f.FacultyId" :key="f.Guid">{{ f.Faculty }}</option>
            </select>
          </div>

          <div class="form-group" v-if="facultyid >= 0">
            <label for="dep">Organisatieonderdeel</label>
            <select v-model="departmentid" class="form-control" id="dep">
              <option v-for="d in departments" :value="d.DepartmentId" :key="d.Guid">{{ d.Department }}</option>
            </select>
          </div>

          <div class="form-group" v-if="departmentid >= 0">
            <label for="sec">Afdeling</label>
            <select v-model="sectionid" class="form-control" id="sec">
              <option v-for="s in sections" :value="s.SectionId" :key="s.Guid">{{ s.Section }}</option>
            </select>
          </div>
        </b-modal>
      </span>
    </div>

    <div v-if="mailsent">
      <div class="row">
        <div class="col-md-12">
          {{ result.SuccessCount }} mail(s) verstuurd.
          <div v-if="result.ErrorCount > 0">
            De volgende {{ result.ErrorCount }} mail(s) zijn niet verzonden:
            <ul>
              <div v-for="e in result.Errors" :key="e.Guid">
                <li>{{ e }}</li>
              </div>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="row">
          <button class="uu-button button-black" width="20%" @click="backToMail()">Terug naar mail</button>
        </div>
      </div>
    </div>
  </admin-layout>
</template>

<script>
import AdminLayout from '@/components/layout/admin-layout.vue'
import Editor from '@tinymce/tinymce-vue'

export default {
  name: 'BeheerMails',
  components: {
    'admin-layout': AdminLayout,
    editor: Editor
  },
  data() {
    return {
      mailsent: false,
      faculties: [],
      departments: [],
      sections: [],
      facultyid: -1,
      departmentid: -1,
      sectionid: -1,
      mail: {},
      result: [],
      noresult: false,
      editoroptions: {
        menubar: false,
        plugins: 'table code lists link image media autoresize paste',
        toolbar:
          'undo redo | code | formatselect | bold italic underline superscript subscript | bullist numlist outdent indent | copy paste',
        autoresize_bottom_margin: 2,
        autoresize_max_height: 300,
        autoresize_min_height: 200,
        block_formats: 'Paragraph=p;Header 1=h1;Header 2=h2;Header 3=h3',
        paste_enable_default_filters: false,
        paste_word_valid_elements:
          'b,strong,i,em,h1,h2,ul,li,ol,table,tr,td,th,tbody,thead',
        paste_as_text: true,
        paste_data_images: false,
        relative_urls: false
      }
    };
  },
  methods: {
    loaddata: function() {
      this.$http.get('Public/GetFaculties?l=NL').then(function(response) {
        this.faculties = response.data;
      });

      this.$http.get('Admin/GetMailDefaults').then(function(response) {
        this.mail = response.data;
      });

      this.facultyid = -1;
      this.departmentid = -1;
      this.sectionid = -1;
    },
    loaddepartments: function(facId) {
      this.departments = [];
      this.sections = [];
      this.$Progress.start();
      this.$http.get('Public/GetDepartments?f=' + facId.toString()).then(
        function(response) {
          this.$Progress.finish();
          this.departments = response.data;
          this.departments.splice(0, 0, {
            DepartmentId: -1,
            Department: '<Alle>'
          });
        },
        function() {
          // Fail
          this.$Progress.finish();
        }
      );

      this.departmentid = -1;
      this.sectionid = -1;
    },
    loadsections: function(depId) {
      this.sections = [];
      this.$Progress.start();
      this.$http.get('Public/GetSections?d=' + depId.toString()).then(
        function(response) {
          this.$Progress.finish();
          this.sections = response.data;
          this.sections.splice(0, 0, { SectionId: -1, Section: '<Alle>' });
        },
        function() {
          // Fail
          this.$Progress.finish();
        }
      );

      this.sectionid = -1;
    },
    sendMails: function() {
      this.$Progress.start();
      this.$http.post('Admin/SendMail', this.mail).then(
        function(response) {
          this.$Progress.finish();
          this.result = response.data;
          this.noresult = response.data.length === 0;
          this.mailsent = true;
        },
        function() {
          this.$Progress.finish();
        }
      );
    },
    backToMail: function() {
      this.mailsent = false;
    },
    selectMailAddresses: function() {
      this.facultyid = -1;
      this.departmentid = -1;
      this.sectionid = -1;

      this.departments = [];
      this.sections = [];

      this.$refs.modalPopup.show();
    },
    closemodal: function() {
      this.$refs.modalPopup.hide();
    },
    modalokclick: function() {
      this.$Progress.start();
      if (this.sectionid >= 0) {
        this.$http
          .get('Admin/GetMailAddresses?s=' + this.sectionid.toString())
          .then(
            function(response) {
              this.$Progress.finish();
              this.mail.To = this.mail.To + response.data.Message;
            },
            function() {
              this.$Progress.finish();
            }
          );
      } else if (this.departmentid >= 0) {
        this.$http
          .get('Admin/GetMailAddresses?d=' + this.departmentid.toString())
          .then(
            function(response) {
              this.$Progress.finish();
              this.mail.To = this.mail.To + response.data.Message;
            },
            function() {
              this.$Progress.finish();
            }
          );
      } else if (this.facultyid >= 0) {
        this.$http
          .get('Admin/GetMailAddresses?f=' + this.facultyid.toString())
          .then(
            function(response) {
              this.$Progress.finish();
              this.mail.To = this.mail.To + response.data.Message;
            },
            function() {
              this.$Progress.finish();
            }
          );
      }
    },
    modalcancelclick: function(e) {
      this.closemodal(e);
    }
  },
  watch: {
    facultyid: function(facId) {
      this.loaddepartments(facId);
    },
    departmentid: function(depId) {
      this.loadsections(depId);
    }
  },
  created: function() {
    this.loaddata();
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.help-text {
  font-size: 13px;
  line-height: 20px;
}
</style>

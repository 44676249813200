<template>
  <div class="TabHeader font-sidebar">
    <div v-if="medewerker" class="row">
      <div class="col-md-2">
        <!-- Profielfoto -->
        <div class="profielfoto">
          <img
            :src="$http.options.root + medewerker.PhotoUrl + token"
            :title="medewerker.Name"
            class="img-fluid"
            v-if="medewerker.PhotoUrl"
          />
        </div>
        <div v-if="medewerker.LinksSocialMedia" class="SocialMedia">
          <span v-for="l in medewerker.LinksSocialMedia" :key="l.Guid">
            <a :href="l.Url" target="_blank">
              <img :src="l.IconUrl" :title="l.Name" :alt="l.Name" />
            </a>
          </span>
        </div>
      </div>
      <div class="col-md-10">
        <!-- Naam, aanstellingen, contactgegevens -->
        <h1 class="font-headline extrawitruimte">{{medewerker.Name}}</h1>

        <!--  aanstellingen en contactgegevens -->
        <div class="row">
          <!-- Aanstelling 1 -->
          <div class="col-md-4 aanstelling">
            <div v-if="medewerker.Positions[0]">
              <!-- Functie van aanstelling 1-->
              <div>{{medewerker.Positions[0].Position}}&nbsp;</div>
              <!-- OM-elementen van aanstelling 1 -->
              <div>
                <a
                  :href="medewerker.Positions[0].Level1Url"
                  target="_blank"
                >{{medewerker.Positions[0].Level1}}</a>
              </div>
              <div>
                <a
                  :href="medewerker.Positions[0].Level2Url"
                  target="_blank"
                >{{medewerker.Positions[0].Level2}}</a>
              </div>
              <div>
                <a
                  :href="medewerker.Positions[0].Level3Url"
                  target="_blank"
                >{{medewerker.Positions[0].Level3}}</a>
              </div>
              <div>
                <a
                  :href="medewerker.Positions[0].Level4Url"
                  target="_blank"
                >{{medewerker.Positions[0].Level4}}</a>
              </div>
            </div>
          </div>

          <!-- Aanstelling 2 -->
          <div class="col-md-4 aanstelling">
            <div v-if="medewerker.Positions[1]">
              <!-- Functie van aanstelling 2-->
              <div>{{medewerker.Positions[1].Position}}&nbsp;</div>
              <!-- OM-elementen van aanstelling 2 -->
              <div>
                <a
                  :href="medewerker.Positions[1].Level1Url"
                  target="_blank"
                >{{medewerker.Positions[1].Level1}}</a>
              </div>
              <div>
                <a
                  :href="medewerker.Positions[1].Level2Url"
                  target="_blank"
                >{{medewerker.Positions[1].Level2}}</a>
              </div>
              <div>
                <a
                  :href="medewerker.Positions[1].Level3Url"
                  target="_blank"
                >{{medewerker.Positions[1].Level3}}</a>
              </div>
              <div>
                <a
                  :href="medewerker.Positions[1].Level4Url"
                  target="_blank"
                >{{medewerker.Positions[1].Level4}}</a>
              </div>
            </div>
          </div>
          <div class="col-md-4 ContactBlok">
            <!-- Primaire telefoonnummer -->
            <div>
              <div v-if="medewerker.ContactDetails && medewerker.ContactDetails[0]">
                <img src="../../assets/phone.png" class="icon" v-show="medewerker.ContactDetails[0].Phone != null || medewerker.ContactDetails[0].PhoneDepartment != null" />
                <label-tel-lng class="telnr" NL EN :tel="medewerker.ContactDetails[0].Phone" />
                <label-tel-lng
                  class="telnr"
                  v-if="!medewerker.ContactDetails[0].Phone"
                  NL
                  EN
                  :tel="medewerker.ContactDetails[0].PhoneDepartment"
                />
              </div>
              <!-- Emailadres -->
              <div v-if="medewerker.Email && medewerker.Email != ''">
                <img src="../../assets/envelope.png" class="icon" />
                <a :href="'mailto:' + medewerker.Email" class="underlined-link">{{medewerker.Email}}</a>
              </div>
               <!-- Hoofd locatie - Gebouwnaam-->
              <div v-if="medewerker.ContactDetails && medewerker.ContactDetails[0]">
                <img src="../../assets/thumbtack.png" class="icon" v-show="medewerker.ContactDetails[0].Building != null" />
                    <a
                      :href="medewerker.ContactDetails[0].Url"
                      target="_blank"
                      v-if="medewerker.ContactDetails[0].Url"
                    >{{medewerker.ContactDetails[0].Building}}</a>
                    <span
                      v-if="!medewerker.ContactDetails[0].Url"
                    >{{medewerker.ContactDetails[0].Building}}</span>
              </div>

              <div
                v-if="medewerker.ContactDetails && medewerker.ContactDetails[0]"
                class="LocatieBlok"
              >
                <!-- Punaise van locatie -->
                <span>
                  <a :href="medewerker.ContactDetails[0].Url" target="_blank" v-show="medewerker.ContactDetails[0].Address != null || medewerker.ContactDetails[0].Room != null">
                    <img src="../../assets/pin.png" class="icon iconloc" />
                  </a>
                </span>
                <span>
                  <!-- De primare locatie  -->
                  <div>{{medewerker.ContactDetails[0].Address}}</div>
                  <div>
                    <label-lng NL="Kamer" EN="Room" v-show="medewerker.ContactDetails[0].Room && medewerker.ContactDetails[0].Room != ''" />
                    {{medewerker.ContactDetails[0].Room}}
                  </div>
                  <div>{{medewerker.ContactDetails[0].Zipcode}} {{medewerker.ContactDetails[0].City}}</div>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import labeltellng from '@/components/layout/label-tel-lng.vue'
import labellng from '@/components/layout/label-lng.vue'

export default {
  name: 'tab-header',
  props: ['medewerker'],
  data () {
    return {
      token: window.localStorage.getItem('token')
    };
  },
  components: {
    'label-tel-lng': labeltellng,
    'label-lng': labellng
  }
};
</script>

<style>
.telnr a {
  color: #000000 !important;
}
</style>

<style scoped>
/* Lettertype wat kleiner dan standaard font-headline */
.font-sidebar {
  font-size: 12pt;
}

/* Margens boven/onder header */
.TabHeader {
  margin-top: 0px;
  margin-bottom: 20px;
}

.TabHeader a {
  color: black !important;
}

/* Profielfoto */
.profielfoto {
  max-width: 175px;
  /*padding-top: 30px;*/
  max-height: 260px; /* 260 + padding-top (nu 0) */
  text-align: center;
}

.icon {
  margin-right: 10px;
}

/* Locatieblok is rechter blok (met locatie en hoofdfaculteit)*/
.LocatieBlok {
  line-height: 34px;
}

.ContactBlok {
  line-height: 34px;
}
/* Dit is de punaise voor de locatie */
.LocatieBlok > span:first-child {
  position: relative;
  top: 3px;
  line-height: 34px;
}
/* Locatie rechts van de punaise */
.LocatieBlok > span {
  display: inline-block;
  vertical-align: top;
  line-height: 34px;
}

/* Extra witruimte tussen blokjes tekst */
.extrawitruimte {
  margin-bottom: 20px;
}

/* Functieomschrijving in groter font */
.aanstelling > div > div:first-child {
  font-size: 18px;
  font-weight: bold;
  line-height: 34px;
}

.aanstelling {
  line-height: 34px;
}

.SocialMedia {
  padding-top: 10px;
  width: 175px;
  text-align: center;
}

.iconloc {
  position: relative;
  top:-3px;
}
</style>

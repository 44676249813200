<template>
  <div class="mobile organogram font-headline">
    <!-- Header -->
    <mobile-header />

    <div class="row gele-balk">
      <div class="col-12">
        <label-lng NL="Organogram" EN="Organisational Chart" />
      </div>
    </div>

    <div class="no-margin">
      <div v-show="mode=='T'" class="om-tree">
        <div v-show="currentLevel==1">
          <div class="ou-title">
            <label-lng NL="Universiteit Utrecht" EN="Utrecht University" />
          </div>
          <b-list-group>
            <b-list-group-item
              v-for="f in faculties"
              :key="f.Guid"
              @click="selectFaculty(f)"
              class="flex-column align-items-start"
            >
              <div class="d-flex w-100 justify-content-between">
                <div class="ou-naam level-1">{{f.Faculty}}</div>
                <img
                  class="button-details"
                  @click="detailsFaculty(f);"
                  src="../../assets/medewerker-icon.png"
                />
              </div>
            </b-list-group-item>
          </b-list-group>
        </div>

        <div v-show="currentLevel==2">
          <div class="ou-title" v-if="selectedFaculty">{{selectedFaculty.Faculty}}</div>
          <b-list-group>
            <b-list-group-item
              v-for="d in departments"
              :key="d.Guid"
              @click="selectDepartment(d)"
              class="flex-column align-items-start"
            >
              <div class="d-flex w-100 justify-content-between">
                <div class="ou-naam level-2">{{d.Department}}</div>
                <img
                  class="button-details"
                  @click="detailsDepartment(d);"
                  src="../../assets/medewerker-icon.png"
                />
              </div>
            </b-list-group-item>
          </b-list-group>
        </div>

        <div v-show="currentLevel==3">
          <div class="ou-title" v-if="selectedDepartment">{{selectedDepartment.Department}}</div>
          <b-list-group>
            <b-list-group-item
              v-for="s in sections"
              :key="s.Guid"
              @click="selectSection(s)"
              class="flex-column align-items-start"
            >
              <div class="d-flex w-100 justify-content-between">
                <div class="ou-naam level-3">{{s.Section}}</div>
                <img
                  class="button-details"
                  @click="detailsSection(s);"
                  src="../../assets/medewerker-icon.png"
                />
              </div>
            </b-list-group-item>
          </b-list-group>
        </div>

        <div v-show="currentLevel==4">
          <div class="ou-title" v-if="selectedSection">{{selectedSection.Section}}</div>
          <b-list-group>
            <b-list-group-item
              v-for="g in groups"
              :key="g.Guid"
              @click="selectGroup(g)"
              class="flex-column align-items-start"
            >
              <div class="d-flex w-100 justify-content-between">
                <div class="ou-naam level-4">{{g.Group}}</div>
                <img
                  class="button-details"
                  @click="detailsGroup(g);"
                  src="../../assets/medewerker-icon.png"
                />
              </div>
            </b-list-group-item>
          </b-list-group>
        </div>
      </div>

      <!-- Medewerkers -->
      <div v-show="mode=='E'">
        <!-- Medewerkers -->
        <div class="ou-title">{{selectedOM}}</div>
        <div v-if="ouDetails" class="employee-container">
          <div class="employee-block" v-for="e in ouDetails.Employees" :key="e.Guid">
            <div class="photo-div">
              <router-link
                :to="{ name: 'mobile' + $root.lng, params:{ employee: e.Url }}"
                v-if="e.PhotoUrl"
              >
                <img
                  :src="$http.options.root + e.PhotoUrl + token"
                  :title="e.Name"
                  class="img-fluid"
                />
              </router-link>
            </div>
            <div>
              <router-link
                :to="{ name: 'mobile' + $root.lng, params:{ employee: e.Url }}"
              >{{e.Name}}</router-link>
            </div>
            <div>{{e.Position}}</div>
            <div>
              <a :href="'mailto:'+e.Email">{{e.Email}}</a>
            </div>
            <div>
              <a :href="'tel:'+e.Phone">{{e.Phone}}</a>
            </div>
          </div>
        </div>
        <!-- More-button -->
        <div class="more-results" v-if="ouDetails && ouDetails.More > 0">
          <button class="uu-button button-black" @click="showMore();" v-show="!loadingMore">
            <label-lng
              :NL="'Overige ' + ouDetails.More.toString() + ' medewerker' + (ouDetails.More != 1 ? 's' : '') + ' ophalen'"
              :EN="'Get remaining ' + ouDetails.More.toString() + ' employee' + (ouDetails.More != 1 ? 's' : '')"
            />
          </button>
          <span>
            <label-lng v-show="loadingMore" NL="Bezig met laden..." EN="Loading..." />
          </span>
        </div>
        <!-- Opvulling voor achter <terug-balk -->
        <div class="margin-footer" v-show="ouDetails"></div>
      </div>
    </div>

    <div class="fixed-bottom back-button" @click="goBack()">
      <img src="../../assets/tree-back.png" />
      <label-lng NL="Terug" EN="Back" />
    </div>
  </div>
</template>

<script>
import labellng from "@/components/layout/label-lng.vue";
import mobileheader from "@/components/mobile/mobile-header.vue";

export default {
  name: "mobileorganogram",
  components: {
    "label-lng": labellng,
    "mobile-header": mobileheader
  },
  data() {
    return {
      token: window.localStorage.getItem("token"),
      currentLevel: 1,
      faculties: [],
      departments: [],
      sections: [],
      groups: [],
      mode:
        this.$route.query.m == null || this.$route.query.m === ""
          ? "T"
          : this.$route.query.m,
      selectedFaculty: null,
      selectedDepartment: null,
      selectedSection: null,
      selectedGroup: null,
      ouDetails: null,
      selectedOM: "",
      empUrl: "",
      loadingMore: false
    };
  },
  methods: {
    selectFaculty: function(faculty) {
      this.currentLevel++;
      this.selectedFaculty = faculty;
      this.setUrl(2);
      if (faculty.ChildCount > 0) {
        this.getLevel(2, faculty.Code);
      } else {
        // Geen onderliggende => details openen
        this.detailsFaculty(faculty);
      }
    },
    selectDepartment: function(department) {
      this.currentLevel++;
      this.selectedDepartment = department;
      this.setUrl(3);
      if (department.ChildCount > 0) {
        this.getLevel(3, department.Code);
      } else {
        // Geen onderliggende => details openen
        this.detailsDepartment(department);
      }
    },
    selectSection: function(section) {
      this.currentLevel++;
      this.selectedSection = section;
      this.setUrl(4);
      if (section.ChildCount > 0) {
        this.getLevel(4, section.Code);
      } else {
        // Geen onderliggende => details openen
        this.detailsSection(section);
      }
    },
    selectGroup: function(group) {
      this.currentLevel++;
      this.selectedGroup = group;
      this.setUrl(5);
      this.detailsGroup(group);
    },
    getLevel: function(level, parentId, autoselectItem, showdetails) {
      if (level >= 1 && level <= 4) {
        this.$http
          .get(this.getUrlForLevel(level, parentId))
          .then(function(response) {
            switch (level) {
              case 1:
                this.faculties = response.data;
                this.selectFacultyByCode(autoselectItem, showdetails);
                break;
              case 2:
                this.departments = response.data;
                this.selectDepartmentByCode(autoselectItem, showdetails);
                break;
              case 3:
                this.sections = response.data;
                this.selectSectionByCode(autoselectItem, showdetails);
                break;
              case 4:
                this.groups = response.data;
                this.selectGroupByCode(autoselectItem, showdetails);
                break;
            }
          });
      } else {
        if (level === 5) {
          // Level 5 (groep) heeft geen onderliggenden.
          if (showdetails) this.detailsGroup(this.selectedGroup);
        }
      }
    },

    getUrlForLevel: function(level, parentId) {
      var url = "Public/";
      switch (level) {
        case 1:
          return (url +=
            "GetFaculties?l=" + this.$root.lng + "&skipNoName=true");
        case 2:
          return (url +=
            "GetDepartments?fc=" +
            parentId.toString() +
            "&l=" +
            this.$root.lng +
            "&skipNoName=true");
        case 3:
          return (url +=
            "GetSections?dc=" +
            parentId.toString() +
            "&l=" +
            this.$root.lng +
            "&skipNoName=true");
        case 4:
          return (url +=
            "GetGroups?sc=" +
            parentId.toString() +
            "&l=" +
            this.$root.lng +
            "&skipNoName=true");
      }
      return "";
    },

    goBack: function() {
      if (this.mode === "T") {
        // In tree een niveau omhoog
        if (this.currentLevel > 1) {
          this.currentLevel--;
          this.setUrl(this.currentLevel);
        } else {
          // Na hoogste niveau
          this.$router.push({ name: "mobileSearch" + this.$root.lng });
        }
      }
      if (this.mode === "E") {
        // Van medewerkerview naar tree
        this.currentLevel--; // Doordat click op "details" ook een niveau dieper in tree gaat nu een niveau terug.
        this.mode = "T";
        this.setUrl(this.currentLevel);
      }
    },

    detailsFaculty: function(faculty) {
      this.mode = "E";
      this.selectedOM = faculty.Faculty;
      this.getEmployees(1, faculty.FacultyId);
    },
    detailsDepartment: function(department) {
      this.mode = "E";
      this.selectedOM = department.Department;
      this.getEmployees(2, department.DepartmentId);
    },
    detailsSection: function(section) {
      this.mode = "E";
      this.selectedOM = section.Section;
      this.getEmployees(3, section.SectionId);
    },
    detailsGroup: function(group) {
      this.mode = "E";
      this.selectedOM = group.Group;
      this.getEmployees(4, group.GroupId);
    },
    getEmployees: function(level, parent) {
      var url = "Public/GetEmployeesOrganogram?";
      switch (level) {
        case 1:
          url += "f=" + parent.toString();
          break;
        case 2:
          url += "d=" + parent.toString();
          break;
        case 3:
          url += "s=" + parent.toString();
          break;
        case 4:
          url += "g=" + parent.toString();
          break;
      }
      url += "&l=" + this.$root.lng + "&token=" + this.token;
      this.empUrl = url;
      this.$http.get(this.empUrl).then(function(response) {
        this.ouDetails = response.data;
      });
    },
    // Zet de huidige positie in de OM-boom in het url
    setUrl: function(level) {
      if (level === 1) {
        this.$router.push({
          name: "mobileorganogram" + this.$root.lng,
          params: {}
        });
      }
      if (level === 2) {
        this.$router.push({
          name: "mobileorganogram1" + this.$root.lng,
          params: { faculty: this.selectedFaculty.Code }
        });
      }
      if (level === 3) {
        this.$router.push({
          name: "mobileorganogram2" + this.$root.lng,
          params: {
            faculty: this.selectedFaculty.Code,
            department: this.selectedDepartment.Code
          }
        });
      }
      if (level === 4) {
        this.$router.push({
          name: "mobileorganogram3" + this.$root.lng,
          params: {
            faculty: this.selectedFaculty.Code,
            department: this.selectedDepartment.Code,
            section: this.selectedSection.Code
          }
        });
      }
      if (level === 5) {
        this.$router.push({
          name: "mobileorganogram4" + this.$root.lng,
          params: {
            faculty: this.selectedFaculty.Code,
            department: this.selectedDepartment.Code,
            section: this.selectedSection.Code,
            group: this.selectedGroup.Code
          }
        });
      }
    },
    // Ophalen faculteit/dep/sec/group uit url.
    gotoOMfromUrl: function() {
      var level = 1;
      if (this.$route.params.faculty) level = 2;
      if (this.$route.params.department) level = 3;
      if (this.$route.params.section) level = 4;
      if (this.$route.params.group) level = 5;
      this.currentLevel = level;
      var showdetails = this.$route.query.m && this.$route.query.m === "E";
      if (level >= 1) {
        this.getLevel(
          1,
          null,
          this.$route.params.faculty,
          showdetails && level === 2
        ); // faculteiten ophalen
      }
      if (level >= 2) {
        this.getLevel(
          2,
          this.$route.params.faculty,
          this.$route.params.department,
          showdetails && level === 3
        ); // Departementen ophalen
      }
      if (level >= 3) {
        this.getLevel(
          3,
          this.$route.params.department,
          this.$route.params.section,
          showdetails && level === 4
        ); // Afdelingen ophalen
      }
      if (level >= 4) {
        this.getLevel(
          4,
          this.$route.params.section,
          this.$route.params.group,
          showdetails && level === 5
        ); // Groepen ophalen
      }
    },
    selectFacultyByCode: function(facultyCode, showdetails) {
      if (facultyCode) {
        for (var i = 0; i < this.faculties.length; i++) {
          if (
            this.faculties[i].Code.toUpperCase() === facultyCode.toUpperCase()
          ) {
            this.selectedFaculty = this.faculties[i];
          }
        }
        if (showdetails) {
          this.detailsFaculty(this.selectedFaculty);
        }
      }
    },
    selectDepartmentByCode: function(departmentCode, showdetails) {
      if (departmentCode) {
        for (var i = 0; i < this.departments.length; i++) {
          if (
            this.departments[i].Code.toUpperCase() ===
            departmentCode.toUpperCase()
          ) {
            this.selectedDepartment = this.departments[i];
          }
        }
        if (showdetails) {
          this.detailsDepartment(this.selectedDepartment);
        }
      }
    },
    selectSectionByCode: function(sectionCode, showdetails) {
      if (sectionCode) {
        for (var i = 0; i < this.sections.length; i++) {
          if (
            this.sections[i].Code.toUpperCase() === sectionCode.toUpperCase()
          ) {
            this.selectedSection = this.sections[i];
          }
        }
        if (showdetails) {
          this.detailsSection(this.selectedSection);
        }
      }
    },
    selectGroupByCode: function(groupCode, showdetails) {
      if (groupCode) {
        for (var i = 0; i < this.groups.length; i++) {
          if (this.groups[i].Code.toUpperCase() === groupCode.toUpperCase()) {
            this.selectedGroup = this.groups[i];
          }
        }
        if (showdetails) {
          this.detailsGroup(this.selectedGroup);
        }
      }
    },
    showMore: function() {
      if (this.empUrl !== "") {
        this.empUrl += "&fullresult=true";
        this.loadingMore = true;
        this.$http.get(this.empUrl).then(function(response) {
          this.ouDetails = response.data;
          this.loadingMore = false;
        });
      }
    }
  },
  watch: {
    mode: function(newval) {
      this.$router.push({ query: { m: newval } });
    }
  },
  created: function() {
    // this.getLevel(1);
    this.gotoOMfromUrl();
  }
};
</script>

<style scoped>
/* Header */
.gele-balk {
  background-color: #ffcd00;
  min-height: 50px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5px;
  font-size: 20px;
}

.no-margin {
  margin-left: -15px;
  margin-right: -15px;
  padding-left: 0px;
  padding-right: 0px;
}

/* Footer met terug-knop*/
.back-button {
  background-color: #262626;
  border-top: 4px solid #fff;
  line-height: 50px;
  margin-left: 0px;
  padding-left: 5px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
}

/* Tree */

/* Omvat hele om-tree. */
.om-tree {
  padding-bottom: 55px; /* padding zorgt dat onderste item niet verdwijnt achter < terug */
}

/* Knop details achter naam van organisatieonderdeel */
.button-details {
  width: 24px;
  height: 24px;
}

/* Grijze titelbalk met naam van huidige onderdeel */
.ou-title {
  background-color: #efefef;
  min-height: 30px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 21px;
}

/* Employee's (foto's) */
.employee-container {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 0px;
  margin-top: 10px;
}

/* Volledige blok rond foto */
.employee-block {
  display: inline-block;
  width: 170px;
  height: 265px;
  background-color: #efefef;
  margin-top: 0px;
  margin-left: 10px;
  margin-bottom: 10px;
  margin-right: 0px;
  overflow: hidden;
  padding: 5px;
  font-size: 12px;
  font-family: "Open Sans";
  line-height: 1.45;
}

.employee-block image {
  width: 100%;
}

.employee-block div {
  width: 100%;
  text-align: center;
}

.more-results {
  margin-bottom: 15px;
  text-align: center;
  min-height: 40px;
  line-height: 40px;
}

.photo-div {
  height: 175px;
  line-height: 175px;
}

.photo-div img {
  max-height: 175px;
}

.margin-footer {
  height: 55px;
}
</style>

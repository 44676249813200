<template>
  <div v-if="medewerker" class="TabEditProfile">
    <confirm ref="confirmdialog"></confirm>

    <div class="row">
      <div class="col-md-9">
        <table class="table table-sm table-bordered">
          <thead>
            <tr>
              <th>
                <label-lng
                  NL="Vaardigheid (Nederlands)"
                  EN="Skill (Dutch)"
                ></label-lng>
              </th>
              <th>
                <label-lng
                  NL="Vaardigheid (Engels)"
                  EN="Skill (English)"
                ></label-lng>
              </th>
              <th class="iconcolumn"></th>
              <th class="iconcolumn"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="s in medewerker.Skills" :key="s.Guid">
              <td>{{ s.NameNL }}</td>
              <td>{{ s.NameEN }}</td>
              <td>
                <iconbutton
                  icon="chevron-up"
                  titleNL="Hoger"
                  titleEN="Up"
                  @click="up(s)"
                  class="up"
                />&nbsp;<iconbutton
                  icon="chevron-down"
                  titleNL="Lager"
                  titleEN="Down"
                  @click="down(s)"
                  class="down"
                />
              </td>
              <td>
                <iconbutton
                  icon="trash"
                  titleNL="Verwijderen"
                  titleEN="Delete"
                  @click="deleteSkill(s)"
                />
              </td>
            </tr>
          </tbody>
        </table>
        <button
          class="uu-button button-black"
          v-on:click="addSkill()"
          variant="link"
        >
          <label-lng NL="Vaardigheid toevoegen" EN="Add skill"></label-lng>
        </button>
      </div>
    </div>

    <popup
      ref="popupSkill"
      titleNL="Vaardigheid"
      titleEN="Skill"
    >
      <form slot-scope="popup">
        <div v-if="popup.data != null">
          <div class="form-group">
            <label for="skillnl">
              <label-lng NL="Vaardigheid" EN="Skill"></label-lng>
            </label>
            <autocomplete
              ref="skillnl"
              :placeholder="
                $root.lng == 'NL' ? 'Zoek een vaardigheid' : 'Search for skill'
              "
              :sourceurl="
                $http.options.root +
                '/Edit/SearchSkill?l=' +
                $root.lng +
                '&term='
              "
              @OnObjectSelect="selectSkill"
              results-display="Value"
              results-value="Id"
              input-class
            />
            <span v-html="GetInfo(info, 'Profiel.VaardigheidToevoegen')"></span>
          </div>
        </div>
      </form>
    </popup>
  </div>
</template>

<script>
import labellng from "@/components/layout/label-lng.vue";
import confirmdialog from "@/components/layout/confirm.vue";
import popup from "@/components/layout/popup.vue";
import Autocomplete from "@/components/layout/autocomplete.vue";
import iconbutton from "@/components/layout/iconbutton.vue";
import infohelper from "@/helpers/info-helper.vue";
import sorting from "@/helpers/sorting.vue";

export default {
  name: "tab-edit-profile-skills",
  props: ["medewerker", "info"],
  data() {
    return {};
  },
  components: {
    "label-lng": labellng,
    confirm: confirmdialog,
    popup: popup,
    autocomplete: Autocomplete,
    iconbutton: iconbutton,
  },
  methods: {
    addSkill: function () {
      var maxorderindex = 0;
      for (var i = 0; i < this.medewerker.Skills.length; i++) {
        if (this.medewerker.Skills[i].OrderIndex > maxorderindex) {
          maxorderindex = this.medewerker.Skills[i].OrderIndex;
        }
      }
      this.$refs.popupSkill.show(
        { OrderIndex: maxorderindex + 1 },
        this.medewerker.Skills,
        this
      );
      if (this.$refs.skillnl) {
        this.$refs.skillnl.clear();
        this.$refs.skillnl.focus();
      }
    },
    deleteSkill: function (skill) {
      var mdw = this.medewerker;
      this.$refs.confirmdialog.confirm(
        "Vaardigheid verwijderen?",
        "Delete skill?",
        function () {
          var i = mdw.Skills.indexOf(skill);
          if (i !== -1) {
            mdw.Skills.splice(i, 1);
          }
        }
      );
    },
    selectSkill: function (skill) {
      var data = this.$refs.popupSkill.getData();
      data.NameNL = skill.NameNL;
      data.NameEN = skill.NameEN;
      data.Id = skill.Id;
    },
    // Sortering
    up: function (skill) {
      this.sortup(skill, this.medewerker.Skills);
    },
    down: function (skill) {
      this.sortdown(skill, this.medewerker.Skills);
    },
  },
  mixins: [infohelper, sorting],
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
table {
  table-layout: fixed;
}
td {
  overflow: hidden;
}

.iconcolumn {
  width: 50px;
}
</style>

<template>
  <span>
    <img src="../../assets/tree.png" v-show="ChildCount!=0 && !Selected" />
    <img src="../../assets/tree.png" v-show="ChildCount!=0 && Selected" />
    <img src="../../assets/tree-no-child.png" v-show="ChildCount==0" />
  </span>
</template>

<script>
export default {
  name: "tree-node",
  data() {
    return {}
  },
  props: ["ChildCount", "Selected"]
};
</script>

<style scoped>
</style>

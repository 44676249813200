<template>
  <div v-if="medewerker" class="TabEditProfile">
    <confirm ref="confirmdialog"></confirm>
    <alert ref="alertdialog"></alert>

    <edit-layout
      nameNL="Profiel"
      nameEN="Profile"
      :info="GetInfo(info, 'Profiel.ProfielNL')"
    >
      <edit-textfield
        :value="medewerker.Profile"
        multilng="true"
        rtf="true"
        name="pt"
      ></edit-textfield>
      <div class="row">
        <div class="col-md-1"></div>
        <div class="col-md">
          <button class="uu-button button-black" @click="SendToPure('NL')">
            <label-lng
              NL="Profieltekst NL naar Pure"
              EN="Send profile text NL to Pure"
            />
            <span class="uu-button button-black">
              <icon name="chevron-right"></icon>
            </span></button
          >&nbsp;<button
            class="uu-button button-black"
            @click="SendToPure('EN')"
          >
            <label-lng
              NL="Profieltekst EN naar Pure"
              EN="Send profile text EN to Pure"
            />
            <span class="uu-button button-black">
              <icon name="chevron-right"></icon>
            </span>
          </button>
        </div>
      </div>
    </edit-layout>

    <!-- Links -->
    <edit-layout
      nameNL="Url Catalogus Professorum"
      nameEN="Url Catalogus Professorum"
      :info="GetInfo(info, 'Profiel.Catalogusprofessorum')"
      v-if="medewerker.UrlCatalogusProfessorum"
    >
      <edit-textfield
        :value="medewerker.UrlCatalogusProfessorum"
        name="EP2"
      ></edit-textfield>
    </edit-layout>

    <edit-layout
      nameNL="Links"
      nameEN="Links"
      :info="GetInfo(info, 'Profiel.Links')"
      v-if="medewerker.Links"
    >
      <tab-edit-profile-links :medewerker="medewerker"></tab-edit-profile-links>
    </edit-layout>

    <!-- Images -->
    <edit-layout
      nameNL="Afbeeldingen"
      nameEN="Images"
      :info="GetInfo(info, 'Profiel.Afbeeldingen')"
      v-if="medewerker.Images"
    >
      <tab-edit-profile-images
        :medewerker="medewerker"
        :info="info"
      ></tab-edit-profile-images>
    </edit-layout>
  </div>
</template>

<script>
import labellng from "@/components/layout/label-lng.vue";
import edittextfield from "@/components/layout/edit-textfield.vue";
import confirmdialog from "@/components/layout/confirm.vue";
import alertdialog from "@/components/layout/alert.vue";
import profilelinks from "@/components/edit/tab-edit-profile-links.vue";
import profileimages from "@/components/edit/tab-edit-profile-images.vue";
import editlayout from "@/components/layout/edit-layout.vue";
import infohelper from "@/helpers/info-helper.vue";

export default {
  name: "tab-edit-profile",
  props: ["medewerker", "faculties", "info"],
  data() {
    return {};
  },
  components: {
    "label-lng": labellng,
    "edit-textfield": edittextfield,
    confirm: confirmdialog,
    alert: alertdialog,
    "tab-edit-profile-links": profilelinks,
    "tab-edit-profile-images": profileimages,
    "edit-layout": editlayout,
  },
  methods: {
    loaddata: function () {},
    SendToPure: function (lng) {
      var data = {
        lng: lng,
        text:
          lng === "NL"
            ? this.medewerker.Profile.NL
            : this.medewerker.Profile.EN,
      };
      this.$http.post("Edit/ProfiletextToPure", data).then(function () {
        this.$refs.alertdialog.show(
          "Profieltekst " + lng + " verzonden naar Pure.",
          "Profile Text " + lng + " sent to Pure.",
          "Verzonden naar Pure",
          "Sent to Pure"
        );
      });
    },
  },
  created: function () {
    this.loaddata();
  },
  mixins: [infohelper],
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.free-text {
  margin-bottom: 10px;
}
</style>

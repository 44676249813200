<template>
  <div class="PageIndex">
    <div class="row header-balk font-headline">
      <div class="col-md-1"></div>
      <div class="col-md-10">
        <h1>
          <span v-show="$root.lng=='NL'">Medewerkers Universiteit Utrecht</span>
          <span v-show="$root.lng=='EN'">Staff Utrecht University</span>
        </h1>
      </div>
      <div class="col-md-1"></div>
    </div>

    <br />

    <div class="row font-sidebar">
      <div class="col-md-1"></div>
      <div class="col-md-4">
        <div class="row" v-show="$root.lng=='NL'">
          Heeft u niet gevonden wie u zocht? Sommige medewerkers zijn alleen te vinden via het &nbsp;
          <a href="https://intranet.uu.nl">UU-intranet</a> &nbsp; (alleen voor medewerkers).
        </div>
        <div class="row" v-show="$root.lng=='EN'">
          Please keep in mind that some employees can only be found by using search for employee on the &nbsp;
          <a href="https://intranet.uu.nl">UU-intranet</a> &nbsp; (employees only)
        </div>
        <div class="col-md-7"></div>
      </div>
    </div>

    <br />

    <div class="row">
      <div class="col-md-1"></div>
      <div class="col-md-2">
        <label-lng NL="Faculteit/dienst" EN="Faculty/Service Department" />
      </div>

      <div class="col-md-8">
        <select v-model="facultyid" class="form-control" id="fac">
          <option v-for="f in faculties" :value="f.FacultyId" :key="f.Guid">{{ f.Faculty }}</option>
        </select>
      </div>
    </div>

    <br />

    <div class="row font-sidebar">
      <div class="col-md-1"></div>
      <div class="col-md-11">
        <ul v-if="employeelist">
          <div class="letter" v-for="letter in employeelist" :key="letter.Guid">
            <div v-if="letter.Employees && letter.Employees.length > 0">
              <h2>{{ letter.FirstCharacter }}</h2>
              <div v-for="emp in letter.Employees" :key="emp.Guid">
                <li>
                  <a v-bind:href="'/'+emp.Url" target="_blank">{{ emp.Name }}</a>
                </li>
              </div>
            </div>
          </div>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Labellng from "@/components/layout/label-lng.vue";

export default {
  name: "PageIndex",
  data() {
    return {
      facultyid: -1,
      faculties: [],
      employeelist: []
    };
  },
  methods: {
    loaddata: function() {
      this.$http
        .get("Public/GetFaculties?l=" + this.$root.lng)
        .then(function(response) {
          this.faculties = response.data;
          this.faculties.splice(0, 0, { FacultyId: -1, Faculty: "<Alle>" });
        });

      this.loademployees(-1);
    },
    loademployees: function(newfaculty) {
      this.$Progress.start();

      if (newfaculty === -1) {
        this.$http.get("public/GetEmployeeList").then(
          function(response) {
            this.employeelist = response.data;
            this.$Progress.finish();
          },
          function() {
            // Fail
            this.$Progress.finish();
          }
        );
      } else {
        this.$http
          .get("public/GetEmployeeList?f=" + newfaculty.toString())
          .then(
            function(response) {
              this.employeelist = response.data;
              this.$Progress.finish();
            },
            function() {
              // Fail
              this.$Progress.finish();
            }
          );
      }
    }
  },
  components: {
    "label-lng": Labellng
  },
  watch: {
    facultyid: function(newfaculty) {
      this.loademployees(newfaculty);
    }
  },
  created: function() {
    this.loaddata();
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.header-balk {
  background-color: #ffcd00;
  padding-top: 70px;
  padding-bottom: 23px;
  min-height: 50px;
}

.letter {
  padding-top: 10px;
}
</style>

<template>
  <div v-if="medewerker" class="TabEditProfileFocusAreas">
    <div class="row">
      <div class="col-md-12 two-columns">
        <ul class="list-unstyled">
          <li v-for="f in focusareas" :key="f.Guid">
            <input type="checkbox" :id="'FA'+f.Id" :value="f.Id" v-model="medewerker.FocusAreas" />
            <label :for="'FA'+f.Id">{{f.Name}}</label>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "tab-edit-profile-focusareas",
  props: ["medewerker"],
  data() {
    return {
      focusareas: []
    };
  },
  components: {
  },
  methods: {
    loaddata: function() {
      this.$http
        .get("Public/GetFocusAreas?l=" + this.$root.lng)
        .then(function(response) {
          this.focusareas = response.data;
        });
    }
  },
  created: function() {
    this.loaddata();
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
input[type=checkbox] {
  margin-right: 4px;

}
</style>

<template>
  <div class="mobile-header">
    <b-navbar type="light" class="navbar-fixed-top" toggleable="">
      <b-navbar-toggle target="nav_collapse"></b-navbar-toggle>

      <b-navbar-brand :to="{ name: 'mobileSearchNL' }">
        <img src="../../assets/logo.svg" title="Universiteit Utrecht" class="logo" />
      </b-navbar-brand>
      <b-navbar-nav>
        <b-nav-text>
          <div class="searchbutton" :title="$root.lng=='NL'?'Zoek':'Search'" @click="searchclick()">
            <icon name="search" class="searchicon"></icon>
          </div>
          <div class="lng-switch" @click="switchlng()">
            <label-lng NL="EN" EN="NL" />
          </div>
        </b-nav-text>
      </b-navbar-nav>

      <b-collapse is-nav id="nav_collapse" class="nav-collapse">
        <b-navbar-nav>
          <div class="hamburger-home" @click="searchclick()">
            <img src="../../assets/home-mobile.png" title="Home" />
            <label-lng NL="Medewerkers" EN="Employees" />
          </div>
          <b-nav-item
            :href="$root.lng=='NL' ? '/medewerkers/mobiel/organogram' : '/staff/mobile/organisationalchart'"
          >
            <label-lng NL="Organogram" EN="Organisational Chart" />
          </b-nav-item>
          <b-nav-item :href="$root.lng=='NL' ? '/medewerkers/mobiel' : '/staff/mobile'">
            <label-lng NL="Zoeken" EN="Search" />
          </b-nav-item>
          <div class="hamburger-uu-home">
            <div @click="uu_nl()">
              <img src="../../assets/arrow-left-yellow-black.png" title="UU Home" /> UU home
            </div>
          </div>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import labellng from "@/components/layout/label-lng.vue";

export default {
  name: "mobile-header",
  components: {
    "label-lng": labellng
  },
  data() {
    return {};
  },
  methods: {
    searchclick: function() {
      // this.$router.push( {name : "mobileSearch" + this.$root.lng })
      if (this.$root.lng === "NL") window.location = "/medewerkers/mobiel";
      else window.location = "/staff/mobile";
    },
    uu_nl: function() {
      if (this.$root.lng === "NL") window.location = "https://www.uu.nl";
      else window.location = "https://www.uu.nl/en";
    },
    switchlng: function() {
      if (this.$root.lng === "NL") {
        let url = window.location.toString();
        url = url.toLowerCase();
        url = url.replace("/medewerkers", "/staff");
        url = url.replace("/zoek", "/search");
        url = url.replace("/organogram", "/organisationalchart");
        url = url.replace("/mobiel", "/mobile");
        window.location = url;
      }
      if (this.$root.lng === "EN") {
        let url = window.location.toString();
        url = url.toLowerCase();
        url = url.replace("/staff", "/medewerkers");
        url = url.replace("/search", "/zoek");
        url = url.replace("/organisationalchart", "/organogram");
        url = url.replace("/mobile", "/mobiel");
        window.location = url;
      }
    }
  }
};
</script>

<style scoped>
/* De header-bar */
.navbar {
  padding: 0px;
  margin-left: -15px;
  margin-right: -15px;
  padding-left: 5px;
  padding-right: 5px;
  min-height: 50px;
  border-bottom: 1px solid #efefef;
  justify-content: flex-start;
  font-family: "Open Sans";
  font-size: 12px;
}

/* Afstand tussen hamburger en UU-logo */
.navbar-brand {
  margin-left: 20px;
}

/* Afbeelding UU-logo */
.logo {
  width: 150px;
}

/* Taalswitch (NL/EN) met streep links */
.lng-switch {
  display: inline-block;
  height: 50px;
  line-height: 50px;
  text-align: center;
  border-left: 1px solid #efefef;
  width: 40px;
  color: #3b3b3b;
}

.navbar-text {
  padding-top: 0px;
  padding-bottom: 0px;
}

/* Zwart zoekblokje met loepje */
.searchbutton {
  height: 40px;
  width: 40px;
  line-height: 40px;
  text-align: center;
  display: inline-block;
  cursor: pointer;
  background-color: #000000;
  color: #ffffff;
  margin-right: 5px;
}

/* Positionering loepje binnen blokje. */
.searchicon {
  position: relative;
  top: 2px;
}

/* Hamburger-menu-knop */
.navbar-toggler {
  border: 0px;
  width: 36px;
  height: 41px;
  padding: 0px;
  margin: 0px;
}

/* Dit zorgt er voor dat taalswitch en zoekicoon rechts uitgelijnd worden */
.navbar .navbar-nav {
  margin-left: auto;
}

/* Bovenste item in hamburger-menu (rood font) */
.hamburger-home {
  color: #c00a35;
  background-color: #fff;
  height: 70px;
  line-height: 70px;
  font-weight: 400;
  font-size: 20px;
}

/* Icoon met huisje bovenaan hamburger-menu */
.hamburger-home > img {
  position: relative;
  top: -3px;
  margin-right: 2px;
}

/* Overkoepelende div hamburger-menu*/
.nav-collapse {
  padding-left: 10px;
}

/* Item in hamburger-menu */
.nav-item {
  background-color: #000;
  margin-left: -15px;
  margin-right: -5px;
  padding-left: 15px;
  font-size: 16px;
  font-weight: 400;
  border-bottom: 1px solid #fff;
  padding-top: 10px;
  padding-bottom: 10px;
}

/* Onderste hamburge-menu-item (home-uu) */
.hamburger-uu-home {
  background-color: #000;
  color: #fff;
  margin-left: -15px;
  margin-right: -5px;
  padding-left: 15px;
  padding-top: 20px;
  min-height: 1000px;
  font-size: 16px;
}

.hamburger-uu-home img {
  margin-right: 4px;
}
</style>

<style>
/* Hamburger-menu (plaatje afhankelijk van opengeklapt/dichtgeklapt */
.mobile-header
  .navbar-light
  > .navbar-toggler[aria-expanded="false"]
  > .navbar-toggler-icon {
  background-image: url("../../assets/menu-white.png");
}
.mobile-header
  .navbar-light
  > .navbar-toggler[aria-expanded="true"]
  > .navbar-toggler-icon {
  background-image: url("../../assets/menu-white-open.png");
}

/* Afmeting van hamburger */
.mobile-header .navbar-light > .navbar-toggler > .navbar-toggler-icon {
  width: 36px;
  height: 41px;
}

/* Tekstkleur van hamburgen-menu items moet in globale styling.*/
.mobile-header .nav-link > span > span {
  color: #fff !important;
}
</style>

<template>
  <span>
    <span v-if="$root.lng=='NL'">{{NL}}</span>
    <span v-if="$root.lng=='EN'">{{EN}}</span>
  </span>
</template>
<script>
export default {
  name: 'label-lng',
  data () {
    return {}
  },
  props: ['NL', 'EN']
};
</script>

<template>
  <div v-if="medewerker" class="TabEditProfileImages">
    <confirm ref="confirmdialog"></confirm>

    <div class="row">
      <div class="col-md-12">
        <table class="table table-sm table-bordered">
          <thead>
            <tr>
              <th>
                <label-lng NL="Bestand" EN="File" />
              </th>
              <th>
                <label-lng NL="Omschrijving (Nederlands)" EN="Description (Dutch)" />
              </th>
              <th>
                <label-lng NL="Omschrijving (Engels)" EN="Description (English)" />
              </th>
              <th class="iconcolumn"></th>
              <th class="iconcolumn"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="img in medewerker.Images" :key="img.Guid">
              <td>{{img.Filename}}</td>
              <td>{{img.DescriptionNL}}</td>
              <td>{{img.DescriptionEN}}</td>
              <td>
                <iconbutton
                  icon="chevron-up"
                  titleNL="Hoger"
                  titleEN="Up"
                  @click="up(img)"
                  class="up"
                />&nbsp;<iconbutton
                  icon="chevron-down"
                  titleNL="Lager"
                  titleEN="Down"
                  @click="down(img)"
                  class="down"
                />
              </td>
              <td>
                <iconbutton
                  icon="pencil-alt"
                  titleNL="Bewerken"
                  titleEN="Edit"
                  @click="editImage(img)"
                />&nbsp;<iconbutton
                  icon="trash"
                  titleNL="Verwijderen"
                  titleEN="Delete"
                  @click="deleteImage(img)"
                />
              </td>
            </tr>
          </tbody>
        </table>

        <button class="uu-button button-black" v-on:click="addImage()" variant="link">
          <label-lng NL="Afbeelding toevoegen" EN="Add image" />
        </button>
      </div>
    </div>

    <popup
      ref="popupImage"
      @okclick="beforeSaveImage()"
      titleNL="Afbeelding"
      titleEN="Image"
    >
      <form slot-scope="popup">
        <div v-if="popup.data != null">
          <edit-layout-popup
            nameNL="Afbeelding"
            nameEN="Image"
            :info="GetInfo(info, 'Profiel.Afbeelding.Upload')"
          >
            <b-form-file
              id="imginput"
              v-model="imagefile"
              required
              accept=".png, .jpg"
              placeholder="Geen afbeelding gekozen"
              choose-label="Selecteer"
              class="form-control"
            ></b-form-file>

            <!-- Onderstaande werkt niet omdat je niet een "popup" binnen een popup mag hebben -->
            <!-- <label class="form-control" > {{ popup.data.Filename }}</label>
          <input class="form-control" id="inpFile" type="file" style="display:none" v-on:change="onFileChoose($event)" accept=".png, .jpg"/>
          <div class="form-group" >
            <button id="btnFile" class="form-control" onclick="document.getElementById('inpFile').click()"><label-lng NL="Selecteer" EN="Select" /></button>
          </div class="form-group">
            -->
          </edit-layout-popup>

          <edit-layout-popup
            nameNL="Omschrijving (NL)"
            nameEN="Description (NL)"
            :info="GetInfo(info, 'Profiel.Afbeelding.OmschrijvingNL')"
          >
            <input v-model="popup.data.DescriptionNL" id="imgdescrnl" class="form-control" />
          </edit-layout-popup>

          <edit-layout-popup
            nameNL="Omschrijving (EN)"
            nameEN="Description (EN)"
            :info="GetInfo(info, 'Profiel.Afbeelding.OmschrijvingEN')"
          >
            <input v-model="popup.data.DescriptionEN" id="imgdescren" class="form-control" />
          </edit-layout-popup>
        </div>
      </form>
    </popup>
  </div>
</template>

<script>
import labellng from "@/components/layout/label-lng.vue";
import confirmdialog from "@/components/layout/confirm.vue";
import popup from "@/components/layout/popup.vue";
import iconbutton from "@/components/layout/iconbutton.vue";
import sorting from "@/helpers/sorting.vue";
import editlayoutpopup from "@/components/layout/edit-layout-popup.vue";
import infohelper from "@/helpers/info-helper.vue";

export default {
  name: "tab-edit-profile-images",
  props: ["medewerker", "info"],
  data() {
    return {
      imagefile: null,
      imgindex: null
    };
  },
  components: {
    "label-lng": labellng,
    confirm: confirmdialog,
    popup: popup,
    iconbutton: iconbutton,
    "edit-layout-popup": editlayoutpopup
  },
  methods: {
    addImage: function() {
      this.imagefile = null;
      this.imgindex = null;
      var maxorderindex = 0;
      for (var i = 0; i < this.medewerker.Images.length; i++) {
        if (this.medewerker.Images[i].OrderIndex > maxorderindex) {
          maxorderindex = this.medewerker.Images[i].OrderIndex;
        }
      }
      this.$refs.popupImage.show(
        { OrderIndex: maxorderindex + 1, Filename: "" },
        this.medewerker.Images,
        this
      );
    },
    editImage: function(img) {
      this.imagefile = null; // { name : img.Filename };
      this.imgindex = this.medewerker.Images.indexOf(img);
      this.$refs.popupImage.show(img, this.medewerker.Images, this);
    },
    deleteImage: function(img) {
      var mdw = this.medewerker;
      this.$refs.confirmdialog.confirm(
        "Afbeelding verwijderen?",
        "Delete image?",
        function() {
          var i = mdw.Images.indexOf(img);
          if (i !== -1) mdw.Images.splice(i, 1);
        }
      );
    },
    onFileChoose: function(event) {
      var files = event.target.files || event.dataTransfer.files;
      if (files.length) this.imagefile = files[0];
    },
    beforeSaveImage: function() {
      var self = this;

      if (self.imagefile) {
        var data = self.$refs.popupImage.getData();
        var reader = new FileReader();

        reader.addEventListener(
          "load",
          function() {
            var contents = JSON.stringify(reader.result);

            self.$http
              .post("Edit/SaveFile", {
                Filename: self.imagefile.name,
                Content: contents,
                EmployeeId: self.medewerker.Id,
                Filetype: "Img"
              })
              .then(
                function(response) {
                  if (response.data === "") {
                    alert(
                      "Maximale grootte voor bestand is 5 MB. / Maxium file size is 5 MB."
                    );
                    return;
                  }
                  data.Filename = self.imagefile.name;
                  data.Code = response.data;
                  if (this.imgindex != null && this.imgindex !== -1) {
                    self.medewerker.Images[this.imgindex].Code = response.data;
                    self.medewerker.Images[this.imgindex].Filename =
                      self.imagefile.name;
                  }
                },
                function() {
                  // TODO: Hier nog iets doen ?
                }
              );
          },
          false
        );

        reader.readAsDataURL(self.imagefile);
      }
    },
    // Sortering
    up: function(img) {
      this.sortup(img, this.medewerker.Images);
    },
    down: function(img) {
      this.sortdown(img, this.medewerker.Images);
    }
  },
  mixins: [sorting, infohelper]
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
table {
  table-layout: fixed;
}
td {
  overflow: hidden;
}

.iconcolumn {
  width: 50px;
}
</style>

<template>
  <div class="mobile">
    <!-- Header -->
    <mobile-header />

    <!-- Mobiele pagina -->
    <div v-if="medewerker">
      <!-- Foto en naam -->
      <div class="row gele-balk">
        <div class="col-3">
          <div class="profielfoto">
            <img
              :src="$http.options.root + medewerker.PhotoUrl + token"
              :title="medewerker.Name"
              class="img-fluid"
              v-if="medewerker.PhotoUrl"
            />
          </div>
        </div>
        <div class="col-9 name-container" >{{ medewerker.Name }}
          <div v-if="medewerker.LinksSocialMedia && medewerker.LinksSocialMedia.length > 0" class="headerlinks">
            <span v-for="l in medewerker.LinksSocialMedia" :key="l.Guid" class="smicon">
                  <a :href="l.Url" target="_blank" :title="l.Url"
                    ><img :src="l.IconUrl"
                  /></a>
            </span>
          </div>
        </div>
      </div>

      <!-- Contact -->
      <b-row class="Mobile-Block Grijs Mobile-font" v-if="(medewerker.Email && medewerker.Email != '') || (medewerker.ContactDetails && medewerker.ContactDetails[0] && medewerker.ContactDetails[0].Phone != null || medewerker.ContactDetails[0].PhoneDepartment != null)">
        <b-col sm="12">
          <span class="Caption">Contact</span>
          <!-- Emailadres -->
          <div
            v-if="medewerker.Email && medewerker.Email != ''"
            class="mobile-item"
          >
            <span class="icon">
              <icon name="envelope" scale="0.9" />
            </span>
            <a :href="'mailto:' + medewerker.Email" class="mobile-link">{{
              medewerker.Email
            }}</a>
          </div>
          <!-- Primaire telefoonnummer -->
          <div
            v-if="medewerker.ContactDetails && medewerker.ContactDetails[0]"
            class="mobile-item"
          >
            <span class="icon">
              <icon name="phone" scale="0.9" v-show="medewerker.ContactDetails[0].Phone != null || medewerker.ContactDetails[0].PhoneDepartment != null" />
            </span>
            <label-tel-lng NL EN :tel="medewerker.ContactDetails[0].Phone" />
            <label-tel-lng
              v-if="!medewerker.ContactDetails[0].Phone"
              NL
              EN
              :tel="medewerker.ContactDetails[0].PhoneDepartment"
              class="mobile-telnr"
            />
          </div>
        </b-col>
      </b-row>

      <div class="row Mobile-Block Wit Mobile-font">
        <div class="col-12">
          <div
            v-if="medewerker.Positions.length > 0 && medewerker.Positions[0]"
          >
            <span class="Caption">{{ medewerker.Positions[0].Position }}</span>
            <div>
              <a :href="medewerker.Positions[0].Level1Url" target="_blank">{{
                medewerker.Positions[0].Level1
              }}</a>
            </div>
            <div>
              <a :href="medewerker.Positions[0].Level2Url" target="_blank">{{
                medewerker.Positions[0].Level2
              }}</a>
            </div>
          </div>
        </div>
      </div>

      <!-- Locatie -->
      <div class="row Mobile-Block Grijs Mobile-font" v-show="medewerker.ContactDetails && medewerker.ContactDetails[0] && (medewerker.ContactDetails[0].Address || medewerker.ContactDetails[0].Building || medewerker.ContactDetails[0].Room)">
        <div class="col-12">
          <span class="Caption">
            <label-lng NL="Locatie" EN="Location" />
          </span>
          <!-- Hoofd locatie -->
          <div
            v-if="medewerker.ContactDetails && medewerker.ContactDetails[0]"
            class="LocatieBlok"
          >
            <!-- Punaise van locatie -->
            <span>
              <a :href="medewerker.ContactDetails[0].Url" target="_blank">
                <img src="../../assets/thumbtack.png" class="icon locicon" /><br/>
                <img src="../../assets/pin.png" class="icon locicon" v-show="medewerker.ContactDetails[0].Address || medewerker.ContactDetails[0].Building"/>
              </a>
            </span>
            <span>
              <!-- De primare locatie  -->
              <div>
                <a
                  :href="medewerker.ContactDetails[0].Url"
                  v-if="medewerker.ContactDetails[0].Url"
                  target="_blank"
                  class="mobile-link"
                  >{{ medewerker.ContactDetails[0].Building }}</a
                >
                <span v-if="!medewerker.ContactDetails[0].Url">{{
                  medewerker.ContactDetails[0].Building
                }}</span>
              </div>
              <div>{{ medewerker.ContactDetails[0].Address }}</div>
              <div>
                <label-lng NL="Kamer" EN="Room" v-show="medewerker.ContactDetails[0].Room && medewerker.ContactDetails[0].Room != ''" />
                {{ medewerker.ContactDetails[0].Room }}
              </div>
              <div>
                {{ medewerker.ContactDetails[0].Zipcode }}
                {{ medewerker.ContactDetails[0].City }}
              </div>
            </span>
          </div>
        </div>
      </div>

      <div
        class="row Mobile-Block Wit Mobile-font"
        v-show="medewerker.Availability"
      >
        <div class="col-12">
          <span class="Caption">
            <label-lng NL="Werkdagen" EN="Workdays" />
          </span>
          <table
            class="table table-condensed table-bordered"
            v-if="medewerker.Availability && medewerker.Availability != ''"
          >
            <thead>
              <tr>
                <th></th>
                <th>
                  <label-lng NL="Ma" EN="Mo" />
                </th>
                <th>
                  <label-lng NL="Di" EN="Tu" />
                </th>
                <th>
                  <label-lng NL="Wo" EN="We" />
                </th>
                <th>
                  <label-lng NL="Do" EN="Th" />
                </th>
                <th>
                  <label-lng NL="Vr" EN="Fr" />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>
                  <label-lng NL="Ochtend" EN="Morning" />
                </th>
                <td>
                  <img
                    v-show="medewerker.Availability.indexOf('MaO') != -1"
                    src="../../assets/check.png"
                  />
                </td>
                <td>
                  <img
                    v-show="medewerker.Availability.indexOf('DiO') != -1"
                    src="../../assets/check.png"
                  />
                </td>
                <td>
                  <img
                    v-show="medewerker.Availability.indexOf('WoO') != -1"
                    src="../../assets/check.png"
                  />
                </td>
                <td>
                  <img
                    v-show="medewerker.Availability.indexOf('DoO') != -1"
                    src="../../assets/check.png"
                  />
                </td>
                <td>
                  <img
                    v-show="medewerker.Availability.indexOf('VrO') != -1"
                    src="../../assets/check.png"
                  />
                </td>
              </tr>
              <tr>
                <th>
                  <label-lng NL="Middag" EN="Aftern." />
                </th>
                <td>
                  <img
                    v-show="medewerker.Availability.indexOf('MaM') != -1"
                    src="../../assets/check.png"
                  />
                </td>
                <td>
                  <img
                    v-show="medewerker.Availability.indexOf('DiM') != -1"
                    src="../../assets/check.png"
                  />
                </td>
                <td>
                  <img
                    v-show="medewerker.Availability.indexOf('WoM') != -1"
                    src="../../assets/check.png"
                  />
                </td>
                <td>
                  <img
                    v-show="medewerker.Availability.indexOf('DoM') != -1"
                    src="../../assets/check.png"
                  />
                </td>
                <td>
                  <img
                    v-show="medewerker.Availability.indexOf('VrM') != -1"
                    src="../../assets/check.png"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="row Mobile-Block Grijs Mobile-font">
        <div class="col-12">
          <div v-if="medewerker.Expertises && medewerker.Expertises.length > 0">
            <span class="Caption">
              <label-lng NL="Expertises" EN="Areas of expertise" />
            </span>
            <ul>
              <li v-for="e in medewerker.Expertises" :key="e.Guid">
                <a :href="e.Url" target="_blank">{{ e.Name }}</a>
              </li>
            </ul>
          </div>
          <div v-if="medewerker.Skills && medewerker.Skills.length > 0">
            <span class="Caption">
              <label-lng NL="Vaardigheden" EN="Skills" />
            </span>
            <ul>
              <li v-for="s in medewerker.Skills" :key="s.Guid">
                <a :href="s.Url" target="_blank">{{ s.Name }}</a>
              </li>
            </ul>
          </div>
          <div v-if="medewerker.FocusAreas && medewerker.FocusAreas.length > 0">
            <div class="Caption">
              <label-lng NL="Onderzoeksthema's" EN="Research areas" />
            </div>
            <ul>
              <li v-for="fa in medewerker.FocusAreas" :key="fa.Guid">
                <a :href="fa.Url">{{ fa.Name }}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <!-- Tabs met volledige PP-inhoud -->
      <!-- Profiel -->
      <div
        class="row Mobile-font mobile-tab first"
        v-if="medewerker.Tabs.Profile.Visible"
      >
        <div class="col-12">
          <div @click="tabclick('profiel')" class="tab-header">
            <img
              v-show="activetab != 'profiel'"
              src="../../assets/mobile-expand.png"
            />
            <img
              v-show="activetab == 'profiel'"
              src="../../assets/mobile-collapse.png"
            />
            {{ medewerker.Tabs.Profile.Title }}
          </div>
          <div v-show="activetab == 'profiel'" class="tab-content">
            <div class="field-box">
              <span v-html="medewerker.Profile"></span>
            </div>
            <div
              v-show="
                medewerker.StudyProgrammes &&
                  medewerker.StudyProgrammes.length > 0
              "
            >
              <div class="field-header">
                <label-lng
                  NL="Betrokken bij opleiding(en)"
                  EN="Involved in the following study programme(s)"
                />
              </div>
              <div class="field-box">
                <ul>
                  <li v-for="sp in medewerker.StudyProgrammes" :key="sp.Guid">
                    {{ sp.Name }} {{ sp.Addition }}
                  </li>
                </ul>
              </div>
            </div>
            <div v-show="medewerker.Links && medewerker.Links.length > 0">
              <div class="field-header">
                <label-lng NL="Links" EN="Links" />
              </div>
              <div class="field-box">
                <ul>
                  <li v-for="l in medewerker.Links" :key="l.Guid">
                    <a
                      :href="l.Url"
                      target="_blank"
                      :title="l.Url"
                      class="external"
                      >{{ l.Name }}</a
                    >
                  </li>
                </ul>
              </div>
            </div>
            <div v-show="medewerker.Chair">
              <div class="field-header">
                <label-lng NL="Leeropdracht" EN="Chair" />
              </div>
              <div class="field-box">{{ medewerker.Chair }}</div>
            </div>
            <div v-show="medewerker.EndowedBy">
              <div class="field-header">
                <label-lng NL="Leerstoel van" EN="Endowed by" />
              </div>
              <div class="field-box">{{ medewerker.EndowedBy }}</div>
            </div>
            <div v-show="medewerker.DateOfAppointment">
              <div class="field-header">
                <label-lng NL="Aanstellingsdatum" EN="Date of Appointment" />
              </div>
              <div class="field-box">
                {{ formatdate(medewerker.DateOfAppointment) }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- CV -->
      <div class="row Mobile-font mobile-tab" v-if="medewerker.Tabs.CV.Visible">
        <div class="col-12">
          <div @click="tabclick('cv')" class="tab-header">
            <img
              v-show="activetab != 'cv'"
              src="../../assets/mobile-expand.png"
            />
            <img
              v-show="activetab == 'cv'"
              src="../../assets/mobile-collapse.png"
            />
            {{ medewerker.Tabs.CV.Title }}
          </div>
          <div v-show="activetab == 'cv'" class="tab-content">
            <div class="field-box">
              <span v-html="medewerker.CV"></span>
            </div>
            <a
              :href="$http.options.root + medewerker.CVUrl + token"
              target="_blank"
              v-if="medewerker.CVUrl"
            >
              <button class="uu-button button-black">
                <label-lng NL="Download CV" EN="Download CV" />
              </button>
            </a>
          </div>
        </div>
      </div>
      <!-- In de Media -->
      <div
        class="row Mobile-font mobile-tab publications"
        v-if="medewerker.Tabs.Media.Visible"
      >
        <div class="col-12">
          <div @click="tabclick('media')" class="tab-header">
            <img
              v-show="activetab != 'media'"
              src="../../assets/mobile-expand.png"
            />
            <img
              v-show="activetab == 'media'"
              src="../../assets/mobile-collapse.png"
            />
            {{ medewerker.Tabs.Media.Title }}
          </div>
          <div v-show="activetab == 'media'" class="tab-content">
            <div class="field-box" v-show="publicationsLoading">
              Media laden....
            </div>

            <div class="field-box" v-if="medewerker.MediaFreeText">
              <div v-html="medewerker.MediaFreeText" />
            </div>
            <div
              v-for="grp in medewerker.Media"
              class="publicationgroup"
              :key="grp.Guid"
            >
              <div class="field-header" v-show="grp.Groupname != ''">
                <h2>{{ grp.Groupname }}</h2>
              </div>
              <div class="field-box">
                <div
                  v-for="pub in grp.Publications"
                  v-html="pub"
                  :key="pub.Guid"
                  class="pub-item"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Prijzen  -->
      <div
        class="row Mobile-font mobile-tab publications"
        v-if="medewerker.Tabs.Prizes.Visible"
      >
        <div class="col-12">
          <div @click="tabclick('prizes')" class="tab-header">
            <img
              v-show="activetab != 'prizes'"
              src="../../assets/mobile-expand.png"
            />
            <img
              v-show="activetab == 'prizes'"
              src="../../assets/mobile-collapse.png"
            />
            {{ medewerker.Tabs.Prizes.Title }}
          </div>
          <div v-show="activetab == 'prizes'" class="tab-content">
            <div class="field-box" v-show="publicationsLoading">
              Prijzen laden....
            </div>

            <div class="field-box" v-if="medewerker.PrizesFreeText">
              <div v-html="medewerker.PrizesFreeText" />
            </div>
            <div
              v-for="grp in medewerker.Prizes"
              class="publicationgroup"
              :key="grp.Guid"
            >
              <div class="field-header" v-show="grp.Groupname != ''">
                <h2>{{ grp.Groupname }}</h2>
              </div>
              <div class="field-box">
                <div
                  v-for="pub in grp.Publications"
                  v-html="pub"
                  :key="pub.Guid"
                  class="pub-item"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Publicaties -->
      <div
        class="row Mobile-font mobile-tab publications"
        v-if="medewerker.Tabs.Publications.Visible"
      >
        <div class="col-12">
          <div @click="tabclick('pub')" class="tab-header">
            <img
              v-show="activetab != 'pub'"
              src="../../assets/mobile-expand.png"
            />
            <img
              v-show="activetab == 'pub'"
              src="../../assets/mobile-collapse.png"
            />
            {{ medewerker.Tabs.Publications.Title }}
          </div>
          <div v-show="activetab == 'pub'" class="tab-content">
            <div class="field-box" v-show="publicationsLoading">
              Publicaties laden....
            </div>
            <div class="field-box" v-if="medewerker.PublicationsFreeTop">
              <div v-html="medewerker.PublicationsFreeTop" />
            </div>
            <div
              v-for="grp in medewerker.Publications"
              class="publicationgroup"
              :key="grp.Guid"
            >
              <div class="field-header" v-show="grp.Groupname != ''">
                <h2>{{ grp.Groupname }}</h2>
              </div>
              <div class="field-box">
                <div
                  v-for="pub in grp.Publications"
                  v-html="pub"
                  :key="pub.Guid"
                  class="pub-item"
                ></div>
              </div>
            </div>

            <div
              class="field-box extra-field-margin"
              v-if="medewerker.PublicationsFreeBottom"
            >
              <div v-html="medewerker.PublicationsFreeBottom" />
            </div>
          </div>
        </div>
      </div>
      <!-- Activities -->
      <div
        class="row Mobile-font mobile-tab publications"
        v-if="medewerker.Tabs.Activities.Visible"
      >
        <div class="col-12">
          <div @click="tabclick('activities')" class="tab-header">
            <img
              v-show="activetab != 'activities'"
              src="../../assets/mobile-expand.png"
            />
            <img
              v-show="activetab == 'activities'"
              src="../../assets/mobile-collapse.png"
            />
            {{ medewerker.Tabs.Activities.Title }}
          </div>
          <div v-show="activetab == 'activities'" class="tab-content">
            <div class="field-box" v-show="publicationsLoading">
              Activiteiten laden....
            </div>

            <div class="field-box" v-if="medewerker.ActivitiesFreeText">
              <div v-html="medewerker.ActivitiesFreeText" />
            </div>
            <div
              v-for="grp in medewerker.Activities"
              class="publicationgroup"
              :key="grp.Guid"
            >
              <div class="field-header" v-show="grp.Groupname != ''">
                <h2>{{ grp.Groupname }}</h2>
              </div>
              <div class="field-box">
                <div
                  v-for="pub in grp.Publications"
                  v-html="pub"
                  :key="pub.Guid"
                  class="pub-item"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Onderzoek -->
      <div
        class="row Mobile-font mobile-tab"
        v-if="medewerker.Tabs.Research.Visible"
      >
        <div class="col-12">
          <div @click="tabclick('research')" class="tab-header">
            <img
              v-show="activetab != 'research'"
              src="../../assets/mobile-expand.png"
            />
            <img
              v-show="activetab == 'research'"
              src="../../assets/mobile-collapse.png"
            />
            {{ medewerker.Tabs.Research.Title }}
          </div>
          <div v-show="activetab == 'research'" class="tab-content">
            <div class="field-box">
              <span v-html="medewerker.ProjectsFreeTextTop"></span>
            </div>

            <div class="field-header">
              <label-lng
                NL="Projecten"
                EN="Projects"
                v-if="medewerker.Projects && medewerker.Projects.length > 0"
              />
            </div>
            <div v-for="p in medewerker.Projects" :key="p.Guid">
              <div class="field-header">
                {{ p.Title }}
                <span class="project-timespan">
                  <span v-if="p.Start">{{ formatdate(p.Start) }}</span>
                  <span v-if="p.End">
                    <label-lng NL="tot" EN="to" />
                    {{ formatdate(p.End) }}
                  </span>
                </span>
              </div>
              <div v-if="p.DescriptionGeneral">
                <div class="field-header">
                  <label-lng
                    NL="Algemene projectbeschrijving"
                    EN="General project description"
                  />
                </div>
                <div class="field-box">
                  <span v-html="p.DescriptionGeneral"></span>
                </div>
              </div>
              <div v-if="p.Role">
                <div class="field-header">
                  <label-lng NL="Rol" EN="Role" />
                </div>
                <div class="field-box">
                  <span v-html="p.Role"></span>
                </div>
              </div>
              <div v-if="p.DescriptionIndividual">
                <div class="field-header">
                  <label-lng
                    NL="Individuele projectbeschrijving"
                    EN="Individual project description"
                  />
                </div>
                <div class="field-box">
                  <span v-html="p.DescriptionIndividual"></span>
                </div>
              </div>
              <div v-if="p.Funding">
                <div class="field-header">
                  <label-lng NL="Financiering" EN="Funding" />
                </div>
                <div class="field-box">
                  {{ p.Funding }} {{ p.FundingDescription }}
                </div>
              </div>
              <div v-if="p.UUMembers && p.UUMembers.length > 0">
                <div class="field-header">
                  <label-lng NL="Projectleden" EN="Project members UU" />
                </div>
                <div class="field-box">
                  <ul>
                    <li v-for="l in p.UUMembers" :key="l.Guid">
                      <a :href="l.Url" target="_blank">{{ l.Name }}</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div v-if="p.NonUUMembers && p.NonUUMembers.length > 0">
                <div class="field-header">
                  <label-lng
                    NL="Overige projectleden"
                    EN="External project members"
                  />
                </div>
                <div class="field-box">
                  <ul>
                    <li v-for="l in p.UUMembers" :key="l.Guid">
                      <a :href="l.Url" target="_blank">{{ l.Name }}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="field-header">
              <label-lng
                NL="Afgesloten projecten"
                EN="Completed Projects"
                v-if="
                  medewerker.ProjectsCompleted &&
                    medewerker.ProjectsCompleted.length > 0
                "
              />
            </div>
            <div v-for="p in medewerker.ProjectsCompleted" :key="p.Guid">
              <div class="field-header">
                {{ p.Title }}
                <span class="project-timespan">
                  <span v-if="p.Start">{{ formatdate(p.Start) }}</span>
                  <span v-if="p.End">
                    <label-lng NL="tot" EN="to" />
                    {{ formatdate(p.End) }}
                  </span>
                </span>
              </div>
              <div v-if="p.DescriptionGeneral">
                <div class="field-header">
                  <label-lng
                    NL="Algemene projectbeschrijving"
                    EN="General project description"
                  />
                </div>
                <div class="field-box">
                  <span v-html="p.DescriptionGeneral"></span>
                </div>
              </div>
              <div v-if="p.Role">
                <div class="field-header">
                  <label-lng NL="Rol" EN="Role" />
                </div>
                <div class="field-box">
                  <span v-html="p.Role"></span>
                </div>
              </div>
              <div v-if="p.DescriptionIndividual">
                <div class="field-header">
                  <label-lng
                    NL="Individuele projectbeschrijving"
                    EN="Individual project description"
                  />
                </div>
                <div class="field-box">
                  <span v-html="p.DescriptionIndividual"></span>
                </div>
              </div>
              <div v-if="p.Funding">
                <div class="field-header">
                  <label-lng NL="Financiering" EN="Funding" />
                </div>
                <div class="field-box">
                  {{ p.Funding }} {{ p.FundingDescription }}
                </div>
              </div>
              <div v-if="p.UUMembers && p.UUMembers.length > 0">
                <div class="field-header">
                  <label-lng NL="Projectleden" EN="Project members UU" />
                </div>
                <div class="field-box">
                  <ul>
                    <li v-for="l in p.UUMembers" :key="l.Guid">
                      <a :href="l.Url" target="_blank">{{ l.Name }}</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div v-if="p.NonUUMembers && p.NonUUMembers.length > 0">
                <div class="field-header">
                  <label-lng
                    NL="Overige projectleden"
                    EN="External project members"
                  />
                </div>
                <div class="field-box">
                  <ul>
                    <li v-for="l in p.UUMembers" :key="l.Guid">
                      <a :href="l.Url" target="_blank">{{ l.Name }}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="field-box extra-field-margin">
              <span v-html="medewerker.ProjectsFreeTextBottom"></span>
            </div>
          </div>
        </div>
      </div>
      <!-- Onderwijs -->
      <div
        class="row Mobile-font mobile-tab"
        v-if="medewerker.Tabs.Courses.Visible"
      >
        <div class="col-12">
          <div @click="tabclick('courses')" class="tab-header">
            <img
              v-show="activetab != 'courses'"
              src="../../assets/mobile-expand.png"
            />
            <img
              v-show="activetab == 'courses'"
              src="../../assets/mobile-collapse.png"
            />
            {{ medewerker.Tabs.Courses.Title }}
          </div>
          <div v-show="activetab == 'courses'" class="tab-content">
            <div class="field-header">
              <span
                v-show="
                  $root.lng == 'NL' &&
                    medewerker.Courses &&
                    medewerker.Courses.length > 0
                "
                >Op dit moment verzorgt {{ medewerker.Name }} de volgende
                cursus(sen):</span
              >
              <span
                v-show="
                  $root.lng == 'EN' &&
                    medewerker.Courses &&
                    medewerker.Courses.length > 0
                "
                >Currently {{ medewerker.Name }} teaches the following
                course(s):</span
              >
            </div>
            <div v-for="c in medewerker.Courses" :key="c.Guid">
              <div class="field-header">
                <span v-if="c.Url == null || c.Url == ''">{{ c.Name }}</span>
                <a
                  :href="c.Url"
                  target="_blank"
                  v-if="c.Url != null && c.Url != ''"
                  >{{ c.Name }}</a
                >
              </div>
              <div class="field-box">{{ c.Code }}</div>
              <div class="field-box">
                <label-lng v-show="c.Type == 'V'" NL="Voltijd" EN="Fulltime" />
                <label-lng
                  v-show="c.Type == 'D'"
                  NL="Deeltijd"
                  EN="Parttime"
                />-
                <label-lng NL="niveau" EN="level" />
                {{ c.Level }} - ECTS {{ c.ECTS }}
              </div>
            </div>

            <div class="field-box">
              <span v-html="medewerker.CoursesFreeText"></span>
            </div>
          </div>
        </div>
      </div>
      <!-- Nevenfuncties -->
      <div
        class="row Mobile-font mobile-tab"
        v-if="medewerker.Tabs.AdditionalFunctions.Visible"
      >
        <div class="col-12">
          <div @click="tabclick('af')" class="tab-header">
            <img
              v-show="activetab != 'af'"
              src="../../assets/mobile-expand.png"
            />
            <img
              v-show="activetab == 'af'"
              src="../../assets/mobile-collapse.png"
            />
            {{ medewerker.Tabs.AdditionalFunctions.Title }}
          </div>
          <div v-show="activetab == 'af'" class="tab-content">
            <div class="field-box">
              <span v-html="medewerker.AdditionalFunctions"></span>
            </div>
          </div>
        </div>
      </div>
      <!-- Vrije tabs -->
      <div
        class="row Mobile-font mobile-tab"
        v-if="medewerker.Tabs.CustomTab1.Visible"
      >
        <div class="col-12">
          <div @click="tabclick('c1')" class="tab-header">
            <img
              v-show="activetab != 'c1'"
              src="../../assets/mobile-expand.png"
            />
            <img
              v-show="activetab == 'c1'"
              src="../../assets/mobile-collapse.png"
            />
            {{ medewerker.Tabs.CustomTab1.Title }}
          </div>
          <div v-show="activetab == 'c1'" class="tab-content">
            <div class="field-box">
              <span v-html="medewerker.CustomTab1"></span>
            </div>
          </div>
        </div>
      </div>
      <div
        class="row Mobile-font mobile-tab"
        v-if="medewerker.Tabs.CustomTab2.Visible"
      >
        <div class="col-12">
          <div @click="tabclick('c2')" class="tab-header">
            <img
              v-show="activetab != 'c2'"
              src="../../assets/mobile-expand.png"
            />
            <img
              v-show="activetab == 'c2'"
              src="../../assets/mobile-collapse.png"
            />
            {{ medewerker.Tabs.CustomTab2.Title }}
          </div>
          <div v-show="activetab == 'c2'" class="tab-content">
            <div class="field-box">
              <span v-html="medewerker.CustomTab2"></span>
            </div>
          </div>
        </div>
      </div>
      <div
        class="row Mobile-font mobile-tab"
        v-if="medewerker.Tabs.CustomTab3.Visible"
      >
        <div class="col-12">
          <div @click="tabclick('c3')" class="tab-header">
            <img
              v-show="activetab != 'c3'"
              src="../../assets/mobile-expand.png"
            />
            <img
              v-show="activetab == 'c3'"
              src="../../assets/mobile-collapse.png"
            />
            {{ medewerker.Tabs.CustomTab3.Title }}
          </div>
          <div v-show="activetab == 'c3'" class="tab-content">
            <div class="field-box">
              <span v-html="medewerker.CustomTab3"></span>
            </div>
          </div>
        </div>
      </div>

      <!-- Contact -->
      <div
        class="row Mobile-font mobile-tab"
        v-if="medewerker.Tabs.Contact.Visible"
      >
        <div class="col-12">
          <div @click="tabclick('contact')" class="tab-header">
            <img
              v-show="activetab != 'contact'"
              src="../../assets/mobile-expand.png"
            />
            <img
              v-show="activetab == 'contact'"
              src="../../assets/mobile-collapse.png"
            />
            {{ medewerker.Tabs.Contact.Title }}
          </div>
          <div v-show="activetab == 'contact'" class="tab-content">
            <div
              class="field-header"
              v-show="
                medewerker.ContactDetails &&
                  medewerker.ContactDetails.length > 0
              "
            >
              <label-lng NL="Contactgegevens" EN="Contact details" />
            </div>
            <div
              v-for="contact in medewerker.ContactDetails"
              :key="contact.Guid"
            >
              <div class="field-box">
                <div>
                  <a :href="contact.Url" target="_blank" v-show="contact.Url">{{
                    contact.Building
                  }}</a>
                  <span v-show="!contact.Url">{{ contact.Building }}</span>
                </div>
                <div>{{ contact.Address }}</div>
                <div>
                  <label-lng NL="Kamer" EN="Room" v-show="contact.Room && contact.Room != ''" />
                  {{ contact.Room }}
                </div>
                <div>{{ contact.Zipcode }} {{ contact.City }}</div>
                <div v-if="$root.lng == 'EN'">The Netherlands</div>
                <div v-if="contact.Phone">
                  <label-tel-lng
                    NL="Telefoonnummer direct "
                    EN="Phone number (direct) "
                    :tel="contact.Phone"
                  />
                </div>
                <div v-if="contact.PhoneDepartment">
                  <label-tel-lng
                    NL="Telefoonnummer afdeling "
                    EN="Phone number (department) "
                    :tel="contact.PhoneDepartment"
                  />
                </div>
              </div>
              <div style="height:10px;"></div>
            </div>

            <div v-show="medewerker.Email">
              <div class="field-header">
                <label-lng NL="E-mail" EN="E-mail" />
              </div>
              <div class="field-box">
                <a :href="'mailto:' + medewerker.Email">{{
                  medewerker.Email
                }}</a>
              </div>
            </div>
            <div v-show="medewerker.MobileNumber">
              <div class="field-header">
                <label-lng NL="Mobiel" EN="Mobile phone" />
              </div>
              <div class="field-box">
                <label-tel-lng NL EN :tel="medewerker.MobileNumber" />
              </div>
            </div>

            <div v-if="medewerker.PostalAddress">
              <div class="field-header">
                <label-lng NL="Postadres" EN="Postal Address" />
              </div>
              <div class="field-box">
                <div>{{ medewerker.PostalAddress.Address }}</div>
                <div>
                  {{ medewerker.PostalAddress.Zipcode }}
                  {{ medewerker.PostalAddress.City }}
                </div>
                <div v-if="$root.lng == 'EN'">The Netherlands</div>
              </div>
            </div>

            <div v-show="medewerker.ContactFreeText">
              <div style="height:10px;"></div>
              <div class="field-box" v-html="medewerker.ContactFreeText"></div>
            </div>
          </div>
        </div>
      </div>

      <div class="opvul"></div>

      <div class="fixed-bottom footer-element font-headline" @click="goBack()">
        <div @click="goBack()" class="back-button">
          <img src="../../assets/tree-back.png" />
          <label-lng NL="Terug" EN="Back" />
        </div>
        <div class="desktop-view" @click="toDesktop()" v-if="false">
          <label-lng NL="Desktop weergave" EN="Desktop view" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import labellng from "@/components/layout/label-lng.vue";
import labeltellng from "@/components/layout/label-tel-lng.vue";
import mobileheader from "@/components/mobile/mobile-header.vue";
import datehelper from "@/helpers/date-helper.vue";

export default {
  name: "mobile",
  components: {
    "label-lng": labellng,
    "label-tel-lng": labeltellng,
    "mobile-header": mobileheader
  },
  data() {
    return {
      medewerker: null,
      token: window.localStorage.getItem("token"),
      activetab: "",
      publicationLoaded: false,
      publicationsLoading: false
    };
  },
  head: {
    meta: function() {
      return [
        {
          name: "description",
          content: this.medewerker ? this.medewerker.Bio : "Medewerker UU",
          id: "desc"
        }
      ];
    },
    link: function() {
      return [{ rel: "canonical", href: this.getDesktopUrl(), id: "link-dt" }];
    }
  },
  methods: {
    loaddata: function() {
      if (this.$route.params.employee) {
        if (!this.token) this.token = "";
        this.$Progress.start();
        // alert("Ophalen pagina: " + this.$route.params.employee);
        this.$http
          .get(
            "Public/getEmployeeData?page=" +
              this.$route.params.employee +
              "&t=" +
              this.token +
              "&l=" +
              this.$root.lng +
              "&m=true" +
              "&_=" +
              new Date().getTime()
          )
          .then(
            function(response) {
              this.$Progress.finish();
              this.medewerker = response.data.Employee;
              if (response.data.State === "Unknown") {
                this.$router.push({ name: "Default", params: {} });
                return;
              }
              if (response.data.State === "Redirect") {
                // alert("Redirect naar:" + response.data.RedirectTo + " in taal " + this.$root.lng);
                this.$router.push({
                  name: "mobile" + this.$root.lng,
                  params: { employee: response.data.RedirectTo }
                });
                this.loaddata();
                return;
              }
              if (response.data.State === "Internal") {
                this.$router.push({ name: "forbidden", params: {} });
                return;
              }

              // Redirect naar EN-variant als dit op faculteit-niveau is geconfigureerd
              if (this.medewerker.OnlyEN && this.$root.lng === "NL") {
                this.$router.push({
                  name: "mobileEN",
                  params: { employee: this.$route.params.employee }
                });
                // this.$root.lng = "EN";
                this.$parent.switchlng("EN");
                this.loaddata();
                return;
              }

              // Gegevens in header bijwerken (gaat niet automatisch)
              this.$emit("updateHead");
            },
            function() {
              // Onbekende medewerker => zoekscherm
              this.$Progress.finish();
              this.medewerker = null;
            }
          );
      }
    },
    loadPublications() {
      this.publicationsLoading = true;
      this.$http
        .get(
          "Public/getEmployeeData?page=" +
            this.$route.params.employee +
            "&t=" +
            this.token +
            "&l=" +
            this.$root.lng +
            "&m=false"
        )
        .then(
          function(response) {
            this.medewerker.Publications = response.data.Employee.Publications;
            this.medewerker.Media = response.data.Employee.Media;
            this.medewerker.Activities = response.data.Employee.Activities;
            this.medewerker.Prizes = response.data.Employee.Prizes;
            // this.medewerker.KeyPublications = response.data.Employee.KeyPublications;
            this.publicationsLoading = false;
            this.publicationLoaded = true;
          },
          function() {
            this.publicationsLoading = false;
          }
        );
    },
    switchlng: function() {},
    goBack: function() {
      this.$router.go(-1);
    },
    toDesktop: function() {
      var url = window.location.toString();
      url = url.replace("/mobiel", "");
      url = url.replace("/mobile", "");
      window.sessionStorage.setItem("mobile2desktop", true);
      window.location = url;
    },
    tabclick: function(tab) {
      if (this.activetab === tab) this.activetab = "";
      else {
        this.activetab = tab;
        // Log dat er op een tab is geklikt
        this.$http.get(
          "Public/LogOpenTabMobile?page=" +
            this.$route.params.employee +
            "&tab=" +
            tab
        );
      }
      // Laadt publicaties  als tabje publications wordt geopend...
      if (
        (tab === "pub" ||
          tab === "media" ||
          tab === "activities" ||
          tab === "prizes") &&
        !this.publicationLoaded
      ) {
        this.loadPublications();
      }
    },
    getDesktopUrl: function() {
      if (this.$root.lng === "NL") {
        return "https://www.uu.nl/medewerkers/" + this.$route.params.employee;
      } else {
        return "https://www.uu.nl/staff/" + this.$route.params.employee;
      }
    }
  },
  watch: {},
  created: function() {
    this.$root.$on("switchlng", this.switchlng);
    this.loaddata();
  },
  mounted: function() {},
  mixins: [datehelper]
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

a {
  text-decoration: underline;
}

.gele-balk {
  background-color: #ffcd00;
  min-height: 100px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: 700;
}

.Mobile-Block {
  min-height: 100px;
  padding-top: 20px;
  padding-bottom: 10px;
}

.Grijs {
  background-color: #efefef;
  padding-top: 10px;
}

.Wit {
  background-color: #fff;
  padding-top: 10px;
}

.Mobile-font {
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 0.95em;
}

.Caption {
  font-weight: bold;
}

/* Dit is de punaise voor de locatie */
.LocatieBlok > span:first-child {
  width: 20px;
  position: relative;
  top: 3px;
}
/* Locatie rechts van de punaise */
.LocatieBlok > span {
  display: inline-block;
  vertical-align: top;
  margin-right: 12px;
}

.mobile-item {
  margin-top: 20px;
  margin-bottom: 20px;
  line-height: 0.7em;
}

.Search-Input {
  min-height: 60px;
  padding-top: 15px;
}

/* Footer met terug-knop*/
.footer-element {
  background-color: #262626;
  border-top: 4px solid #fff;
  line-height: 50px;
  margin-left: 0px;
  padding-left: 10px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
}

.opvul {
  height: 55px;
}

.desktop-view {
  width: 50%;
  text-align: right;
  float: left;
  padding-right: 15px;
}

.back-button {
  width: 50%;
  float: left;
}

/* Icons bij email/telnr */
.icon {
  display: inline-block;
  width: 20px;
}

/* Weergave volledige inhoud */
.tab-header {
  cursor: pointer;
  font-family: "Open Sans";
  font-weight: 700;
  font-size: 17px;
  height: 55px;
  line-height: 55px;
}

.tab-header img {
  margin-right: 10px;
  position: relative;
  top: -1px;
}

.tab-content {
  padding-bottom: 5px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;
  background-color: white;
  margin-bottom: 15px;
  margin-left: -15px;
  margin-right: -15px;
}

.field-box {
  background-color: white;
  font-size: 13px;
  color: #3b3b3b;
  font-family: "Merriweather";
}

.field-box ul {
  padding-left: 15px;
}

.field-header {
  font-size: 13px;
  margin-top: 15px;
  font-weight: 700;
}

.mobile-tab {
  padding-top: 0px;
  padding-bottom: 0px;
  background-color: #efefef;
  margin-bottom: 3px;
}

.first {
  margin-top: 3px;
}

.extra-field-margin {
  margin-top: 15px;
}
</style>

<style>

.publications a {
  text-decoration: underline;
}

.publications h2
{
  font-size: 1.5rem;
  font-weight: bold;
}

.publications h3 {
  font-size: 1.5rem;
  font-weight: bold;
  border-bottom: solid 1px black;
}

.publications h4 {
  font-size: 1.0rem;
  font-weight: bold;
}

.publications h1, .publications h2
{
  margin-bottom: 1rem;
}

.publications h3
{
  margin-bottom: 0.5rem;
}

.publications h4
{
  margin-bottom: 0.5rem;
}

.publications .link {
  word-break: break-all;
}

.mobile-link {
  text-decoration: underline;
}

.field-box p:last-child {
  margin-bottom: 0px;
}

/* Publicaties */
.pub-item {
  margin-bottom: 10px;
}

/* Verzoek om datum achter de titel te zetten bij activities */
.rendering_activity h2 {
  display: inline;
}
.rendering_activity .dates {
  display: inline;
  text-transform: lowercase;
}

/* Komma tussen titel en datum invoegen */
.rendering_activity .dates::before {
  content: ", ";
}

/* Namen bij activities niet tonen */
.rendering_activity .person
{
  display: none;
}

/* Bij prizes datum achter de titel zetten */
.rendering_prize h2,
.rendering_prize p {
  display: inline;
}

.rendering_prize .prize_shortrenderer_date::before {
  content: ", ";
}

.rendering_prize .prize_shortrenderer_date {
  text-transform: lowercase;
}

/* Style de media-containers */
.media-cnt ul {
  /* Geen bullets voor sub-media-items */
  list-style-type: none;
  padding-left: 0px;
}

.media-cnt li {
  /* Afstand tussen sub-media-items */
  line-height: 25px;
  margin-bottom: 25px;
  margin-top: 25px;
}

.media-cnt .media-title {
  border-bottom: solid black 1px;
  width: 100%;
  font-weight: bold;
}
/* Einde publicaties */

.locicon {
  position: relative;
  top: -5px;
}
/* socialmedia icons in header */
.smicon {
  margin-right: 10px;
}

.name-container {
  position: relative;
}

.headerlinks {
  position: absolute;
  bottom: 0px;
}
</style>

<template>
  <div v-if="medewerker" class="TabPublications">
    <div
      v-if="medewerker.PublicationsFreeTop && pubtype=='Publications'"
      v-html="medewerker.PublicationsFreeTop"
      class="rtffield ftop"
    ></div>
    <div
      v-if="medewerker.ActivitiesFreeText && pubtype=='Activities'"
      v-html="medewerker.ActivitiesFreeText"
      class="rtffield ftop"
    ></div>
    <div
      v-if="medewerker.PrizesFreeText && pubtype=='Prizes'"
      v-html="medewerker.PrizesFreeText"
      class="rtffield ftop"
    ></div>
    <div
      v-if="medewerker.MediaFreeText && pubtype=='Media'"
      v-html="medewerker.MediaFreeText"
      class="rtffield ftop"
    ></div>

    <div class="pub-tab tab-research-output" v-show="pubtype=='Activities'">
      <div v-for="grp in medewerker.Activities" class="publicationgroup" :key="grp.Guid">
        <h2 v-show="grp.Groupname != ''">{{grp.Groupname}}</h2>
        <div v-for="pub in grp.Publications" v-html="pub" class="pub" :key="pub.Guid"></div>
      </div>
    </div>

    <div class="pub-tab tab-activities" v-show="pubtype=='Publications'">
      <div v-for="grp in medewerker.Publications" class="publicationgroup" :key="grp.Guid">
        <h2 v-show="grp.Groupname != ''">{{grp.Groupname}}</h2>
        <div v-for="pub in grp.Publications" v-html="pub" class="pub" :key="pub.Guid"></div>
      </div>
    </div>

    <div class="pub-tab tab-prizes" v-show="pubtype=='Prizes'">
      <div v-for="grp in medewerker.Prizes" class="publicationgroup" :key="grp.Guid">
        <h2 v-show="grp.Groupname != ''">{{grp.Groupname}}</h2>
        <div v-for="pub in grp.Publications" v-html="pub" class="pub" :key="pub.Guid"></div>
      </div>
    </div>

    <div class="pub-tab tab-media" v-show="pubtype=='Media'">
      <div v-for="grp in medewerker.Media" class="publicationgroup" :key="grp.Guid">
        <h2 v-show="grp.Groupname != ''">{{grp.Groupname}}</h2>
        <div v-for="pub in grp.Publications" v-html="pub" class="pub" :key="pub.Guid"></div>
      </div>
    </div>

    <div
      v-if="medewerker.PublicationsFreeBottom && pubtype=='Publications'"
      v-html="medewerker.PublicationsFreeBottom"
      class="rtffield"
    ></div>
  </div>
</template>

<script>

export default {
  name: "tab-publications",
  data() {
    return {
      activetab: "research-output",
    };
  },
  methods: {
    setActivePage: function (tab) {
      this.activetab = tab;
    },
  },
  computed: {
    ResearchOutput: function () {
      return this.medewerker.Publications.filter(function (item) {
        return item.GroupType === "R";
      });
    },
    Activities: function () {
      return this.medewerker.Publications.filter(function (item) {
        return item.GroupType === "A";
      });
    },
    Prizes: function () {
      return this.medewerker.Publications.filter(function (item) {
        return item.GroupType === "P";
      });
    },
    Media: function () {
      return this.medewerker.Publications.filter(function (item) {
        return item.GroupType === "M";
      });
    },
  },
  props: ["medewerker", "lng", "pubtype"],
  components: {
  },
};
</script>

<style>
.TabPublications h2
{
  font-size: 1.75rem;
  font-weight: bold;
}

.TabPublications h3 {
  font-size: 1.75rem;
  font-weight: bold;
  border-bottom: solid 1px black;
}

.TabPublications h4 {
  font-size: 1.25rem;
  font-weight: bold;
}

.TabPublications h1, .TabPublications h2, .TabPublications h3
{
  margin-bottom: 1rem;
}

.TabPublications h4
{
  margin-bottom: 0.5rem;
}

.TabPublications .link {
  word-break: break-all;
}
</style>

<style scoped>

.publicationgroup > div {
  margin-bottom: 5px;
  padding-bottom: 15px;
}

.TabPublications {
  line-height: 28px;
}

.pub-tab {
  margin-bottom: 15px;
}

.tab-buttons {
  margin-bottom: 15px;
}
</style>

<style>
.pub .title {
  font-size: 18px;
}

/* Verzoek om datum achter de titel te zetten bij activities */
.rendering_activity h2 {
  display: inline;
}
.rendering_activity .dates {
  display: inline;
  text-transform: lowercase;
}

/* Komma tussen titel en datum invoegen */
.rendering_activity .dates::before {
  content: ", ";
}

/* Namen bij activities niet tonen */
.rendering_activity .person
{
  display: none;
}

/* Bij prizes datum achter de titel zetten */
.rendering_prize h2,
.rendering_prize p {
  display: inline;
}

.rendering_prize .prize_shortrenderer_date::before {
  content: ", ";
}

.rendering_prize .prize_shortrenderer_date {
  text-transform: lowercase;
}

/* Style de media-containers */
.media-cnt ul {
  /* Geen bullets voor sub-media-items */
  list-style-type: none;
  padding-left: 0px;
}

.media-cnt li {
  /* Afstand tussen sub-media-items */
  line-height: 25px;
  margin-bottom: 25px;
  margin-top: 25px;
}

.media-cnt .media-title {
  border-bottom: solid black 1px;
  width: 100%;
  font-weight: bold;
}

.ftop {
  margin-bottom: 15px;
}
</style>

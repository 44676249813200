<template>
  <div class="wrapper">
    <div id="app" class="container-fluid max-width">
      <!-- Bovenste balk met links logo en rechts zoeken/taalswitch -->
      <div class="desktop-header" v-if="!mobile">
        <div class="row">
          <div class="col-md-1"></div>
          <div class="col-md-11 header-wrapper">
            <div class="hamburger-container">
              <div>
                <b-dropdown id="ddown1" no-caret variant="link">
                  <template slot="button-content">
                    <div class="hamburger-menu"></div>
                  </template>
                  <b-dropdown-item href="https://www.uu.nl" v-if="$root.lng == 'NL'"
                    >UU home</b-dropdown-item
                  >
                  <b-dropdown-item
                    href="https://www.uu.nl/onderwijs"
                    v-if="$root.lng == 'NL'"
                    >Onderwijs</b-dropdown-item
                  >
                  <b-dropdown-item
                    href="https://www.uu.nl/onderzoek"
                    v-if="$root.lng == 'NL'"
                    >Onderzoek</b-dropdown-item
                  >
                  <b-dropdown-item
                    href="https://www.uu.nl/organisatie"
                    v-if="$root.lng == 'NL'"
                    >Organisatie</b-dropdown-item
                  >
                  <b-dropdown-item
                    href="https://www.uu.nl/samenwerken"
                    v-if="$root.lng == 'NL'"
                    >Samenwerken</b-dropdown-item
                  >
                  <b-dropdown-item
                    href="https://www.uu.nl/organisatie/nieuws-en-agenda"
                    v-if="$root.lng == 'NL'"
                    >Nieuws en agenda</b-dropdown-item
                  >
                  <b-dropdown-item
                    href="https://www.uu.nl/bachelors/"
                    v-if="$root.lng == 'NL'"
                    >Bacheloropleidingen</b-dropdown-item
                  >
                  <b-dropdown-item
                    href="https://www.uu.nl/masters/"
                    v-if="$root.lng == 'NL'"
                    >Masterprogramma's</b-dropdown-item
                  >
                  <b-dropdown-item href="https://students.uu.nl/" v-if="$root.lng == 'NL'"
                    >Studenten</b-dropdown-item
                  >
                  <b-dropdown-item href="https://intranet.uu.nl" v-if="$root.lng == 'NL'"
                    >Intranet</b-dropdown-item
                  >

                  <b-dropdown-item href="https://www.uu.nl/en" v-if="$root.lng == 'EN'"
                    >UU home</b-dropdown-item
                  >
                  <b-dropdown-item
                    href="https://www.uu.nl/en/education"
                    v-if="$root.lng == 'EN'"
                    >Education</b-dropdown-item
                  >
                  <b-dropdown-item
                    href="https://www.uu.nl/en/research"
                    v-if="$root.lng == 'EN'"
                    >Research</b-dropdown-item
                  >
                  <b-dropdown-item
                    href="https://www.uu.nl/en/organisation"
                    v-if="$root.lng == 'EN'"
                    >Organisation</b-dropdown-item
                  >
                  <b-dropdown-item
                    href="https://www.uu.nl/en/collaborate"
                    v-if="$root.lng == 'EN'"
                    >Collaborate</b-dropdown-item
                  >
                  <b-dropdown-item
                    href="https://www.uu.nl/en/organisation/news-and-events"
                    v-if="$root.lng == 'EN'"
                    >News and events</b-dropdown-item
                  >
                  <b-dropdown-item
                    href="https://www.uu.nl/bachelors/en"
                    v-if="$root.lng == 'EN'"
                    >Bachelor's programmes</b-dropdown-item
                  >
                  <b-dropdown-item
                    href="https://www.uu.nl/masters/en"
                    v-if="$root.lng == 'EN'"
                    >Master's programmes</b-dropdown-item
                  >
                  <b-dropdown-item
                    href="https://students.uu.nl/en"
                    v-if="$root.lng == 'EN'"
                    >Students</b-dropdown-item
                  >
                  <b-dropdown-item
                    href="https://intranet.uu.nl/en"
                    v-if="$root.lng == 'EN'"
                    >Intranet</b-dropdown-item
                  >
                </b-dropdown>
              </div>
            </div>
            <div class="header-logo-lng-edit">
              <div class="header-logo">
                <a
                  :href="$root.lng == 'NL' ? 'https://www.uu.nl' : 'https://www.uu.nl/en'"
                >
                  <img
                    src="./assets/logo.svg"
                    title="Universiteit Utrecht"
                    class="uu-logo img-fluid"
                  />
                </a>
              </div>
              <div class="header-quicksearch">
                <quicksearch />
              </div>
              <div class="header-lng-edit">
                <!-- Taalswitch -->
                <div class="lng-switch">
                  <div
                    class="language"
                    v-show="
                      $root.lng == 'EN' &&
                      (showlngswitch == true ||
                        (showlngswitch == null && $root.lngSwitchOverride))
                    "
                  >
                    <a href="#" title="Switch to Dutch" v-on:click="switchlng('NL')"
                      >Nederlands</a
                    >
                  </div>
                  <div
                    class="language"
                    v-show="
                      $root.lng == 'NL' &&
                      (showlngswitch == true ||
                        (showlngswitch == null && $root.lngSwitchOverride))
                    "
                  >
                    <a href="#" title="Schakel naar Engels" v-on:click="switchlng('EN')"
                      >English</a
                    >
                  </div>
                </div>
                <!-- Bewerk-knop -->
                <div class="edit d-none d-md-block">
                  <a
                    href="#"
                    v-show="
                      $route.name == 'PublicNL' ||
                      $route.name == 'PublicEN' ||
                      $route.name == 'PublicTabNL' ||
                      $route.name == 'PublicTabEN'
                    "
                    :title="$root.lng == 'NL' ? 'Pagina bewerken' : 'Edit page'"
                    v-on:click="edit()"
                  >
                    <label-lng NL="Bewerken" EN="Edit" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <!-- div class="col-md-1"></div -->
        </div>
      </div>

      <!-- Zwarte balk met huisje, faculteit en medewerker  -->
      <div class="row menubar font-headline" v-if="!mobile">
        <div class="col-md-1"></div>
        <div class="col-md-10">
          <span class="huisje">
            <router-link :to="{ name: 'Search' + $root.lng }">
              <img src="./assets/Home.png" title="uu.nl" /> </router-link
            >&nbsp;</span
          >
          <span>
            <router-link to="/medewerkers" v-show="$root.lng == 'NL'"
              >Medewerkers</router-link
            ><router-link to="/staff" v-show="$root.lng == 'EN'">Employees</router-link
            >&nbsp;</span
          >
          <span>
            <router-link to="/medewerkers/organogram" v-show="$root.lng == 'NL'"
              >Organogram</router-link
            ><router-link to="/staff/organisationalchart" v-show="$root.lng == 'EN'"
              >Organisational Chart</router-link
            >&nbsp;</span
          >
          <span>
            <router-link to="/medewerkers/admin" v-show="showAdmin">Admin</router-link>
          </span>
        </div>
        <div class="col-md-1">
          <label-lng NL="intern" EN="internal" v-show="internal()" class="internal" />
        </div>
      </div>

      <!-- Content -->
      <div class="router-view">
        <router-view></router-view>
      </div>

      <!-- Footer -->
      <div class="row footer" v-if="footerlinks && !mobile">
        <div class="col-md-1"></div>
        <div class="col-md-3">
          <div class="footerheader font-headline">
            <a
              :href="footerlinks.TitleColumn1.Url"
              :target="footerlinks.TitleColumn1.NewTab ? '_blank' : ''"
              class="footerheaderlink"
              >{{ footerlinks.TitleColumn1.Title }}</a
            >
          </div>
          <ul>
            <li v-for="l in footerlinks.Column1" :key="l.Guid">
              <a :href="l.Url" :target="l.NewTab ? '_blank' : ''" class="footerlink">{{
                l.Title
              }}</a>
            </li>
          </ul>
        </div>
        <div class="col-md-3">
          <div class="footerheader font-headline">
            <a
              :href="footerlinks.TitleColumn2.Url"
              :target="footerlinks.TitleColumn2.NewTab ? '_blank' : ''"
              class="footerheaderlink"
              >{{ footerlinks.TitleColumn2.Title }}</a
            >
          </div>
          <ul>
            <li v-for="l in footerlinks.Column2" :key="l.Guid">
              <a :href="l.Url" :target="l.NewTab ? '_blank' : ''" class="footerlink">{{
                l.Title
              }}</a>
            </li>
          </ul>
        </div>
        <div class="col-md-3">
          <div class="footerheader font-headline">
            <a
              :href="footerlinks.TitleColumn3.Url"
              :target="footerlinks.TitleColumn3.NewTab ? '_blank' : ''"
              class="footerheaderlink"
              >{{ footerlinks.TitleColumn3.Title }}</a
            >
          </div>
          <ul>
            <li v-for="l in footerlinks.Column3" :key="l.Guid">
              <a :href="l.Url" :target="l.NewTab ? '_blank' : ''" class="footerlink">{{
                l.Title
              }}</a>
            </li>
          </ul>
        </div>
        <div class="col-md-1"></div>
        <div class="col-md-1"></div>
      </div>

      <!-- Onderaan footer een balk in zwart met logo -->
      <div class="row footerlogobar" v-if="!mobile">
        <div class="col-md-1"></div>
        <div class="col-md-10">
          <img
            src="./assets/logo_footer.png"
            title="Univeautocomplete-quicksearchrsiteit Utrecht"
          />
        </div>
        <div class="col-md-1 text-right uitloggen">
          <!-- Uitlog-knop -->
          <iconbutton
            icon="sign-out-alt"
            scale="1.5"
            @click="logout"
            titleNL="Uitloggen"
            titleEN="Logout"
          />
        </div>
      </div>
    </div>
    <!-- set progressbar -->
    <vue-progress-bar></vue-progress-bar>
  </div>
</template>

<script>
import labellng from "@/components/layout/label-lng.vue";
import quicksearch from "@/components/common/quicksearch.vue";
import iconbutton from "@/components/layout/iconbutton.vue";

export default {
  name: "app",
  data() {
    return {
      footerlinks: null,
      mobile: false,
      showlngswitch: this.$route.meta.lngSwitch,
      showAdmin: false,
    };
  },
  components: {
    "label-lng": labellng,
    quicksearch: quicksearch,
    iconbutton: iconbutton,
  },
  methods: {
    switchlng: function (lng) {
      if (lng === "EN") {
        let url = window.location.toString();
        // url = url.toLowerCase();
        url = url.replace(/\/medewerkers/gi, "/staff"); // url.replace("/medewerkers","/staff");
        url = url.replace(/\/zoek/gi, "/search"); // url = url.replace("/zoek","/search");
        url = url.replace(/\/organogram/gi, "/organisationalchart"); // url = url.replace("/organogram","/organisationalchart");
        url = url.replace(/\/mobiel/gi, "/mobile"); // url = url.replace("/mobiel","/mobile");
        window.location = url;
      }
      if (lng === "NL") {
        let url = window.location.toString();
        // url = url.toLowerCase();
        url = url.replace(/\/staff/gi, "/medewerkers"); // url = url.replace("/staff","/medewerkers");
        url = url.replace(/\/search/gi, "/zoek"); // url = url.replace("/search","/zoek");
        url = url.replace(/\/organisationalchart/gi, "/organogram"); // url = url.replace("/organisationalchart","/organogram");
        url = url.replace(/\/mobile/gi, "/mobiel"); // url = url.replace("/mobile","/mobiel");
        window.location = url;
      }
    },
    edit: function () {
      var routename = this.$root.lng === "NL" ? "EditNL" : "EditEN";
      this.$router
        .push({
          name: routename,
          params: { employee: this.$route.params.employee },
        })
        .catch((e) => {alert(e);});
    },
    loadfooterlinks: function () {
      this.$http
        .get("Public/GetFooterLinks?l=" + this.$root.lng)
        .then(function (response) {
          this.footerlinks = response.data;
        });
    },
    internal: function () {
      if (
        window.localStorage.getItem("token") &&
        window.localStorage.getItem("token").length > 10
      ) {
        return true;
      } else return false;
    },
    setIsAdmin: function () {
      this.showAdmin = window.localStorage.getItem("isadmin") === "true";
    },
    logout: function () {
      this.$http
        .get(
          "Edit/GetLogoutUrl?returnurl=" +
            encodeURIComponent(window.location.origin + "/medewerkers")
        )
        .then(function (response) {
          // Doorsturen naar SAML IdP uitlog
          window.location = response.data;
        });
    },
  },
  created: function () {
    this.mobile = this.$route.name != null && this.$route.name.match(/^mobile/g); // .startsWith("mobile");  // startWith werkt niet in IE11 :(
    this.loadfooterlinks();
    this.setIsAdmin();
    this.$root.$on("login_event", this.setIsAdmin);
  },
  mounted: function () {},
  watch: {
    "$route.meta.lngSwitch": function (value) {
      this.showlngswitch = value;
    },
  },
};
</script>

<!-- Algemene style voor de hele app -->
<style>
#app {
  /* Algemene lettertype en kleur*/
  font-family: "Merriweather";
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #3b3b3b;
  margin-top: 0px;
}

/* Zorgt voor maximale breedte van 1600px in fluid-mode */
/*
.wrapper {
  max-width: 1600px;
  margin: 0 auto;
}
*/
.max-width {
  max-width: 1600px;
  overflow-x: hidden;
}

body {
  /* Achtergrondkleur buiten de applicatie */
  background-color: #f8f8f8;
  /* Altijd verticale scrollbar om springen te voorkomen */
  overflow-y: scroll;
}

/* Pagina is minimaal 200 px hoog*/
.router-view {
  min-height: 200px;
}
/* Links niet in blauw, maar zwart*/
a {
  color: #3b3b3b !important; /* Niet zo fraai, maar anders werkt het niet als je een npm run build doet. Bootstrap.css komt dan na deze css... */
}

/* mouse over link */
a:hover {
  text-decoration: underline !important; /* Niet zo fraai, maar anders werkt het niet als je een npm run build doet. Bootstrap.css komt dan na deze css... */
  color: #3b3b3b !important;
}

.container,
.container-fluid {
  /* Achtergrondkleur van de applicatie */
  background-color: #ffffff;
}

.underlined-link {
  text-decoration: underline;
}

.public-tab-content {
  line-height: 34px;
}

.uu-button {
  border: 0px;
  text-transform: uppercase;
  font-size: 15px;
  padding: 10px 14px 10px 14px;
  cursor: pointer;
  margin-top: 15px;
  text-decoration: none;
  font-family: "Open Sans";
  font-weight: 700;
  line-height: 24px;
}

.button-black > span {
  color: #ffffff;
}

.button-yellow > span {
  color: #000000;
}

.uu-button:hover {
  text-decoration: none !important;
}

.button-black {
  color: #ffffff;
  background-color: #000000;
}

.button-yellow {
  color: #000000;
  background-color: #ffcd00;
}

.uu-button-arrow {
  position: relative;
  left: 13px;
  top: 4px;
}
/* Font voor headlines, menu, etc */
.font-headline {
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 16px;
}

.font-sidebar {
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 16px;
}
.font-sidebar-subtabs {
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 14px;
}

h1.font-headline {
  font-size: 32px;
}

/* Rand van textbox die focus heeft */
.form-control:focus {
  border-color: #ffcd00;
  /* Tijdelijk uitgezet, geen schaduwrand...  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(255, 205, 0, 0.6);*/
  box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.075), 0 0 0px rgba(255, 205, 0, 0.6) !important;
}

/* Tekstvelden met opmaak: geen witruimte tussen alinea's */
.rtffield p {
  margin-bottom: 5px;
}

/* Externe links krijgen een pijltje achter de link. */
.external:after,                         /* Alle links met class external */
.fieldcontent a[target="_blank"]:after,  /* Alle links binnen fieldcontent */
.rtffield a[target="_blank"]:after       /* Alle links binnen rtffield */ {
  content: url("./assets/external.png");
  padding: 0 0 0 7px;
  text-decoration: none;
  display: inline-block;
  margin-right: 7px;
  text-indent: 0;
}

/* ul in twee kolommen */
.two-columns {
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
  -webkit-column-gap: 40px;
  column-gap: 40px;
  -moz-column-gap: 40px;
}

/* Styling hamburger-menu dropdown-variant */
.hamburger-container .dropdown-menu {
  border-radius: 0;
  border: none;
  padding: 0px;
  margin-left: -14px;
  min-width: 300px;
  top: 10px;
}

/* Tooltips van editor voldoende naar voren halen */
.ql-tooltip {
  z-index: 1000;
}

/* Sortering-pijltjes: zorg dat bovenste up en onderste down niet zichtbaar is */
tbody > tr:first-child > td > .up {
  display: none;
}
tbody > tr:first-child > td > .down {
  margin-left: 20px;
}
tbody > tr:last-child > td > .down {
  display: none;
}

.ql-video {
  width: 100%;
  height: 500px;
}
</style>

/*****************************************************************************/

<!-- Styling voor app.vue -->
<style scoped>
/* nav-bar */
.navbar {
  padding: 0px;
  margin-left: -15px;
  margin-right: -15px;
  padding-left: 5px;
  padding-right: 5px;
  min-height: 56px;
  /*border-bottom: 1px solid #efefef;*/
  justify-content: flex-start;
  font-family: "Open Sans";
  font-size: 12px;
}

/* Afstand tussen hamburger en UU-logo */
.navbar-brand {
  margin-left: 23px;
}

/* Afbeelding UU-logo */
/*
.uu-logo {
  width: 252px;
}
*/

.header-wrapper {
  display: flex;
}

.header-logo-lng-edit {
  height: 100%;
  flex-grow: 1;
}

.header-logo {
  width: 37%;
  height: 100%;
  float: left;
  padding-left: 1%;
  padding-top: 12px;
}

.header-quicksearch {
  width: 32%;
  height: 100%;
  float: left;
  padding-left: 0px;
  padding-top: 25px;
}

.header-lng-edit {
  width: 31%;
  height: 90px;
  float: left;
  padding-left: 1%;
  padding-top: 0px;
  overflow: hidden;
}

/* Taalswitch (NL/EN) met streep links */
.lng-switch {
  border-left: 1px solid #efefef;
  width: 40px;
  color: #000000;
  height: 90px;
  line-height: 90px;
  margin-left: 25px;
  padding-left: 17px;
  width: 100px;
  font-size: 14px;
  float: left;
}

.edit {
  height: 90px;
  line-height: 90px;
  width: 90px;
  text-align: center;
  font-size: 14px;
  float: left;
}

.quicksearch {
  height: 90px;
  padding-top: 23px;
}

.navbar-text {
  padding-top: 0px;
  padding-bottom: 0px;
  padding-right: 136px;
  display: flex;
}

/* Hamburger-menu-knop */
.navbar-toggler {
  border: 0px;
  width: 36px;
  height: 41px;
  padding: 0px;
  margin: 0px;
}

/* Dit zorgt er voor dat taalswitch en zoekicoon rechts uitgelijnd worden */
.navbar .navbar-nav {
  margin-left: auto;
}

/* Bovenste item in hamburger-menu (rood font) */
.hamburger-home {
  color: #c00a35;
  background-color: #fff;
  height: 70px;
  line-height: 70px;
  font-weight: 400;
  font-size: 20px;
}

/* Icoon met huisje bovenaan hamburger-menu */
.hamburger-home > img {
  position: relative;
  top: -3px;
  margin-right: 2px;
}

/* Overkoepelende div hamburger-menu*/
.nav-collapse {
  padding-left: 10px;
}

.hamburger-uu-home img {
  margin-right: 4px;
}

/* Grijs vlak rond hamburger */
.hamburger-container {
  float: left;
  width: 90px;
  height: 90px;
  background-color: #efefef;
  text-align: center;
  padding-top: 25px;
}

/* Afstand tussen <- en tekst van hamburger-menu-items */
.nav-link img {
  margin-right: 20px;
}

/* Item in hamburger-menu (dropdown-variant) */
.hamburger-container .dropdown-item {
  background-color: #efefef; /* #FFCD00;*/
  border-bottom: 1px solid #c3c1c1;
  border-left: 1px solid #c3c1c1;
  border-right: 1px solid #c3c1c1;
  padding-top: 12px;
  padding-bottom: 12px;
}

.hamburger-container .dropdown-item img {
  margin-right: 20px;
}

.hamburger-menu {
  width: 36px;
  height: 41px;
  background-image: url("./assets/menu-gray.png");
}

.show .hamburger-menu {
  background-image: url("./assets/menu-gray-open.png");
}

/*************************************/

/* Zwarte balk met menu */
.menubar {
  /*font-family: 'Open Sans';*/
  background-color: #000000;
  height: 56px;
  line-height: 56px;
  color: #ffffff;
  /*font-weight: 600;*/
  padding-left: 73px;
}

/* Horizontale afstand tussen items in menubar */
.menubar > div > span {
  margin-right: 20px;
}

.menubar a {
  color: #ffffff !important; /* Niet zo fraai, maar anders werkt het niet als je een npm run build doet. */
  text-decoration: none !important;
}

.menubar a:hover {
  color: #ffcd00 !important; /* Menu-items gele font als je er met de muis overheen gaat. */
}

.menubar.font-headline {
  font-size: 16px;
  /*font-weight: bold; Niet bold volgens mij  */
}

.huisje > a {
  position: relative;
  top: -2px;
}

/* Footer */
.footer {
  padding-top: 55px;
  background-color: #262626;
  color: white;
  min-height: 487px;
}

.footerlogobar {
  background-color: black;
  margin-bottom: 0px;
  min-height: 138px;
  line-height: 138px;
}

/* Dit zijn de drie kolomkopjes in de footer */
.footerheader {
  margin-bottom: 25px;
}

.footerheaderlink {
  font-size: 26px !important;
  color: #ffffff !important;
}

.footerlink {
  color: #a1a1a1 !important;
  font-size: 16px !important;
  font-family: Open Sans !important;
  line-height: 20px !important;
  font-weight: 400 !important;
}

.footerlink:hover {
  color: #ffffff !important; /* Wit als je er met de muis over gaat*/
  text-decoration: none !important; /* Niet onderstrepen als je er met de muis over gaat */
}

/* Geen bullets voor de footer-links */
.footer ul {
  list-style-type: none;
  padding: 0;
  margin: 0px;
}

.bewerken-uitloggen a {
  margin-right: 5px;
  font-size: 14px;
}

.uitloggen > a > svg {
  color: #a1a1a1;
  margin-right: 15px;
}

.internal {
  color: #fff;
}
</style>

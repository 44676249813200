<template>
  <span>
    <b-modal
      id="modalPopup"
      ref="modalPopup"
      :title="$root.lng == 'NL' ? titleNL : titleEN"
      @ok="onclick"
      @cancel="cancelclick"
      :ok-title="$root.lng == 'NL' ? 'Ok' : 'Ok'"
      :cancel-title="$root.lng == 'NL' ? 'Annuleren' : 'Cancel'"
      :size="size"
      centered
      no-close-on-backdrop
      :ok-only="okonly"
    >
      <slot :data="data"></slot>
    </b-modal>
  </span>
</template>

<script>
export default {
  name: "popup",
  components: {},
  props: [
    "titleNL",
    "titleEN",
    "okclick",
    "data-vv-scope",
    "size",
    "focuselement",
    "okonly",
  ],
  data() {
    return {
      data: null,
      srcdata: null,
      collection: [],
      isnew: false,
      err: null,
      validator: null,
    };
  },
  methods: {
    hidden: function () {
      alert("hidden");
    },
    onclick: function (e) {
      e.preventDefault();
      if (this.validator && this.dataVvScope) {
        this.validator
          .validateAll(this.dataVvScope)
          .then((result) => {
            if (!result) {
              return;
            }
            this.$emit("okclick");
            this.updateCollection();
            this.close();
          })
          .catch(() => {});
      } else {
        this.$emit("okclick");
        this.updateCollection();
        this.close();
      }
    },
    cancelclick: function () {
      if (this.err && this.dataVvScope) this.err.clear(this.dataVvScope);
    },
    show: function (data, collection, jsscope) {
      if (this.dataVvScope) {
        this.err = jsscope.errors;
        this.validator = jsscope.$validator;
        this.err.clear(this.dataVvScope);
        this.validator.validateAll(this.dataVvScope);
      }
      // Data is een deep-copy van oorspronkelijke record. Deze ga je editten zodat de oorspronkelijke data ongewijzigd blijft voor het geval van cancel.
      this.data = JSON.parse(JSON.stringify(data));
      // Srcdata is referentie naar het oorspronkelijke record
      this.srcdata = data;

      if (collection) {
        this.isnew = collection.indexOf(data) === -1;
        // Bewaar referentie naar collection waar oorspronkelijk object onderdeel van maakt
        this.collection = collection;
      } else {
        this.isnew = false;
        this.collection = null;
      }
      this.$refs.modalPopup.show();
    },
    showSimple: function () {
      this.$refs.modalPopup.show();
    },
    close: function () {
      this.$nextTick(() => {
        this.$bvModal.hide("modalPopup");
      });
    },
    getData: function () {
      return this.data;
    },
    forceUpdate: function () {
      this.$forceUpdate();
    },
    // Voor wijziging die in 'deep copy' is gemaakt definitief door in de collection.
    updateCollection: function () {
      if (this.collection) {
        if (this.isnew) {
          // Add
          this.collection.push(this.data);
        } else {
          // Edit
          var index = this.collection.indexOf(this.srcdata);
          this.collection.splice(
            index,
            1,
            JSON.parse(JSON.stringify(this.data))
          );
        }
      }
    },
    focusMyElement: function () {
      // this.$parent.$refs.posPosition.focus();
      // this.validator.update();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

<style>
/* Style de buttons in popup volgens de UU-styling */
.modal-footer .btn {
  border: 0px;
  text-transform: uppercase;
  font-size: 15px;
  padding: 10px 14px 10px 14px;
  cursor: pointer;
  margin-top: 15px;
  text-decoration: none;
  font-family: "Open Sans";
  font-weight: 700;
  line-height: 24px;
  border-radius: 0px;
}

/* Primary-button wordt UU-geel */
.modal-footer .btn-primary {
  color: #000000;
  background-color: #ffcd00;
}

/* Secondary button (annuleren) wordt UU-zwart */
.modal-footer .btn-secondary {
  color: #ffffff;
  background-color: #000000;
}

.modal-content {
  font-family: "Merriweather";
  color: #3b3b3b;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;  
}
</style>

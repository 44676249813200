<template>
  <div v-if="medewerker" class="TabEditContact">
    <confirm ref="confirmdialog"></confirm>

    <edit-layout
      nameNL="E-mail"
      nameEN="Email"
      :info="GetInfo(info, 'Contact.Email')"
      v-if="medewerker.Email"
    >
      <input
        type="text"
        v-model="medewerker.Email.NL"
        disabled="disabled"
        v-if="!medewerker.EditingUserIsAdmin"
      />
      <input
        type="text"
        v-model="medewerker.Email.NL"
        maxlength="200"
        v-if="medewerker.EditingUserIsAdmin"
      />

      <span v-if="$root.lng == 'NL'">Zichtbaarheid</span>
      <span v-if="$root.lng == 'EN'">Visibility</span>&nbsp;<select
        class="select-button"
        v-if="$root.lng == 'NL'"
        v-model="medewerker.Email.Visibility"
      >
        <option value="1">Publiek</option>
        <option value="2">Alleen binnen UU</option>
        <option value="3">Niet tonen</option>
      </select>
      <select
        class="select-button"
        v-if="$root.lng == 'EN'"
        v-model="medewerker.Email.Visibility"
      >
        <option value="1">Public</option>
        <option value="2">Within UU only</option>
        <option value="3">Not visible</option>
      </select>
    </edit-layout>

    <edit-layout
      nameNL="Mobiel telefoonnummer"
      nameEN="Mobile phone"
      :info="GetInfo(info, 'Contact.TelMobiel')"
      v-if="medewerker.MobileNumber"
    >
      <div class="row">
        <div class="col-md-2">
          <input
            type="radio"
            v-model="medewerker.MobileNumber.UseSrcVal"
            :value="false"
            name="mfsrc"
            id="mfsrch"
          />&nbsp;<label for="mfsrch">
            <label-lng NL="Handmatig" EN="Manual" />
          </label>
        </div>
        <div class="col-md-10">
          <input
            type="text"
            v-model="medewerker.MobileNumber.NL"
            :maxlength="medewerker.MobileNumber.MaxLength"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-md-2">
          <input
            type="radio"
            v-model="medewerker.MobileNumber.UseSrcVal"
            :value="true"
            name="mfsrc"
            id="mfsrchr"
          />&nbsp;<label for="mfsrchr">
            <label-lng NL="HR" EN="HR" />
          </label>
        </div>
        <div class="col-md-10">
          <input
            type="text"
            v-model="medewerker.MobileNumber.SrcVal"
            :maxlength="medewerker.MobileNumber.MaxLength"
            disabled="disabled"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-md-2">
          <label-lng NL="Zichtbaarheid" EN="Visibility" />
        </div>
        <div class="col-md-10">
          <select
            v-if="$root.lng == 'NL'"
            v-model="medewerker.MobileNumber.Visibility"
          >
            <option value="1">Publiek</option>
            <option value="2">Alleen binnen UU</option>
          </select>
          <select
            v-if="$root.lng == 'EN'"
            v-model="medewerker.MobileNumber.Visibility"
          >
            <option value="1">Public</option>
            <option value="2">Within UU only</option>
          </select>
        </div>
      </div>
    </edit-layout>

    <!-- Locaties -->
    <edit-layout
      nameNL="Werklocatie(s)"
      nameEN="Work address(es)"
      :info="GetInfo(info, 'Contact.Locaties')"
      v-if="medewerker.ContactDetails"
    >
      <table class="table table-sm table-bordered table-locations">
        <thead>
          <tr>
            <th class="ColNarrow">
              <label-lng NL="Bron" EN="Source"></label-lng>
            </th>
            <th class="ColNarrow">
              <label-lng NL="Tonen" EN="Visible"></label-lng>
            </th>
            <th>
              <label-lng NL="Locatie" EN="Location"></label-lng>
            </th>
            <th>
              <label-lng NL="Kamer" EN="Room"></label-lng>
            </th>
            <th>
              <label-lng NL="Telefoon" EN="Telephone"></label-lng>
            </th>
            <th>
              <label-lng NL="Telnr afd" EN="Telephone dep"></label-lng>
            </th>
            <th class="iconcolumn"></th>
            <th class="iconcolumn"></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="cnt in medewerker.ContactDetails"
            v-bind:class="{ preferred: cnt.Preferred }"
            v-show="!(medewerker.IAMLocatieDisconnected && cnt.IAM)"
            :key="cnt.Guid"
          >
            <td class="ColNarrow">
              <label-lng NL="HR" EN="HR" v-show="cnt.IAM" />
              <label-lng NL="Handmatig" EN="Manual" v-show="!cnt.IAM" />
            </td>
            <td class="ColNarrow">
              <input type="checkbox" v-model="cnt.Visible" />
            </td>
            <td>{{ cnt.Location.NL }}</td>
            <td>{{ cnt.Room.NL }}</td>
            <td>{{ cnt.Phone.NL }}</td>
            <td>{{ cnt.PhoneDepartment.NL }}</td>
            <td>
              <iconbutton
                icon="chevron-up"
                titleNL="Hoger"
                titleEN="Up"
                @click="locationUp(cnt)"
                class="up"
              />&nbsp;<iconbutton
                icon="chevron-down"
                titleNL="Lager"
                titleEN="Down"
                @click="locationDown(cnt)"
                class="down"
              />
            </td>
            <td>
              <iconbutton
                icon="pencil-alt"
                titleNL="Bewerken"
                titleEN="Edit"
                @click="editContact(cnt)"
              />&nbsp;<iconbutton
                icon="trash"
                titleNL="Verwijderen"
                titleEN="Delete"
                @click="deleteContact(cnt)"
                v-show="!cnt.IAM"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <button
        class="uu-button button-black"
        v-on:click="addContact()"
        variant="link"
      >
        <label-lng
          NL="Handmatig adres toevoegen"
          EN="Add manual address"
        ></label-lng>
      </button>
      <br />
      <input
        id="IAMLocatieDisconnected"
        type="checkbox"
        v-model="medewerker.IAMLocatieDisconnected"
        v-if="
          medewerker.EditingUserIsAdmin || medewerker.IsSecretaryForThisPage
        "
      />&nbsp;<label
        for="IAMLocatieDisconnected"
        v-if="
          medewerker.EditingUserIsAdmin || medewerker.IsSecretaryForThisPage
        "
      >
        <label-lng
          NL="IAM/SAP informatie uitschakelen"
          EN="disable IAM/SAP information"
        />
      </label>

      <!-- Zichtbaarheid locaties -->
      <div>
        <span v-if="$root.lng == 'NL'">Zichtbaarheid werklocatie(s) en telefoonnummer(s)</span>
        <span v-if="$root.lng == 'EN'">Visibility Work address(es) and phone number(s)</span>
        <select
          class="select-button"
          v-if="$root.lng == 'NL'"
          v-model="medewerker.ContactDetailsVisibility"
        >
          <option value="1">Publiek</option>
          <option value="2">Alleen binnen UU</option>
          <option value="3">Niet tonen</option>
        </select>
        <select
          class="select-button"
          v-if="$root.lng == 'EN'"
          v-model="medewerker.ContactDetailsVisibility"
        >
          <option value="1">Public</option>
          <option value="2">Within UU only</option>
          <option value="3">Not visible</option>
        </select>
      </div>
    </edit-layout>

    <popup
      ref="popupContact"
      titleNL="Werklocatie details"
      titleEN="Work Address details"
      size="lg"
      @okclick="beforeSave()"
    >
      <form slot-scope="popup">
        <div v-if="popup.data != null">
          <edit-layout-popup nameNL="Bron" nameEN="Source" info>
            <label-lng
              NL="Handmatig ingevoerd adres"
              EN="Manual address"
              v-if="!popup.data.IAM"
            />
            <label-lng NL="HR adres" EN="HR address" v-if="popup.data.IAM" />
          </edit-layout-popup>
          <edit-layout-popup
            nameNL="Locatie"
            nameEN="Location"
            :info="infolocatie.locatie"
            class="extra-marge"
          >
            <div class="form-group">
              <label for="cntBuilding">
                <label-lng NL="Gebouw" EN="Building"></label-lng>
              </label>
              <select
                v-model="popup.data.LocationId"
                id="cntBuilding"
                class="form-control"
                :disabled="popup.data.IAM"
                v-validate="'required'"
                name="cntBuilding"
              ><!--:class="{ 'is-invalid': errors.has('vvsCnt.cntBuilding') }"-->
                <option v-for="l in locations" :value="l.Id" :key="l.Guid">
                  {{ l.BuildingName }}
                </option>
              </select>
              <span v-if="$root.lng == 'NL'">Zichtbaarheid</span>
              <span v-if="$root.lng == 'EN'">Visibility</span>&nbsp;<select
                class="select-button"
                v-if="$root.lng == 'NL'"
                v-model="popup.data.Location.Visibility"
              >
                <option value="1">Publiek</option>
                <option value="2">Alleen binnen UU</option>
                <option value="3">Niet tonen</option>
              </select>
              <select
                class="select-button"
                v-if="$root.lng == 'EN'"
                v-model="popup.data.Location.Visibility"
              >
                <option value="1">Public</option>
                <option value="2">Within UU only</option>
                <option value="3">Not visible</option>
              </select>
            </div>
          </edit-layout-popup>
          <edit-layout-popup
            nameNL="Kamernummer"
            nameEN="Room number"
            :info="infolocatie.kamernr"
            class="extra-marge"
          >
            <edit-textfield
              :value="popup.data.Room"
              :disabled="popup.data.IAM"
              name="EC1"
            ></edit-textfield>
          </edit-layout-popup>
          <edit-layout-popup
            nameNL="Telefoonnummer"
            nameEN="Telephone"
            :info="infolocatie.telnr"
            class="extra-marge"
          >
            <edit-textfield
              :value="popup.data.Phone"
              :disabled="popup.data.IAM"
              name="EC2"
            ></edit-textfield>
          </edit-layout-popup>
          <edit-layout-popup
            nameNL="Telefoonnummer afdeling"
            nameEN="Telephone departement"
            :info="infolocatie.telafd"
            class="extra-marge"
          >
            <edit-textfield
              :value="popup.data.PhoneDepartment"
              :disabled="popup.data.IAM"
              name="EC3"
            ></edit-textfield>
          </edit-layout-popup>
        </div>
      </form>
    </popup>

    <!-- Afdeling/Functie -->
    <edit-layout
      nameNL="Functie en organisatieonderdeel"
      nameEN="Position and department"
      :info="GetInfo(info, 'Contact.Functies')"
      v-if="medewerker.Positions"
    >
      <table class="table table-sm table-bordered table-positions">
        <thead>
          <tr>
            <th class="ColNarrow">
              <label-lng NL="Bron" EN="Source"></label-lng>
            </th>
            <th class="ColNarrow">
              <label-lng NL="Tonen" EN="Visible"></label-lng>
            </th>
            <th>
              <label-lng NL="Functie" EN="Position"></label-lng>
            </th>
            <th>
              <label-lng NL="Faculteit" EN="Faculty"></label-lng>
            </th>
            <th>
              <label-lng NL="Organisatieonderdeel" EN="Department"></label-lng>
            </th>
            <th>
              <label-lng NL="Afdeling" EN="Section"></label-lng>
            </th>
            <th>
              <label-lng NL="Groep" EN="Group"></label-lng>
            </th>
            <th class="iconcolumn"></th>
            <th class="iconcolumn"></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="p in medewerker.Positions"
            v-bind:class="{ preferred: p.Preferred }"
            v-show="!(medewerker.IAMAanstellingDisconnected && p.IAM)"
            :key="p.Guid"
          >
            <td class="ColNarrow">
              <label-lng NL="HR" EN="HR" v-show="p.IAM" />
              <label-lng NL="Handmatig" EN="Manual" v-show="!p.IAM" />
            </td>
            <td class="ColNarrow">
              <input type="checkbox" v-model="p.Visible" />
            </td>
            <td>{{ p.Position }}</td>
            <td>{{ p.Faculty }}</td>
            <td>{{ p.Department }}</td>
            <td>{{ p.Section }}</td>
            <td>{{ p.Group }}</td>
            <td>
              <iconbutton
                icon="chevron-up"
                titleNL="Hoger"
                titleEN="Up"
                @click="positionUp(p)"
                class="up"
              />&nbsp;<iconbutton
                icon="chevron-down"
                titleNL="Lager"
                titleEN="Down"
                @click="positionDown(p)"
                class="down"
              />
            </td>
            <td>
              <iconbutton
                icon="pencil-alt"
                titleNL="Bewerken"
                titleEN="Edit"
                @click="editPosition(p)"
                v-show="
                  !p.IAM &&
                  (medewerker.EditingUserIsAdmin ||
                    medewerker.IsSecretaryForThisPage)
                "
              />&nbsp;<iconbutton
                icon="trash"
                titleNL="Verwijderen"
                titleEN="Delete"
                @click="deletePosition(p)"
                v-show="
                  !p.IAM &&
                  (medewerker.EditingUserIsAdmin ||
                    medewerker.IsSecretaryForThisPage)
                "
              />
            </td>
          </tr>
        </tbody>
      </table>
      <button
        class="uu-button button-black"
        v-on:click="addPosition()"
        v-if="
          medewerker.EditingUserIsAdmin || medewerker.IsSecretaryForThisPage
        "
      >
        <label-lng
          NL="Handmatig functie/onderdeel toevoegen"
          EN="Add manual position"
        ></label-lng>
      </button>
      <br />
      <input
        id="IAMAanstellingDisconnected"
        type="checkbox"
        v-model="medewerker.IAMAanstellingDisconnected"
        v-if="
          medewerker.EditingUserIsAdmin || medewerker.IsSecretaryForThisPage
        "
      />&nbsp;<label
        for="IAMAanstellingDisconnected"
        v-if="
          medewerker.EditingUserIsAdmin || medewerker.IsSecretaryForThisPage
        "
      >
        <label-lng
          NL="IAM/SAP informatie uitschakelen"
          EN="disable IAM/SAP information"
        />
      </label>
    </edit-layout>

    <popup
      ref="popupPosition"
      titleNL="Functie details"
      titleEN="Position details"
      size="lg"
      @okclick="beforeSavePosition()"
    >
      <form slot-scope="popup">
        <div v-if="popup.data != null">
          <edit-layout-popup nameNL="Bron" nameEN="Source" info>
            <label-lng
              NL="Handmatige ingevoerde functie"
              EN="Manual position"
              v-if="!popup.data.IAM"
            />
            <label-lng NL="HR functie" EN="HR position" v-if="popup.data.IAM" />
          </edit-layout-popup>

          <edit-layout-popup
            nameNL="Functie"
            nameEN="Position"
            :info="GetInfo(info, 'Contact.AfdelingFunctie.Functie')"
          >
            <div class="form-group">
              <select
                v-model="popup.data.PositionId"
                id="posPosition"
                class="form-control"
                v-validate="'required'"
                name="Position"
              ><!-- :class="{ 'is-invalid': errors.has('vvsPos.Position') }" -->
                <option
                  v-for="f in positions"
                  :value="f.PositionId"
                  :key="f.Guid"
                >
                  {{ f.Position }}
                </option>
              </select>
            </div>
          </edit-layout-popup>

          <edit-layout-popup
            nameNL="Faculteit"
            nameEN="Faculty"
            :info="GetInfo(info, 'Contact.AfdelingFunctie.Faculteit')"
          >
            <div class="form-group">
              <select
                v-model="selectedfaculty"
                id="posFaculty"
                class="form-control"
                v-validate="'required'"
                name="Department"
              ><!-- :class="{ 'is-invalid': errors.has('vvsPos.Department') }" -->
                <option
                  v-for="f in faculties"
                  :value="f.FacultyId"
                  :key="'fal' + f.FacultyId"
                >
                  {{ f.Faculty }}
                </option>
              </select>
            </div>
          </edit-layout-popup>

          <edit-layout-popup
            nameNL="Organisatieonderdeel"
            nameEN="Department"
            :info="
              GetInfo(info, 'Contact.AfdelingFunctie.Organisatieonderdeel')
            "
          >
            <div class="form-group">
              <select
                v-model="selecteddepartment"
                id="posDepartment"
                class="form-control"
                v-validate="'required'"
                name="Department"
              ><!-- :class="{ 'is-invalid': errors.has('vvsPos.Department') }" -->
                <option
                  v-for="f in departments"
                  :value="f.DepartmentId"
                  :key="'del' + f.DepartmentId"
                >
                  {{ f.Department }}
                </option>
              </select>
            </div>
          </edit-layout-popup>

          <edit-layout-popup
            nameNL="Afdeling"
            nameEN="Section"
            :info="GetInfo(info, 'Contact.AfdelingFunctie.Afdeling')"
          >
            <div class="form-group">
              <select
                v-model="selectedsection"
                id="posSection"
                class="form-control"
                v-validate="'required'"
                name="Section"
              ><!-- :class="{ 'is-invalid': errors.has('vvsPos.Section') }" -->
                <option
                  v-for="f in sections"
                  :value="f.SectionId"
                  :key="'sel' + f.SectionId"
                >
                  {{ f.Section }}
                </option>
              </select>
            </div>
          </edit-layout-popup>

          <edit-layout-popup
            nameNL="Groep"
            nameEN="Group"
            :info="GetInfo(info, 'Contact.AfdelingFunctie.Groep')"
            v-show="true || groups.length > 0"
          >
            <div class="form-group">
              <select
                v-model="selectedgroup"
                id="posGroup"
                class="form-control"
              >
                <option
                  v-for="f in groups"
                  :value="f.GroupId"
                  :key="'grl' + f.SectionId"
                >
                  {{ f.Group }}
                </option>
              </select>
            </div>
          </edit-layout-popup>
        </div>
      </form>
    </popup>

    <!-- Postadres -->
    <edit-layout
      nameNL="Postadres"
      nameEN="Postal address"
      :info="GetInfo(info, 'Contact.Postadres')"
    >
      <select v-model="medewerker.PostalLocationId" class="form-control">
        <option v-for="pl in postallocation" :value="pl.Id" :key="pl.Guid">
          {{ pl.BuildingName }}
        </option>
      </select>
      <span v-if="$root.lng == 'NL'">Zichtbaarheid</span>
      <span v-if="$root.lng == 'EN'">Visibility</span>&nbsp;
      <select
        class="select-button"
        v-if="$root.lng == 'NL'"
        v-model="medewerker.PostalLocationVisibility"
      >
        <option value="1">Publiek</option>
        <option value="2">Alleen binnen UU</option>
        <option value="3">Niet tonen</option>
      </select>
      <select
        class="select-button"
        v-if="$root.lng == 'EN'"
        v-model="medewerker.PostalLocationVisibility"
      >
        <option value="1">Public</option>
        <option value="2">Within UU only</option>
        <option value="3">Not visible</option>
      </select>
    </edit-layout>

    <!-- Availability -->
    <edit-layout
      nameNL="Werkdagen"
      nameEN="Workdays"
      :info="GetInfo(info, 'Contact.Aanwezigheid')"
    >
      <div class="row">
        <div class="col-md-8">
          <table
            class="table table-condensed table-bordered table-aanwezigheid"
          >
            <thead>
              <tr>
                <th></th>
                <th>
                  <label-lng NL="Ma" EN="Mo" />
                </th>
                <th>
                  <label-lng NL="Di" EN="Tue" />
                </th>
                <th>
                  <label-lng NL="Wo" EN="Wed" />
                </th>
                <th>
                  <label-lng NL="Do" EN="Thu" />
                </th>
                <th>
                  <label-lng NL="Vr" EN="Fr" />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>
                  <label-lng NL="Ochtend" EN="Morning" />
                </th>
                <td>
                  <input
                    type="checkbox"
                    id="cntMaO"
                    v-model="medewerker.Availability.MaO"
                  />
                </td>
                <td>
                  <input
                    type="checkbox"
                    id="cntDiO"
                    v-model="medewerker.Availability.DiO"
                  />
                </td>
                <td>
                  <input
                    type="checkbox"
                    id="cntWoO"
                    v-model="medewerker.Availability.WoO"
                  />
                </td>
                <td>
                  <input
                    type="checkbox"
                    id="cntDoO"
                    v-model="medewerker.Availability.DoO"
                  />
                </td>
                <td>
                  <input
                    type="checkbox"
                    id="cntVrO"
                    v-model="medewerker.Availability.VrO"
                  />
                </td>
              </tr>
              <tr>
                <th>
                  <label-lng NL="Middag" EN="Afternoon" />
                </th>
                <td>
                  <input
                    type="checkbox"
                    id="cntMaM"
                    v-model="medewerker.Availability.MaM"
                  />
                </td>
                <td>
                  <input
                    type="checkbox"
                    id="cntDiM"
                    v-model="medewerker.Availability.DiM"
                  />
                </td>
                <td>
                  <input
                    type="checkbox"
                    id="cntWoM"
                    v-model="medewerker.Availability.WoM"
                  />
                </td>
                <td>
                  <input
                    type="checkbox"
                    id="cntDoM"
                    v-model="medewerker.Availability.DoM"
                  />
                </td>
                <td>
                  <input
                    type="checkbox"
                    id="cntVrM"
                    v-model="medewerker.Availability.VrM"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-md-4"></div>
      </div>
    </edit-layout>

    <edit-layout
      nameNL="Vrije tekst"
      nameEN="Free text"
      :info="GetInfo(info, 'Contact.TekstNL')"
    >
      <edit-textfield
        nameNL="Vrije tekst"
        nameEN="Free text"
        :value="medewerker.ContactFreeText"
        rtf="true"
        name="cnt"
        multilng="true"
      ></edit-textfield>
    </edit-layout>
  </div>
</template>

<script>
import labellng from "@/components/layout/label-lng.vue";
import edittextfield from "@/components/layout/edit-textfield.vue";
import confirmdialog from "@/components/layout/confirm.vue";
import popup from "@/components/layout/popup.vue";
import iconbutton from "@/components/layout/iconbutton.vue";
import sorting from "@/helpers/sorting.vue";
import editlayout from "@/components/layout/edit-layout.vue";
import editlayoutpopup from "@/components/layout/edit-layout-popup.vue";
import infohelper from "@/helpers/info-helper.vue";

export default {
  name: "tab-edit-contact",
  props: ["medewerker", "info"],
  data() {
    return {
      locations: [],
      positions: [],
      faculties: [],
      departments: [],
      sections: [],
      groups: [],
      selectedfaculty: null,
      selecteddepartment: null,
      selectedsection: null,
      selectedgroup: null,
      positioneditmode: false,
      postallocation: [],
      infolocatie: {
        locatie: "",
        kamernr: "",
        tel: "",
        telafd: "",
      },
    };
  },
  components: {
    "label-lng": labellng,
    "edit-textfield": edittextfield,
    confirm: confirmdialog,
    popup: popup,
    iconbutton: iconbutton,
    "edit-layout": editlayout,
    "edit-layout-popup": editlayoutpopup,
  },
  methods: {
    loaddata: function () {
      this.$http
        .get("Edit/GetLocations?l=" + this.$root.lng)
        .then(function (response) {
          this.locations = response.data;
        });
      this.$http
        .get("Edit/GetPostalLocations?l=" + this.$root.lng)
        .then(function (response) {
          this.postallocation = response.data;
        });
      this.$http
        .get("Edit/GetPositions?l=" + this.$root.lng)
        .then(function (response) {
          this.positions = response.data;
        });
      this.$http
        .get("Public/GetFaculties?l=" + this.$root.lng)
        .then(function (response) {
          this.faculties = response.data;
          this.departments = [];
          this.selecteddepartment = null;
          this.sections = [];
          this.selectedsection = null;
          this.groups = [];
          this.selectedgroup = null;
        });
    },
    deleteContact: function (cnt) {
      var data = this;
      this.$refs.confirmdialog.confirm(
        "Locatiegegevens verwijderen?",
        "Delete location details?",
        function () {
          var i = data.medewerker.ContactDetails.indexOf(cnt);
          if (i !== -1) data.medewerker.ContactDetails.splice(i, 1);
        }
      );
    },
    addContact: function () {
      this.$http
        .get(
          "Edit/NewLocation?l=" +
            this.$root.lng +
            "&e=" +
            this.medewerker.PageName
        )
        .then(function (response) {
          this.setInfoLocatieManual();
          var cnt = response.data;
          this.$refs.popupContact.show(
            cnt,
            this.medewerker.ContactDetails,
            this
          );
        });
    },
    editContact: function (cnt) {
      if (cnt.IAM) this.setInfoLocatieSAP();
      else this.setInfoLocatieManual();
      this.$refs.popupContact.show(cnt, this.medewerker.ContactDetails, this);
    },
    beforeSave: function () {
      var data = this.$refs.popupContact.getData();
      data.Location.NL = this.locations.filter(function (obj) {
        return obj.Id === data.LocationId;
      })[0].BuildingName;
    },
    locationUp: function (l) {
      this.sortup(l, this.medewerker.ContactDetails);
    },
    locationDown: function (l) {
      this.sortdown(l, this.medewerker.ContactDetails);
    },
    setPreferredLocation: function () {
      var pf = false;
      for (var i = 0; i < this.medewerker.ContactDetails.length; i++) {
        if (this.medewerker.ContactDetails[i].Visible) {
          if (pf === false) {
            this.medewerker.ContactDetails[i].Preferred = true;
            pf = true;
          } else this.medewerker.ContactDetails[i].Preferred = false;
        } else this.medewerker.ContactDetails[i].Preferred = false;
      }
    },
    setPreferredPosition: function () {
      var pp = 0;
      for (var i = 0; i < this.medewerker.Positions.length; i++) {
        if (this.medewerker.Positions[i].Visible) {
          if (pp < 2) {
            this.medewerker.Positions[i].Preferred = true;
            pp++;
          } else this.medewerker.Positions[i].Preferred = false;
        } else this.medewerker.Positions[i].Preferred = false;
      }
    },
    positionUp: function (p) {
      this.sortup(p, this.medewerker.Positions);
    },
    positionDown: function (p) {
      this.sortdown(p, this.medewerker.Positions);
    },
    addPosition: function () {
      this.selectedfaculty = null;
      this.selecteddepartment = null;
      this.selectedsection = null;
      this.selectedgroup = null;
      this.$http
        .get(
          "Edit/NewPosition?l=" +
            this.$root.lng +
            "&e=" +
            this.medewerker.PageName
        )
        .then(function (response) {
          var pos = response.data;
          this.$refs.popupPosition.show(pos, this.medewerker.Positions, this);
        });
    },
    editPosition: function (p) {
      this.positioneditmode = true;
      this.selectedfaculty = p.FacultyId;
      this.selecteddepartment = p.DepartmentId;
      this.selectedsection = p.SectionId;
      this.selectedgroup = p.GroupId;

      this.$refs.popupPosition.show(p, this.medewerker.Positions, this);
      /*
      this.$http.get('Public/GetDepartments?l='+this.$root.lng + '&includeunused=true&f='+this.selectedfaculty.toString()).then(function(response) {
        this.departments = response.data;
        this.selecteddepartment = p.DepartmentId;
        this.$http.get('Public/GetSections?l='+this.$root.lng + '&includeunused=true&d='+this.selecteddepartment.toString()).then(function(response) {
          this.sections = response.data;
          this.selectedsection = p.SectionId;
          if (p.GroupId != null)
          {
            this.$http.get('Public/GetGroups?l='+this.$root.lng + '&includeunused=true&s='+this.selectedsection.toString()).then(function(response) {
              this.groups = response.data;
              this.selectedgroup = p.GroupId;
              this.$refs.popupPosition.show(p, this.medewerker.Positions, this);
            });

          }
          else {
            this.groups = [];
            this.$refs.popupPosition.show(p, this.medewerker.Positions, this);
          }
        });
      });
      */
    },
    deletePosition: function (p) {
      var data = this;
      this.$refs.confirmdialog.confirm(
        "Aanstelling verwijderen?",
        "Delete position?",
        function () {
          var i = data.medewerker.Positions.indexOf(p);
          if (i !== -1) data.medewerker.Positions.splice(i, 1);
        }
      );
    },
    beforeSavePosition: function () {
      var data = this.$refs.popupPosition.getData();
      data.FacultyId = this.selectedfaculty;
      data.DepartmentId = this.selecteddepartment;
      data.SectionId = this.selectedsection;
      data.GroupId = this.selectedgroup;
      data.Faculty = this.faculties.filter(function (obj) {
        return obj.FacultyId === data.FacultyId;
      })[0].Faculty;
      data.Department = this.departments.filter(function (obj) {
        return obj.DepartmentId === data.DepartmentId;
      })[0].Department;
      data.Section = this.sections.filter(function (obj) {
        return obj.SectionId === data.SectionId;
      })[0].Section;
      if (data.GroupId == null) {
        data.Group = "";
      } else {
        data.Group = this.groups.filter(function (obj) {
          return obj.GroupId === data.GroupId;
        })[0].Group;
      }
      data.Position = this.positions.filter(function (obj) {
        return obj.PositionId === data.PositionId;
      })[0].Position;
    },

    setInfoLocatieSAP: function () {
      this.infolocatie.locatie = this.GetInfo(
        this.info,
        "Contact.Locatie.Locatie"
      );
      this.infolocatie.kamernr = this.GetInfo(
        this.info,
        "Contact.Locatie.Kamernummer"
      );
      this.infolocatie.tel = this.GetInfo(
        this.info,
        "Contact.Locatie.Telefoonnummer"
      );
      this.infolocatie.telafd = this.GetInfo(
        this.info,
        "Contact.Locatie.TelefoonnummerAfdeling"
      );
    },
    setInfoLocatieManual: function () {
      this.infolocatie.locatie = this.GetInfo(
        this.info,
        "Contact.Locatie.Locatie-Manual"
      );
      this.infolocatie.kamernr = this.GetInfo(
        this.info,
        "Contact.Locatie.Kamernummer-Manual"
      );
      this.infolocatie.tel = this.GetInfo(
        this.info,
        "Contact.Locatie.Telefoonnummer-Manual"
      );
      this.infolocatie.telafd = this.GetInfo(
        this.info,
        "Contact.Locatie.TelefoonnummerAfdeling-Manual"
      );
    },
  },
  watch: {
    "medewerker.ContactDetails": {
      handler: function () {
        this.setPreferredLocation();
      },
      deep: true,
    },
    "medewerker.Positions": {
      handler: function () {
        this.setPreferredPosition();
      },
      deep: true,
    },
    selectedfaculty: function (newValue) {
      if (newValue != null) {
        this.$http
          .get(
            "Public/GetDepartments?l=" +
              this.$root.lng +
              "&includeunused=true&f=" +
              newValue.toString()
          )
          .then(function (response) {
            this.departments = response.data;
            if (!this.positioneditmode) {
              if (this.departments.length > 0) {
                this.selecteddepartment = this.departments[0].DepartmentId;
              } else {
                this.selecteddepartment = null;
                this.sections = [];
                this.selectedsection = null;
                this.groups = [];
                this.selectedgroup = null;
              }
            }
          });
      }
    },
    selecteddepartment: function (newValue) {
      if (newValue != null) {
        this.$http
          .get(
            "Public/GetSections?l=" +
              this.$root.lng +
              "&includeunused=true&d=" +
              newValue.toString()
          )
          .then(function (response) {
            this.sections = response.data;
            if (!this.positioneditmode) {
              if (this.sections.length > 0) {
                this.selectedsection = this.sections[0].SectionId;
              } else {
                this.selectedsection = null;
                this.groups = [];
                this.selectedgroup = null;
              }
            }
          });
      }
    },
    selectedsection: function (newValue) {
      if (newValue != null) {
        this.$http
          .get(
            "Public/GetGroups?l=" +
              this.$root.lng +
              "&includeunused=true&s=" +
              newValue.toString()
          )
          .then(function (response) {
            this.groups = response.data;
            if (!this.positioneditmode) {
              if (this.groups.length > 0) {
                this.selectedgroep = this.groups[0].GroupId;
              } else this.selectedgroup = null;
              this.positioneditmode = false;
            }
          });
      } else {
        this.positioneditmode = false;
      }
    },
  },
  created: function () {
    this.loaddata();
  },
  mixins: [sorting, infohelper],
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
table {
  table-layout: fixed;
}
td {
  overflow: hidden;
}

.iconcolumn {
  width: 50px;
}

.preferred {
  background-color: #ffcd00;
}

.table-positions,
.table-locations {
  font-size: 9pt; /* Wat kleinere fonts in tabellen*/
}

/* Sortering-pijltjes: zorg dat bovenste up en onderste down niet zichtbaar is */
tbody > tr:first-child > td > .up {
  display: none;
}
tbody > tr:first-child > td > .down {
  margin-left: 20px;
}
tbody > tr:last-child > td > .down {
  display: none;
}

.table-aanwezigheid {
  text-align: center;
}

.ColNarrow {
  width: 80px;
}

.extra-marge {
  margin-top: 30px;
}
</style>

<template>
  <span>
    <b-modal
      id="modalConfirm"
      ref="modalConfirm"
      :title='($root.lng=="NL" ? "Bevestigen" : "Confirm")'
      @ok="onclick"
      :ok-title='($root.lng=="NL" ? "Ja" : "Yes")'
      :cancel-title='($root.lng=="NL" ? "Nee" : "No")'
      v-model="showmodal"
    >
      <p>
        <span v-html='($root.lng=="NL" ? messageNL : messageEN)' />
      </p>
    </b-modal>
  </span>
</template>

<script>
export default {
  name: "confirm",
  props: [],
  data() {
    return {
      messageNL: null,
      messageEN: null,
      showmodal: false,
      callbackfunction: null
    };
  },
  methods: {
    onclick: function() {
      if (this.callbackfunction != null) this.callbackfunction();
    },
    confirm(msgNL, msgEN, callback) {
      this.showmodal = true;
      this.messageNL = msgNL;
      this.messageEN = msgEN;
      this.callbackfunction = callback;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

<template>
  <div class="DivLogin">
    <div class="row">
      <div class="col-md-1"></div>
      <div class="col-md-10">
        <div class="card">
          <div class="card-block">
            <form v-on:submit.prevent="noop">
              <h4 class="card-title font-sidebar">
                <label-lng NL="Inloggen" EN="Please sign in" />
              </h4>
              <div class="form-group">
                <label for="username" class="sr-only">Solis ID</label>
                <input
                  type="text"
                  id="username"
                  class="form-control"
                  placeholder="Solis ID"
                  required
                  autofocus
                  v-model="credentials.Username"
                />
              </div>
              <div class="form-group">
                <label for="password" class="sr-only">
                  <label-lng NL="Wachtwoord" EN="Password" />
                </label>
                <input
                  type="password"
                  id="password"
                  class="form-control"
                  placeholder="Password"
                  required
                  v-model="credentials.Password"
                />
              </div>
              <div class="form-group font-sidebar">
                <input
                  type="checkbox"
                  id="rememberpassword"
                  v-model="Remember"
                />
                <label for="rememberpassword">
                  <label-lng NL="Wachtwoord onthouden" EN="Remember password" />
                </label>
              </div>
              <div>
                <b-alert variant="danger" v-if="loginfailed" show>
                  <label-lng NL="Inloggen mislukt" EN="Login failed" />
                </b-alert>
              </div>
              <button
                type="submit"
                class="uu-button button-yellow"
                v-on:click="login()"
                ref="login"
              >
                <label-lng NL="Inloggen" EN="Sign in" />
              </button>
            </form>
          </div>
        </div>
      </div>
      <div class="col-md-1"></div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import labellng from "@/components/layout/label-lng.vue";

export default {
  name: "login",
  data() {
    return {
      credentials: {
        Username: "",
        Password: "",
        Token: "",
      },
      Remember: false,
      loginfailed: false,
    };
  },
  methods: {
    login: function () {
      /* Alleen voor DEV/TST, in ACC/PROD wordt SAML gebruikt */
      this.credentials.Token = "";
      this.$http.post("Edit/CheckCredentials", this.credentials).then(
        function (response) {
          if (response.data.Success) {
            window.localStorage.setItem("token", response.data.Token);
            Vue.http.headers.common["Authorization"] =
              "Bearer " + response.data.Token;

            /* Oude auth
            Vue.http.headers.common["Authorization"] =
              "Basic " +
              btoa(this.credentials.Username + ":" + this.credentials.Password);
            window.localStorage.setItem("token", response.data.Token);
            this.loginfailed = false;
            // Credentials opslaan in local storage
            window.sessionStorage.setItem(
              "authUser",
              btoa(JSON.stringify(this.credentials))
            );
            */
            window.localStorage.setItem(
              "authUserTimeout",
              new Date(new Date().getTime() + 28800000)
            );
            window.localStorage.setItem("isadmin", response.data.IsAdmin);
            this.$root.$emit("login_event");

            var url = this.$route.params.returnpath;
            this.$router.push({ path: url });
          } else {
            this.loginfailed = true;
            window.localStorage.setItem("authUserTimeout", null);
            window.localStorage.setItem("token", null);
          }
        },
        // error
        function () {
          this.loginfailed = true;
        }
      );
    },
    switchlng: function () {},
    noop: function () {},
    dc: function (a) {
      if (a && a !== "null") return atob(a);
      else return null;
    },
  },
  created: function () {
    this.$root.$on("switchlng", this.switchlng);

    // Probeer automatisch in te loggen met gegevens uit localstorage
    var lsUser = JSON.parse(this.dc(window.sessionStorage.getItem("authUser")));
    if (lsUser) {
      // Controleer of de gegevens uit localStorage niet over de datum zijn...
      var timeout = new Date(window.localStorage.getItem("authUserTimeout"));
      if (timeout == null || timeout > new Date()) {
        this.Remember = true;
        this.credentials.Username = lsUser.Username;
        this.credentials.Password = lsUser.Password;
        this.login();
      }
    }
  },
  components: {
    "label-lng": labellng,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
a {
  color: #ffcd00;
}

.card {
  border: none;
}

.card-title {
  font-size: 18px;
}

.form-group {
  font-size: 14px;
}

.DivLogin {
  margin-top: 20px;
  margin-bottom: 20px;
}

#rememberpassword {
  margin-right: 8px;
}

.card {
  width: 20rem;
}
</style>

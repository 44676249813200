<template>
  <div v-if="medewerker" class="TabEditProfileLinks">
    <confirm ref="confirmdialog"></confirm>

    <div class="row">
      <div class="col-md-12">
        <table class="table table-sm table-bordered">
          <thead>
            <tr>
              <th>
                <label-lng NL="Link (Nederlands)" EN="Link (Dutch)" />
              </th>
              <th>
                <label-lng NL="Link (Engels)" EN="Link (English)" />
              </th>
              <th class="iconcolumn"></th>
              <th class="iconcolumn"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="l in medewerker.Links" :key="l.Guid">
              <td>{{ l.UrlNL }}</td>
              <td>{{ l.UrlEN }}</td>
              <td>
                <iconbutton
                  icon="chevron-up"
                  titleNL="Hoger"
                  titleEN="Up"
                  @click="up(l)"
                  class="up"
                  style="margin-right: 4px"
                />
                <iconbutton
                  icon="chevron-down"
                  titleNL="Lager"
                  titleEN="Down"
                  @click="down(l)"
                  class="down"
                />
              </td>
              <td>
                <iconbutton
                  icon="pencil-alt"
                  titleNL="Bewerken"
                  titleEN="Edit"
                  @click="editLink(l)"
                  style="margin-right: 4px"
                />
                <iconbutton
                  icon="trash"
                  titleNL="Verwijderen"
                  titleEN="Delete"
                  @click="deleteLink(l)"
                />
              </td>
            </tr>
          </tbody>
        </table>
        <button
          class="uu-button button-black"
          v-on:click="addLink()"
          variant="link"
        >
          <label-lng NL="Link toevoegen" EN="Add link"></label-lng>
        </button>
      </div>
    </div>

    <popup ref="popupLink" titleNL="Link" titleEN="Link">
      <form slot-scope="popup">
        <div v-if="popup.data != null">
          <div class="form-group">
            <label for="linkurlnl">
              <label-lng NL="Link (NL)" EN="Link (NL)"></label-lng>
            </label>
            <input
              v-model="popup.data.UrlNL"
              id="linkurlnl"
              class="form-control"
            />
          </div>
          <div class="form-group">
            <label for="linknamenl">
              <label-lng
                NL="Omschrijving (NL)"
                EN="Description (NL)"
              ></label-lng>
            </label>
            <input
              v-model="popup.data.NameNL"
              id="linknamenl"
              class="form-control"
            />
          </div>
          <div class="form-group">
            <label for="linkurlen">
              <label-lng NL="Link (EN)" EN="Link (EN)"></label-lng>
            </label>
            <input
              v-model="popup.data.UrlEN"
              id="linkurlen"
              class="form-control"
            />
          </div>
          <div class="form-group">
            <label for="linknameen">
              <label-lng
                NL="Omschrijving (EN)"
                EN="Description (EN)"
              ></label-lng>
            </label>
            <input
              v-model="popup.data.NameEN"
              id="linknameen"
              class="form-control"
            />
          </div>
        </div>
      </form>
    </popup>
  </div>
</template>

<script>
import labellng from "@/components/layout/label-lng.vue";
import confirmdialog from "@/components/layout/confirm.vue";
import popup from "@/components/layout/popup.vue";
import iconbutton from "@/components/layout/iconbutton.vue";
import sorting from "@/helpers/sorting.vue";

export default {
  name: "tab-edit-profile-links",
  props: ["medewerker"],
  data() {
    return {};
  },
  components: {
    "label-lng": labellng,
    confirm: confirmdialog,
    popup: popup,
    iconbutton: iconbutton,
  },
  methods: {
    addLink: function () {
      var maxorderindex = 0;
      for (var i = 0; i < this.medewerker.Links.length; i++) {
        if (this.medewerker.Links[i].OrderIndex > maxorderindex) {
          maxorderindex = this.medewerker.Links[i].OrderIndex;
        }
      }
      this.$refs.popupLink.show(
        { OrderIndex: maxorderindex + 1 },
        this.medewerker.Links,
        this
      );
    },
    editLink: function (link) {
      this.$refs.popupLink.show(link, this.medewerker.Links, this);
    },
    deleteLink: function (link) {
      var mdw = this.medewerker;
      this.$refs.confirmdialog.confirm(
        "Link verwijderen?",
        "Delete link?",
        function () {
          var i = mdw.Links.indexOf(link);
          if (i !== -1) {
            mdw.Links.splice(i, 1);
          }
        }
      );
    },

    // Sortering
    up: function (link) {
      this.sortup(link, this.medewerker.Links);
    },
    down: function (link) {
      this.sortdown(link, this.medewerker.Links);
    },
  },
  mixins: [sorting],
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
table {
  table-layout: fixed;
}
td {
  overflow: hidden;
}

.iconcolumn {
  width: 50px;
}
</style>

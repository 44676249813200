<template>
  <div v-if="medewerker" class="TabCourses public-tab-content">
    <textfield
      nameNL
      nameEN
      :lng="lng"
      :html="medewerker.CoursesFreeText"
      v-if="medewerker.CoursesFreeText"
    ></textfield>

    <textfield
      nameNL="Betrokken bij opleiding(en)"
      nameEN="Involved in the following study programme(s)"
      v-if="medewerker.StudyProgrammes && medewerker.StudyProgrammes.length > 0"
    >
      <ul>
        <li v-for="sp in medewerker.StudyProgrammes" :key="sp.Guid">
          <a :href="sp.Url" target="_blank" v-show="sp.Url">{{sp.Name}}</a>
          <span v-show="!sp.Url">{{sp.Name}}</span>
          {{sp.Addition}}
        </li>
      </ul>
    </textfield>

    <textfield
      nameNL="Betrokken bij de volgende cursus(sen)"
      nameEN="Involved in the following course(s)"
      v-if="medewerker.Courses && medewerker.Courses.length > 0"
    >
      <ul>
        <li v-for="c in medewerker.Courses" :key="c.Guid">
          <span v-if="c.Url == null || c.Url == ''">{{c.Name}}</span>
          <a
            :href="c.Url"
            class="external"
            target="_blank"
            v-if="c.Url != null && c.Url != ''"
          >{{c.Name}}</a>
        </li>
      </ul>
    </textfield>
  </div>
</template>

<script>
import textfield from '@/components/layout/textfield.vue'

export default {
  name: 'tab-courses',
  data () {
    return {}
  },
  props: ['medewerker', 'lng'],
  components: {
    textfield: textfield,
  }
};
</script>

<style scoped>
.table-condense {
  margin-top: 34px;
}
</style>

<script>
/*
    Toelichtingsteksten
*/

export default {
  methods: {
    GetInfo: function(arr, info) {
      if (arr) {
        var a = arr.find(x => x.Key === info);
        if (a) return a.Value;
        else return "";
      } else return "";
    }
  }
};
</script>

<template>
  <admin-layout title="Handmatig profielpagina aanmaken" v-if="medewerker">
    <div class="row">
      <div class="col-md-2">Faculteit/dienst</div>
      <div class="col-md-6">
        <select v-model="medewerker.FaculteitId">
          <option v-for="f in faculties" :value="f.FacultyId" :key="f.Guid">{{f.Faculty}}</option>
        </select>
      </div>
      <div class="col-md-4"></div>
    </div>
    <div class="row">
      <div class="col-md-2">Url</div>
      <div class="col-md-6">
        https://www.uu.nl/medewerkers/
        <input
          v-model="medewerker.ProfielpaginaNaam"
          maxlength="250"
          class="InputUrl"
        />
      </div>
      <div class="col-md-4"></div>
    </div>
    <div class="row">
      <div class="col-md-2">Voorletters</div>
      <div class="col-md-6">
        <input v-model="medewerker.Voorletters" maxlength="50" />
      </div>
      <div class="col-md-4"></div>
    </div>
    <div class="row">
      <div class="col-md-2">Roepnaam</div>
      <div class="col-md-6">
        <input v-model="medewerker.Roepnaam" maxlength="200" />
      </div>
      <div class="col-md-4"></div>
    </div>
    <div class="row">
      <div class="col-md-2">Voorvoegsel</div>
      <div class="col-md-6">
        <input v-model="medewerker.Voorvoegsel" maxlength="50" />
      </div>
      <div class="col-md-4"></div>
    </div>
    <div class="row">
      <div class="col-md-2">Achternaam</div>
      <div class="col-md-6">
        <input v-model="medewerker.Achternaam" maxlength="200" />
      </div>
      <div class="col-md-4"></div>
    </div>
    <div class="row">
      <div class="col-md-2">E-mail</div>
      <div class="col-md-6">
        <input v-model="medewerker.Email" maxlength="200" />
      </div>
      <div class="col-md-4"></div>
    </div>
    <div class="row">
      <div class="col-md-2">AD name</div>
      <div class="col-md-6">
        <input v-model="medewerker.ADname" maxlength="250" />
      </div>
      <div class="col-md-4"></div>
    </div>
    <div class="row">
      <div class="col-md-2">SolisId</div>
      <div class="col-md-6">
        <input v-model="medewerker.SolisId" maxlength="10" />
      </div>
      <div class="col-md-4"></div>
    </div>
    <div class="row">
      <div class="col-md-2"></div>
      <div class="col-md-6">
        <button class="uu-button button-black" @click="createPage()">Pagina aanmaken</button>
      </div>
      <div class="col-md-4"></div>
    </div>
  </admin-layout>
</template>

<script>
import AdminLayout from "@/components/layout/admin-layout.vue";

export default {
  name: "BeheerNewPage",
  components: {
    "admin-layout": AdminLayout
  },
  data() {
    return {
      medewerker: null,
      faculties: []
    };
  },
  methods: {
    loaddata: function() {
      this.$Progress.start();
      this.$http.get("Admin/InitNewPage").then(
        function(response) {
          this.medewerker = response.data;
          this.$Progress.finish();
        },
        function() {
          // Fail
          this.$Progress.finish();
        }
      );
      this.$http
        .get("Public/GetFaculties?l=" + this.$root.lng)
        .then(function(response) {
          this.faculties = response.data;
        });
    },
    createPage: function() {
      this.$Progress.start();
      this.$http.post("Admin/CreatePage", this.medewerker).then(
        function(response) {
          this.$Progress.finish();
          if (response.data.Success) {
            // Redirect naar zojuist aangemaakte pagina in bewerk-mode
            var routename =
              this.$root.lng === "NL" ? "EditNL" : (routename = "EditEN");
            this.$router.push({
              name: routename,
              params: { employee: response.data.Message }
            });
          } else {
            alert(response.data.Message);
          }
        },
        function() {
          // Fail
          this.$Progress.finish();
          alert("Aanmaken pagina mislukt.");
        }
      );
    }
  },
  created: function() {
    this.loaddata();
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
input {
  width: 100%;
}

select {
  width: 100%;
}

.InputUrl {
  width: 300px;
}

.row {
  margin-top: 8px;
  margin-bottom: 8px;
}
</style>

<template>
  <div v-if="medewerker" class="TabEditAdmin">
    <edit-layout
      nameNL="Profielpaginanaam"
      nameEN="Profile page name"
      :info="GetInfo(info, 'Beheer.PaginaNaam')"
    >
      <input
        type="text"
        v-model="medewerker.EditPageName"
        maxlength="250"
        v-on:keyup="checkpagename"
        :class="{ invalid: invalidpagename }"
      />
      <span v-show="invalidpagename"
        >Er bestaat al een pagina met deze naam</span
      >
    </edit-layout>

    <edit-layout
      nameNL="Active Directory Name"
      nameEN="Active Directory Name"
      :info="GetInfo(info, 'Beheer.ADnaam')"
    >
      <input type="text" v-model="medewerker.ADName" maxlength="250" />
    </edit-layout>

    <edit-layout
      nameNL="SolisId"
      nameEN="SolisId"
      :info="GetInfo(info, 'Beheer.SolisID')"
    >
      <input type="text" v-model="medewerker.SolisId" maxlength="10" />
    </edit-layout>

    <edit-layout
      nameNL="Status"
      nameEN="State"
      :info="GetInfo(info, 'Beheer.Zichtbaar')"
    >
      <select class="form-control" v-model="medewerker.State">
        <option v-for="s in medewerker.Stati" :value="s.Key" :key="s.Guid">
          {{ s.Value }}
        </option>
      </select>
      * Huidige status
      <br />
      <span v-show="medewerker.State == 3">
        <input
          type="checkbox"
          v-model="medewerker.WelcomeMail"
          id="ckwelkomstmail"
          :value="true"
          :disabled="medewerker.WelcomeMailDisabled"
        />&nbsp;<label for="ckwelkomstmail">
          Welkomstmail versturen
          <span v-show="medewerker.WelcomeMailDisabled"
            >(verplicht bij OBP)</span
          >
        </label>
      </span>
      <br />
      Toestemming voor publiceren gevraagd? {{ medewerker.InfoAskedPermission }}
      <br />
      Toestemming voor publiceren gegeven? {{ medewerker.InfoPermission }}
      <br />
      <b-button
        v-show="medewerker.ShowResendWelcomeMail"
        :disabled="resendmailresult != null"
        @click="resendWelcomeMail"
        >Welkomstmail opnieuw verzenden</b-button
      >
      <span v-show="resendmailresult">{{ resendmailresult }}</span>
    </edit-layout>

    <edit-layout
      nameNL="Pagina offline per"
      nameEN="Pagina offline per"
      :info="GetInfo(info, 'Beheer.OfflinePer')"
    >
      <edit-datefield :value="medewerker.OfflineDate"></edit-datefield>
    </edit-layout>

    <edit-layout
      nameNL="Commentaar"
      nameEN="Comments"
      :info="GetInfo(info, 'Beheer.Commentaar')"
    >
      <input type="text" v-model="medewerker.Comments" maxlength="1000" />
    </edit-layout>

    <edit-layout
      nameNL="Pure-koppeling"
      nameEN="Pure connection"
      :info="GetInfo(info, 'Publicaties.Enabled')"
    >
      <input
        type="checkbox"
        v-model="medewerker.PureConnection"
        id="PureConnection"
      />&nbsp;<label for="PureConnection">Toon publicaties uit Pure</label>
    </edit-layout>

    <edit-layout
      nameNL="Secretariaatrechten"
      nameEN="Secretariaatrechten"
      :info="GetInfo(info, 'Beheer.SecretariaatRechten')"
    >
      <input
        type="checkbox"
        v-model="medewerker.Secretary"
        id="Secretary"
      />&nbsp;<label for="Secretary"
        >Medewerker heeft secretariaatrechten</label
      >
    </edit-layout>

    <edit-layout
      nameNL="IAM gegevens"
      nameEN="IAM data"
      info
      v-show="medewerker.IAMInfo && medewerker.IAMInfo.length > 0"
    >
      <table class="table table-sm">
        <thead>
          <tr>
            <th>Veld</th>
            <th>Waarde</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="n in medewerker.IAMInfo" :key="n.Guid">
            <td>{{ n.Key }}</td>
            <td>
              <span v-html="n.Value"></span>
            </td>
          </tr>
        </tbody>
      </table>
    </edit-layout>

    <edit-layout
      nameNL="IAM Reset"
      nameEN="IAM Reset"
      :info="GetInfo(info, 'Beheer.ResetiConnect')"
      v-show="medewerker.EditingUserIsAdmin"
    >
      <button @click="iAMReset()" class="uu-button button-black">
        Voer IAM Reset uit
      </button>
    </edit-layout>
  </div>
</template>

<script>
import infohelper from "@/helpers/info-helper.vue";
import editdatefield from "@/components/layout/edit-datefield.vue";
import editlayout from "@/components/layout/edit-layout.vue";

export default {
  name: "tab-edit-admin",
  props: ["medewerker", "info"],
  data() {
    return {
      resendmailresult: null,
      invalidpagename: false,
    };
  },
  components: {
    "edit-datefield": editdatefield,
    "edit-layout": editlayout,
  },
  methods: {
    resendWelcomeMail: function () {
      this.resendmailresult = "Bezig met verzenden...";
      this.$http
        .get("edit/ResendWelcomeMail?p=" + this.medewerker.PageName)
        .then(
          function (response) {
            if (response.data.Success) {
              this.resendmailresult = "Mail is verzonden.";
            } else {
              this.resendmailresult =
                "Mail niet verzonden. " + response.data.Message;
            }
          },
          function () {
            this.resendmailresult = "Mail niet verzonden. ";
          }
        );
    },
    checkpagename: function () {
      this.$http
        .get(
          "edit/PageNameAvailable?id=" +
            this.medewerker.Id.toString() +
            "&newname=" +
            this.medewerker.EditPageName
        )
        .then(function (response) {
          this.invalidpagename = !response.data.Success;
        });
    },
    iAMReset: function () {
      this.$http
        .get("admin/iamreset?medewerkerid=" + this.medewerker.Id.toString())
        .then(function (response) {
          if (response.data.Success) {
            alert(
              "IAM-reset is succesvol uitgevoerd. Refresh deze pagina om de gewijzigde gegevens te zien."
            );
          } else {
            alert(response.data.Message);
          }
        });
    },
  },
  mixins: [infohelper],
  created: function () {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
button {
  margin-bottom: 2px;
}

.invalid {
  background-color: red;
}
</style>

<template>
  <admin-layout title="Rapportage vulling profielpagina's">
    <div class="row">
      <div class="col-md-2">Faculteit</div>
      <div class="col-md-10">
        <select v-model="facultyId" class="inputfilter" :disabled="rapportagedetails.length!=0">
          <option v-for="f in faculties" :value="f.FacultyId" :key="f.Guid">{{f.Faculty}}</option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="col-md-2">Soort rapport</div>
      <div class="col-md-10">
        <select v-model="typeId" class="inputfilter" :disabled="rapportagedetails.length!=0">
          <option value="N12">Nevenfuncties afgelopen jaar bijgewerkt (alleen hoogleraren)</option>
          <option value="N6">Nevenfuncties afgelopen 6 maanden bijgewerkt (alleen hoogleraren)</option>
          <option value="P12">Profielpagina afgelopen jaar bijgewerkt</option>
          <option value="P6">Profielpagina afgelopen 6 maanden bijgewerkt</option>
        </select>
      </div>
    </div>

    <div class="row" v-if="rapportage.length > 0 && rapportagedetails.length==0">
      <div class="col-md-12">
        <table class="table table-sm table-bordered">
          <thead>
            <tr>
              <th>Departement</th>
              <th>Afdeling</th>
              <th>Percentage</th>
              <th>
                Aantal
                <span v-show="typeId[0]=='N'">hoogleraren</span>
                <span v-show="typeId[0]=='P'">medewerkers</span>
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="r in rapportage" :key="r.Guid">
              <td>{{r.DepartmentDisp}}</td>
              <td>{{r.Section}}</td>
              <td>{{r.Pct}}</td>
              <td>{{r.ProfessorCount}}</td>
              <td>
                <a href="#" @click="details(r);" v-if="r.ProfessorCount > 0">Details...</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row" v-if="rapportagedetails.length > 0">
      <div class="col-md-12">
        <br />
        <h2>
          {{currentDepartment}}
          <span v-show="currentSection!=null && currentSection!=''">-</span>
          {{currentSection}}
        </h2>
        <a href="#" @click="back()">&lt;&lt; Terug</a>
        <table class="table table-sm table-bordered">
          <thead>
            <tr>
              <th>Departement</th>
              <th>Afdeling</th>
              <th>Medewerker</th>
              <th>Datum bijgewerkt</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="r in rapportagedetails" :key="r.Guid">
              <td>{{r.Department}}</td>
              <td>{{r.Section}}</td>
              <td>
                <router-link
                  :to="{ name: 'Public' + $root.lng, params:{ employee: r.Url }}"
                  target="_blank"
                >{{r.Name}}</router-link>
              </td>
              <td>{{r.Date}}</td>
              <td>
                <img src="../../assets/green.png" v-if="r.Updated" />
                <img src="../../assets/red.png" v-if="!r.Updated" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </admin-layout>
</template>

<script>
import AdminLayout from "@/components/layout/admin-layout.vue";

export default {
  name: "BeheerVulling",
  components: {
    "admin-layout": AdminLayout
  },
  data() {
    return {
      rapportage: [],
      facultyId: null,
      faculties: [],
      typeId: "N12",
      rapportagedetails: [],
      currentDepartment: "",
      currentSection: ""
    };
  },
  methods: {
    loaddata: function() {
      if (this.facultyId != null) {
        this.$Progress.start();
        this.$http
          .get(
            "Admin/RapportageVulling?faculty=" +
              this.facultyId.toString() +
              "&type=" +
              this.typeId
          )
          .then(
            function(response) {
              this.rapportage = response.data;
              this.$Progress.finish();
            },
            function() {
              // Fail
              this.$Progress.finish();
            }
          );
      }
    },
    details: function(r) {
      this.$Progress.start();
      var section = "";
      if (r.SectionId != null) section = r.SectionId.toString();
      var department = "";
      if (r.DepartmentId != null) department = r.DepartmentId.toString();

      this.currentDepartment = r.Department;
      this.currentSection = r.Section;

      this.$http
        .get(
          "Admin/RapportageVulling?faculty=" +
            this.facultyId.toString() +
            "&type=" +
            this.typeId +
            "&section=" +
            section +
            "&department=" +
            department
        )
        .then(
          function(response) {
            this.rapportagedetails = response.data;
            this.$Progress.finish();
          },
          function() {
            // Fail
            this.$Progress.finish();
          }
        );
    },
    back: function() {
      this.rapportagedetails = [];
      this.currentDepartment = "";
      this.currentSection = "";
    }
  },
  created: function() {
    this.$http.get("Admin/GetAdminFaculties").then(function(response) {
      this.faculties = response.data;
      if (this.faculties.length > 0) {
        this.facultyId = this.faculties[0].FacultyId;
      }
    });
  },
  watch: {
    facultyId: function() {
      this.loaddata();
    },
    typeId: function() {
      this.loaddata();
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.iconcolumn {
  width: 30px;
}

.inputfilter {
  width: 500px;
}
</style>

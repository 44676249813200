<template>
  <div v-if="medewerker" class="TabEditProfileExpertises">
    <confirm ref="confirmdialog"></confirm>

    <div class="row">
      <div class="col-md-9">
        <table class="table table-sm table-bordered">
          <thead>
            <tr>
              <th>
                <label-lng
                  NL="Expertise (Nederlands)"
                  EN="Expertise (Dutch)"
                ></label-lng>
              </th>
              <th>
                <label-lng
                  NL="Expertise (Engels)"
                  EN="Expertise (English)"
                ></label-lng>
              </th>
              <th class="iconcolumn"></th>
              <th class="iconcolumn"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="e in medewerker.Expertises" :key="e.Guid">
              <td>{{ e.NameNL }}</td>
              <td>{{ e.NameEN }}</td>
              <td>
                <iconbutton
                  icon="chevron-up"
                  titleNL="Hoger"
                  titleEN="Up"
                  @click="up(e)"
                  class="up"
                />&nbsp;<iconbutton
                  icon="chevron-down"
                  titleNL="Lager"
                  titleEN="Down"
                  @click="down(e)"
                  class="down"
                />
              </td>
              <td>
                <iconbutton
                  icon="trash"
                  titleNL="Verwijderen"
                  titleEN="Delete"
                  @click="deleteExp(e)"
                />
              </td>
            </tr>
          </tbody>
        </table>
        <button
          class="uu-button button-black"
          v-on:click="addExp()"
          variant="link"
        >
          <label-lng NL="Expertise toevoegen" EN="Add expertise"></label-lng>
        </button>
      </div>
    </div>

    <popup ref="popupExpertise" titleNL="Expertise" titleEN="Expertise">
      <form slot-scope="popup">
        <div v-if="popup.data != null">
          <div class="form-group">
            <label for="expnl">
              <label-lng NL="Expertise" EN="Expertise"></label-lng>
            </label>
            <autocomplete
              ref="expnl"
              :placeholder="
                $root.lng == 'NL'
                  ? 'Zoek een expertise'
                  : 'Search for expertise'
              "
              :sourceurl="
                $http.options.root +
                '/Edit/SearchExpertise?l=' +
                $root.lng +
                '&term='
              "
              @OnObjectSelect="selectExp"
              results-display="Value"
              results-value="Id"
              input-class
            />

            <span v-html="GetInfo(info, 'Profiel.ExpertiseToevoegen')"></span>
          </div>
        </div>
      </form>
    </popup>
  </div>
</template>

<script>
import labellng from "@/components/layout/label-lng.vue";
import confirmdialog from "@/components/layout/confirm.vue";
import popup from "@/components/layout/popup.vue";
import Autocomplete from "@/components/layout/autocomplete.vue";
import iconbutton from "@/components/layout/iconbutton.vue";
import infohelper from "@/helpers/info-helper.vue";
import sorting from "@/helpers/sorting.vue";

export default {
  name: "tab-edit-profile-expertises",
  props: ["medewerker", "info"],
  data() {
    return {};
  },
  components: {
    "label-lng": labellng,
    confirm: confirmdialog,
    popup: popup,
    autocomplete: Autocomplete,
    iconbutton: iconbutton,
  },
  methods: {
    addExp: function () {
      var maxorderindex = 0;
      for (var i = 0; i < this.medewerker.Expertises.length; i++) {
        if (this.medewerker.Expertises[i].OrderIndex > maxorderindex) {
          maxorderindex = this.medewerker.Expertises[i].OrderIndex;
        }
      }
      this.$refs.popupExpertise.show(
        { OrderIndex: maxorderindex + 1 },
        this.medewerker.Expertises,
        this
      );
      if (this.$refs.expnl) {
        // Wordt niet gevonden omdat expnl in popup zit
        this.$refs.expnl.clear();
        this.$refs.expnl.focus();
      }
    },
    deleteExp: function (exp) {
      var mdw = this.medewerker;
      this.$refs.confirmdialog.confirm(
        "Expertise verwijderen?",
        "Delete expertise?",
        function () {
          var i = mdw.Expertises.indexOf(exp);
          if (i !== -1) {
            mdw.Expertises.splice(i, 1);
          }
        }
      );
    },
    selectExp: function (exp) {
      var data = this.$refs.popupExpertise.getData();
      data.NameNL = exp.NameNL;
      data.NameEN = exp.NameEN;
      data.Id = exp.Id;
    },

    // Sortering
    up: function (expertise) {
      this.sortup(expertise, this.medewerker.Expertises);
    },
    down: function (expertise) {
      this.sortdown(expertise, this.medewerker.Expertises);
    },
  },
  mixins: [infohelper, sorting],
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
table {
  table-layout: fixed;
}
td {
  overflow: hidden;
}

.iconcolumn {
  width: 50px;
}
</style>

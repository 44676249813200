<template>
  <span v-html="gettel"></span>
</template>

<script>
export default {
  name: "label-tel-lng",
  data() {
    return {};
  },
  props: ["NL", "EN", "tel"],
  computed: {
    gettel: function() {
      if (this.tel) {
        if (this.$root.lng === "EN") {
          return (
            this.EN +
            '<a href="tel:' +
            this.tel.replace(/ /g, "") +
            '" class="telnr">' +
            this.tel +
            "</a>"
          );
        } else {
          return (
            this.NL +
            '<a href="tel:' +
            this.tel.replace(/ /g, "") +
            '" class="telnr">' +
            this.tel +
            "</a>"
          );
        }
      } else {
        return "";
      }
    }
  }
};
</script>

<style>
.telnr {
  text-decoration: underline;
}
</style>

<template>
  <admin-layout title="Beheer nevenfunctie configuratie">
    <div class="row">
      <div class="col-md-3">
        <label for="faculty">Faculteit</label>
      </div>
      <div class="col-md-9">
        <select class="form-control" v-model="faculty" id="faculty">
          <option v-for="f in faculties" :value="f.FacultyId" :key="f.Guid">{{f.Faculty}}</option>
        </select>
      </div>
    </div>

    <div class="row">
      <div class="col-md-3" v-if="workflow">
        <label for="WorkflowEnabled">Nevenfuncties via workflow</label>
      </div>
      <div class="col-md-9" v-if="workflow">
        <input id="WorkflowEnabled" type="checkbox" v-model="workflow.WorkflowEnabled" />
      </div>
    </div>

    <div class="row">
      <div class="col-md-3" v-if="workflow">
        <label for="SolisIDs">Accordeerders</label>
      </div>
      <div class="col-md-9" v-if="workflow">
        <textarea rows="8" cols="50" name="SolisIDs" v-model="workflow.SolisIDs"></textarea>
      </div>
    </div>

    <div class="row">
      <label v-if="result && result.Success">Opslaan gelukt</label>
      <label v-if="result && !result.Success">Opslaan mislukt: {{ result.Message }}</label>
    </div>
    <div class="row">
      <input
        type="submit"
        class="uu-button button-black"
        @click="saveworkflow()"
        value="Opslaan"
        v-if="workflow"
      />>
    </div>
  </admin-layout>
</template>

<script>
import AdminLayout from "@/components/layout/admin-layout.vue";

export default {
  name: "BeheerWorkflowNevenfuncties",
  components: {
    "admin-layout": AdminLayout
  },
  data() {
    return {
      faculty: "5",
      faculties: null,
      workflow: null,
      result: null
    };
  },
  methods: {
    loaddata: function() {
      this.result = null;
      this.$http
        .get("Public/GetFaculties?l=" + this.$root.lng)
        .then(function(response) {
          this.faculties = response.data;
        });
    },
    loadworkflow: function(newfaculty) {
      this.result = null;
      this.$http
        .get(
          "Admin/GetAdditionalFunctionSettings?facultyId=" +
            newfaculty.toString()
        )
        .then(function(response) {
          this.workflow = response.data;
        });
    },
    saveworkflow: function() {
      this.$Progress.start();
      this.$http
        .post("Admin/SaveAdditionalFunctionSettings", this.workflow)
        .then(
          function(response) {
            this.result = response.data;
            this.$Progress.finish();
          },
          function() {
            this.result = null;
            this.$Progress.finish();
          }
        );
    }
  },
  watch: {
    faculty: function(newfaculty) {
      this.loadworkflow(newfaculty);
    }
  },
  created: function() {
    this.loaddata();
    this.loadworkflow(this.faculty);
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

<template>
  <div>
    <div class="row" v-if="!medewerker">
      <div class="col-md-1"></div>
      <div class="col-md-10">Gegevens worden geladen....</div>
      <div class="col-md-1"></div>
    </div>
    <div class="profielpagina" v-if="medewerker">
      <bs-title
        :title="
          medewerker.Name +
            ' - ' +
            medewerker.Faculties[0].Faculty +
            ' - ' +
            medewerker.Organisation
        "
      ></bs-title>

      <div class="row name-bar">
        <div class="col-md-1"></div>
        <div class="col-md-10">
          <tab-header :medewerker="medewerker" />
        </div>
        <div class="col-md-1"></div>
      </div>

      <div class="row">
        <div class="col-md-1 sidebar-background"></div>
        <div class="col-md-2 sidebar-background">
          <!--Sidebar-->
          <div class="sidebar font-sidebar">
            <div class="sidebar-tabs">
              <a
                href="javascript:void(0)"
                class="nav-link"
                :class="{ actief: activetab == medewerker.Tabs.Profile.Title }"
                v-if="medewerker.Tabs.Profile.Visible"
                v-on:click="switchtab(medewerker.Tabs.Profile.Title)"
                >{{ medewerker.Tabs.Profile.Title }}</a
              >
              <a
                href="javascript:void(0)"
                class="nav-link"
                :class="{ actief: activetab == medewerker.Tabs.CV.Title }"
                v-if="medewerker.Tabs.CV.Visible"
                v-on:click="switchtab(medewerker.Tabs.CV.Title)"
                >{{ medewerker.Tabs.CV.Title }}</a
              >
              <a
                href="javascript:void(0)"
                class="nav-link"
                :class="{ actief: activetab == medewerker.Tabs.Media.Title }"
                v-if="medewerker.Tabs.Media.Visible"
                v-on:click="switchtab(medewerker.Tabs.Media.Title)"
                >{{ medewerker.Tabs.Media.Title }}</a
              >
              <a
                href="javascript:void(0)"
                class="nav-link"
                :class="{ actief: activetab == medewerker.Tabs.Prizes.Title }"
                v-if="medewerker.Tabs.Prizes.Visible"
                v-on:click="switchtab(medewerker.Tabs.Prizes.Title)"
                >{{ medewerker.Tabs.Prizes.Title }}</a
              >
              <a
                href="javascript:void(0)"
                class="nav-link"
                :class="{
                  actief: activetab == medewerker.Tabs.Publications.Title
                }"
                v-if="medewerker.Tabs.Publications.Visible"
                v-on:click="switchtab(medewerker.Tabs.Publications.Title)"
                >{{ medewerker.Tabs.Publications.Title }}</a
              >
              <a
                href="javascript:void(0)"
                class="nav-link"
                :class="{
                  actief: activetab == medewerker.Tabs.Activities.Title
                }"
                v-if="medewerker.Tabs.Activities.Visible"
                v-on:click="switchtab(medewerker.Tabs.Activities.Title)"
                >{{ medewerker.Tabs.Activities.Title }}</a
              >
              <a
                href="javascript:void(0)"
                class="nav-link"
                :class="{ actief: activetab == medewerker.Tabs.Research.Title }"
                v-if="medewerker.Tabs.Research.Visible"
                v-on:click="switchtab(medewerker.Tabs.Research.Title)"
                >{{ medewerker.Tabs.Research.Title }}</a
              >
              <a
                href="javascript:void(0)"
                class="nav-link"
                :class="{ actief: activetab == medewerker.Tabs.Courses.Title }"
                v-if="medewerker.Tabs.Courses.Visible"
                v-on:click="switchtab(medewerker.Tabs.Courses.Title)"
                >{{ medewerker.Tabs.Courses.Title }}</a
              >
              <a
                href="javascript:void(0)"
                class="nav-link"
                :class="{
                  actief: activetab == medewerker.Tabs.AdditionalFunctions.Title
                }"
                v-if="medewerker.Tabs.AdditionalFunctions.Visible"
                v-on:click="
                  switchtab(medewerker.Tabs.AdditionalFunctions.Title)
                "
                >{{ medewerker.Tabs.AdditionalFunctions.Title }}</a
              >
              <a
                href="javascript:void(0)"
                class="nav-link"
                :class="{
                  actief: activetab == medewerker.Tabs.CustomTab1.Title
                }"
                v-if="medewerker.Tabs.CustomTab1.Visible"
                v-on:click="switchtab(medewerker.Tabs.CustomTab1.Title)"
                >{{ medewerker.Tabs.CustomTab1.Title }}</a
              >
              <a
                href="javascript:void(0)"
                class="nav-link"
                :class="{
                  actief: activetab == medewerker.Tabs.CustomTab2.Title
                }"
                v-if="medewerker.Tabs.CustomTab2.Visible"
                v-on:click="switchtab(medewerker.Tabs.CustomTab2.Title)"
                >{{ medewerker.Tabs.CustomTab2.Title }}</a
              >
              <a
                href="javascript:void(0)"
                class="nav-link"
                :class="{
                  actief: activetab == medewerker.Tabs.CustomTab3.Title
                }"
                v-if="medewerker.Tabs.CustomTab3.Visible"
                v-on:click="switchtab(medewerker.Tabs.CustomTab3.Title)"
                >{{ medewerker.Tabs.CustomTab3.Title }}</a
              >
              <a
                href="javascript:void(0)"
                class="nav-link"
                :class="{ actief: activetab == medewerker.Tabs.Contact.Title }"
                v-if="medewerker.Tabs.Contact.Visible"
                v-on:click="switchtab(medewerker.Tabs.Contact.Title)"
                >{{ medewerker.Tabs.Contact.Title }}</a
              >
            </div>

            <div
              class="sidebar-block"
              v-if="medewerker.FocusAreas && medewerker.FocusAreas.length > 0"
            >
              <div>
                <label-lng NL="Onderzoeksthema's" EN="Research areas" />
              </div>
              <ul v-for="fa in medewerker.FocusAreas" :key="fa.Guid">
                <li>
                  <a :href="fa.Url">{{ fa.Name }}</a>
                </li>
              </ul>
            </div>
            <div
              class="sidebar-block"
              v-if="medewerker.Expertises && medewerker.Expertises.length > 0"
            >
              <div>
                <label-lng NL="Expertises" EN="Areas of expertise" />
              </div>
              <ul>
                <li v-for="e in medewerker.Expertises" :key="e.Guid">
                  <a :href="e.Url">{{ e.Name }}</a>
                </li>
              </ul>
            </div>
            <div
              class="sidebar-block"
              v-if="medewerker.Skills && medewerker.Skills.length > 0"
            >
              <div>
                <label-lng NL="Vaardigheden" EN="Skills" />
              </div>
              <ul>
                <li v-for="s in medewerker.Skills" :key="s.Guid">
                  <a :href="s.Url">{{ s.Name }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-md-8 maincontent">
          <!-- Main content -->
          <div v-show="istabactive(medewerker.Tabs.Profile)">
            <tab-profile :medewerker="medewerker" />
          </div>
          <div v-show="istabactive(medewerker.Tabs.CV)">
            <tab-cv :medewerker="medewerker" />
          </div>
          <div v-show="istabactive(medewerker.Tabs.Media)">
            <tab-publications :medewerker="medewerker" pubtype="Media" />
          </div>
          <div v-show="istabactive(medewerker.Tabs.Prizes)">
            <tab-publications :medewerker="medewerker" pubtype="Prizes" />
          </div>
          <div v-show="istabactive(medewerker.Tabs.Publications)">
            <tab-publications :medewerker="medewerker" pubtype="Publications" />
          </div>
          <div v-show="istabactive(medewerker.Tabs.Activities)">
            <tab-publications :medewerker="medewerker" pubtype="Activities" />
          </div>
          <div v-show="istabactive(medewerker.Tabs.Research)">
            <tab-research :medewerker="medewerker" />
          </div>
          <div v-show="istabactive(medewerker.Tabs.Courses)">
            <tab-courses :medewerker="medewerker" />
          </div>
          <div v-show="istabactive(medewerker.Tabs.AdditionalFunctions)">
            <tab-additionalfunctions :medewerker="medewerker" />
          </div>
          <div v-show="istabactive(medewerker.Tabs.CustomTab1)">
            <tab-custom :content="medewerker.CustomTab1" />
          </div>
          <div v-show="istabactive(medewerker.Tabs.CustomTab2)">
            <tab-custom :content="medewerker.CustomTab2" />
          </div>
          <div v-show="istabactive(medewerker.Tabs.CustomTab3)">
            <tab-custom :content="medewerker.CustomTab3" />
          </div>
          <div v-show="istabactive(medewerker.Tabs.Contact)">
            <tab-contact :medewerker="medewerker" />
          </div>
        </div>
        <div class="col-md-1"></div>
      </div>
    </div>
  </div>
</template>

<script>
import tabheader from "./tab-header.vue";
import tabprofile from "./tab-profile.vue";
import tabpublications from "./tab-publications.vue";
import tabcv from "./tab-cv.vue";
import tabcontact from "./tab-contact.vue";
import tabadditionalfunctions from "./tab-additionalfunctions.vue";
import tabcourses from "./tab-courses.vue";
import tabresearch from "./tab-research.vue";
import tabcustom from "./tab-custom.vue";
import labellng from "@/components/layout/label-lng.vue";
import bstitle from "@/components/layout/title.vue";
import datehelper from "@/helpers/date-helper.vue";
import mobiledetect from "mobile-detect";

export default {
  name: "profielpagina",
  components: {
    "tab-header": tabheader,
    "tab-profile": tabprofile,
    "tab-publications": tabpublications,
    "tab-cv": tabcv,
    "tab-contact": tabcontact,
    "tab-additionalfunctions": tabadditionalfunctions,
    "tab-courses": tabcourses,
    "tab-research": tabresearch,
    "tab-custom": tabcustom,
    "label-lng": labellng,
    "bs-title": bstitle
  },
  data() {
    return {
      medewerker: null,
      activetab: ""
    };
  },
  head: {
    meta: function() {
      return [
        {
          name: "description",
          content: this.medewerker ? this.medewerker.Bio : "Medewerker UU",
          id: "desc"
        }
      ];
    },
    link: function() {
      return [
        {
          rel: "alternate",
          media: "only screen and (max-width: 750px)",
          href: this.getMobileUrl(),
          id: "link-mob"
        }
      ];
    }
  },
  methods: {
    // Load employee-data from webserivce
    loaddata: function() {
      var token = window.localStorage.getItem("token");
      if (!token) token = "";
      this.$Progress.start();
      this.$http
        .get(
          "Public/getEmployeeData?page=" +
            this.$route.params.employee +
            "&t=" +
            token +
            "&l=" +
            this.$root.lng +
            "&_=" +
            new Date().getTime()
        )
        .then(
          function(response) {
            this.$Progress.finish();
            if (response.data.State === "Unknown") {
              this.$router.push({
                name: "NotFound" + this.$root.lng,
                params: {}
              });
              return;
            }
            if (response.data.State === "Redirect") {
              this.$router.push({
                name: "Public" + this.$root.lng,
                params: { employee: response.data.RedirectTo }
              });
              return;
            }
            if (response.data.State === "Internal") {
              this.$router.push({ name: "forbidden", params: {} });
              return;
            }
            if (response.data.State === "FacultyRedirect") {
              // Redirect naar organogram
              this.$router.push({
                name: "organogram1" + this.$root.lng,
                params: { faculty: this.$route.params.employee }
              });
              return;
            }
            this.medewerker = response.data.Employee;

            // Redirect naar EN-variant als dit op faculteit-niveau is geconfigureerd
            if (this.medewerker.OnlyEN && this.$root.lng === "NL") {
              this.$router.push({
                name: "PublicEN",
                params: { employee: this.$route.params.employee }
              });
              // this.$root.lng = "EN";
              this.$parent.switchlng("EN");
              this.loaddata();
              return;
            }

            // Vertaal nummer naar tabnaam
            if (
              this.$route.params.tab &&
              /^[0-9]$/.test(this.$route.params.tab)
            ) {
              var t = this.tabbynr(parseInt(this.$route.params.tab));
              if (t) this.$router.push({ params: { tab: t.Title } });
            }

            // this.$root.HeaderFaculteitUrl = this.medewerker.Faculties[0].FacultyUrl;
            if (this.$route.params.tab) {
              if (this.tabexists(this.$route.params.tab)) {
                this.activetab = this.$route.params.tab;
              } else {
                this.activetab = this.firsttab().Title;
                this.$route.params.tab = "";
              }
            } else this.activetab = this.firsttab().Title;

            this.$root.lngSwitchOverride = !this.medewerker.OnlyEN;

            // Gegevens in header bijwerken (gaat niet automatisch)
            this.$emit("updateHead");
          },
          function() {
            // Onbekende medewerker => zoekscherm
            this.$Progress.finish();
            this.$router.push({ name: "Default", params: {} });
          }
        );
    },
    switchlng: function() {
      if (this.$root.lng === "EN") {
        this.$router.push({
          name: "PublicEN",
          params: { employee: this.$route.params.employee, tab: "" }
        });
        this.loaddata();
        return;
      }
      if (this.$root.lng === "NL") {
        this.$router.push({
          name: "PublicNL",
          params: { employee: this.$route.params.employee, tab: "" }
        });
        this.loaddata();
      }
    },
    istabactive: function(tab) {
      if (!tab.Visible) {
        return false;
      }
      if (!this.$route.params.tab) {
        return tab.Title === this.firsttab().Title;
      } else {
        return Boolean(
          this.$route.params.tab &&
            this.$route.params.tab.toLowerCase() === tab.Title.toLowerCase()
        );
      }
    },
    switchtab: function(tabname) {
      var routename = "PublicTabNL";
      if (this.$root.lng === "EN") routename = "PublicTabEN";
      this.$router.push({
        name: routename,
        params: { employee: this.$route.params.employee, tab: tabname }
      });
      this.activetab = tabname;
    },
    firsttab: function() {
      if (this.medewerker.Tabs.Profile.Visible) {
        return this.medewerker.Tabs.Profile;
      }
      if (this.medewerker.Tabs.CV.Visible) {
        return this.medewerker.Tabs.CV;
      }
      if (this.medewerker.Tabs.Publications.Media) {
        return this.medewerker.Tabs.Media;
      }
      if (this.medewerker.Tabs.Publications.Prizes) {
        return this.medewerker.Tabs.Prizes;
      }
      if (this.medewerker.Tabs.Publications.Visible) {
        return this.medewerker.Tabs.Publications;
      }
      if (this.medewerker.Tabs.Activities.Visible) {
        return this.medewerker.Tabs.Activities;
      }
      if (this.medewerker.Tabs.Research.Visible) {
        return this.medewerker.Tabs.Research;
      }
      if (this.medewerker.Tabs.Courses.Visible) {
        return this.medewerker.Tabs.Courses;
      }
      if (this.medewerker.Tabs.AdditionalFunctions.Visible) {
        return this.medewerker.Tabs.AdditionalFunctions;
      }
      if (this.medewerker.Tabs.CustomTab1.Visible) {
        return this.medewerker.Tabs.CustomTab1;
      }
      if (this.medewerker.Tabs.CustomTab2.Visible) {
        return this.medewerker.Tabs.CustomTab2;
      }
      if (this.medewerker.Tabs.CustomTab3.Visible) {
        return this.medewerker.Tabs.CustomTab3;
      }
      if (this.medewerker.Tabs.Contact.Visible) {
        return this.medewerker.Tabs.Contact;
      }
      return this.medewerker.Tabs.Profile;
    },
    tabbynr: function(nr) {
      var tabs = new Array();
      if (this.medewerker.Tabs.Profile.Visible) {
        tabs.push(this.medewerker.Tabs.Profile);
      }
      if (this.medewerker.Tabs.CV.Visible) {
        tabs.push(this.medewerker.Tabs.CV);
      }
      if (this.medewerker.Tabs.Media.Visible) {
        tabs.push(this.medewerker.Tabs.Media);
      }
      if (this.medewerker.Tabs.Prizes.Visible) {
        tabs.push(this.medewerker.Tabs.Prizes);
      }
      if (this.medewerker.Tabs.Publications.Visible) {
        tabs.push(this.medewerker.Tabs.Publications);
      }
      if (this.medewerker.Tabs.Activities.Visible) {
        tabs.push(this.medewerker.Tabs.Activities);
      }
      if (this.medewerker.Tabs.Research.Visible) {
        tabs.push(this.medewerker.Tabs.Research);
      }
      if (this.medewerker.Tabs.Courses.Visible) {
        tabs.push(this.medewerker.Tabs.Courses);
      }
      if (this.medewerker.Tabs.AdditionalFunctions.Visible) {
        tabs.push(this.medewerker.Tabs.AdditionalFunctions);
      }
      if (this.medewerker.Tabs.CustomTab1.Visible) {
        tabs.push(this.medewerker.Tabs.CustomTab1);
      }
      if (this.medewerker.Tabs.CustomTab2.Visible) {
        tabs.push(this.medewerker.Tabs.CustomTab2);
      }
      if (this.medewerker.Tabs.CustomTab3.Visible) {
        tabs.push(this.medewerker.Tabs.CustomTab3);
      }
      if (this.medewerker.Tabs.Contact.Visible) {
        tabs.push(this.medewerker.Tabs.Contact);
      }
      if (nr < tabs.length) {
        return tabs[nr];
      } else {
        return null;
      }
    },
    tabexists: function(tabtitle) {
      tabtitle = tabtitle.toLowerCase();
      if (
        this.medewerker.Tabs.Profile.Title.toLowerCase() === tabtitle &&
        this.medewerker.Tabs.Profile.Visible
      ) {
        return true;
      }
      if (
        this.medewerker.Tabs.CV.Title.toLowerCase() === tabtitle &&
        this.medewerker.Tabs.CV.Visible
      ) {
        return true;
      }
      if (
        this.medewerker.Tabs.Media.Title.toLowerCase() === tabtitle &&
        this.medewerker.Tabs.Media.Visible
      ) {
        return true;
      }
      if (
        this.medewerker.Tabs.Prizes.Title.toLowerCase() === tabtitle &&
        this.medewerker.Tabs.Prizes.Visible
      ) {
        return true;
      }
      if (
        this.medewerker.Tabs.Publications.Title.toLowerCase() === tabtitle &&
        this.medewerker.Tabs.Publications.Visible
      ) {
        return true;
      }
      if (
        this.medewerker.Tabs.Activities.Title.toLowerCase() === tabtitle &&
        this.medewerker.Tabs.Activities.Visible
      ) {
        return true;
      }
      if (
        this.medewerker.Tabs.Research.Title.toLowerCase() === tabtitle &&
        this.medewerker.Tabs.Research.Visible
      ) {
        return true;
      }
      if (
        this.medewerker.Tabs.Courses.Title.toLowerCase() === tabtitle &&
        this.medewerker.Tabs.Courses.Visible
      ) {
        return true;
      }
      if (
        this.medewerker.Tabs.AdditionalFunctions.Title.toLowerCase() ===
          tabtitle &&
        this.medewerker.Tabs.AdditionalFunctions.Visible
      ) {
        return true;
      }
      if (
        this.medewerker.Tabs.CustomTab1.Title.toLowerCase() === tabtitle &&
        this.medewerker.Tabs.CustomTab1.Visible
      ) {
        return true;
      }
      if (
        this.medewerker.Tabs.CustomTab2.Title.toLowerCase() === tabtitle &&
        this.medewerker.Tabs.CustomTab2.Visible
      ) {
        return true;
      }
      if (
        this.medewerker.Tabs.CustomTab3.Title.toLowerCase() === tabtitle &&
        this.medewerker.Tabs.CustomTab3.Visible
      ) {
        return true;
      }
      if (
        this.medewerker.Tabs.Contact.Title.toLowerCase() === tabtitle &&
        this.medewerker.Tabs.Contact.Visible
      ) {
        return true;
      }
      return false;
    },
    mobileDectectAndRedirect: function() {
      if (window.sessionStorage.getItem("mobile2desktop") == null) {
        // Niet naar mobiel switchen als mobile2desktop gezet is.
        var mobdect = new mobiledetect(window.navigator.userAgent);
        if (mobdect.mobile() != null && mobdect.tablet() == null) {
          // Mobile device (behalve tablets) gaan naar mobiele view
          if (this.$root.lng === "NL") {
            window.location = window.location
              .toString()
              .toLowerCase()
              .replace("/medewerkers", "/medewerkers/mobiel");
          } else {
            window.location = window.location
              .toString()
              .toLowerCase()
              .replace("/staff", "/staff/mobile");
          }
        }
      }
    },
    getMobileUrl: function() {
      if (this.$root.lng === "NL") {
        return (
          "https://www.uu.nl/medewerkers/mobiel/" + this.$route.params.employee
        );
      } else {
        return "https://www.uu.nl/staff/mobile/" + this.$route.params.employee;
      }
    }
  },
  created: function() {
    this.mobileDectectAndRedirect();
    this.loaddata();
    this.$root.$on("switchlng", this.switchlng);
  },
  mounted: function() {},
  watch: {
    "$route.params.employee": function() {
      this.loaddata(); // Zorgt voor reload als medewerker in url wordt aangepast, bv door quick-search.
    }
  },
  mixins: [datehelper]
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* Sidebar aan de linker kant */
.sidebar {
  height: 100%;
  padding-top: 60px;
  padding-bottom: 100px;
}

.sidebar-background {
  background-color: #efefef;
  padding-right: 0px;
}

/*
.sidebar-tabs {
}
*/

/* De menu-items (profiel, cv, etc) */
.sidebar-tabs a {
  color: #3b3b3b;
  padding-left: 0px;
  border-bottom: 1px solid #c3c1c1; /* grijze lijn onder ieder item */
}

.sidebar-tabs a:hover {
  color: #3b3b3b;
  text-decoration: underline;
}

.sidebar-tabs a:first-child {
  border-top: 3px solid #c3c1c1; /* Dikkere lijn boven bovenste menu-item */
}

/* link van geselecteerd tabje */
.actief {
  font-weight: bold;
}

.sidebar-block {
  margin-top: 30px;
  padding-right: 15px;
}

.sidebar-block > div:first-child {
  font-weight: bold;
}

/* Bullets in sidebar */
.sidebar ul {
  padding: 0;
  margin: 0px 0px 0px 16px;
}

/* Dit is de gele balk met naam van de medewerker */
.name-bar {
  background-color: #ffcd00;
  padding-top: 40px;
  padding-bottom: 0px;
  min-height: 200px;
  color: #000000;
}

/* Hele blok met profielfoto en teksten */
.maincontent {
  padding-left: 100px;
  padding-top: 60px;
  padding-right: 95px;
  padding-bottom: 35px;
}

.maincontent ul {
  list-style-type: disc;
  padding: 0 0 0 20px;
  list-style-position: outside;
}
</style>

<style>
.maincontent a {
  text-decoration: underline !important;
}
</style>

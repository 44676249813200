<script>
/*
    Sortering van items in een grid.
  */

export default {
  methods: {
    sortup: function (obj, collection) {
      var index = collection.indexOf(obj)
      if (index > 0) {
        // Indexen omwisselen
        var oi = collection[index].OrderIndex
        collection[index].OrderIndex = collection[index - 1].OrderIndex
        collection[index - 1].OrderIndex = oi
        // Omwisselen in array (Gebruik van splice zorgt ervoor dat vue ook daadwerkelijk update. Dat gebeurt niet bij wisselen van item rechtstreeks op de array)
        var o = collection[index]
        collection.splice(index, 1, collection[index - 1])
        collection.splice(index - 1, 1, o)
      }
    },
    sortdown: function (obj, collection) {
      var index = collection.indexOf(obj)
      if (index < collection.length - 1) {
        // Indexen omwisselen
        var oi = collection[index].OrderIndex
        collection[index].OrderIndex = collection[index + 1].OrderIndex
        collection[index + 1].OrderIndex = oi
        // Omwisselen in array (Gebruik van splice zorgt ervoor dat vue ook daadwerkelijk update. Dat gebeurt niet bij wisselen van item rechtstreeks op de array)
        var o = collection[index]
        collection.splice(index, 1, collection[index + 1])
        collection.splice(index + 1, 1, o)
      }
    }
  }
}
</script>
